import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class SearchDasFree extends Component {
	render() {
		
		const search = this.props.results;

		// console.log(search);

		return (
			<div className="container-fluid">
				<div className="row page-info mb-4">
					<div className="col-lg-12 text-center">
						<h1>My <span>Search</span> Result</h1>
						{/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus volutpat neque urna, a vestibulum tellus sollicitudin ut. Vivamus dictum, dolor eget placerat mollis, risus tortor ornare nisi, at interdum urna dui eget urna.</p> */}
					</div>
				</div>

				<div className="row">								
							{search?.jobs?.map((job, index) => {
								let startDate = job.start_date;
								let endDate = job.end_date;
								return (
									<div className="col-sm-12 mb-4" key={index}>
										<div className="post-card mb-xl-4">
											<div className="pc-status status-active">Accepted</div>
											<p><span className="d-none d-md-inline-block">Posted on : </span>{job.post_date}</p>
											<h2><Link onClick={this.props.modal} rel="nofollow" to={`/f/job/${job.job_id}`} dangerouslySetInnerHTML={{__html: job.title}} /></h2>
											<p dangerouslySetInnerHTML={{__html: job.desc}} />
											{job.location && <div className="pc-info d-none d-md-block"><i className="fas fa-map-pin"></i> {job.location && job.location.street_address}</div>}
											{job.location && <div className="pc-info d-block d-md-none"><i className="fas fa-map-marker-alt"></i> {job.location && job.location.street_address}</div>}
											<div className="pc-info"><i className="far fa-calendar-alt"></i> {startDate} - {endDate}</div>
											<div className="pc-info"><i className="fas fa-dollar-sign"></i> ${parseInt(job.pay.amount).toLocaleString()}</div>
											<div className="pc-info"><i className="fas fa-users"></i> {job.freelancer_booked} {job.freelancer_booked > 1 ? "Applicants" : "Applicant"}</div>
											<div className="pc-tags">
												{job.skills && job.skills.map((skill, index) => (
													<div className="pc-tag" key={index}>{skill.name}</div>
												))}
											</div>
										</div>
									</div>
								)
							})}

							{search?.jobs[0] ? null : (
								<div className="col-sm-12 mb-md-5 mb-4">
									<div className="blank-area text-center">
										<h3>No result Found</h3>
									</div>
								</div>
							)}
						
					</div>
			</div>
		);
	}
}

export default SearchDasFree;