import React, { useState } from 'react';

import { Button, Modal } from 'react-bootstrap';

const NeedHelp = () => {
    const [show, setShow] = useState(false);
  
    return (
      <>
        <Button onClick={() => setShow(true)} variant="" className=" form-field need-help d-flex align-items-center text-center justify-content-center">
          Need help with the event description?
        </Button>
  
        <Modal
          size="lg"
          show={show}
          centered={true}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w need-help-modal"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Need help with the event description
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
            To find the most suitable <strong>event freelancer</strong>, it is a good idea to provide not just the event details but also information about the type of person who would best suit the role. Include information such as the job purpose, the role and responsibilities, the tasks and any expectations you would have of the freelancer. This will help the freelancer make an informed decision about whether they are suitable for your job.
            </p>
            <h4>Here is an example:</h4>
            <p>We are looking for a <strong>site manager</strong> who is detailed oriented and can build relationships quickly with a diverse range of people. It is ideal if you have an outgoing personality with physical and mental health for what will be a hectic and demanding few days. Most of the work will be outdoors so you will be working in a range of weather conditions and your office will be in a portable site office. </p>
            <p>You will be responsible for the <strong>MDS</strong> and site schedule ensuring that the production team have the <strong>FF&E</strong> they need, where they need it, when they need it. <strong>Rostering</strong> and <strong>managing</strong> the site crew is also your responsibility, ensuring that site crew is on site and ready to start working in line with the production schedule.</p>
            <p>Our expectations are that you will turn up on time, be organised, make decisions when you need to and escalate when you need some help. Although we work really hard and this is a friendly environment where people help each other to get the job done.</p>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  export default NeedHelp;