import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
// import ReactTimeAgo from 'react-time-ago';
import { Dropdown, Alert } from 'react-bootstrap';

import { withRouter } from "react-router";
import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';

class Chat extends Component {
    chatContainer = React.createRef();

    constructor(props){
        super(props);

        this.state= {
            users: [],
            usersData: [],
            jobs: [],
            chatHistory: [],
            chatId : '',
            fields: {},
            errors: {},
            chatView: false,
            alert: false,
			userDetails:{},
			planStatus:1,
			loader: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
	}
	
	async componentDidMount(){
		
		// let Auth = '180';

		var myHeaders = new Headers();
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		try {
			let Auth = localStorage.getItem('auth_empplyOrgUID');
			// ?itemType=freelancer_user_detail_web&user_id=1572&eo_user_id=1571
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=event_orgniser_and_freelancer_history_web&user_id=${Auth}&freelancer_user_id=${this.props.match.params.id}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				//   console.log(result);
				this.setState({
				userDetails : result.user_detail,
				users: result.get_user_chatted_with?.user_list ?? [],
				usersData: result.get_user_chatted_with?.user_list ?? [],
				chatHistory: result.fetch_user_chat_history?.chat_history ?? [],
				loader: true,
				jobs: result.get_all_myjobs,
				planStatus : result.subscription_status
				})

				this.scrollToMyRef();    
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}

		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=freelancer_user_detail&user_id=${this.props.match.params.id}`, requestOptions)
		//   .then(response => response.json())
		//   .then(result => {
		// 	this.setState({
		// 	  userDetails : result.user_detail
		// 	})
		//   })
		//   .catch(error => console.log('error', error));
		
		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_user_chatted_with&from_user_id=${Auth}&to_user_id=${this.props.match.params.id}`, requestOptions)
		// .then(response => response.json())
		// .then(result => {
		// 	console.log(result);
		// 	this.setState({
		// 		users: result.user_list,
		// 		usersData: result.user_list
		// 	})
		// })
		// .catch(error => console.log('error', error));

        // await fetch(`${process.env.REACT_APP_APIURL}?endpoint=fetch_user_chat_history&from_user_id=${Auth}&to_user_id=${this.props.match.params.id}`, requestOptions)
        //     .then(response => response.json())
        //     .then(result => {    
		// 		// console.log(chatHistory.slice(-1)[0].message_id);            
		// 		// 
		// 		// console.log(result.chat_history);
        //         this.setState({
        //             chatHistory: result.chat_history,
		// 			loader: true
        //         })     
		// 		this.scrollToMyRef();       
        //     })
        //     .catch(error => console.log('error', error));

        // fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_all_myjobs&user_id=${Auth}`, requestOptions)
        // .then(response => response.json())
        // .then(result => {
        //     this.setState({
        //         jobs: result
        //     })
        // })
        // .catch(error => console.log('error', error));

		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=count_unread_by_user&from_user_id=${Auth}&to_user_id=${this.props.match.params.id}`, requestOptions)
		// 	.then(response => response.json())
		// 	.then(result => {
		// 		console.log(result)
		// 	})
		// 	.catch(error => console.log('error', error));

		this.setState({
            chatId: this.props.match.params.id
        })

		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${Auth}`, requestOptions)
		//   .then(response => response.json())
		//   .then(result => {
		// 	  this.setState({
		// 		  planStatus : result.data
		// 	  });
		//   })
		//   .catch(error => console.log('error', error));

        
	// }

    // componentDidUpdate () {
    //     this.scrollToMyRef();

		// var myHeaders = new Headers();		
		// var requestOptions = {
		// 	method: 'GET',
		// 	headers: myHeaders,
		// 	redirect: 'follow'
		// };

		// setTimeout(() => {
		// 	let message = this.state.chatHistory && this.state.chatHistory.slice(-1)[0] && this.state.chatHistory.slice(-1)[0].message_id;
		// 	fetch(`${process.env.REACT_APP_APIURL}?endpoint=update_read&message_id=${message}`, requestOptions)
		// 		.then(response => response.json())
		// 		.then(result => {
		// 			// console.log(result)
		// 		})
		// 		.catch(error => console.log('error', error));
		// }, 1000);
	}
	// scrollToBottom = () => {
	// 	this.messagesEnd.scrollIntoView({ behavior: "auto" });
	// }

    handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault();

		var myHeaders = new Headers();
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		let Auth = localStorage.getItem('auth_empplyOrgUID');
		if (this.validateForm()) {
			
			// setTimeout(() => {
				fetch(`${process.env.REACT_APP_APIURL}?endpoint=send_message&from_user_id=${Auth}&to_user_id=${this.state.chatId}&message=${this.state.fields.message}`, requestOptions)
				.then(response => response.json())
				.then(result => {
					this.setState({
						fields: {
							message: ''
						}
					})
					setTimeout(() => {
						if(result.status === 200){
							this.scrollToMyRef();
						} 
					}, 320);
					// this.scrollToMyRef();				
					// this.state.usersData && this.state.usersData.some(function (obj) {
					// 	obj.id !== this.props.match.params.id ? window.location.reload() : false
					// })
				})
				.catch(error => console.log('error', error));
			// }, 500);

            let intervalId =  setInterval(async () => {
				await fetch(`${process.env.REACT_APP_APIURL}?endpoint=fetch_user_chat_history&from_user_id=${Auth}&to_user_id=${this.state.chatId}`, requestOptions)
					.then(response => response.json())
					.then(result => {    
						this.setState({
							chatHistory: result.chat_history
						})      
						this.setState({ intervalId: intervalId })

						setTimeout(() => {
							if(this.state.chatHistory.length !== result.chat_history.length){
								this.scrollToMyRef();
							} 
						}, 200);
						
					})
					.catch(error => console.log('error', error));
			}, 500);

			
        }
    }

	componentWillUnmount(){
		clearInterval(this.state.intervalId)
	}

    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
    
        // Email Address error messages
        if (!fields["message"]) {
          formIsValid = false;
          errors["message"] = "*Please type your message.";
        }
    
    
        this.setState({
          errors: errors
        });
        return formIsValid;
	}

    chatToggle(){
        this.setState({
            chatView: !this.state.chatView
        });

		setTimeout(async () => {
			var myHeaders = new Headers();
		
			var requestOptions = {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			};
			let Auth = localStorage.getItem('auth_empplyOrgUID');
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=fetch_user_chat_history&from_user_id=${Auth}&to_user_id=${this.props.match.params.id}`, requestOptions)
				.then(response => response.json())
				.then(result => {                
					this.setState({
						chatHistory: result.chat_history
					})            
				})
				.catch(error => console.log('error', error));

			// let message = this.state.chatHistory && this.state.chatHistory.slice(-1)[0] && this.state.chatHistory.slice(-1)[0].message_id;
			// console.log(message);
			// console.log(this.state.chatHistory);
			// fetch(`${process.env.REACT_APP_APIURL}?endpoint=update_read&message_id=${message}`, requestOptions)
			// 	.then(response => response.json())
			// 	.then(result => {
			// 		console.log(result)
			// 	})
			// 	.catch(error => console.log('error', error));
		}, 200);
    }

    async addedFreelancer(id){
		// alert(id);
		var myHeaders = new Headers();
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		try {
			// 
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=select_candidate&job_id=${id}&freelancer_id=${this.props.match.params.id}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				this.setState({
					alert: true
				});
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}

	}

    handleSearch = async (e) => {
        e.preventDefault();

		// let Auth = "180";
		
		var myHeaders = new Headers();
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		try {
			// 
			let Auth = localStorage.getItem('auth_empplyOrgUID');
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=filter_chat_user&from_user_id=${Auth}&src=${this.state.fields.username}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					users: result.user_list
				})
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
    }

	// getItemInfo = () => {
	// 	alert('cdscds');
	// }
	
    scrollToMyRef = () => {
		const scroll =
		  this.chatContainer.current.scrollHeight -
		  this.chatContainer.current.clientHeight;
		this.chatContainer.current.scrollTo(0, scroll);
	  };

	render() {
        const userDetails = this.state.userDetails;
        const users = this.state.users;
        const usersData = this.state.usersData;
        const chatHistory = this.state.chatHistory;
        const AllJobs = this.state.jobs;
        let Auth = localStorage.getItem('auth_empplyOrgUID');
		const profileStatus = this.state.planStatus;
        // let Auth = '180';
        // console.log(AllJobs);

		if(this.state.chatView){
			return <Redirect push to="/e/chat" />;
		}

		if(profileStatus === 0){
			return(
				<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Event Organizer Chat" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-md-5 mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>conversations</span></h1>
								<p className="d-none d-md-block">Need to ask a freelancer a question?  Has a freelancer accepted your job offer?  Use the chat to connect to freelancers.</p>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 mb-md-5 mb-4">
								<div className="blank-area text-center">
									<h3>You have no <span>active plan</span>.</h3>
									<p><small>Please buy plan <Link rel="nofollow" className="text-primary" to="/e/membership">here</Link>!</small></p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			)
		}

		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Event Organizer Chat" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>conversations</span></h1>
								<p className="d-none d-md-block">Need to ask a freelancer a question?  Has a freelancer accepted your job offer?  Use the chat to connect to freelancers.</p>

                                {this.state.alert && <Alert variant="success" onClose={() => this.setState({alert: false})} dismissible>You have selected for this job</Alert>}
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12">
								<div className="chat-area mb-md-4 d-flex w-100">
									<div className="chat-list d-flex flex-column">
                                        <form onSubmit={this.handleSearch} className="input-group input-chat-search">
										<div className="input-group-prepend">
											<span className="input-group-text text-secondary pr-lg-2 pr-1 pl-ld-4 pl-3 border-0 bg-transparent" id="basic-addon1"><i className="fas fa-search"></i></span>
										</div>
										<input type="text" onChange={this.handleChange} name="username" className="form-control pl-2 border-0 bg-transparent" placeholder="Search here" />
										</form>

										<div className="chat-contact-list w-100 flex-grow-1 overflow-auto">
                                            {users && users.map((user, index) => (
												<a rel="nofollow" href={'/e/user/' + user.id} className={`chat-contact contact-online1 position-relative w-100 d-flex align-items-center ${user.id === parseInt(this.props.match.params.id) ? "active" : ""}`} key={index}>
													{user.unread_count > 0 ? (<span className="contact-active"></span>) : null}
													<span className="contact-auth">{user.first_name && user.first_name.slice(0, 1)}{user.last_name && user.last_name.slice(0, 1)}</span>
													<div className="contact-name">
														<strong className="text-truncate w-100">{user.userFullName}</strong>
														<span className="d-none d-md-block">{user.date}</span>
														<span className="d-block text-truncate w-100 d-md-none">{user.message}</span>
													</div>
													<div className="contact-date d-block d-md-none">
														{/* {user?.date && (
	                                                        <ReactTimeAgo date={user.date} timeStyle="twitter" locale="en-US"/>
														)} */}
														{user?.date}
														{user.unread_count > 0 ? (<span className="badge">{user.unread_count}</span>) : null}
													</div>
												</a>
											))}

                                            {users && users[0] ? null : (<div className="text-center">No result found for {this.state.fields.username}</div>)}
											
										</div>
									</div>
									<div className={`chat-section ${this.state.chatId ? 'active' : ''} w-100`}>
                                        	{/* {userDetails && userDetails} */}
											<div className="chat-header position-relative w-100 d-flex align-items-center">
                                                <button type="button" onClick={() => this.chatToggle()} className="btn d-block d-md-none chat-back p-0 mr-3 text-secondary"><i className="fas fa-arrow-left"></i></button>
                                                <span className="contact-auth">{userDetails.first_name && userDetails.first_name.slice(0, 1)}{userDetails.last_name && userDetails.last_name.slice(0, 1)}</span>
                                                <div className="contact-name">
                                                    <strong className="text-truncate w-100">{userDetails.userFullName}</strong>
													{usersData && usersData.filter(id => id.id === parseInt(this.props.match.params.id)).map((user, index) => (
                                                    	<span className="d-none d-md-block" key={index}>{user.date}</span>
													))}
                                                </div>
                                                {AllJobs.length > 0 && (
                                                    <Dropdown className="jobs-dropdown">
                                                        <Dropdown.Toggle variant="primary" className="" id="dropdown-basic">
                                                            SELECT FOR
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="dropdown-menu-right">
                                                            {AllJobs && AllJobs.filter(e => e.job_status === "1").map((job, index) => (
                                                                <button type="button" className="dropdown-item" onClick={() => this.addedFreelancer(job.job_id)} key={index} dangerouslySetInnerHTML={{__html: job.title}} />
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                )}
                                                {/* <Link to="/" className="btn btn-hm-primary py-0 btn-hired">HIRED NOW</Link>
                                                */}
                                            </div>

										<div className="chat-messages">
											<div className="chat-messages-inner" ref={this.chatContainer}>
												{/* <div className="chat-message chat-received flex-column d-flex">
													<div className="chat-text">
														<span className="contact-auth d-block d-md-none">eb</span>
														<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur placerat ligula vitae lorem semper molestie nec ac velit. Aliquam erat volutpat. Morbi volutpat non mauris a molestie.</p>
													</div>
													<div className="chat-time">
														3:00 PM
													</div>
												</div>
												<div className="chat-message chat-sent flex-column d-flex">
													<div className="chat-text">
														<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur placerat ligula vitae lorem semper molestie nec ac velit. Aliquam erat volutpat. Morbi volutpat non mauris a molestie.</p>
													</div>
													<div className="chat-time">
														3:00 PM
													</div>
												</div> */}

                                                {chatHistory?.map((chat, index) => (
                                                    <div className={`chat-message ${chat?.from_user_id.toString() !== Auth.toString() ? 'chat-received' : 'chat-sent' } flex-column d-flex`} key={index}>

														{/* {chat.is_read === 1 ? this.getItemInfo.call(this, chat.message_id) : null} */}
														{chat.message && (
															<div className="chat-text">
																<span className="contact-auth d-block d-md-none">{chat.first_name && chat.first_name.slice(0, 1)}{chat.last_name && chat.last_name.slice(0, 1)}</span>
																<p dangerouslySetInnerHTML={{__html: chat.message}} />
															</div>
														)}
														{chat?.created_at && (
															<div className="chat-time">
																	{/* <ReactTimeAgo date={chat.created_at} timeStyle="twitter" locale="en-US"/> */}
																{/* created_at */}
																{chat.created_at}
															</div>
														)}
                                                    </div>
                                                ))}

												{/* <div ref={(el) => { this.messagesEnd = el; }} /> */}
												
											</div>

											<div className="chat-type">
												<form onSubmit={this.handleSubmit} className="d-flex align-items-center">
													<input type="text" onChange={this.handleChange} name="message" value={this.state.fields.message} placeholder="Type something..." className="form-control border-0" />
													<button type="submit" className="btn"><i className="fas fa-paper-plane"></i></button>
												</form>
                                                <div className={`invalid-feedback text-left ${!this.state.fields.message ? (this.state.errors.message ? 'd-block' : null) : null }`}>{this.state.errors.message}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		);
	}
}

export default withRouter(Chat);