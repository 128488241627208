import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import GooglePlaceholder from "../snippet/GoogleSearchLocator";

import fewMoreDetails from '../../assets/images/few-more-details.png';
import fewDetailsMob from '../../assets/images/few-details-mob.png';

import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';
import { withRouter } from "react-router";


class FewMoreDetails extends Component {
	constructor(props){
        super(props);

        this.state= {
            phone: '',
            phoneError: '',
            fields: {},
            errors: {},
            location: {},
			LocationError: '',
            redirect: false,
			pageContent: {},
			formLoader: false,
            loader: false
        }

		this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.passAddress = this.passAddress.bind(this);
	}
	
	handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
	}

	handleSubmit = async (e) => {
        e.preventDefault();
		
        if (this.validateForm()  && this.state.location.lat) {
			this.setState({
				formLoader: true
			})

			// let location_term00 = this.state.location.location && this.state.location.location.value.terms[0] ? this.state.location.location.value.terms[0].value : "";
			// let location_term01 = this.state.location.location && this.state.location.location.value.terms[1] ? this.state.location.location.value.terms[1].value : "";
			// let location_term02 = this.state.location.location && this.state.location.location.value.terms[2] ? this.state.location.location.value.terms[2].value : "";

			let location_term00 = this.state.location.location_address[1] ? this.state.location.location_address[1].long_name : "";
			let location_term01 = this.state.location.location_address[2] ? this.state.location.location_address[2].long_name : "";
			let location_term02 = this.state.location.location_address[3] ? this.state.location.location_address[3].long_name : "";

            var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
			myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
			try {
			var urlencoded = new URLSearchParams();
			urlencoded.append("endpoint", "supplier_update");
			urlencoded.append("latitude", this.state.location.lat);
			urlencoded.append("longitude", this.state.location.long);
			// urlencoded.append("street_address", location_term01 ? location_term01 +", " + location_term00 : location_term00);
			urlencoded.append("street_address", this.state.location.location);
			urlencoded.append("suburb", location_term00);
			urlencoded.append("state", location_term01);
			urlencoded.append("country", location_term02);
			urlencoded.append("user_id", this.props.location.state);
			urlencoded.append("abn", this.state.fields.abn ? this.state.fields.abn : '');
			urlencoded.append("phone", this.state.phone);
			urlencoded.append("company", this.state.fields.company);

			var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: urlencoded,
			redirect: 'follow'
			};

			await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
			.then(response => response.json())
			.then(result => {
				this.setState({
					redirect: true,
					formLoader: false
				})
			})
			.catch(error => console.log('error', error));
        } catch (error) {
			console.log('error', error);
		}
        } //this.validateForm
	}
	
	validateForm() {
		let phoneError = this.state.phoneError;
		let LocationError = this.state.LocationError;
		let fields = this.state.fields;
		let formIsValid = true;
		let errors = {};
	
		// ABN error messages
		// if (!fields["abn"]) {
		// 	formIsValid = false;
		// 	errors["abn"] = "*Please enter ABN Number.";
		// }

		// Company error messages
		if (!fields["company"]) {
			formIsValid = false;
			errors["company"] = "*Please enter Company Name.";
		}

		if (!this.state.phone) {
			formIsValid = false;
			phoneError = "Please enter your phone number.";
		}

		if (!this.state.location.lat) {
			formIsValid = false;
			LocationError = "Please enter your location.";
		} 
	
	
		this.setState({
		  errors: errors,
		  phoneError,
		  LocationError
		});
		return formIsValid;
	}

	passAddress = (value) =>{
		// console.log(value);
		if(value.lat){
		  this.setState({
			location : value
		  })
		}
	}

	async componentDidMount(){
		var myHeaders = new Headers();
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		try {
			// 
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=Few More Details`, requestOptions)
			.then(response => response.json())
			.then(result => {
				this.setState({
					pageContent: result,
					loader : true
				})
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
	  }

	render() {
		// console.log(this.props.location.state)
		if (this.state.redirect) {
			localStorage.setItem('auth_empplySplierUID', this.props.location.state);
			return <Redirect push to="/s/membership" />;
		}

		return (
      <div>
		<Loader loader={this.state.loader} />
		<SEOPage pageTitle="Few More Details" />
        <main className="page-access">
          <div
            className="page-image"
            style={{ backgroundImage: `url(${this.state.pageContent && this.state.pageContent.background_image ? this.state.pageContent.background_image : fewMoreDetails})` }}
          >
            <img
              src={this.state.pageContent && this.state.pageContent.background_image ? this.state.pageContent.background_image : fewDetailsMob}
              className="w-100 d-block d-md-none"
              alt="imag"
            />
          </div>
          <div className="container p-0 container-xxl">
            <div className="row no-gutters">
              <div className="col-lg-6">
				<div className="page-content-inner px-4">
					<h1 className="mw-100">
					Just a few more details for us to get to <span>know you better</span>
					</h1>

					<div className="form-area pt-md-3 pt-2">
					<form onSubmit={this.handleSubmit}>
						<div className="form-row">
						<div className="form-group col-12">
							<div className="form-field">
							<label>Phone number</label>
							<PhoneInput
								inputExtraProps={{
									required: true,
									autoFocus: true
								}}
								defaultCountry={"au"}
								enableAreaCodeStretch
								enableAreaCodes={true}
								value={this.state.phone}
								dropdownClass="form-shadow form-radius border-0"
								inputClass="form-shadow form-radius border-0"
								onChange={(phone) => this.setState({ phone })}
							/>
							</div>
							<div
								className={`invalid-feedback text-right ${
									!this.state.phone
									? this.state.phoneError
										? "d-block"
										: null
									: null
								}`}
								>
								{this.state.phoneError}
								</div>
						</div>
						<div className="form-group col-12">
							<div className="form-field">
							<label htmlFor="abn">ABN</label>
							<input
								id="abn"
								type="text"
								className="form-control"
								name="abn"
								maxLength="11"
								minLength="11"
								pattern="[0-9]+"
								onChange={this.handleChange}
								placeholder="123-345-3455"
							/>
							</div>
							<div
								className={`invalid-feedback text-right ${
									!this.state.fields.abn
									? this.state.errors.abn
										? "d-block"
										: null
									: null
								}`}
								>
								{this.state.errors.abn}
								</div>
						</div>
						<div className="form-group col-12">
							<div className="form-field">
							<label htmlFor="abn">Company name</label>
							<input
								id="company"
								type="text"
								className="form-control"
								name="company"
								onChange={this.handleChange}
								placeholder="Enter your company name"
							/>
							</div>
							<div
								className={`invalid-feedback text-right ${
									!this.state.fields.company
									? this.state.errors.company
										? "d-block"
										: null
									: null
								}`}
								>
								{this.state.errors.company}
								</div>
						</div>
						<div className="form-group col-12">
							<div className="form-field">
							<label>Location</label>
							<GooglePlaceholder
								getAddress={(e) => this.passAddress(e)}
							/>
							</div>
							<div
							className={`invalid-feedback text-right ${
								!this.state.location.lat
								? this.state.LocationError
									? "d-block"
									: null
								: null
							}`}
							>
							{this.state.LocationError}
							</div>
						</div>
						<div className="form-group col-12">
							<button className="btn btn-hm-primary btn-block btn-lg mb-3  px-4 d-flex align-items-center" disabled={this.state.formLoader ? true : false}>{this.state.formLoader ? 'Sending...' : 'Continue'}{" "}
							<i className="fas ml-auto fa-arrow-right"></i>
							</button>
						</div>
						</div>
					</form>
					</div>
					</div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
	}
}

export default withRouter(FewMoreDetails);