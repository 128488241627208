import React from 'react';
import HorizontalScroll from 'react-scroll-horizontal';

const HorizontalScrollCom = (props) => (
  <HorizontalScroll 
    className={props.className}
    reverseScroll={true}
    >
    {props.children}
  </HorizontalScroll>
);

export default HorizontalScrollCom;


// const HorizontalScrollCom = (props) => {
//   // console.log(props);
//   if(props.children.length > 2){
//     return(
//       <HorizontalScroll 
//         className={props.className}
//         reverseScroll={true}
//         >
//         {props.children}
//       </HorizontalScroll>
//     ) 
//   }else{
//     if(!props.children.length){
//       return(
//         <div className="blank-area text-center">
//           <h3>No result Found</h3>
//         </div>
//       ) 
//     }
//     return props.children;
//   }
// };

// export default HorizontalScrollCom;