import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import dots from '../../assets/images/dots.png';
import userIcon from '../../assets/images/user-icon.png';
import monthly from '../../assets/images/monthly.png';
import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';

// import Layout from '../../global/PrimaryLayoutFreelancer';

class MembershipPlan extends Component {
	constructor(props){
        super(props);

        this.state= {
			planStatus:{},
			loader: false
        }
	}

	async componentDidMount(){
		// console.log(id);
		var myHeaders = new Headers();
		// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");

		var requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
		};

		try {
			let AuthFree = localStorage.getItem('auth_empplyFreeUID');
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${AuthFree}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				this.setState({
					planStatus : result.data,
					loader: true
				});
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
	}

	render() {
		const status = this.state.planStatus;
		//console.log(status);
		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer Membership" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-100">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>plan</span></h1>
								<p>Welcome to you FREE EventMploy plan. Update your profile and let event organisers find you, saving you time and worry, making finding your next gig easy.</p>
								{/*  Membership also gives you access to EventMploy Suppliers. */}
								{/* <p><span className="text-primary">Sign up today</span> and get the first month for free. When first month free trial ends payment subscription starts</p> */}
							</div>
						</div>

						<div className="row flex-md-nowrap overflow-auto">
						<div className="col-md-5 col-lg-4 col-xl-auto mr-xl-3 col-sm-6 mb-4">
								<div className="member-card">
									<img src={dots} className="card-dots" alt="dots" />
									<div className="member-plan"><img src={monthly} alt="icon" /></div>
									<h2>Free Subscription</h2>
									<p>EventMploy will provide you with unlimited job offers for free.</p>

									<div className="member-price">
										<strong>$0.00</strong>
										Life time
									</div>

									<Link  rel="nofollow" to={{
											pathname: "/f/payment",
											state: "price_1ILOpTINkYRCPskeuf4GazGa"
										}} className="btn disabled btn-block rounded-4 btn-md btn-hm-primary">Free Plan</Link>

									{/* {status.subscription_status ? (
										status.current_plan_id === "price_1ILOpTINkYRCPskeuf4GazGa" ? (<button className="btn btn-block rounded-4 btn-md btn-hm-primary" disabled>Current Plan</button>) : (
											<Link to={{
												pathname: "/f/payment",
												state: "price_1ILOpTINkYRCPskeuf4GazGa"
											}} disabled={true} className="btn btn-block rounded-4 disabled btn-md btn-hm-primary">Downgrade Plan</Link>
										)
									): (
										<Link to={{
											pathname: "/f/payment",
											state: "price_1ILOpTINkYRCPskeuf4GazGa"
										}} disabled={true} className="btn btn-block rounded-4 disabled btn-md btn-hm-primary">BUY PLAN</Link>
									)} */}
								</div>
							</div>
							{/* <!-- /.col-md-4 col-sm-6 --> */}
							<div className="col-md-5 d-none col-lg-4 col-xl-auto mr-xl-3 col-sm-6 mb-4">
								<div className="member-card member-card-gray">
									<img src={dots} className="card-dots" alt="dots" />
									<div className="member-plan"><img src={monthly} alt="icon" /></div>
									<h2>Monthly Subscription</h2>
									<p>EventMploy will provide you with unlimited job offers for one month.</p>

									<div className="member-price">
										<strong>${status.monthly_price ? status.monthly_price : '9.99'}</strong>
										Per month
									</div>

									{status.subscription_status ? (
										status.current_plan_id === "price_1ILOpTINkYRCPskeuf4GazGa" ? (<button className="btn btn-block rounded-4 btn-md btn-hm-primary" disabled>Current Plan</button>) : (
											<Link  rel="nofollow" to={{
												pathname: "/f/payment",
												state: "price_1ILOpTINkYRCPskeuf4GazGa"
											}} disabled={true} className="btn btn-block rounded-4 disabled btn-md btn-hm-primary">Downgrade Plan</Link>
										)
									): (
										<Link  rel="nofollow" to={{
											pathname: "/f/payment",
											state: "price_1ILOpTINkYRCPskeuf4GazGa"
										}} disabled={true} className="btn btn-block rounded-4 disabled btn-md btn-hm-primary">BUY PLAN</Link>
									)}
								</div>
							</div>
							{/* <!-- /.col-md-4 col-sm-6 --> */}
							<div className="col-md-5 col-lg-4 d-none col-xl-auto mr-xl-3 col-sm-6 mb-4">
								<div className="member-card member-card-gray">
									<img src={dots} className="card-dots" alt="dots" />
									<div className="member-plan"><img src={userIcon} alt="icon" /></div>
									<h2>Annual Subscription</h2>
									<p>EventMploy will provide you with unlimited job offers for twelve months.</p>

									<div className="member-price">
										<strong>${status.yearly_price ? status.yearly_price : '99.99'}</strong>
										Per year
									</div>

									{/* <Link to={{
										pathname: "/f/payment",
										state: "price_1ILOpTINkYRCPskeuf4GazGa"
									}}><button className="btn btn-block rounded-4 btn-md btn-hm-primary">BUY PLAN</button></Link> */}

									{status.subscription_status ? (
										status.current_plan_id === "price_1ILOoqINkYRCPske86AALs1I" ? (<button className="btn btn-block rounded-4 btn-md btn-gray" disabled>Current Plan</button>) : (
											<Link  rel="nofollow" to={{
												pathname: "/f/payment",
												state: "price_1ILOoqINkYRCPske86AALs1I"
											}} disabled={true} className="btn btn-block rounded-4 disabled btn-md btn-hm-primary">Upgrade Plan</Link>
										)
									): (
										<Link  rel="nofollow" to={{
											pathname: "/f/payment",
											state: "price_1ILOoqINkYRCPske86AALs1I"
										}} disabled={true} className="btn btn-block rounded-4 disabled btn-md btn-hm-primary">BUY PLAN</Link>
									)}
								</div>
							</div>
							{/* <!-- /.col-md-4 col-sm-6 --> */}
						</div>
					</div>
				</main>
			</div>
		);
	}
}

export default MembershipPlan;