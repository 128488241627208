import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SearchDash extends Component {
    // formatDate = (date) => {
    //     var d = new Date(date),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         year = d.getFullYear();
    
    //     if (month.length < 2) 
    //         month = '0' + month;
    //     if (day.length < 2) 
    //         day = '0' + day;
    
	// 	return [day, month, year].join('/');
    // }
	render() {
		
		const search = this.props.results;

		// console.log(search);

		return (
            <div className="container-fluid">
                <div className="row page-info mb-4">
                    <div className="col-lg-12 text-center">
                        <h1>My <span>Search</span> Result</h1>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus volutpat neque urna, a vestibulum tellus sollicitudin ut. Vivamus dictum, dolor eget placerat mollis, risus tortor ornare nisi, at interdum urna dui eget urna.</p> */}
                    </div>
                </div>

                <div className="row">
                    
                        {search?.jobs?.map((job, index) => {
                            let startDate = job.start_date;
                            let endDate = job.end_date;
                            return (
                                <div className="col-sm-12 mb-4" key={index}>
                                    <div className="post-card mb-xl-4">
                                        {/* {(() => {
                                            if(job.job_status === "1"){
                                                return <div className="pc-status status-active">Active</div>
                                            }else if(job.job_status === "2"){
                                                return <div className="pc-status status-closed">Closed</div>
                                            }else if(job.job_status === "3"){
                                                return <div className="pc-status status-closed">Cancelled</div>
                                            }else{
                                                return <div className="pc-status status-closed">Non - Active</div>
                                            }
                                        })()} */}
                                        <p><span className="d-none d-md-inline-block">Posted on : </span>{job.post_date}<br />
                                        <span className="d-inline-block">Status : </span>
                                        {(() => {
                                            if(job.job_status === "1"){
                                                return <div className="pc-status status-active">Active</div>
                                            }else if(job.job_status === "2"){
                                                return <div className="pc-status status-closed">Closed</div>
                                            }else if(job.job_status === "3"){
                                                return <div className="pc-status status-closed">Cancelled</div>
                                            }else{
                                                return <div className="pc-status status-closed">Non - Active</div>
                                            }
                                        })()}
                                        </p>
                                        <h2><Link  onClick={this.props.modal} rel="nofollow" to={`/e/job/${job.job_id}`} dangerouslySetInnerHTML={{__html: job.title}} /></h2>
                                        <p dangerouslySetInnerHTML={{__html: job.desc}} />
                                        {job.location && <div className="pc-info d-none d-md-block"><i className="fas fa-map-pin"></i> {job.location && job.location.street_address}</div>}
                                        {job.location && <div className="pc-info d-block d-md-none"><i className="fas fa-map-marker-alt"></i> {job.location && job.location.street_address}</div>}
                                        <div className="pc-info"><i className="far fa-calendar-alt"></i> {startDate} - {endDate}</div>
                                        <div className="pc-info"><i className="fas fa-dollar-sign"></i> ${parseInt(job.pay.amount).toLocaleString()}</div>
                                        <div className="pc-info"><i className="fas fa-users"></i> {job.freelancer_booked} {job.freelancer_booked > 1 ? "Selecteded" : "Selecteded"}</div>
                                        <div className="pc-tags">
                                            {job.skills && job.skills.map((skill, index) => (
                                                <div className="pc-tag" key={index}>{skill.name}</div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}


                        {search?.users?.map((free, index) => (
                            <div className="col-sm-12 mb-md-3 mb-2" key={index}>
                                <div className="search-item p-md-3 p-2">
                                    <div className="search-heading align-items-center d-flex">
                                        <Link  onClick={this.props.modal} to={"/e/freelancer/" + free?.user_id} className="ur_short_name">{free?.firstname?.slice(0, 1)}{free?.lastname?.slice(0, 1)}</Link>
                                        <div className="ml-lg-4 ml-3">
                                            <h3 className="mb-1"><Link  onClick={this.props.modal} to={"/e/freelancer/" + free?.user_id}>{free?.firstname} {free?.lastname?.slice(0, 1)}.</Link></h3>
                                            {free?.location?.street_address && (
                                                <p className="m-0"><i className="fas fa-map-pin mr-1"></i> {free?.location?.street_address}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {search?.jobs[0] || search?.users[0] ? null : (
                            <div className="col-sm-12 mb-md-5 mb-4">
                                <div className="blank-area text-center">
                                    <h3>No result Found</h3>
                                </div>
                            </div>
                        )}
                    
                </div>
            </div>
		);
	}
}

export default SearchDash;