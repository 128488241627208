import React from 'react'

export default function CalenderAvailabilityitem(props) {
    const [toggle, setToggle] = React.useState(false);
    const [startVal, setStartVal] = React.useState(props.start);
    const [endVal, setEndVal] = React.useState(props.end);

    React.useEffect(() => {
        setToggle(props.avail);
        setStartVal(props.start);
        setEndVal(props.end);
    }, [props.avail, props.start, props.end])

    async function handle(){
        setToggle(!toggle);
        
        setStartVal('');
        setEndVal('');
        props.getStart('');
        props.getEnd('');


        let Auth = localStorage.getItem('auth_empplyFreeUID');

        var formdata = new FormData();
        formdata.append("endpoint", "set_availability");
        formdata.append("user_id", Auth);
        formdata.append("type", "date");
        formdata.append("availbility[0][day]",props.date ? props.date : '');
        formdata.append("availbility[0][start]",'');
        formdata.append("availbility[0][end]",'');

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        // console.log(
        //     this.props.date, this.state.weekDay[0].start, this.state.weekDay[0].end
        // )
        try{
            await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                window.location.reload();
            })
            .catch(error => console.log('error', error));
        } catch (error) {
            console.log('error', error);
        }
    }

    function startField(e){
        setStartVal(e.target.value);
        props.getStart(e.target.value);
    }

    function endField(e){
        setEndVal(e.target.value);
        props.getEnd(e.target.value);
    }

    return (
        <div className="mb-2 d-flex align-items-center">
            <div onClick={handle} className={`availability-dot ${toggle ? 'active' : ''}`}></div>
            <div className="availability-day">{props.weekDay}</div>
            <div className="availability-middle d-flex align-items-center justify-content-center">
                {toggle ? (
                    <>
                        <select value={startVal} onChange={(e) => startField(e)} name="timeStart">
                            <option value=""></option>
                            <option value="00:00">12.00 AM</option>
                            <option value="00:30">12.30 AM</option>
                            <option value="01:00">01.00 AM</option>
                            <option value="01:30">01.30 AM</option>
                            <option value="02:00">02.00 AM</option>
                            <option value="02:30">02.30 AM</option>
                            <option value="03:00">03.00 AM</option>
                            <option value="03:30">03.30 AM</option>
                            <option value="04:00">04.00 AM</option>
                            <option value="04:30">04.30 AM</option>
                            <option value="05:00">05.00 AM</option>
                            <option value="05:30">05.30 AM</option>
                            <option value="06:00">06.00 AM</option>
                            <option value="06:30">06.30 AM</option>
                            <option value="07:00">07.00 AM</option>
                            <option value="07:30">07.30 AM</option>
                            <option value="08:00">08.00 AM</option>
                            <option value="08:30">08.30 AM</option>
                            <option value="09:00">09.00 AM</option>
                            <option value="09:30">09.30 AM</option>
                            <option value="10:00">10.00 AM</option>
                            <option value="10:30">10.30 AM</option>
                            <option value="11:00">11.00 AM</option>
                            <option value="11:30">11.30 AM</option>
                            <option value="12:00">12.00 PM</option>
                            <option value="12:30">12.30 PM</option>
                            <option value="13:00">01.00 PM</option>
                            <option value="13:30">01.30 PM</option>
                            <option value="14:00">02.00 PM</option>
                            <option value="14:30">02.30 PM</option>
                            <option value="15:00">03.00 PM</option>
                            <option value="15:30">03.30 PM</option>
                            <option value="16:00">04.00 PM</option>
                            <option value="16:30">04.30 PM</option>
                            <option value="17:00">05.00 PM</option>
                            <option value="17:30">05.30 PM</option>
                            <option value="18:00">06.00 PM</option>
                            <option value="18:30">06.30 PM</option>
                            <option value="19:00">07.00 PM</option>
                            <option value="19:30">07.30 PM</option>
                            <option value="20:00">08.00 PM</option>
                            <option value="20:30">08.30 PM</option>
                            <option value="21:00">09.00 PM</option>
                            <option value="21:30">09.30 PM</option>
                            <option value="22:00">10.00 PM</option>
                            <option value="22:30">10.30 PM</option>
                            <option value="23:00">11.00 PM</option>
                            <option value="23:30">11.30 PM</option>
                        </select> <i className="fas fa-minus mx-2"></i> 
                        <select value={endVal} onChange={(e) => endField(e)} name="timeEnd">
                            <option value=""></option>
                            <option value="00:00">12.00 AM</option>
                            <option value="00:30">12.30 AM</option>
                            <option value="01:00">01.00 AM</option>
                            <option value="01:30">01.30 AM</option>
                            <option value="02:00">02.00 AM</option>
                            <option value="02:30">02.30 AM</option>
                            <option value="03:00">03.00 AM</option>
                            <option value="03:30">03.30 AM</option>
                            <option value="04:00">04.00 AM</option>
                            <option value="04:30">04.30 AM</option>
                            <option value="05:00">05.00 AM</option>
                            <option value="05:30">05.30 AM</option>
                            <option value="06:00">06.00 AM</option>
                            <option value="06:30">06.30 AM</option>
                            <option value="07:00">07.00 AM</option>
                            <option value="07:30">07.30 AM</option>
                            <option value="08:00">08.00 AM</option>
                            <option value="08:30">08.30 AM</option>
                            <option value="09:00">09.00 AM</option>
                            <option value="09:30">09.30 AM</option>
                            <option value="10:00">10.00 AM</option>
                            <option value="10:30">10.30 AM</option>
                            <option value="11:00">11.00 AM</option>
                            <option value="11:30">11.30 AM</option>
                            <option value="12:00">12.00 PM</option>
                            <option value="12:30">12.30 PM</option>
                            <option value="13:00">01.00 PM</option>
                            <option value="13:30">01.30 PM</option>
                            <option value="14:00">02.00 PM</option>
                            <option value="14:30">02.30 PM</option>
                            <option value="15:00">03.00 PM</option>
                            <option value="15:30">03.30 PM</option>
                            <option value="16:00">04.00 PM</option>
                            <option value="16:30">04.30 PM</option>
                            <option value="17:00">05.00 PM</option>
                            <option value="17:30">05.30 PM</option>
                            <option value="18:00">06.00 PM</option>
                            <option value="18:30">06.30 PM</option>
                            <option value="19:00">07.00 PM</option>
                            <option value="19:30">07.30 PM</option>
                            <option value="20:00">08.00 PM</option>
                            <option value="20:30">08.30 PM</option>
                            <option value="21:00">09.00 PM</option>
                            <option value="21:30">09.30 PM</option>
                            <option value="22:00">10.00 PM</option>
                            <option value="22:30">10.30 PM</option>
                            <option value="23:00">11.00 PM</option>
                            <option value="23:30">11.30 PM</option>
                        </select>
                    </>
                ) : <span>Unavailable</span>}                
            </div>
            <button onClick={handle} className="availability-btn p-1 btn btn-reset"><i className={`fas fa-${toggle ? 'minus' : 'plus'}`}></i></button>
        </div>
    )
}
