import { NavLink } from 'react-bootstrap';
// import ImageBg from '../../../assets/images/home-signup-bg.webp';

export default function HomeSignup({data}){
    return(
        <div className="section-home-signup text-center" style={{backgroundImage: `url(${data?.image})`}}>
            <div className="container container-xl">
                <h2>{data?.title}</h2>
                <p>{data?.description}</p>
                <NavLink href="/get-started" className='btn d-inline-block btn-hm btn-hm-primary'>Sign Up</NavLink>
            </div>
        </div>
    )
}