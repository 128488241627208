import React, { useState } from 'react'

export default function MyFav({added, getFav}) {
    const [loader, setLoader] = useState(false);
    const handleFav = () => {
        setLoader(!loader);
        getFav();
    };
  return (
    <span onClick={() => handleFav()} className="fav-heart"><i className={`fas ${added ? 'active' : ''} fa-${loader ? 'spinner' : 'heart'}`}></i></span>
  )
}
