import { Tab, Tabs } from "react-bootstrap";
import PlayIcon from "../../../assets/images/play-icon.svg";
// import { useEffect, useState } from "react";
import {
    Link
  } from "react-router-dom";

export default function HomePosts({data}){
    // console.log(data);
    return(
        <div className="pt-100 pb-100 section-upcoming">
            <div className="container">
                <div className="section-home-title text-center">
                    <h2>Blogs & Webinars</h2>
                </div>

                <Tabs defaultActiveKey="webinars" id="uncontrolled-tab-example">
                    <Tab eventKey="webinars" title="Webinars">
                        <CardListWebinar data={data[1] ?? []} />
                    </Tab>
                    <Tab eventKey="blogs" title="Blogs">
                        <CardListBlog data={data[0] ?? []} />
                    </Tab>
                </Tabs>

                <div className="text-center">
                    <Link to={'/resources'} className="btn btn-hm btn-hm-primary">View All</Link>
                </div>
            </div>
        </div>
    )
}

const CardListWebinar = ({data}) => {
    return(
        data?.length > 0 && (
            <div className="row justify-content-center mb-lg-5 mb-3">
                {data?.map((item, index) => (
                    <div className="col-lg-4 col-md-6 mb-lg-0 mb-4" key={index}>
                        <CardItemWebinar data={item ?? {}} />
                    </div>
                ))}
            </div>
        )
    )
}

const CardListBlog = ({data}) => {
    return(
        data?.length > 0 && (
            <div className="row justify-content-center mb-lg-5 mb-3">
                {data?.map((item, index) => (
                    <div className="col-lg-4 col-md-6 mb-lg-0 mb-4" key={index}>
                        <CardItemBlog data={item ?? {}} />
                    </div>
                ))}
            </div>
        )
    )
}

const CardItemWebinar = ({data}) => {
    return(
        <a rel="noreferrer" href={data?.youtube} target="_blank" className="section-webinar-card">
            <div className="section-webinar-card-image">
                {data?.featured_image && <img src={data?.featured_image} width={480} height={356} alt={data.featured_image_alt_text} className="img-fluid"/>}
            </div>
            <div className="section-webinar-card-content">
                <h2 dangerouslySetInnerHTML={{__html: data.name}} />
                <div className="d-flex align-items-center justify-content-between">
                    <span>Info Session Ended</span>
                    <span>{data?.time}</span>
                </div>
                <div className="d-flex align-items-center">
                    <span>View recording</span>
                    <img src={PlayIcon} width={48} height={48} alt="Play" />
                </div>
            </div>
        </a>
    )
}

const CardItemBlog = ({data}) => {
    return(
        <Link to={'/post/' + data.slug} className="section-webinar-card">
            <div className="section-webinar-card-image">
                {data?.featured_image && <img src={data?.featured_image} width={370} height={240} alt={data.featured_image_alt_text} className="img-fluid"/>}
            </div>
            <div className="section-webinar-card-content">
                <h2 dangerouslySetInnerHTML={{__html: data.name}} />
                <p dangerouslySetInnerHTML={{__html: data.content}} />
                <span className="btn btn-hm-link">Read More</span>
            </div>
        </Link>
    )
}