import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';

// import GooglePlaceholder from "../../snippet/GoogleSearchLocator";

class PostStep1 extends Component {
    constructor(props){
        super(props);

        this.state= {
            phone: '',
            phoneError: '',
            fields: {},
            errors: {},
            redirect: false,
            userDetails: {}
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
		
        if (this.validateForm()) {
            let personalDetails ={
                fullName : this.state.fields.full_name,
                phone : this.state.phone,
                abn : this.state.fields.abn,
                emailAddress: this.state.fields.email_address
            }
            this.props.postData(personalDetails);        
        } //this.validateForm
    }
    
    validateForm() {
		let fields = this.state.fields;
    let phoneError = this.state.phoneError;
		let errors = {};
		let formIsValid = true;
	
		// First Name error messages
		if (!fields["full_name"]) {
		  formIsValid = false;
		  errors["full_name"] = "*Please enter your Full Name.";
		}
	
		// ABN error messages
		if (!fields["abn"]) {
		  formIsValid = false;
		  errors["abn"] = "*Please enter ABN Number.";
		}
	
		// Email Address error messages
		if (!fields["email_address"]) {
		  formIsValid = false;
		  errors["email_address"] = "*Please enter your Email Address.";
		}
	
		if (typeof fields["email_address"] !== "undefined") {
		  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,15}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		  if (!pattern.test(fields["email_address"])) {
			formIsValid = false;
			errors["email_address"] = "*Please enter valid Email Address.";
		  }
		}
	
		// if (!this.state.phone) {
    //     formIsValid = false;
    //     phoneError = "Please enter your phone number.";
    // } 

    if (!this.state.phone) {
      formIsValid = false;
      phoneError = "Please enter your phone number.";
    }

    // if (this.state.phone.length >= 10) {
    //   formIsValid = false;
    //   phoneError = "Please enter your phone number.";
    // }
	
	
		this.setState({
		  errors: errors,
          phoneError
		});
		return formIsValid;
  }
  
  // passAddress(e){
  //   // console.log(e);
  // }

    async componentDidMount(){
      var myHeaders = new Headers();
        myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        try {
          let AuthOrg = localStorage.getItem('auth_empplyOrgUID');

          await fetch(`${process.env.REACT_APP_APIURL}?endpoint=event_orgniser_detail&user_id=${AuthOrg}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              this.setState({
                userDetails : result.user_detail,
                fields: {
                  full_name : result.user_detail.userFullName,
                  abn : result.user_detail.abn,
                  email_address: result.user_detail.emailAddress
                },
                phone : result.user_detail.phoneNumber,
              })
            })
            .catch(error => console.log('error', error));
          } catch (error) {
            console.log('error', error);
        }
    }
    
    render() {

        // const userDetails = this.state.userDetails;
        // console.log(userDetails);

        return (
          <div className="job-post-box">
            <div className="mb-4">
              <h2>
                Confirm your <br />
                personal details
              </h2>
            </div>

            <div className="form-area">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group mb-md-4 mb-3 col-sm-6">
                    <div className="form-field">
                      <label htmlFor="fullname">Full Name</label>
                      <input
                        id="fullname"
                        type="text"
                        value={this.state.fields.full_name}
                        name="full_name"
                        onChange={this.handleChange}
                        className="form-control"
                        placeholder="Enter your full name"
                      />
                    </div>
                    <div
                      className={`invalid-feedback text-right ${
                        !this.state.fields.full_name
                          ? this.state.errors.full_name
                            ? "d-block"
                            : null
                          : null
                      }`}
                    >
                      {this.state.errors.full_name}
                    </div>
                  </div>
                  <div className="form-group mb-md-4 mb-3 col-sm-6">
                    <div className="form-field">
                      <label>Phone number</label>
                      {/* <input
                        id="tel-phone"
                        className="form-control"
                        type="tel"
                      /> */}
                      <PhoneInput
                        inputExtraProps={{
                          required: true
                        }}
                        defaultCountry={"au"}
                        enableAreaCodeStretch
                        enableAreaCodes={true}
                        value={this.state.phone || ""}
                        dropdownClass="form-shadow form-radius border-0"
                        inputClass="form-shadow form-radius border-0"
                        onChange={(phone) => this.setState({ phone })}
                      />
                    </div>
                    <div
                      className={`invalid-feedback text-right ${
                        !this.state.phone
                          ? this.state.phoneError
                            ? "d-block"
                            : null
                          : null
                      }`}
                    >
                      {this.state.phoneError}
                    </div>
                  </div>
                  <div className="form-group mb-md-4 mb-3 col-sm-6">
                    <div className="form-field">
                      <label htmlFor="abnfield">ABN</label>
                      <input
                        id="abnfield"
                        type="text"
                        name="abn"
                        required={true}
                        maxLength="11"
                        minLength="11"
                        pattern="[0-9]+"
                        value={this.state.fields.abn}
                        onChange={this.handleChange}
                        className="form-control"
                        placeholder="Enter your ABN"
                      />
                    </div>
                    <div
                      className={`invalid-feedback text-right ${
                        !this.state.fields.abn
                          ? this.state.errors.abn
                            ? "d-block"
                            : null
                          : null
                      }`}
                    >
                      {this.state.errors.abn}
                    </div>
                  </div>
                  <div className="form-group mb-md-4 mb-3 col-sm-6">
                    <div className="form-field">
                      <label htmlFor="emailAddress">Email Address</label>
                      <input
                        type="email"
                        name="email_address"
                        value={this.state.fields.email_address}
                        onChange={this.handleChange}
                        className="form-control"
                        placeholder="Enter your Email Address"
                      />
                    </div>
                    <div
                      className={`invalid-feedback text-right ${
                        this.state.errors.email_address
                            ? "d-block"
                            : null  
                      }`}
                    >
                      {this.state.errors.email_address}
                    </div>
                  </div>
                  {/* <div className="form-group mb-md-4 mb-3 col-sm-12">
                    <div className="form-field">
                      <label>Location</label>
                      <GooglePlaceholder getAddress={(e) => this.passAddress(e)} lat={userDetails.location && userDetails.location.latitude} long={userDetails.location && userDetails.location.longitude} />
                    </div>
                  </div> */}
                  <div className="w-100 mb-sm-5"></div>
                  <div className="form-group mb-md-0 mb-3 col-sm-6 ml-auto">
                    <button
                      type="submit"
                      className="btn btn-hm-primary btn-block btn-lg mb-3  px-4 d-flex align-items-center"
                    >
                      Continue <i className="fas ml-auto fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        );
    }
}

export default PostStep1;