import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { Tab, Nav, Row, Col } from 'react-bootstrap';

import PostAJobStep1 from "./snippet/PostStep1";
import PostAJobStep2 from "./snippet/PostStep2";
import PostAJobStep3 from "./snippet/PostStep3";
import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';

class PostAJob extends Component {
	constructor(props){
        super(props);

        this.state= {
			redirect: false,
			tabStep: 'first', //third
			postStep1: {},
			firstStepDone: false,

			postStep2: {},
			secondStepDone: false,

			postStep3: {},
			thirdStepDone: false,

			frequency: [],

			jobID: "",
			tabStepBack: true,
			planStatus:1,
			loader: false
		}
		
		this.secondStep = this.secondStep.bind(this);
		this.stepFirst = this.stepFirst.bind(this);
		this.stepSecond = this.stepSecond.bind(this);
		this.firstStep = this.firstStep.bind(this);
		this.submitForm = this.submitForm.bind(this);
	}
	
	async componentDidMount(){
		var myHeaders = new Headers();
		// myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
		// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		try {
			let Auth = localStorage.getItem('auth_empplyOrgUID');
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${Auth}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					planStatus : result.data?.subscription_status,
					loader: true
				});
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
	}

	stepFirst(){	
		this.setState({
			tabStep: 'first',
			tabStepBack: false
		})
	}

	stepSecond(){	
		this.setState({
			tabStep: 'second',
		})
	}

	firstStep(personalDetails){	
		this.setState({
			postStep1: personalDetails,
			firstStepDone: true,
			tabStep: 'second'
		})
	}

	secondStep(aboutEvent){	
		// console.log(aboutEvent);
		this.setState({
			postStep2: aboutEvent,
			secondStepDone: true,
			tabStepBack: true,
			tabStep: this.state.tabStepBack ? 'third' : 'first'
		})
	}

	async submitForm(postStep3){	
		this.setState({
			postStep3: postStep3,
			thirdStepDone: true,
			redirect: true
		})

		const postStep1 = this.state.postStep1;
		const postStep2 = this.state.postStep2;

		// console.log(postStep1, postStep2);

		let Auth = localStorage.getItem('auth_empplyOrgUID');

		let event_term00 = postStep2.location.location_address[1] ? postStep2.location.location_address[1].long_name : "";
		let event_term01 = postStep2.location.location_address[2] ? postStep2.location.location_address[2].long_name : "";
		let event_term02 = postStep2.location.location_address[3] ? postStep2.location.location_address[3].long_name : "";

		var myHeaders = new Headers();
			// myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
			// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
			
			var urlencoded = new URLSearchParams();
			urlencoded.append("endpoint", "post_job");
			urlencoded.append("first_name", postStep1.fullName);
			urlencoded.append("last_name", postStep1.fullName);
			urlencoded.append("mobile", postStep1.phone);
			urlencoded.append("abn", postStep1.abn);
			urlencoded.append("email", postStep1.emailAddress);
			urlencoded.append("event_name", postStep2.event_name);
			urlencoded.append("start_date", postStep2.event_startDate);
			urlencoded.append("end_date", postStep2.event_endDate);
			urlencoded.append("event_description", postStep2.event_desc);
			urlencoded.append("no_of_freelancer", postStep2.freelancer_number);
			urlencoded.append("pay_amount", postStep2.event_pay);
			urlencoded.append("frequency", postStep2.event_pay_duration);
			urlencoded.append("event_latitude", postStep2.location.lat);
			urlencoded.append("event_longitude", postStep2.location.long);
			// urlencoded.append("event_street_address", event_term01 ? event_term01 +", " + event_term00 : event_term00 );
			urlencoded.append("event_street_address", postStep2.location.location );
			urlencoded.append("event_suburb", event_term00);
			urlencoded.append("event_state", event_term01);
			urlencoded.append("event_country", event_term02);
			urlencoded.append("right_to_work_in_australia", postStep3.right_to_work_in_australia);
			urlencoded.append("work_with_children_check", postStep3.work_with_children_check);
			urlencoded.append("australian_driving_license", postStep3.australian_driving_license);
			urlencoded.append("current_police_check", postStep3.current_police_check);
			urlencoded.append("covid_vaccinated", postStep3.covid_vaccinated);
			urlencoded.append("event_abn", postStep3.event_abn);
			urlencoded.append("user_id", Auth);
			urlencoded.append("qualification", postStep3.qualification);
			urlencoded.append("skills", postStep3.skills);
			urlencoded.append("industry", postStep3.industry);

			var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: urlencoded,
			redirect: 'follow'
			};

			// console.log(postStep3.qualification);
			// console.log(postStep3.skills);

			await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					jobID : result.job_id,
					redirect: true
				});
			})
			.catch(error => console.log('error', error));
	}

	render() {
		
		const profileStatus = this.state.planStatus;
		// console.log(this.state.jobID);

		if(this.state.jobID){
			return <Redirect push to={{
							pathname: "/e/thank-you",
							state: this.state.jobID 
						}}  />
		}


		// console.log(AllJobs);
		if(profileStatus === 0){
			return(
				<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Job Post" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>Post A <span>New Job</span></h1>
								<p>Confirm your details, tell us about the event, and then let us know about who you’re looking for so we can find the perfect Freelancer for you.</p>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 mb-md-5 mb-4">
								<div className="blank-area text-center">
									<h3>You have no <span>active plan</span>.</h3>
									<p><small>Please buy plan <Link rel="nofollow" className="text-primary" to="/e/membership">here</Link>!</small></p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			)
		}

		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Job Post" />
				<main className="page-dashboard">
					<div className="container-fluid post-ajob">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>Post A <span>New Job</span></h1>
								<p>Confirm your details, tell us about the event, and then let us know about who you’re looking for so we can find the perfect Freelancer for you.</p>
							</div>
						</div>

						<Tab.Container id="left-tabs-example" activeKey={this.state.tabStep} onSelect={(k) => {
							this.setState({
								tabStep: k
							})
						}}>
							<Row>
								<Col md={12} lg={12} xl={3}>
								<Nav variant="pills" className="flex-column nav-tab-list position-sticky">
									<Nav.Link eventKey="first" className={this.state.firstStepDone ? "done" : " "}>Let’s confirm your details</Nav.Link>
									<Nav.Link eventKey="second" className={this.state.secondStepDone ? "done" : " "}>A little about the event</Nav.Link>
									<Nav.Link eventKey="third" className={this.state.thirdStepDone ? "done" : " "}>A little about the person you are looking for</Nav.Link>
								</Nav>
								</Col>
								<Col md={12} lg={12} xl={8}>
								<Tab.Content>
									<Tab.Pane eventKey="first">
										<PostAJobStep1 postData={(e) => this.firstStep(e)} />
									</Tab.Pane>
									<Tab.Pane eventKey="second">
										<PostAJobStep2 moveStep={this.stepFirst}  postData={(e) => this.secondStep(e)} />
									</Tab.Pane>
									<Tab.Pane eventKey="third">
										<PostAJobStep3 moveStep={this.stepSecond}  postData={(e) => this.submitForm(e)} />
									</Tab.Pane>
								</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</div>
				</main>
			</div>
		);
	}
}

export default PostAJob;