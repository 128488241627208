import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';


import dots from '../../assets/images/dots.png';
// import userIcon from '../../assets/images/user-icon.png';
import monthly from '../../assets/images/monthly.png';

import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';

class MembershipPlan extends Component {
	constructor(props){
        super(props);

        this.state= {
			planStatus:{},
			planList:[],
            loader: false,
			customerID: false,
			planId: ''
        }
	}

	async componentDidMount(){
		// console.log(id);
		var myHeaders = new Headers();
		// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");

		var requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
		};
		try {
			let AuthSup = localStorage.getItem('auth_empplySplierUID');
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${AuthSup}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				this.setState({
					planStatus : result.data,
					// loader : true
				});
			})
			.catch(error => console.log('error', error));

			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_plans`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log('result', result);

				this.setState({
					planList : result?.plans ? result?.plans?.reverse() : [],
					loader: true
				});
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
	}

	async handleBtn(e){
		let AuthSup = localStorage.getItem('auth_empplySplierUID');
		const formdata = new FormData();
		formdata.append("endpoint", "create_stripe_customer");
		formdata.append("user_id", AuthSup);

		const requestOptions = {
		method: "POST",
		body: formdata,
		redirect: "follow"
		};

		await fetch(process.env.REACT_APP_APIURL, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			this.setState({
				customerID: true,
				planId: e
			})
		})
		.catch((error) => console.error(error));
	}

	render() {
		const status = this.state.planStatus;
		const products = this.state.planList ?? [];

		if(this.state.customerID){
			return <Redirect push to={{
				pathname: "/s/payment",
				state: this.state.planId
			}} />
		}

		return (
			<div>
			<Loader loader={this.state.loader} />
			<SEOPage pageTitle="Supplier Membership" />
				<main className="page-dashboard pt-0">
					<div className="container-fluid">
						<div className="row page-info mb-100">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>plan</span></h1>
								<p>Complete your subscription now and start your first month for FREE!</p>
								{/* <p><span className="text-primary">Sign up today</span> and get the first month for free. When first month free trial ends payment subscription starts</p> */}
							</div>
						</div>

						<div className="row flex-md-nowrap overflow-auto">
							{products?.filter(item => item.type === "supplier")?.map((plan) => (
								<div key={plan?.price_id} className="col-md-5 col-lg-6 col-xl-auto mr-xl-3 col-sm-6 mb-4">
									<div className="member-card">
										<img src={dots} className="card-dots" alt="dots" />
										<div className="member-plan"><img src={monthly} alt="icon"/></div>
										<h2>{plan?.product_name}</h2>
										<p style={{minHeight: '175px'}}>
											{plan?.product_description}
										</p>

										<div className="member-price">
											<strong>${plan?.unit_amount}</strong>
											Per {plan?.recurring_interval}
										</div>

										{/* <button type='button' onClick={() => this.handleBtn(plan?.price_id)} className="btn btn-block rounded-4 btn-md btn-hm-primary">Buy Plan</button> */}

										{status.subscription_status ? (
											status.current_plan_id === plan?.price_id ? (<button className="btn btn-block rounded-4 btn-md btn-hm-primary" disabled>Current Plan</button>) : (
												<button type='button' onClick={() => this.handleBtn(plan?.price_id)} className="btn btn-block rounded-4 btn-md btn-hm-primary">
													{status.monthly_price && 'Upgrade Plan'}
													{status.yearly_price && 'Downgrade Plan'}
												</button>
											)
										): (
											<button type='button' onClick={() => this.handleBtn(plan?.price_id)} className="btn btn-block rounded-4 btn-md btn-hm-primary">Buy Plan</button>
										)}

										{/* {status.subscription_status ? (
											status.current_plan_id === "price_1ILOlIINkYRCPskePdrMEykB" || status.current_plan_id === "price_1JcoE4INkYRCPskeT1FcSxZf" ? (<button className="btn btn-block rounded-4 btn-md btn-hm-primary" disabled>Current Plan</button>) : (
												<Link rel="nofollow" to={{
													pathname: "/e/payment",
													state: status.monthly_price === "349.99" ? "price_1ILOlIINkYRCPskePdrMEykB" : "price_1JcoE4INkYRCPskeT1FcSxZf"
												}} className="btn btn-block rounded-4 btn-md btn-hm-primary">Downgrade Plan</Link>
											)
										): (
											<Link rel="nofollow" to={{
												pathname: "/e/payment",
												state: status.monthly_price === "349.99" ? "price_1ILOlIINkYRCPskePdrMEykB" : "price_1JcoE4INkYRCPskeT1FcSxZf"
											}} className="btn btn-block rounded-4 btn-md btn-hm-primary">Buy Plan</Link>
										)} */}
									</div>
								</div>
							))}
						{/* <div className="col-md-5 col-lg-4 col-xl-auto mr-xl-3 col-sm-6 mb-4">
								<div className="member-card">
									<img src={dots} className="card-dots" alt="dots" />
									<div className="member-plan"><img src={monthly} alt="icon" /></div>
									<h2>Advertising <br />Package</h2>
									<p style={{minHeight: '175px'}}>
										- Get seen by EventMploy event organisers and freelancers <br />
										- No spam emails. Only EventMploy subscribers will be able to contact you <br />
										- Affordable annual price <br />
										- Display up to 5 pictures (upto 500kb file size per image & width of 400px & height of 260px)
									</p>

									<div className="member-price">
										<strong>${status.yearly_price ? status.yearly_price : '99.99'}</strong>
										per year
									</div>

									{status.subscription_status ? (
										status.current_plan_id === "price_1JngkjINkYRCPske9pcTZHd4" ? (<button className="btn btn-block rounded-4 btn-md btn-hm-primary" disabled>Current Plan</button>) : (
											<Link rel="nofollow" to={{
												pathname: "/s/payment",
												state: "price_1JngkjINkYRCPske9pcTZHd4"
											}} className="btn btn-block rounded-4 btn-md btn-hm-primary">Downgrade Plan</Link>
										)
									): (
										<Link rel="nofollow" to={{
											pathname: "/s/payment",
											state: "price_1JngkjINkYRCPske9pcTZHd4"
										}} className="btn btn-block rounded-4 btn-md btn-hm-primary">BUY PLAN</Link>
									)}
								</div>
							</div> */}
							{/* <!-- /.col-md-4 col-sm-6 --> */}
							{/* <div className="col-md-5 col-lg-4 col-xl-auto mr-xl-3 col-sm-6 mb-4 d-none">
								<div className="member-card">
									<img src={dots} className="card-dots" alt="dots" />
									<div className="member-plan"><img src={monthly} alt="icon" /></div>
									<h2>Premium Monthly Advertising</h2>
									<p style={{minHeight: '175px'}}>
									- Annual advertising package <br />
									- Unlimited Job Postings for one month
									</p>

									<div className="member-price">
										<strong>${status.monthly_price ? status.monthly_price : '59.99'}</strong>
										Per month
									</div>

									{status.subscription_status ? (
										status.current_plan_id === "price_1JngnEINkYRCPskeazq7GJrl" ? (<button className="btn btn-block rounded-4 btn-md btn-hm-primary" disabled>Current Plan</button>) : (
											<Link rel="nofollow" to={{
												pathname: "/s/payment",
												state: "price_1JngnEINkYRCPskeazq7GJrl"
											}} className="btn btn-block rounded-4 btn-md btn-hm-primary">{status.current_plan_id === "price_1JngkjINkYRCPske9pcTZHd4" ? 'Upgrade Plan' : 'Downgrade Plan'}</Link>
										)
									): (
										<Link rel="nofollow" to={{
											pathname: "/s/payment",
											state: "price_1JngnEINkYRCPskeazq7GJrl"
										}} className="btn btn-block rounded-4 btn-md btn-hm-primary">BUY PLAN</Link>
									)}
								</div>
							</div> */}
							{/* <!-- /.col-md-4 col-sm-6 --> */}
							{/* <div className="col-md-5 col-lg-4 col-xl-auto mr-xl-3 col-sm-6 mb-4 d-none">
								<div className="member-card">
									<img src={dots} className="card-dots" alt="dots" />
									<div className="member-plan"><img src={userIcon} alt="icon" /></div>
									<h2>Premium Annual Advertising</h2>
									<p style={{minHeight: '175px'}}>
									- Annual advertising package <br />
									- Unlimited Job Postings for one year <br />
									- Get two months free with annual subscription
									</p>

									<div className="member-price">
										<strong>${status.premium_yearly ? status.premium_yearly : '599.99'}</strong>
										Per year
									</div>

									{status.subscription_status ? (
										status.current_plan_id === "price_1Jngo9INkYRCPskeMoiCKh3e" ? (<button className="btn btn-block rounded-4 btn-md btn-gray" disabled>Current Plan</button>) : (
											<Link rel="nofollow" to={{
												pathname: "/s/payment",
												state: "price_1Jngo9INkYRCPskeMoiCKh3e"
											}} className="btn btn-block rounded-4 btn-md btn-hm-primary">Upgrade Plan</Link>
										)
									): (
										<Link rel="nofollow" to={{
											pathname: "/s/payment",
											state: "price_1Jngo9INkYRCPskeMoiCKh3e"
										}} className="btn btn-block rounded-4 btn-md btn-hm-primary">BUY PLAN</Link>
									)}
								</div>
							</div> */}
							{/* <!-- /.col-md-4 col-sm-6 --> */}
						</div>
					</div>
				</main>
			</div>
		);
	}
}

export default MembershipPlan;