import { NavLink } from 'react-bootstrap';
// import ImageCard1 from '../../../assets/images/home-card-1.webp';
// import ImageCard2 from '../../../assets/images/art-1.png';
import ImageCardArow from '../../../assets/images/card-arrow.svg';

export default function HomeCards({data}){
    // console.log(data);
    return (
        <div className="pt-100 section-home-cards">
            <div className="container container-xxxl">
                <div className="row">
                    <div className="col-lg-6">
                        <NavLink href="/advertise" className='section-home-card'>
                            <img src={data[0]?.image_url} alt={data[0]?.image_alt} className="img-fluid" width={809} height={789} />
                            <div className="section-home-card-overlay d-flex align-items-center">
                                <h3>{data[0]?.title}</h3>
                                <img src={ImageCardArow} width={67} height={67} alt="ImageCardArow" />
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-lg-6">
                        <div className='row'>
                            <div className="col-12">
                                <NavLink href="/e/sign-up-today-and-post-your-first-job" className='section-home-card section-home-card-half'>
                                    <img src={data[1]?.image_url} alt={data[1]?.image_alt} className="img-fluid" width={761} height={377} />
                                    <div className="section-home-card-overlay d-flex align-items-center">
                                        <h3>{data[1]?.title}</h3>
                                        <img src={ImageCardArow} width={67} height={67} alt="ImageCardArow" />
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-12">
                                <NavLink href="/f/sign-up-today-and-find-your-first-job" className='section-home-card section-home-card-half'>
                                    <img src={data[2]?.image_url} alt={data[2]?.image_alt} className="img-fluid" width={761} height={377} />
                                    <div className="section-home-card-overlay d-flex align-items-center">
                                        <h3>{data[2]?.title}</h3>
                                        <img src={ImageCardArow} width={67} height={67} alt="ImageCardArow" />
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}