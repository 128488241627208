import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import CardImage from '../../../assets/images/home-card-image.webp';
import FilterHomeNew from '../FilterHomeNew';

export default function HomeBanner({data}){

    const slider = data?.banners ?? [];

    if(!data){
        return null
    }

    return (
        <div className="section-home">
            <div className="d-lg-flex justify-content-between container-fluid">
                <div className="section-home-content">
                    <h1>{data?.banner_title}</h1>
                    <p>{data?.banner_description}</p>

                    <FilterHomeNew />
                    {/* <form className="home-banner-form">
                        <div className="row">
                            <div className="col-12 mb-4">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Enter Location"
                                           aria-label="Enter Location" aria-describedby="basic-addon2"/>
                                    <div className="input-group-append">
                                        <button className="btn" type="button" id="button-addon2">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M19.1523 19.3607L13.5415 13.5257C14.9726 12.0904 15.8592 10.1118 15.8592 7.92962C15.8592 3.557 12.3022 0 7.92962 0C3.557 0 0 3.557 0 7.92962C0 12.3022 3.557 15.8592 7.92962 15.8592C9.84896 15.8592 11.6105 15.1731 12.9834 14.0347L18.6078 19.8841C18.6818 19.9615 18.7807 20 18.88 20C18.9744 20 19.0685 19.9649 19.1417 19.8946C19.292 19.75 19.2965 19.511 19.1523 19.3607ZM7.92962 15.104C3.97349 15.104 0.755201 11.8857 0.755201 7.92962C0.755201 3.97349 3.97349 0.755201 7.92962 0.755201C11.8857 0.755201 15.104 3.97349 15.104 7.92962C15.104 11.8857 11.8854 15.104 7.92962 15.104Z"
                                                    fill="#4A55BE"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <button className={'btn btn-hm w-100 btn-hm-white-outline'}>Find a Job</button>
                            </div>
                            <div className="col-6">
                                <button className={'btn btn-hm w-100 btn-hm-white'}>Hire</button>
                            </div>
                        </div>
                    </form> */}
                </div>
                {/* /.section-home-content */}

                <div className="section-home-carousel d-lg-block d-none">
                    <OwlCarousel loop className='owl-theme' margin={10} nav>
                        {slider?.map((item, index) => (
                            <div className='item' key={index}>
                                <img src={item?.url} alt={item?.alt} className={'img-fluid'} width={410} height={483} />
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
                {/* /.section-home-carousel */}
            </div>
            {/* <FilterHome /> */}
        </div>
    )
}