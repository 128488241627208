import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../snippet/Loader';
import MyFav from '../snippet/MyFav';
import SEOPage from '../snippet/SEOPageNoIndex';

// import Layout from "../../global/PrimaryLayoutFreelancer";

class MyRating extends Component {
	constructor(props){
        super(props);

        this.state= {
			starRating : {},
			planStatus:1,
			allFav: [],
			favShow: 6,
			unFavShow: 6,
			loader: false
        }
	}
	
	async componentDidMount(){
		var myHeaders = new Headers();
		  myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };
		  
		  try {
			let AuthFree = localStorage.getItem('auth_empplyFreeUID');

			// ?itemType=get_all_favorites_web&user_id=1021

			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_all_favorites_web&user_id=${AuthFree}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				//   console.log(result);
				this.setState({
					starRating : result.get_average_rating,
					loader: true,
					planStatus : result.subscription_status,
					allFav : result.fav_users
				})
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}

		//  fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${AuthFree}`, requestOptions)
		//   .then(response => response.json())
		//   .then(result => {
		// 	  this.setState({
		// 		//   planStatus : result.data
		// 	  });
		//   })
		//   .catch(error => console.log('error', error));

		//  await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_all_favorites&user_id=${AuthFree}`, requestOptions)
		//   .then(response => response.json())
		//   .then(result => {
		// 	  this.setState({
		// 		// allFav : result.fav_users,
		// 		// loader: true
		// 	  });
		//   })
		//   .catch(error => console.log('error', error));
	}

	addFav = async (id) => {
		let AuthFree = localStorage.getItem('auth_empplyFreeUID');
        var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

			var urlencoded = new URLSearchParams();
			urlencoded.append("endpoint", "add_fav");
			urlencoded.append("user_id", AuthFree);
			urlencoded.append("fav_user_id", id);

			var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
			};

			await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
			.then(response => response.json())
			.then(result => {
                if(result.status === 200){
                    // if(!this.props.FavSupplier[0]){
                        window.location.reload();
                    // }
                }
			})
			.catch(error => console.log('error', error));
	}

	render() {
		
		const profileStatus = this.state.planStatus;

		if(profileStatus === 0){
			return(
				<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer my rating" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>Favourites</span></h1>
								<p>Looking for suppliers for your next event? Search here to connect with EventMploy suppliers. To start your search again click the Refresh button next to Search to refresh the EventMploy search tool. Your service not listed? <Link className="text-primary" to="/contact-us">Let us know</Link>.</p>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 mb-md-5 mb-4">
								<div className="blank-area text-center">
									<h3>You have no <span>active plan</span>.</h3>
									<p><small>Please buy plan <Link rel="nofollow" className="text-primary" to="/f/membership">here</Link>!</small></p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			)
		}

		const favourites = this.state.allFav;
		// console.log(favourites)

		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer my rating" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>Favourites</span></h1>
								<p>Your top favourites appear here. To remove at any time, simply click the heart icon. The chat icon appears automatically here for Event Organisers you have chatted with before. To contact your Favourite Supplier click back to the <Link rel="nofollow" className="d-inline-block text-primary" to="/f/suppliers">Suppliers page</Link>.</p>
							</div>
						</div>

						<div className="row">
							{favourites && favourites.filter(fav => fav.is_favorite === 1).slice(0,this.state.favShow).map((fav, index) => (
								<div className="col-sm-6 col-md-4 mb-sm-2 mb-1" key={index}>
									<div className="chat-contact rating-box fav-box position-relative w-100 d-flex align-items-center">
										{/* <span onClick={() => this.addFav(fav.id)} className="fav-heart"><i className="fas active fa-heart"></i></span> */}
										<MyFav getFav={() => this.addFav(fav.id)} added={true} />
										<div className="contact-name fav-name flex-grow w-100">
											<strong className="text-truncate w-100">{fav.first_name}</strong>
										</div>
										{fav.role === "supplier" ? '' : <Link rel="nofollow" to={'/f/user/' + fav.id} className="fav-chat"><i className="fas fa-comment-dots"></i></Link>}
									</div>
								</div>
							))}
							
						</div>
						
						{favourites && favourites.filter(fav => fav.is_favorite === 1) > this.state.favShow ? (
							<div className="pt-2 d-flex justify-content-end">
								<button onClick={() => this.setState({favShow: 200000})} className="btn p-0 btn-link">View All</button>
							</div>
						) : null}


                        <div className="row mt-5 page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>Save to My <span>Favourites</span></h1>
								<p>Select from the following people you worked with recently to have them added to your favourites above. Simply click the heart icon to “favourite” them.</p>
							</div>
						</div>

						<div className="row">
							{favourites && favourites.filter(fav => fav.is_favorite === 0).slice(0,this.state.unFavShow).map((fav, index) => (
								<div className="col-sm-6 card-btn col-md-4 mb-sm-2 mb-1" key={index}>
									<div className="chat-contact rating-box fav-box position-relative w-100 d-flex align-items-center">
										{/* <span onClick={() => this.addFav(fav.id)} className="fav-heart"><i className="fas fa-heart"></i></span> */}
										<MyFav getFav={() => this.addFav(fav.id)} added={false} />
										<div className="contact-name fav-name flex-grow w-100">
											<strong className="text-truncate w-100">{fav.first_name}</strong>
										</div>
									</div>
								</div>
							))}
						</div>
						
						{favourites && favourites.filter(fav => fav.is_favorite === 0).length > this.state.unFavShow ? (
							<div className="pt-2 d-flex justify-content-end">
								<button onClick={() => this.setState({unFavShow: 200000})} className="btn p-0 btn-link">View All</button>
							</div>
						) : null}

					</div>
				</main>
			</div>
		);
	}
}

export default MyRating;