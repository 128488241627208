import React, { Component } from 'react'

export default class ContactForm extends Component {
    constructor(props){
        super(props);

        this.state= {
            fields: {},
            errors: {},
		    success:'',
            formLoader: false,
            imageAlt: '',
            loader: false
		}
		
		this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
	}

    handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
	}

    handleSubmit = (e) => {
        e.preventDefault();

        // console.log(this.state.fields);
		
        if (this.validateForm()) {
          this.setState({
            formLoader: true
          })

			    var myHeaders = new Headers();
            myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("endpoint", "contact_us");
            urlencoded.append("first_name", this.state.fields.first_name);
            urlencoded.append("last_name", this.state.fields.last_name);
            urlencoded.append("email", this.state.fields.email_address);
            urlencoded.append("type_of_query", this.state.fields.type_of_query);
            urlencoded.append("message", this.state.fields.messages);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
            .then(async response => await response.json())
            .then(result => {
                // console.log(result);
                let fields = this.state.fields;
                fields["first_name"] = "";
                fields["last_name"] = "";
                fields["email_address"] = "";
                fields["messages"] = "";
                fields["type_of_query"] = "";

                this.setState({
                    formLoader: false,
                    success: result.mesage,
                    fields
                });

                setTimeout(() => {
                  window.location.reload();
                }, 800);

            })
            .catch(error => console.log('error', error));
        } //this.validateForm
	}

    validateForm() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		// Email Address error messages
			if (!fields["email_address"]) {
			formIsValid = false;
			errors["email_address"] = "*Please enter your email address.";
			}
		
			if (typeof fields["email_address"] !== "undefined") {
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,15}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (!pattern.test(fields["email_address"])) {
                formIsValid = false;
                errors["email_address"] = "*Please enter valid email address.";
                }
            }
		
		// First Name error messages
        if (!fields["first_name"]) {
            formIsValid = false;
            errors["first_name"] = "*Please enter your first name.";
        }
		
        // Last Name error messages
        if (!fields["last_name"]) {
            formIsValid = false;
            errors["last_name"] = "*Please enter your last name.";
        }
		
        // Subject error messages
        if (!fields["type_of_query"]) {
            formIsValid = false;
            errors["type_of_query"] = "*Please choose one option.";
        }
		
        // Last Name error messages
        if (!fields["messages"]) {
            formIsValid = false;
            errors["messages"] = "*Please type your message.";
        }
		
		
			this.setState({
			errors: errors
		    });
		
			return formIsValid;
		}

  render() {
    return (
        <form>
            {/* onSubmit={this.handleSubmit} */}
            <div className="row">
            <div className="form-group col-sm-6">
                <div className="form-field">
                <label>First Name</label>
                <input
                    type="text"
                    className="form-control"
                    name="first_name"
                    onChange={this.handleChange}
                    value={this.state.fields.first_name}
                    placeholder="Enter your first name"
                />
                </div>
                <div
                className={`invalid-feedback text-right ${
                !this.state.fields.first_name
                ? this.state.errors.first_name
                    ? "d-block"
                    : null
                : null
                }`}
                >
                {this.state.errors.first_name}
            </div>
            </div>
            <div className="form-group col-sm-6">
                <div className="form-field">
                <label>Last Name</label>
                <input
                    type="text"
                    className="form-control"
                    name="last_name"
                    onChange={this.handleChange}
                    value={this.state.fields.last_name}
                    placeholder="Enter your last name"
                />
                </div>
                <div
                className={`invalid-feedback text-right ${
                !this.state.fields.last_name
                ? this.state.errors.last_name
                    ? "d-block"
                    : null
                : null
                }`}
                >
                {this.state.errors.last_name}
            </div>
            </div>
            <div className="form-group col-sm-12">
                <div className="form-field">
                    <label>Email</label>
                    <input
                        type="email"
                        className="form-control"
                        name="email_address"
                        onChange={this.handleChange}
                        value={this.state.fields.email_address}
                        placeholder="Enter your email address"
                    />
                </div>
                <div
                    className={`invalid-feedback text-right ${
                        this.state.errors.email_address
                        ? "d-block"
                        : null
                    }`}
                    >
                    {this.state.errors.email_address}
                </div>
            </div>
            <div className="form-group col-sm-12">
                <div className="form-field">
                    <label>Subject</label>
                    {/* <input
                        type="email"
                        className="form-control"
                        name="email_address"
                        onChange={this.handleChange}
                        value={this.state.fields.email_address}
                        placeholder="Enter your Email Address"
                    /> */}
                    <select className="form-control border-none" name="type_of_query" onChange={this.handleChange} value={this.state.fields.type_of_query}>
                        <option value={''}>--Choose option--</option>
                        <option value={'General Inquiry'}>General Inquiry</option>
                        <option value={'Account Assistance'}>Account Assistance</option>
                        <option value={'Technical Issues'}>Technical Issues</option>
                        <option value={'Complaints or Disputes'}>Complaints or Disputes</option>
                        <option value={'Deactivate Account'}>Deactivate Account</option>
                    </select>
                </div>
                <div
                    className={`invalid-feedback text-right ${
                        this.state.errors.type_of_query
                        ? "d-block"
                        : null
                    }`}
                    >
                    {this.state.errors.type_of_query}
                </div>
            </div>
            <div className="form-group col-sm-12">
                <div className="form-field">
                <label>Type Your Message</label>
                <textarea
                    rows="5"
                    className="form-control form-control-size"
                    name="messages"
                    onChange={this.handleChange}
                    value={this.state.fields.messages}
                    placeholder="Type your message here..."
                ></textarea>
                </div>
                <div
                className={`invalid-feedback text-right ${
                !this.state.fields.messages
                ? this.state.errors.messages
                    ? "d-block"
                    : null
                : null
                }`}
                >
                {this.state.errors.messages}
            </div>
            </div>
            <div className="form-group col-lg-4 col-sm-5">
                <button
                type="button"
                onClick={this.handleSubmit}
                className="btn btn-md-2x btn-text-space btn-hm-primary mb-2 btn-block btn-md "
                disabled={this.state.formLoader ? true : false}>{this.state.formLoader ? 'Sending...' : 'Submit'}
                </button>
                {this.state.success && <span className="text-success d-block">{this.state.success}</span>}
            </div>
            </div>
        </form>
    )
  }
}
