import { Tab, Tabs } from "react-bootstrap";
import CardLocation from "../../../assets/images/card-location-icon.svg";
import Carddate from "../../../assets/images/card-date-icon.svg";
import CardClock from "../../../assets/images/card-clock-icon.svg";
import CardLogo from "../../../assets/images/user-default.png";
// import CardChat from "../../../assets/images/chat-icon.svg";
import CardArrow from "../../../assets/images/card-arrow.svg";
import ApplicantsIcon from "../../../assets/images/applicants.svg";
import {
    Link
  } from "react-router-dom";
// import GMap from "./GoogleMap";
import GoogleMapIframe from "./GoogleIframe";

export default function HomeUpcoming({events}){
    return(
        <div className="pt-100 pb-100 section-upcoming">
            <div className="container container-xl">
                <div className="section-home-title text-center">
                    <h2>Upcoming Events</h2>
                </div>

                <Tabs defaultActiveKey="tab-0" id="uncontrolled-tab-example">
                    {events?.map((event, index) => (
                        <Tab eventKey={`tab-${index}`} key={index} title={event?.tab_name}>
                            <CardList data={event?.events ?? []} />
                        </Tab>
                    ))}
                    {/* <Tab eventKey="weddings" title="Weddings">
                        <CardList />
                    </Tab>
                    <Tab eventKey="music" title="Music Festivals">
                        <CardList />
                    </Tab>
                    <Tab eventKey="gatherings" title="Small Gatherings">
                        <CardList />
                    </Tab>
                    <Tab eventKey="sports" title="Sports Event">
                        <CardList />
                    </Tab> */}
                </Tabs>

                {/* <div className="text-center">
                    <button className="btn btn-hm btn-hm-primary">View All</button>
                </div> */}
            </div>
        </div>
    )
}

const CardList = ({data}) => {
    return(
        <div className="row justify-content-center mb-lg-5 mb-3">
            {data?.slice(0,3)?.map((event, index) =>(
                <div className="col-lg-4 col-md-6 mb-lg-0 mb-4" key={index}>
                    <CardItem eventItem={event} />
                </div>
            ))}
        </div>
    )
}

function convertTo12HourFormat(timeString) {
    // Split the time string into components
    // .seconds
    let [hours, minutes] = timeString.split(':').map(Number);
  
    // Determine AM or PM
    const period = hours >= 12 ? 'pm' : 'am';
  
    // Convert hour from 24-hour to 12-hour format
    hours = hours % 12 || 12;
  
    // Return the formatted time string
    //:${String(seconds).padStart(2, '0')}
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${period}`;
}

const CardItem = ({eventItem}) => {

    if(!eventItem){
        return null
    }

    return(
        <div className="section-upcoming-card">
            <div className="section-upcoming-card-location">
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.758293871769!2d151.20402737630937!3d-33.87011952322607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae3c27682c85%3A0xb1663d1d054300f6!2sSydney%20Town%20Hall!5e0!3m2!1sen!2sin!4v1720159663079!5m2!1sen!2sin" style={{border:"0"}} title="title" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                {/* <GMap lat={eventItem?.lat} long={eventItem?.long} /> */}
                <GoogleMapIframe title={eventItem.name} lat={eventItem?.lat} long={eventItem?.long} />
            </div>
            <div className="section-upcoming-card-info d-flex align-items-center justify-content-between">
                <span><img src={CardLocation} width={25} height={26} alt="CardLocation" /> {eventItem?.suburb}</span>
                <span><img src={Carddate} width={26} height={26} alt="Carddate" /> {eventItem?.event_start_date.split(' ')[0]}</span>
                <span><img src={CardClock} width={26} height={26} alt="CardClock" /> {convertTo12HourFormat(eventItem?.event_start_date.split(' ')[1])} onward</span>
            </div>
            <div className="section-upcoming-card-content">
                <div className="section-upcoming-card-control d-flex align-items-center">
                    {/* <a href="/"><img src={CardChat} alt="CardChat" /></a> */}
                    <Link to={`/login/?job=${eventItem.id}`}><img src={CardArrow} width={67} height={67} alt="CardArrow" /></Link>
                </div>
                <div className="section-upcoming-card-content-title">
                    <h3><img width={36} height={36} src={eventItem?.organiser_logo ? eventItem?.organiser_logo : CardLogo} alt="CardLogo" /> <span>{eventItem?.organiser_name}</span></h3>
                    <h2>{eventItem.name}</h2>
                </div>
                <div className="section-upcoming-card-content-pills d-flex align-items-center">
                    {eventItem?.skills?.map((skill, index) => (
                        <span key={index}>{skill}</span>
                    ))}
                </div>
                <div className="section-upcoming-card-content-footer d-flex align-items-center justify-content-between">
                    <span className="d-flex align-items-center"><strong>${eventItem?.price?.amount}/hr</strong> (9 hr required)</span>
                    <span className="d-flex align-items-center"><strong><img src={ApplicantsIcon} width={27} height={20} alt="ApplicantsIcon" /></strong> {eventItem?.applicant_needed} Applicants</span>
                </div>
            </div>
        </div>
    )
}