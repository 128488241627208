import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import React from 'react';
import { Redirect } from 'react-router-dom';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://eventmploy-v2.netlify.app/e/thank-you-payment",
      },
        redirect: "if_required"
    });


    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setMessage(result.error.message);
    } else if(result.paymentIntent && result.paymentIntent.status === "succeeded"){
        setMessage("Payment status: " + result.paymentIntent.status);
        handleSccess(result.paymentIntent.status, result.paymentIntent.id);
        console.log(result.paymentIntent)
        // console.log('Payment Method ID:', paymentIntent.payment_method);
        // const res = await UpdatePaymentStatus(auth, setupIntent, pid);
        // if(res.message){
        //     // setMessage(res.message);
        //     router.push("/thank-you");
        // }else{
        //     setMessage(res.error);
        // }
    }
    else {
        setMessage("An unexpected error occurred.");
    }
    setIsLoading(false);
  };

  const handleSccess = async (e, id) => {
    const formdata = new FormData();
    formdata.append("endpoint", "update_payment_status");
    formdata.append("status", e);
    formdata.append("user_id", AuthOrg);
    formdata.append("pid", id);
    
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };
    
    await fetch(process.env.REACT_APP_APIURL, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setRedirect(true);
      })
      .catch((error) => console.error(error));    
  }

  if(redirect){
    return <Redirect push to={{
        pathname: "/e/thank-you-payment"
    }} />
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className="btn btn-hm-primary btn-lg mb-3 px-4 d-flex align-items-center" disabled={isLoading || !stripe || !elements}>{isLoading ? "Loading..." : "Pay now"}  <i className="fas ml-auto fa-arrow-right"></i></button>

      {message && <div className={'text-success'}>{message}</div>}
    </form>
  )
};

export default CheckoutForm;