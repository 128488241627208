import React, { useEffect, useState } from 'react';
import {
    Link
  } from "react-router-dom";
  import PlayIcon from "../assets/images/play-icon.svg";
import Loader from './snippet/Loader';
import SEOPage from './snippet/SEOPage';
import { Tab, Tabs } from 'react-bootstrap';

// import BlogBanner from '../assets/images/blogImage.png';

const Resourcespage = () => {
    // const [visible, setVisible] = useState(6);
    // const [items, setTtems] = useState(0);
    // const [loadingState, setLoading] = useState(false);
    const [data, setData] = useState();
	const [loader, setloader] = useState(false)
    const [webinars, setWebinars] = useState([]);
    const [blogs, setBlogs] = useState([]);

    // const myRef = useRef();

    useEffect(() => {
        // var myHeaders = new Headers();
		
		// var requestOptions = {
		// 	method: 'GET',
		// 	headers: myHeaders,
		// 	redirect: 'follow'
		// };
		// // 
		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_blog_list_data&page_title=Our Blog`, requestOptions)
		// .then(async response => await response.json())
		// .then(result => {
        //     // console.log(result);
			
		// })
		// .catch(error => console.log('error', error));

        async function fetchData() {
            var myHeaders = new Headers();
		
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            try {
                // 
                await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_webinars`, requestOptions)
                .then(async response => await response.json())
                .then(result => {
                    // console.log(result);
                    setWebinars(result?.webinars);
                })
                .catch(error => console.log('error', error));

                await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_blogs`, requestOptions)
                .then(async response => await response.json())
                .then(result => {
                    // console.log(result);
                    setBlogs(result.blogs)
                })
                .catch(error => console.log('error', error));

                await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=Our Blog`, requestOptions)
                .then(async response => await response.json())
                .then(result => {
                    setData(result)
                    setloader(true);
                })
                .catch(error => console.log('error', error));
            } catch (error) {
                console.log('error', error);
            }
        }
        fetchData();
    }, [])

    // function loadMore(){    
    //     setLoading(true);
    //     setloader(false);
    //     setTimeout(() => {
    //     //   this.setState((prev) => {
    //     //     return {visible: prev.visible + 15, loadingState: false};
    //     //   });
    //       setVisible(visible + 6);
    //       setLoading(false);
    //       setloader(true);
    //       if (typeof window !== 'undefined' && visible < items ) {
    //         // const height = document.getElementsByClassName('article')[0].clientHeight;
    //         // window.scrollTo({behavior: 'smooth', top: myRef.current.offsetTop - (height * 16)})
    //       }
    //     }, 1000);
    // }

    return(
        <div>
            <Loader loader={loader} />
			<SEOPage pageTitle="Our Resources" />
			<main className="page-content">
				<div className="inner-banner text-white d-flex align-items-center">
                    <img src={data && data.background_image} alt={data && data.alt_text} className="banner-image" />
					<div className="container">
						<div className="row">
							<div className="col-md-11 col-lg-6 pr-md-5 mt-md-5 mt-2 pt-md-5">
								<h1><span>Our</span> Resources</h1>
                                {/* <p>Quisque aliquam egestas semper. Phasellus est ipsum, facilisis at pellentesque et,</p> */}
							</div>
						</div>
					</div>
				</div>

				<div className="page-inner-content section-upcoming">
                    <div className="container">
                        <Tabs defaultActiveKey="webinars" id="uncontrolled-tab-example">
                            <Tab eventKey="webinars" title="Webinars">
                                <CardListWebinar data={webinars ?? []} />
                            </Tab>
                            <Tab eventKey="blogs" title="Blogs">
                                <CardListBlog data={blogs ?? []} />
                            </Tab>
                        </Tabs>

                    </div>
                </div>
			</main>
		</div>
    )
}


const CardListWebinar = ({data}) => {
    return(
        data?.length > 0 && (
            <div className="row justify-content-center">
                {data?.map((item, index) => (
                    <div className="col-lg-4 col-md-6 mb-4" key={index}>
                        <CardItemWebinar data={item ?? {}} />
                    </div>
                ))}
            </div>
        )
    )
}

const CardListBlog = ({data}) => {
    const [visible, setVisible] = useState(6);
    const [loadingState, setLoading] = useState(false);
    function loadMore(){    
        setLoading(true);
        setTimeout(() => {
        //   this.setState((prev) => {
        //     return {visible: prev.visible + 15, loadingState: false};
        //   });
          setVisible(visible + 6);
          setLoading(false);
          if (typeof window !== 'undefined' && visible < data?.length ) {
            // const height = document.getElementsByClassName('article')[0].clientHeight;
            // window.scrollTo({behavior: 'smooth', top: myRef.current.offsetTop - (height * 16)})
          }
        }, 1000);
    }
    return(
        data?.length > 0 && (
            <div className="row justify-content-center">
                {data?.slice(0, visible).map((item, index) => (
                    <div className="col-lg-4 col-md-6 mb-4" key={index}>
                        <CardItemBlog data={item ?? {}} />
                    </div>
                ))}
                {visible < data?.length && 
                    <div className="col-12 text-center pt-md-4 pt-2">
                        <button onClick={loadMore} className="btn btn-hm btn-hm-primary">{loadingState ? 'Loading...' : 'Load More'}</button>
                    </div>
                }
            </div>
        )
    )
}

const CardItemWebinar = ({data}) => {
    return(
        <a rel="noreferrer" href={data.youtubeLink} target="_blank" className="section-webinar-card">
            <div className="section-webinar-card-image">
                {data?.image && <img src={data?.image} alt={data?.title} className="img-fluid"/>}
            </div>
            <div className="section-webinar-card-content">
                <h2 dangerouslySetInnerHTML={{__html: data.title}} />
                <div className="d-flex align-items-center justify-content-between">
                    <span>Info Session Ended</span>
                    <span>{data?.time}</span>
                </div>
                <div className="d-flex align-items-center">
                    <span>View recording</span>
                    <img src={PlayIcon} alt="Play" />
                </div>
            </div>
        </a>
    )
}

const CardItemBlog = ({data}) => {
    return(
        <Link to={'/post/' + data.slug} className="section-webinar-card">
            <div className="section-webinar-card-image">
                {data?.featured_image && <img src={data?.featured_image} alt={data.featured_image_alt_text} className="img-fluid"/>}
            </div>
            <div className="section-webinar-card-content">
                <h2 dangerouslySetInnerHTML={{__html: data.name}} />
                <p dangerouslySetInnerHTML={{__html: data.content}} />
                <span className="btn btn-hm-link">Read More</span>
            </div>
        </Link>
    )
}

  
export default Resourcespage