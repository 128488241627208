import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import GooglePlaceholder from "../snippet/GoogleSearchLocator";

import { Link } from 'react-router-dom';
import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';



class Profile extends Component {

	constructor(props){
        super(props);

        this.state= {
            phone: '',
            phoneError: '',
            fields: {},
            errors: {},
			success:'',
            redirect: false,
            userDetails: {},
			location: "",
			LocationError: '',
			enableFields: false,
			formLoader: false,
			loader: false
		}
		
		this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
	}
	
	handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })

		if(fields['biography']){
			let leth = e.target.value.length;
			// console.log(leth);
			this.setState({
				textareaLength: leth 
			});
		}
	}

	handleSubmit = async (e) => {
        e.preventDefault();
		
        if (this.validateForm()) {
			this.setState({
				formLoader: true
			})

			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
			myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
			
			// let profile_term00 = this.state.location.location && this.state.location.location.value.terms[0] ? this.state.location.location.value.terms[0].value : "";
			// let profile_term01 = this.state.location.location && this.state.location.location.value.terms[1] ? this.state.location.location.value.terms[1].value : "";
			// let profile_term02 = this.state.location.location && this.state.location.location.value.terms[2] ? this.state.location.location.value.terms[2].value : "";

			let profile_term00 = '';
			let profile_term01 = '';
			let profile_term02 = '';

			if(this.state.location && this.state.location.location){
				profile_term00 = this.state.location.location_address[1] ? this.state.location.location_address[1].long_name : "";
				profile_term01 = this.state.location.location_address[2] ? this.state.location.location_address[2].long_name : "";
				profile_term02 = this.state.location.location_address[3] ? this.state.location.location_address[3].long_name : "";
			}
			
			let AuthOrg = localStorage.getItem('auth_empplyOrgUID');

			var urlencoded = new URLSearchParams();
			urlencoded.append("endpoint", "update_user");
			urlencoded.append("first_name", this.state.fields.first_name);
			urlencoded.append("last_name", this.state.fields.last_name);
			urlencoded.append("email", this.state.fields.email_address);

			if(this.state.location && this.state.location.location){
				urlencoded.append("latitude", this.state.location.lat);
				urlencoded.append("longitude", this.state.location.long);
				urlencoded.append("street_address", this.state.location.location);
				urlencoded.append("suburb", profile_term00);
				urlencoded.append("state", profile_term01);
				urlencoded.append("country", profile_term02);
			}else{
				urlencoded.append("latitude", this.state.userDetails.location.latitude);
				urlencoded.append("longitude", this.state.userDetails.location.longitude);
				urlencoded.append("street_address", this.state.userDetails.location.street_address);
				urlencoded.append("suburb", this.state.userDetails.location.suburb);
				urlencoded.append("state", this.state.userDetails.location.state);
				urlencoded.append("country", this.state.userDetails.location.country);
			}
			urlencoded.append("user_id", AuthOrg);
			urlencoded.append("abn", this.state.fields.abn ? this.state.fields.abn : '');
			urlencoded.append("phone", this.state.phone);
			urlencoded.append("company", this.state.fields.company);
			urlencoded.append("bio", this.state.fields.biography);

			var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: urlencoded,
			redirect: 'follow'
			};

			await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
			.then(response => response.json())
			.then(result => {
				this.setState({
					success: result.response_string,
					formLoader: false
				});
				setTimeout(() => {
					window.location.reload();
				}, 800);
			})
			.catch(error => console.log('error', error));
        } //this.validateForm
	}

	validateForm() {
		let phoneError = this.state.phoneError;
		let LocationError = this.state.LocationError;
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		
		if (!this.state.phone) {
			formIsValid = false;
			phoneError = "Please enter your phone number.";
		}

		if (!this.state.userDetails.location && !this.state.location.lat) {
			formIsValid = false;
			LocationError = "Please enter your location.";
		} 
		
		// Email Address error messages
		if (!fields.email_address) {
			formIsValid = false;
			errors["email_address"] = "*Please enter your Email Address.";
		}
		
		if (typeof fields["email_address"] !== "undefined") {
			var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,15}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
			if (!pattern.test(fields["email_address"])) {
				formIsValid = false;
				errors["email_address"] = "*Please enter valid Email Address.";
			}
		}
		
		// First Name error messages
			if (!fields.first_name) {
			formIsValid = false;
			errors["first_name"] = "*Please enter your First Name.";
			}
		
			// Last Name error messages
			if (!fields.last_name) {
			formIsValid = false;
			errors["last_name"] = "*Please enter your Last Name.";
		}
		
		// ABN error messages
			if (!fields.abn) {
				formIsValid = false;
				errors["abn"] = "*Please enter ABN Number.";
			}
		
		// company error messages
			if (!fields.company) {
				formIsValid = false;
				errors["company"] = "*Please enter company name.";
			}

		if (!fields.biography) {
			formIsValid = false;
			errors["biography"] = "*Please enter your Biography.";
		}
		
		
			this.setState({
			errors: errors,
			phoneError,
			LocationError
		});
		
			return formIsValid;
		}

		enableFields(){
			this.setState({
				enableFields: !this.state.enableFields
			})
	}

	passAddress = (value) =>{
		// console.log(value);
		if(value.lat){
		  this.setState({
			location : value
		  })
		}
	}

	async componentDidMount(){
		var myHeaders = new Headers();
		  myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };
  
		  try {
			let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
  
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=event_orgniser_detail&user_id=${AuthOrg}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				this.setState({
				userDetails : result.user_detail ? result.user_detail : '',
				phone: result.user_detail.phoneNumber ? result.user_detail.phoneNumber : '',
					fields: {
						first_name : result.user_detail.first_name ? result.user_detail.first_name :'',
						last_name : result.user_detail.last_name ? result.user_detail.last_name : '',
						email_address: result.user_detail.emailAddress ? result.user_detail.emailAddress : '',
						abn: result.user_detail.abn ? result.user_detail.abn : '',
						company: result.user_detail.company ? result.user_detail.company : '',
						biography: result.user_detail.bio ? result.user_detail.bio : ''
					},
					loader: true
				})
			})
			.catch(error => console.log('error', error));
			} catch (error) {
				console.log('error', error);
			}
	  }

	render() {
		const userDetails = this.state.userDetails;
		// console.log(userDetails);

		return (
			<div>
			<Loader loader={this.state.loader} />
			<SEOPage pageTitle="Organizer Profile" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>Update your <span>profile</span></h1>
								{/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus volutpat neque urna, a vestibulum tellus sollicitudin ut. Vivamus dictum, dolor eget placerat mollis, risus tortor ornare nisi, at interdum urna dui eget urna.</p> */}
							</div>
						</div>

						<div className="row">
							<div className="col-lg-5 mb-4 col-md-4 col-xl-3">
								<div className="profile-card d-flex flex-column align-items-center text-center">
									<div className="position-relative">
										<div className="contact-auth">{userDetails.first_name && userDetails.first_name.slice(0, 1)}{userDetails.last_name && userDetails.last_name.slice(0, 1)}</div>
									</div>

									<strong className="text-capitalize">{userDetails.first_name + ' ' + userDetails.last_name}</strong>
									<span>Event Organiser</span>
								</div>

								<div className="list-group">
									<Link rel="nofollow" to="/e/profile" className="list-group-item active list-group-item-action"><i className="fas fa-user"></i> Personal Information</Link>
									<Link rel="nofollow" to="/e/change-password" className="list-group-item list-group-item-action"><i className="fas fa-lock"></i> Change Password</Link>
								</div>
							</div>
							<div className="col-lg-12 col-md-8 col-xl-8">
								<div className="profile-box">
									<div className="d-flex flex-nowrap mb-3">
										<div className="d-auto">
											<h3>Personal Information</h3>
											{/* <p>Basic info, like your name and address</p> */}
										</div>
										<div className="d-auto ml-auto">
											<button type="button" onClick={() => this.enableFields()} className="btn btn-link p-0 text-primary btn-edit"><i className="fas fa-pen"></i>  {this.state.enableFields && 'Now '} Edit Fields</button>
										</div>
									</div>

									<div className="form-area">
										<form onSubmit={this.handleSubmit}>
											<div className="row">
												<div className="form-group mb-md-4 mb-3 col-sm-6">
												<div className="form-field">
													<label>First Name</label>
													<input
														type="text"
														onChange={this.handleChange}
														className="form-control"
														name="first_name"
														value={this.state.fields && this.state.fields.first_name}
														disabled={this.state.enableFields ? false : true}
													/>
												</div>
												<div
													className={`invalid-feedback text-right ${
													!this.state.fields.first_name
													? this.state.errors.first_name
														? "d-block"
														: null
													: null
													}`}
													>
													{this.state.errors.first_name}
												</div>
												</div>
												<div className="form-group mb-md-4 mb-3 col-sm-6">
												<div className="form-field">
													<label>Last Name</label>
													<input
													type="text"
													className="form-control"
													name="last_name"
													value={this.state.fields && this.state.fields.last_name}
													onChange={this.handleChange}
													disabled={this.state.enableFields ? false : true}
													/>
												</div>
												<div
													className={`invalid-feedback text-right ${
													!this.state.fields.last_name
													? this.state.errors.last_name
														? "d-block"
														: null
													: null
													}`}
													>
													{this.state.errors.last_name}
													</div>
												</div>
												<div className="form-group mb-md-4 mb-3 col-sm-6">
												<div className="form-field">
													<label>Mobile Number</label>
													<PhoneInput
													inputExtraProps={{
														required: true
													}}
													defaultCountry={"au"}
													disabled={this.state.enableFields ? false : true}
													enableAreaCodeStretch
													enableAreaCodes={true}
													value={this.state.phone}
													dropdownClass="form-shadow form-radius border-0"
													inputClass="form-shadow form-radius border-0"
													onChange={(phone) => this.setState({ phone })}
													/>
												</div>
												<div
													className={`invalid-feedback text-right ${
													!this.state.phone
													? this.state.phoneError
														? "d-block"
														: null
													: null
													}`}
													>
													{this.state.phoneError}
													</div>
												</div>
												<div className="form-group mb-md-4 mb-3 col-sm-6">
												<div className="form-field">
													<label>Email Address</label>
													<input
													type="email"
													onChange={this.handleChange}
													className="form-control"
													name="email_address"
													value={this.state.fields && this.state.fields.email_address}
													disabled={this.state.enableFields ? false : true}
													/>
												</div>
												<div
													className={`invalid-feedback text-right ${
														this.state.errors.email_address
														? "d-block"
														: null
													}`}
													>
													{this.state.errors.email_address}
													</div>
												</div>
												<div className="form-group mb-md-4 mb-3 col-sm-6">
												<div className="form-field">
													<label htmlFor="abn">ABN</label>
													<input
													id="abn"
													type="text"
													className="form-control"
													name="abn"
													required={true}
													maxLength="11"
													minLength="11"
													placeholder="Enter ABN number"
													onChange={this.handleChange}
													value={this.state.fields.abn ? this.state.fields.abn : ''}
													disabled={this.state.enableFields ? false : true}
													/>
												</div>
												<div
													className={`invalid-feedback text-right ${
													!this.state.fields.abn
													? this.state.errors.abn
														? "d-block"
														: null
													: null
													}`}
													>
													{this.state.errors.abn}
													</div>
												</div>
												<div className="form-group mb-md-4 mb-3 col-sm-6">
													<div className="form-field">
														<label>Company Name</label>
														<input
															type="text"
															onChange={this.handleChange}
															className="form-control"
															name="company"
															value={this.state.fields && this.state.fields.company}
															disabled={this.state.enableFields ? false : true}
														/>
													</div>
													<div
														className={`invalid-feedback text-right ${
														!this.state.fields.company
														? this.state.errors.company
															? "d-block"
															: null
														: null
														}`}
														>
														{this.state.errors.company}
													</div>
												</div>
												<div className="form-group mb-md-4 mb-3 col-sm-12">
												<div className="form-field">
													<label>Address</label>
													{this.state.enableFields ? (
													<GooglePlaceholder getAddress={(e) => this.passAddress(e)} value={userDetails.location && userDetails.location.street_address}/>
													) : (
													<input
														type="text"
														className="form-control"
														value={userDetails.location && userDetails.location.street_address}
														disabled={this.state.enableFields ? false : true}
													/>
													)}
												</div>
												<div
													className={`invalid-feedback text-right ${
													!this.state.location.lat
													? this.state.LocationError
														? "d-block"
														: null
													: null
													}`}
													>
													{this.state.LocationError}
													</div>
												</div>
												<div className="form-group mb-md-4 mb-3 col-sm-12">
													<div className="form-field">
														<div className="d-flex align-items-center">
															<label>Biography</label>
															<div className="textarea_count ml-auto">{this.state.fields.biography && this.state.fields.biography.length > 0 ? this.state.fields.biography && this.state.fields.biography.length : 0}/500</div>
														</div>
														<textarea maxLength='500' className="form-control" rows="4"  name="biography" value={this.state.fields && this.state.fields.biography} onChange={this.handleChange} disabled={this.state.enableFields ? false : true}></textarea>
													</div>
													<div
														className={`invalid-feedback text-right ${
															!this.state.fields.biography
															? this.state.errors.biography
																? "d-block"
																: null
															: null
														}`}
														>
														{this.state.errors.biography}
													</div>
												</div>
												<div className="w-100"></div>
												<div className="form-group mt-md-4 mb-md-0 mb-3 col-sm-6 ml-auto">
													<button type="submit" className="btn btn-hm-primary btn-block btn-lg mb-3  px-4 d-flex align-items-center" disabled={this.state.formLoader ? true : false}>{this.state.formLoader ? 'Sending...' : 'Save'} <i className="fas ml-auto fa-arrow-right"></i>
													</button>
													{this.state.success && <span className="text-success d-block">{this.state.success}</span>}
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		);
	}
}

export default Profile;