import React, { Component } from 'react';

const starts0 = `
			<i class="far fa-star"></i>
			<i class="far fa-star"></i>
			<i class="far fa-star"></i>
			<i class="far fa-star"></i>
			<i class="far fa-star"></i>`;
const starts05 = `
			<i class="fas fa-star-half-alt"></i>
			<i class="far fa-star"></i>
			<i class="far fa-star"></i>
			<i class="far fa-star"></i>
			<i class="far fa-star"></i>`;

const starts1 = `
			<i class="fas fa-star"></i>
			<i class="far fa-star"></i>
			<i class="far fa-star"></i>
			<i class="far fa-star"></i>
			<i class="far fa-star"></i>`;
const starts15 = `
			<i class="fas fa-star"></i>
			<i class="fas fa-star-half-alt"></i>
			<i class="far fa-star"></i>
			<i class="far fa-star"></i>
			<i class="far fa-star"></i>`;

const starts2 = `
			<i class="fas fa-star"></i>
			<i class="fas fa-star"></i>
			<i class="far fa-star"></i>
			<i class="far fa-star"></i>
			<i class="far fa-star"></i>`;
const starts25 = `
			<i class="fas fa-star"></i>
			<i class="fas fa-star"></i>
			<i class="fas fa-star-half-alt"></i>
			<i class="far fa-star"></i>
			<i class="far fa-star"></i>`;

const starts3 = `
			<i class="fas fa-star"></i>
			<i class="fas fa-star"></i>
			<i class="fas fa-star"></i>
			<i class="far fa-star"></i>
			<i class="far fa-star"></i>`;
const starts35 = `
			<i class="fas fa-star"></i>
			<i class="fas fa-star"></i>
			<i class="fas fa-star"></i>
			<i class="fas fa-star-half-alt"></i>
			<i class="far fa-star"></i>`;

const starts4 = `
			<i class="fas fa-star"></i>
			<i class="fas fa-star"></i>
			<i class="fas fa-star"></i>
			<i class="fas fa-star"></i>
			<i class="far fa-star"></i>`;
const starts45 = `
			<i class="fas fa-star"></i>
			<i class="fas fa-star"></i>
			<i class="fas fa-star"></i>
			<i class="fas fa-star"></i>
			<i class="fas fa-star-half-alt"></i>`;

const starts5 = `
			<i class="fas fa-star"></i>
			<i class="fas fa-star"></i>
			<i class="fas fa-star"></i>
			<i class="fas fa-star"></i>
			<i class="fas fa-star"></i>`;

class GetRating extends Component {
	constructor(props){
        super(props);

        this.state= {
			starRating: {}
        }
	}
	
	renderSwitch(param){
		// console.log(param)
		switch(true) {
			case param < 0.5:
			  return starts0;
			case param < 1:
			  return starts05;
			case param < 1.4:
			  return starts1;
			case param < 2:
			  return starts15;
			case param < 2.5:
			  return starts2;
			case param < 3:
			  return starts25;
			case param < 3.5:
			  return starts3;
			case param < 4:
			  return starts35;
			case param < 4.5:
			  return starts4;
			case param < 5:
			  return starts45;
			case param === 5:
			  return starts5;
			default:
			  return starts0;
		}
	}

	render() {
		const starRating = this.props.starRating;

		// if(!starRating){
		// 	return null
		// }

		return (
			<div className={`rating_stars text-primary ${this.props.className}`} dangerouslySetInnerHTML={{__html: this.renderSwitch(starRating) }} />
		);
	}
}

export default GetRating;