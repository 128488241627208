import React, { useEffect, useState, useRef } from 'react';
import {
    Link
  } from "react-router-dom";

import Loader from './snippet/Loader';
import SEOPage from './snippet/SEOPage';

// import BlogBanner from '../assets/images/blogImage.png';

const Blogpage = () => {
    const [content, setContent] = useState();
    const [visible, setVisible] = useState(6);
    const [items, setTtems] = useState(0);
    const [loadingState, setLoading] = useState(false);
    const [data, setData] = useState();
	const [loader, setloader] = useState(false)

    const myRef = useRef();

    useEffect(() => {
        async function fetchData() {
            var myHeaders = new Headers();
		
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            // 
            try{
                await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_blog_list_data&page_title=Our Blog`, requestOptions)
                .then(async response => await response.json())
                .then(result => {
                    // console.log(result);
                    setContent(result.blogs);
                    setTtems(result.blogs.length);
                    setData(result.blog_data)
                    setloader(true);
                })
                .catch(error => console.log('error', error));
            } catch (error) {
                console.log('error', error);
            }
          }
          fetchData();
        

        // fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=Our Blog`, requestOptions)
        // .then(async response => await response.json())
        // .then(result => {
        //     console.log(result);
        //     setImageAlt(result.alt_text)
        // })
        // .catch(error => console.log('error', error));
    }, [])

    function loadMore(){    
        setLoading(true);
        setloader(false);
        setTimeout(() => {
        //   this.setState((prev) => {
        //     return {visible: prev.visible + 15, loadingState: false};
        //   });
          setVisible(visible + 6);
          setLoading(false);
          setloader(true);
          if (typeof window !== 'undefined' && visible < items ) {
            // const height = document.getElementsByClassName('article')[0].clientHeight;
            // window.scrollTo({behavior: 'smooth', top: myRef.current.offsetTop - (height * 16)})
          }
        }, 1000);
    }

    return(
        <div>
            <Loader loader={loader} />
			<SEOPage pageTitle="Our Blog" />
			<main className="page-content">
				<div className="inner-banner text-white d-flex align-items-center">
                    <img src={data && data.background_image} alt={data && data.alt_text} className="banner-image" />
					<div className="container">
						<div className="row">
							<div className="col-md-11 col-lg-6 pr-md-5 mt-md-5 mt-2 pt-md-5">
								<h1><span>Our</span> Blog</h1>
                                {/* <p>Quisque aliquam egestas semper. Phasellus est ipsum, facilisis at pellentesque et,</p> */}
							</div>
						</div>
					</div>
				</div>

				<div className="page-inner-content">
                    <div className="container">
                        <div className="row mb-md-5 mb-4">
                            <div className="col-md-12 content-section">
                                <h2>Blog List</h2>
                                {/* <p>Donec placerat est enim, molestie finibus sem eleifend. In commodo, velit sed consequat vehicula, ipsum tellus vehicula neque, eget porttitor quam odio vitae tellus. Nullam sem lacus, aliquet sed fermentum vel, placerat sit amet erat volutpat. Quisque rhoncus lectus et nisl mattis aliquet. Aenean eleifend dolor sit amet imperdiet semper phasellus finibus auctor dui eget bibendum. Suspendisse ac tortor consequat, dictum urna ac.</p> */}
                            </div>
                        </div>

                        <div className="row article">
                            {content && content.slice(0, visible).map((post, index) => (
                                <div className="col-sm-6 col-lg-4 mb-4" key={index}>
                                    <div className="post-box">
                                        <div className="post-image" style={{backgroundImage: `url(${post.featured_image})`}}>
                                            <div className="post-meta"><strong>{post.post_date}</strong></div>
                                        </div>
                                        <div className="post-info">
                                            <h3 dangerouslySetInnerHTML={{__html: post.name}} />
                                            {
                                                post.tags && (
                                                    <div className="d-flex flex-wrap">
                                                        {post.tags.map((tag, index) => (
                                                            <div className="post-tag" key={index}><i className="fas fa-tags"></i> {tag.name}</div>
                                                        ))}
                                                    </div>
                                                )
                                            }
                                            <p dangerouslySetInnerHTML={{__html: post.content}} />
                                            <Link to={'/post/' + post.id} className="btn p-0 btn-reset btn-link">Read more <i className="fas fa-arrow-right ml-2"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {visible < items && 
                            <div className="text-center pt-md-4 pt-2">
                                <button onClick={loadMore} ref={myRef} className="btn btn-outline-primary btn-lg btn-text-space px-md-5">{loadingState ? 'Loading...' : 'Load More'}</button>
                            </div>
                        }

                    </div>
                </div>
			</main>
		</div>
    )
}

  
export default Blogpage