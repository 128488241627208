import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';

class PreviousJobs extends Component {
	constructor(props){
        super(props);

        this.state= {
            jobs: [],
			planStatus:1,
			loader: false
        }
	}

	async componentDidMount(){
		
		var myHeaders = new Headers();
		myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		try {
			// 
			let Auth = localStorage.getItem('auth_empplyOrgUID');
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_all_previousjobs&user_id=${Auth}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				this.setState({
					jobs: result,
					loader: true,
					planStatus: result.subscription_status
				})
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}


		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${Auth}`, requestOptions)
		//   .then(response => response.json())
		//   .then(result => {
		// 	  this.setState({
		// 		  planStatus : result.data
		// 	  });
		//   })
		//   .catch(error => console.log('error', error));
	}
	// formatDate = (date) => {
    //     var d = new Date(date),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         year = d.getFullYear();
    
    //     if (month.length < 2) 
    //         month = '0' + month;
    //     if (day.length < 2) 
    //         day = '0' + day;
    
	// 	return [day, month, year].join('/');
    // }
	render() {
		const AllJobs = this.state.jobs.jobs;
		const profileStatus = this.state.planStatus;

		// console.log(AllJobs);

		if(profileStatus === 0){
			return(
				<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Previous Jobs" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-md-5 mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>Previous</span> Jobs</h1>
								<p>All of your completed jobs will be listed here.  Don’t forget to rate the freelancers who have worked with you.</p>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 mb-md-5 mb-4">
								<div className="blank-area text-center">
									<h3>You have no <span>active plan</span>.</h3>
									<p><small>Please buy plan <Link rel="nofollow" className="text-primary" to="/e/membership">here</Link>!</small></p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			)
		}

		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Previous Jobs" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-md-5 mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>Previous</span> Jobs</h1>
								<p>All of your completed jobs will be listed here.  Don’t forget to rate the freelancers who have worked with you.</p>
							</div>
						</div>

						<div className="row">
							{AllJobs && AllJobs.map((job, index) => {
								let startDate = job.start_date;
								let endDate = job.end_date;

								return (
									<div className="col-sm-12 mb-md-5 mb-4" key={index}>
										<div className="post-card mb-xl-4">
											{/* {(() => {
												if(job.job_status === "1"){
													return <div className="pc-status status-active">Active</div>
												}else if(job.job_status === "2"){
													return <div className="pc-status status-closed">Closed</div>
												}else if(job.job_status === "3"){
													return <div className="pc-status status-closed">Cancelled</div>
												}else{
													return <div className="pc-status status-closed">Inactive</div>
												}
											})()} */}
											<p><span className="d-none d-md-inline-block">Posted on : </span>{job.post_date}<br />
											<span className="d-inline-block">Status : </span>
											{(() => {
												if(job.job_status === "1"){
													return <div className="status-active-1 d-inline-block">Active</div>
												}else if(job.job_status === "2"){
													return <div className="status-active-1 d-inline-block">Closed</div>
												}else if(job.job_status === "3"){
													return <div className="status-active-1 d-inline-block">Cancelled</div>
												}else{
													return <div className="status-active-1 d-inline-block">Inactive</div>
												}
											})()}
											</p>
											<h2><Link rel="nofollow" to={`/e/job/${job.job_id}`} dangerouslySetInnerHTML={{__html: job.title}} /></h2>
											<p dangerouslySetInnerHTML={{__html: job.desc}} />
											<div className="pc-info d-none d-md-block"><i className="fas fa-map-pin"></i> {job.location && job.location.street_address}</div>
											<div className="pc-info d-block d-md-none"><i className="fas fa-map-marker-alt"></i> {job.location && job.location.street_address}</div>
											<div className="pc-info"><i className="far fa-calendar-alt"></i> {startDate} - {endDate}</div>
											<div className="pc-info"><i className="fas fa-dollar-sign"></i> ${parseInt(job.pay.amount).toLocaleString() ?? job.pay}</div>
											<div className="pc-info"><i className="fas fa-users"></i> {job.freelancer_booked} {job.freelancer_booked > 1 ? "Selected" : "Selected"}</div>
											<div className="pc-tags">
												{job.skills && job.skills.map((skill, index) => (
													<div className="pc-tag" key={index}>{skill.name}</div>
												))}
											</div>
										</div>
									</div>
								)
							})}

							{AllJobs && AllJobs[0] ? null : (
								<div className="col-sm-12 mb-md-5 mb-4">
									<div className="blank-area text-center">
										<h3>You have not completed any jobs yet.</h3>
										<p><small>Please create your job by <Link rel="nofollow" className="text-primary" to="/e/job-post">Click here</Link> !</small></p>
									</div>
								</div>
							)}
						</div>
					</div>
				</main>
			</div>
		);
	}
}

export default PreviousJobs;