import React, { Component } from 'react';
import {
    Link
  } from "react-router-dom";

import logoSm from '../../assets/images/logo.svg';
import MenuBar from '../../assets/images/menu-bars.svg';

class HeaderLogin extends Component {
    constructor(props){
        super(props);

        this.state= {
            navToggle: false,
			loggedIn : false
        }
	}
	
	toggleFun = () => {
		this.setState({
			navToggle: !this.state.navToggle
		})
    }

	toggleFunLink = () => {
		this.setState({
			navToggle: false
		})
    }

    componentDidMount(){
        let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
		let AuthFree = localStorage.getItem('auth_empplyFreeUID');
		let AuthSup = localStorage.getItem('auth_empplySplierUID');

		if(AuthOrg || AuthFree || AuthSup){
			this.setState({
				loggedIn: true
			})
		}
	}
    
    render() {
		let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
        let AuthFree = localStorage.getItem('auth_empplyFreeUID');
        let AuthSup = localStorage.getItem('auth_empplySplierUID');
        
        return (
            <header className="header section-header header-access px-0 w-100 position-absolute">
                <div className="container-fluid">
                    <nav className="navbar p-0 navbar-expand-lg">
                    <Link className="navbar-brand p-md-0" to="/" onClick={() => this.toggleFunLink()}>
                        <img src={logoSm} alt="logo" />
                    </Link>
                    <button className="navbar-toggler p-0" type="button" onClick={() => this.toggleFun()} data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"><img src={MenuBar} width={30} height={30} className="d-block" alt="bar" /></span>
                    </button>

                    <div className={`collapse navbar-collapse ${this.state.navToggle ? 'show' : ''}`} id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <Link className="nav-link" onClick={() => this.toggleFunLink()} to="/">Home</Link>
                            </li>
                            <li className="nav-item active">
                                {AuthSup ? (
                                    <Link className="nav-link" rel="nofollow" onClick={() => this.toggleFunLink()} to={"/s/dashboard"}>Advertise</Link>
                                ):(
                                    <Link className="nav-link" onClick={() => this.toggleFunLink()} to={"/advertise"}>Advertise</Link>
                                )}
                            </li>
                            {this.state.loggedIn ? (
                                <li className={`nav-item ${AuthOrg || AuthSup ? 'd-none' : ''}`}>
                                    {AuthFree ? (
                                        <Link className="nav-link" rel="nofollow" onClick={() => this.toggleFunLink()} to={"/f/accepted"}>Jobs</Link>
                                    ):(
                                        <Link className="nav-link" onClick={() => this.toggleFunLink()} to={"/f/sign-up-today-and-find-your-first-job"}>Jobs</Link>
                                    )}
                                </li>
                            ): (
                                <li className="nav-item">
                                    <Link className="nav-link" onClick={() => this.toggleFunLink()} to="/f/sign-up-today-and-find-your-first-job">Jobs</Link>
                                </li>
                            )}

                            {this.state.loggedIn ? (
                                <li className={`nav-item ${AuthFree || AuthSup ? 'd-none' : ''}`}>
                                    {AuthOrg ? (
                                        <Link className="nav-link" rel="nofollow" onClick={() => this.toggleFunLink()} to={"/e/job-post"}>Post A Job</Link>
                                    ):(
                                        <Link className="nav-link" onClick={() => this.toggleFunLink()} to={"/e/sign-up-today-and-post-your-first-job"}>Post A Job</Link>
                                    )}
                                </li>
                            ): (
                                <li className="nav-item">
                                    <Link className="nav-link" onClick={() => this.toggleFunLink()} to="/e/sign-up-today-and-post-your-first-job">Post A Job</Link>
                                </li>
                            )}
                            {/* <li className="nav-item">
                                <Link className="nav-link" onClick={() => this.toggleFunLink()} to="/webinars">webinars</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" onClick={() => this.toggleFunLink()} to="/blog">blog</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link" onClick={() => this.toggleFunLink()} to="/resources">Resources</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" onClick={() => this.toggleFunLink()} to="/about">About Us</Link>
                            </li>
                        </ul>

                        {this.state.loggedIn ? null : (
                            <Link className="nav-link ml-lg-auto btn-hm btn-hm-primary" onClick={() => this.toggleFunLink()} to="/login">Log In</Link>
                        )}
                        
                        {this.state.loggedIn ? null : (
                            <Link onClick={() => this.toggleFunLink()} to="/get-started" className="btn mb-md-0 mb-4 d-block d-md-inline-block btn-hm btn-hm-primary-outline">Sign Up</Link>
                        )}
                    </div>
                    </nav>
                </div>
            </header>
        );
    }
}

export default HeaderLogin;