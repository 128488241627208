import React from "react";

class GoogleMapIframe extends React.Component {
  render() {
    const { lat, long, title } = this.props;
    const apiKey = "AIzaSyAdOlkaDW84QLIDh112c_kNPkXjbrQEOkY"; // Replace with your actual API key
    const mapSrc = `https://www.google.com/maps/embed/v1/search?key=${apiKey}&q=${lat},${long}&zoom=14`;
    // `https://www.google.com/maps/embed/v1/view?key=${apiKey}&center=${lat},${long}&zoom=12&markers=${lat},${long}`

    return (
        <iframe
        title={title}
        style={{ border: 0 }}
        loading="lazy"
        allowFullScreen
        src={mapSrc}
        ></iframe>
    );
  }
}

export default GoogleMapIframe;