import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';

// import Layout from '../../global/PrimaryLayoutFreelancer';
import SupplierFav from '../supplier/snippet/AddFav'
import SupplierForm from '../supplier/snippet/SupplierForm'
import { withRouter } from "react-router";
import SEOPost from '../snippet/SEOPostNoIndex';

// import DummyPic from '../../assets/images/dummy.png'

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    // TwitterIcon,
    // TwitterShareButton
  } from "react-share";
import Loader from '../snippet/Loader';

class SupplierSingle extends Component {
	constructor(props){
        super(props);

        this.state= {
			supplier:{},
            fav_users: [],
            options: {
                autoWidth: true,
                loop: true,
                margin: 20,
                nav: false,
                responsive: {
                    0: {
                        items: 1,
                    },
                    576: {
                        items: 2,
                        margin: 10,
                    },
                    768: {
                        items: 3,
                    }
                },
            },
            loader: false
        }
	}
	
	async componentDidMount(){
        try {

		    let Auth = localStorage.getItem('auth_empplyFreeUID');
        
            var formdata = new FormData();
            formdata.append("endpoint", "supplier_detail_web");
            formdata.append("user_id", this.props.match.params.id);
            formdata.append("current_user_id", Auth);

            var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result);
              this.setState({
                  supplier : result.user_detail,
                  fav_users : result.fav_users,
                  loader: true
              })
            })
            .catch(error => console.log('error', error));
        } catch (error) {
            console.log('error', error);
        }

            // fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_all_favorites&user_id=${Auth}`, requestOptions)
            // .then(response => response.json())
            // .then(result => {
            //     // console.log(result);
            //     this.setState({
            //         fav_users : result.fav_users
            //     })
            // })
            // .catch(error => console.log('error', error));
	}

	// formatDate = (date) => {
	// 	var d = new Date(date),
	// 		month = '' + (d.getMonth() + 1),
	// 		day = '' + d.getDate(),
	// 		year = d.getFullYear();
	
	// 	if (month.length < 2) 
	// 		month = '0' + month;
	// 	if (day.length < 2) 
	// 		day = '0' + day;
	
	// 	return [day, month, year].join('/');
	//   }

	render() {
        const supplier = this.state.supplier;
        let Auth = localStorage.getItem('auth_empplyFreeUID');
        // console.log(supplier);
		return (
			<div>
                <Loader loader={this.state.loader} />
            	<SEOPost WpPostObj={supplier && supplier.seo} />	
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-5">
								<h1><span>Suppliers</span> {supplier.first_name}</h1>
								<p>{supplier.location && supplier.location.street_address}</p>
							</div>
							<div className="col-lg-auto d-flex flex-sm-row flex-column align-items-lg-center ml-lg-auto">
                                <div className="share-icons d-flex align-items-center">
                                    <EmailShareButton body={``} subject={'Check out this Supplier page on https://www.eventmploy.com/f/supplier/' + this.props.match.params.id} url={'https://www.eventmploy.com'}>
                                        <EmailIcon size={32} round={true} />
                                    </EmailShareButton>
                                    <FacebookShareButton quote={'Check out this Supplier page on https://www.eventmploy.com/f/supplier/' + this.props.match.params.id} url={'https://www.eventmploy.com/'}>
                                        <FacebookIcon size={32} round={true} />
                                    </FacebookShareButton>
                                    <LinkedinShareButton title={'Check out this Supplier page on https://www.eventmploy.com/f/supplier/' + this.props.match.params.id} summary={'Check out this Supplier page on https://www.eventmploy.com/f/supplier/' + this.props.match.params.id} source={'Check out this Supplier page on https://www.eventmploy.com/f/supplier/' + this.props.match.params.id} url={'https://www.eventmploy.com/'}>
                                        <LinkedinIcon size={32} round={true} />
                                    </LinkedinShareButton>
                                    {/* <TwitterIcon url={'https://www.eventmploy.com/f/supplier/' + this.props.match.params.id} subject={props.title} size={32} round={true} /> */}
                                    {/* <TwitterShareButton url={'https://www.eventmploy.com/f/supplier/' + this.props.match.params.id}>
                                        <TwitterIcon size={32} round={true} />
                                    </TwitterShareButton> */}
                                    <span>Share</span>
                                </div>

                                {/* <button className="btn btn-reset d-inline-flex ml-md-3 p-0 align-items-center btn-add-fav"><i className="fas fa-heart"></i> Add to My Favourites</button> */}
                                <SupplierFav userID={Auth} FavSupplier={this.state.fav_users && this.state.fav_users.filter(fav => parseInt(fav.id) === parseInt(this.props.match.params.id))} FavID={this.props.match.params.id} /> 
                            </div>
						</div>

						<div className="pb-4 gallery-list">
                            {/* {supplier?.gallery_images && (
                                <OwlCarousel
                                    {...this.state.options}
                                >
                                    {supplier?.gallery_images.map((image, index) => (
                                        <div className="item" key={index}>
                                            <img src={image} className="d-block w-100" alt={supplier.company} />
                                        </div>
                                    ))}
                                </OwlCarousel>
                            )} */}
                            {supplier?.gallery_images && (
                                <OwlCarousel className='owl-theme' margin={10}
                                    responsive={{
                                        0: {
                                            items: 1,
                                        },
                                        576: {
                                            items: 2,
                                            margin: 10,
                                        },
                                        1200: {
                                            items: 3,
                                        }
                                    }}
                                >
                                    {supplier?.gallery_images?.map((image, index) => (
                                        <div className="item" key={index}>
                                            <img src={image} className="d-block w-100" alt={supplier.company} />
                                        </div>
                                    ))}
                                </OwlCarousel>
                                )}
                        {/* <div className="row d-none gallery-list flex-nowrap overflow-auto">
                            {supplier.gallery_images && supplier.gallery_images.map((image, index) => (
                                <div className="col-5" key={index}>
                                    <div className="sp-gal">
                                        <img src={image} className="d-block w-100" alt="pic" />
                                    </div>
                                </div>
                            ))}
                        </div> */}
                        </div>

                        <div className="py-2 supplier-content">
                            <h4>{supplier.company}</h4>
                            <div dangerouslySetInnerHTML={{__html:supplier.bio}} />
                        </div>

                        <SupplierForm userID={this.props.match.params.id} />
					</div>
				</main>
			</div>
		);
	}
}

export default withRouter(SupplierSingle);