import React, { Component } from 'react';
import {Dropdown} from 'react-bootstrap';

import { NavLink, Link, Redirect } from 'react-router-dom';

import logoSm from '../../assets/images/logo.svg';
import menuBars from '../../assets/images/menu-bars.svg';

class DashboardHeader extends Component {
	constructor(props){
        super(props);

        this.state= {
			redirect: false,
			userDetails: {},
			sideNav: false,
			dropShow: false
        }
	}
	
	async componentDidMount(){
		var myHeaders = new Headers();
		//   myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };
		  try {
		let AuthSup = localStorage.getItem('auth_empplySplierUID');
		//   ?itemType=freelancer_user_detail_web&user_id=1446
		await fetch(`${process.env.REACT_APP_APIURL}?endpoint=freelancer_user_detail_web&user_id=${AuthSup}`, requestOptions)
		  .then(response => response.json())
		  .then(result => {
			this.setState({
			  userDetails : result.user_detail,
			  counter : result.count_unread?.message_count,
			  counterNotification : result.count_unread_notification?.count
			})
		  })
		  .catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}

		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=count_unread&user_id=${AuthSup}`, requestOptions)
		//   .then(response => response.json())
		//   .then(result => {
		// 	//   console.log(result);
		// 	this.setState({
		// 		counter : result.message_count
		// 	})
		//   })
		//   .catch(error => console.log('error', error));

		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=count_unread_notification&user_id=${AuthSup}`, requestOptions)
		//   .then(response => response.json())
		//   .then(result => {
		// 	//   console.log(result);
		// 	this.setState({
		// 		counterNotification : result.count
		// 	})
		//   })
		//   .catch(error => console.log('error', error));
	}

	logout = () => {
        localStorage.removeItem("auth_empplySplierUID");
        localStorage.removeItem("auth_empplyFreeUID");
        localStorage.removeItem("auth_empplyOrgUID");

        this.setState({
            redirect: true,
			sideNav: !this.state.sideNav
        });
	};

	toggleFun = () => {
		this.setState({
			sideNav: !this.state.sideNav
		})
	}

	// handleDrop = (e) => {
	// 	this.setState({
	// 		dropShow : e
	// 	})
	// }

	render() {
		const userDetails = this.state.userDetails;
		// console.log(this.state.starRating);

		if (this.state.redirect) {
            return <Redirect push to="/" />;
		}

		return (
			<>
				<aside className={`sidebar d-flex flex-column ${this.state.sideNav ? 'active' : ''}`}>

					<div className="sidebar_logo d-flex align-items-center">
						<Link rel="nofollow" onClick={() => this.toggleFun()} to="/"><img src={logoSm} alt="logo" /></Link>
						<button onClick={() => this.toggleFun()} className="btn text-secondary d-block d-lg-none btn-close ml-auto p-0"><i className="fas fa-times"></i></button>
					</div>
					
					<div className="sidebar_links flex-grow-1">
						<ul className="list-unstyled">
							<li><NavLink rel="nofollow" onClick={() => this.toggleFun()} activeClassName="active" exact to="/">Home</NavLink></li>
						</ul>
						
						<h6>Portfolio</h6>
						<ul className="list-unstyled">
							<li><NavLink rel="nofollow" onClick={() => this.toggleFun()} activeClassName="active" exact to="/">My Current Page</NavLink></li>
							<li><NavLink rel="nofollow" onClick={() => this.toggleFun()} activeClassName="active" exact to="/s/contact-admin">Contact Admin</NavLink></li>
						</ul>
						
						<h6>Me</h6>
						<ul className="list-unstyled">
							<li><NavLink rel="nofollow" onClick={() => this.toggleFun()} activeClassName="active" exact to="/s/membership">My Plan</NavLink></li>
						</ul>

						<div className="d-block d-lg-none">
							<h6>User Access</h6>
							<ul className="list-unstyled">
								<li><NavLink rel="nofollow" onClick={() => this.toggleFun()} activeClassName="active" exact to="/s/profile">Profile</NavLink></li>
								<li><Link rel="nofollow" to="/" onClick={() => this.logout()}>Sign out</Link></li>
							</ul>
						</div>
					</div>

					{/* <div className="sidebar_user d-flex align-items-center">
						<img src={user} className="d-block d-lg-none" alt="user" />
						<div>
							<strong className="text-truncate text-capitalize">{userDetails.first_name + ' ' + userDetails.last_name}</strong>
							<div className="rating_stars text-primary">
								<i className="fas fa-star"></i>
								<i className="fas fa-star"></i>
								<i className="fas fa-star"></i>
								<i className="fas fa-star-half-alt"></i>
								<i className="far fa-star"></i>
							</div>
						</div>
					</div> */}
				</aside>

				<div className="header-dashboard d-flex align-items-center">
 
					<a className="navbar-brand d-block d-lg-none p-md-0" onClick={() => this.toggleFun()} href="/">
						<img src={logoSm} alt="logo" />
					</a>

					<div className="header-controls ml-auto d-flex align-items-center">
						{/* <NavLink to="/s/notifications" className="mr-4 mr-3">
							<i className="fas fa-bell"></i>
							{this.state.counterNotification > 0 && (<span className="badge badge-count">{this.state.counterNotification}</span>)}
						</NavLink> */}
						{/* <NavLink to="/f/chat" className="mr-4 mr-3">
							<i className="fas fa-comment-dots"></i>
							{this.state.counter > 0 && (<span className="badge badge-count">{this.state.counter}</span>)}
						</NavLink> */}
						<Dropdown>
							<Dropdown.Toggle variant="primary" className="ur_short_name p-0 border-0 btn-hm-primary dropdown-no-arrow dropdown-menu-right d-none d-md-block" id="dropdown-basic">
							{userDetails.first_name && userDetails.first_name.slice(0, 1)}{userDetails.last_name && userDetails.last_name.slice(0, 1)}
							</Dropdown.Toggle> 

							<Dropdown.Menu show={this.state.dropShow ? true : false} menualign="right">
								<div className="dropdown-info text-secondary d-flex align-items-center">
									<div className="ur_short_name">
										{userDetails.first_name && userDetails.first_name.slice(0, 1)}{userDetails.last_name && userDetails.last_name.slice(0, 1)}
									</div>
									<div className="ml-2">
										<h3>{userDetails.userFullName}</h3>
										{userDetails.emailAddress}
									</div>
								</div>
								<NavLink rel="nofollow" onClick={() => this.toggleFun()} className="dropdown-item" to="/s/profile">Profile</NavLink>
								<Link rel="nofollow" className="dropdown-item" to="/" onClick={() => this.logout()}>Log Out</Link>
							</Dropdown.Menu>
						</Dropdown>
						<button onClick={() => this.toggleFun()} className="btn btn-menu d-block d-lg-none p-0 ml-lg-0 ml-md-4"><img className="d-block" width={30} height={30} src={menuBars} alt="bar" /></button>
					</div>
				</div>
				<div className='body-search d-none'></div>
				</>
		);
	}
}

export default DashboardHeader;