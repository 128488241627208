import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import ReactTimeAgo from 'react-time-ago';
import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';

// import Layout from '../../global/PrimaryLayoutFreelancer';

class Chat extends Component {
	constructor(props){
        super(props);

        this.state= {
            users: [],
            userList: [],
            fields: {},
			chatId:"",
			planStatus:1,
			loader: false
        }

		
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
	}
	
	async componentDidMount(){
		let Auth = localStorage.getItem('auth_empplyFreeUID');
		// let Auth = 180;

		var myHeaders = new Headers();
		// myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
		// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		// 
		try {
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_user_chatted_with_web&from_user_id=${Auth}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					users: result.user_list,
					userList: result.user_list,
					loader: true,
					planStatus : result.subscription_status
				})
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}

		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${Auth}`, requestOptions)
		// 	.then(response => response.json())
		// 	.then(result => {
		// 		this.setState({
		// 			planStatus : result.data
		// 		});
		// 	})
		// 	.catch(error => console.log('error', error));

		// const {id} = useParams();
		// this.setState({
        //     chatId: this.id
        // })
	}

	handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
    }

	handleSearch = async (e) => {
        e.preventDefault();

        let Auth = localStorage.getItem('auth_empplyFreeUID');
		// let Auth = "180";
		
		var myHeaders = new Headers();
		// myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
		// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		// 
		await fetch(`${process.env.REACT_APP_APIURL}?endpoint=filter_chat_user&from_user_id=${Auth}&src=${this.state.fields.username}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			// console.log(result);
			this.setState({
				users: result.user_list
			})
		})
		.catch(error => console.log('error', error));
    }

	render() {
		const users = this.state.users;
		const userList = this.state.userList;
		const profileStatus = this.state.planStatus;


		if(profileStatus === 0){
			return(
				<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer Chat" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>conversations</span></h1>
								<p className="d-none d-md-block">Need to ask a question about a job offer?  Chat with the event organiser here.</p>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 mb-md-5 mb-4">
								<div className="blank-area text-center">
									<h3>You have no <span>active plan</span>.</h3>
									<p><small>Please buy plan <Link rel="nofollow" className="text-primary" to="/f/membership">here</Link>!</small></p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			)
		}

		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer Chat" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>conversations</span></h1>
								<p className="d-none d-md-block">Need to ask a question about a job offer?  Chat with the event organiser here.</p>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12">
								<div className="blank-area pt-0">
									{/* <h3>Select user to <span className="text-primary">start conversation</span>.</h3> */}
									{userList && userList[0] ? (<h3>Select user to <span className="text-primary">start a conversation</span></h3>) : (<h3>Accept your job offer then <span className="text-primary">start a conversation</span></h3>)}
									{/* <p><small>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</small></p> */}
								</div>
							</div>
							<div className="col-sm-12">
								<div className="chat-area mb-md-4 d-flex w-100">
									<div className={`chat-list flex-column ${userList && userList[0] ? 'd-flex' : 'd-none'}`}>
										<form onSubmit={this.handleSearch} className="input-group input-chat-search">
										<div className="input-group-prepend">
											<span className="input-group-text text-secondary pr-lg-2 pr-1 pl-ld-4 pl-3 border-0 bg-transparent" id="basic-addon1"><i className="fas fa-search"></i></span>
										</div>
										<input type="text" onChange={this.handleChange} name="username" className="form-control pl-2 border-0 bg-transparent" placeholder="Search here" />
										</form>

										<div className="chat-contact-list w-100 flex-grow-1 overflow-auto">
											{users && users.map((user, index) => (
												<a rel="nofollow" href={'/f/user/' + user.id} className={`chat-contact contact-online1 position-relative w-100 d-flex align-items-center ${user.id === parseInt(this.state.chatId) ? "active" : ""}`} key={index}>
													{user.unread_count > 0 ? (<span className="contact-active"></span>) : null}
													<span className="contact-auth">{user.first_name && user.first_name.slice(0, 1)}{user.last_name && user.last_name.slice(0, 1)}</span>
													<div className="contact-name">
														<strong className="text-truncate w-100">{user.userFullName}</strong>
														<span className="d-none d-md-block">{user.date}</span>
														<span className="d-block text-truncate w-100 d-md-none">{user.message}</span>
													</div>
													<div className="contact-date d-block d-md-none">
														{/* <ReactTimeAgo date={user.date} timeStyle="twitter" locale="en-US"/> */}
														{user.date}
														{user.unread_count > 0 ? (<span className="badge">{user.unread_count}</span>) : null}
													</div>
												</a>
											))}
											{users && users[0] ? null : (<div className="text-center">No result found for "{this.state.fields.username}"</div>)}
											{/* <div className="chat-contact position-relative w-100 d-flex align-items-center">
												<div className="contact-active"></div>
												<span className="contact-auth">eb</span>
												<div className="contact-name">
													<strong className="text-truncate w-100">Elise Beverley</strong>
													3rd March 2021
												</div>
												<div className="contact-date d-block d-md-none">
													3rd Mar
												</div>
											</div> */}
										</div>
									</div>
									<div className="chat-section w-100">
										{/* selected used */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		);
	}
}

export default Chat;