import React, { useContext, useEffect, useState } from 'react';
import {
    Link
  } from "react-router-dom";
import FaqBanner from '../assets/images/faq-banner.png';
import Loader from './snippet/Loader';
import SEOPage from './snippet/SEOPage';
import {Accordion, Card, useAccordionToggle, AccordionContext} from 'react-bootstrap'


const FAQpage = () => {
    const [content, setContent] = useState()
    const [imageAlt, setImageAlt] = useState()
	const [loader, setloader] = useState(false)

    useEffect(() => {
        async function fetchData() {
            var myHeaders = new Headers();
            myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
            myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
            
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            // 
            // fetch(`${process.env.REACT_APP_APIURL}?endpoint=faq`, requestOptions)
            // .then(async response => await response.json())
            // .then(result => {
            // 	setContent(result);
            // 	setloader(true);
            // })
            // .catch(error => console.log('error', error));
            try{
                await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=FAQ`, requestOptions)
                .then(async response => await response.json())
                .then(result => {
                    // console.log(result);
                    setContent(result);
                    setloader(true);
                    setImageAlt(result.alt_text)
                })
                .catch(error => console.log('error', error));
            } catch (error) {
                console.log('error', error);
            }
          }
          fetchData();
    }, [])

    return(
        <div>
            <Loader loader={loader} />
            <SEOPage pageTitle="FAQ" />
			<main className="page-content">
				<div className="inner-banner text-white d-flex align-items-center">
                    <img src={content && content.background_image ? content.background_image : FaqBanner} alt={imageAlt} className="banner-image" />
					<div className="container">
						<div className="row">
							<div className="col-md-11 col-lg-6 pr-md-5 mt-md-5 mt-2 pt-md-5">
								<h1>FAQ</h1>
                                <p dangerouslySetInnerHTML={{__html: content && content.tag_line}} />
							</div>
						</div>
					</div>
				</div>

				<div className="page-inner-content">
                    <div className="container">
                        <div className="row mb-md-5 mb-4 pb-2 justify-content-center text-center">
                            <div className="col-md-9 col-lg-7 content-section" dangerouslySetInnerHTML={{__html: content && content.content}} />
                        </div>

                        
                        <div className="row justify-content-center">
                            <div className="col-md-12 col-lg-10 col-xl-9 mb-5">
                                {/* defaultActiveKey="0" */}
                                <Accordion>
                                    {content && content.faq.map((question, index) => (
                                        <Card key={index}>
                                            <ContextAwareToggle eventKey={index.toString()}>
                                                <i className="fas fa-question-circle"></i>
                                                {question.title}

                                                <div className="accord-control ml-auto">
                                                    <i className="fas fa-minus"></i>
                                                    <i className="fas fa-plus"></i>
                                                </div> 
                                            </ContextAwareToggle>
                                            <Accordion.Collapse eventKey={index.toString()}>
                                                <Card.Body>{question.content}</Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    ))}
                                </Accordion>
                            </div>
                            <div className="col-sm-12 text-center">
                                <Link to="/contact-us" className="btn btn-hm-primary btn-md text-uppercase">Ask a question</Link>
                            </div>
                        </div>

                    </div>
                </div>
			</main>
		</div>
    )
}

function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <div
        className={`card-header d-flex align-items-center ${isCurrentEventKey ? 'down' : 'up'}`}
        onClick={decoratedOnClick}
      >
        {children}
      </div>
    );
  }

  
export default FAQpage