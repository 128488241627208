import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';

import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    // TwitterIcon,
    // TwitterShareButton
  } from "react-share";

class dashboard extends Component {
	constructor(props){
        super(props);

        this.state= {
			supplier:{},
			userDetails: {},
			planStatus:{},
            options: {
                autoWidth: true,
                loop: true,
                margin: 20,
                nav: false,
                responsive: {
                    0: {
                        items: 1,
                    },
                    576: {
                        items: 2,
                        margin: 10,
                    },
                    768: {
                        items: 3,
                    }
                },
            },
            loader: false
        }

	}

	


	async componentDidMount(){
		// var myHeaders = new Headers();
		//   myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		//   var requestOptions = {
		//   method: 'GET',
		//   headers: myHeaders,
		//   redirect: 'follow'
		//   };
  
		
  
		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=freelancer_user_detail&user_id=${AuthSup}`, requestOptions)
		//   .then(response => response.json())
		//   .then(result => {
		// 	this.setState({
		// 	  userDetails : result.user_detail
		// 	})
		//   })
		//   .catch(error => console.log('error', error));

		//   fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${AuthSup}`, requestOptions)
		//   .then(response => response.json())
		//   .then(result => {
		// 	  this.setState({
		// 		  planStatus : result.data
		// 	  });
		//   })
		//   .catch(error => console.log('error', error));


        try {
        
            let AuthSup = localStorage.getItem('auth_empplySplierUID');
        var formdata = new FormData();
        formdata.append("endpoint", "supplier_detail");
        formdata.append("user_id", AuthSup);
        
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        // console.log(AuthSup);
            await fetch(`${process.env.REACT_APP_APIURL}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                
              this.setState({
                  supplier : result.user_detail,
                  loader: true
              })
            })
            .catch(error => console.log('error', error));
        } catch (error) {
            console.log('error', error);
        }
	}

	render() {
		// const userDetails = this.state.userDetails;
		const supplier = this.state.supplier;
		// const profileStatus = this.state.planStatus.subscription_status;

		// if(profileStatus === 0){
		// 	return <Redirect to="/s/membership" />;
		// }

		return(
			<div>
			<Loader loader={this.state.loader} />
			<SEOPage pageTitle="Supplier Dashboard" />
				<main className="page-dashboard pt-0">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-5">
								<h1>{supplier.first_name}</h1>
								<p>{supplier.location && supplier.location.street_address}</p>
							</div>
							<div className="col-lg-auto d-flex flex-sm-row flex-column align-items-lg-center ml-lg-auto">
                                <div className="share-icons d-flex align-items-center">
                                    <EmailShareButton body="" subject="Check out this Supplier page on https://www.eventmploy.com/s/dashboard" url={'https://www.eventmploy.com'}>
                                        <EmailIcon size={32} round={true} />
                                    </EmailShareButton>
                                    <FacebookShareButton quote="Check out this Supplier page on https://www.eventmploy.com/s/dashboard.com." url={'https://www.eventmploy.com'}>
                                        <FacebookIcon size={32} round={true} />
                                    </FacebookShareButton>
                                    <LinkedinShareButton title="Check out this Supplier page on https://www.eventmploy.com/s/dashboard." summary="Check out this Supplier page on https://www.eventmploy.com/s/dashboard." source="Check out this Supplier page on https://www.eventmploy.com/s/dashboard." url={'https://www.eventmploy.com/s/dashboard'}>
                                        <LinkedinIcon size={32} round={true} />
                                    </LinkedinShareButton>
                                    {/* <TwitterIcon url={'https://www.eventmploy.com/s/dashboard' + this.props.match.params.id} subject={props.title} size={32} round={true} /> */}
                                    {/* <TwitterShareButton url={'https://www.eventmploy.com/s/dashboard' + this.props.match.params.id}>
                                        <TwitterIcon size={32} round={true} />
                                    </TwitterShareButton> */}
                                    <span>Share</span>
                                </div>
                            </div>
						</div>

						<div className="pb-5 gallery-list">
                            {/* 
                            <OwlCarousel
                                    {...this.state.options}
                                >
                                    {supplier.gallery_images.map((image, index) => (
                                        <div className="item" key={index}>
                                          
                                            <img src={image} className="d-block w-100" alt="pic" />
                                        </div>
                                    ))}
                                </OwlCarousel>
                            */}
                            {supplier.gallery_images && (
                                <OwlCarousel className='owl-theme' margin={10}
                                    responsive={{
                                        0: {
                                            items: 1,
                                        },
                                        576: {
                                            items: 2,
                                            margin: 10,
                                        },
                                        1200: {
                                            items: 3,
                                        }
                                    }}
                                >
                                    {supplier?.gallery_images?.map((image, index) => (
                                        <div className="item" key={index}>
                                            <img src={image} className="d-block w-100" alt={supplier.company} />
                                        </div>
                                    ))}
                                </OwlCarousel>
                            )}
                            
                            {/* <div className="row gallery-list flex-nowrap overflow-auto">
                                {supplier.gallery_images && supplier.gallery_images.map((image, index) => (
                                    <div className="col-5" key={index}>
                                        <div className="sp-gal">
                                            <img src={image} className="d-block w-100" alt="pic" />
                                        </div>
                                    </div>
                                ))}
                            </div> */}
                        </div>

                        <div className="py-2 supplier-content">
                            <h4>{supplier.company}</h4>
                            <div dangerouslySetInnerHTML={{__html: supplier.bio}} />
                        </div>
					</div>
				</main>
			</div>
		)
	}
}

export default dashboard;