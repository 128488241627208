import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContactPopupLetMeKnow from '../organizer/snippet/ContactPopupLetMeKnow';
import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';
import Default from '../../assets/images/em-default.png';

// import Layout from '../../global/PrimaryLayoutFreelancer';
import SupplierFilter from '../supplier/snippet/SupplierFilter'



class Supplier extends Component {
	constructor(props){
        super(props);

        this.state= {
			supplier:[],
			loader: false
        }
	}

    storeResult(data){
        this.setState({
            supplier : data
        })
    }
	
	async componentDidMount(){
		// let Auth = localStorage.getItem('auth_empplyFreeUID');
        var myHeaders = new Headers();
		//   myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };
  
		// let AuthFree = localStorage.getItem('auth_empplyFreeUID');
		try {
		 await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_all_supplier`, requestOptions)
		  .then(response => response.json())
		  .then(result => {
			//   console.log(result);
			this.setState({
				supplier : result.suppliers,
				loader: true
			})
		  })
		  .catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
	}

	

	render() {
		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Supplier" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-8">
								<h1>EventMploy <span>Suppliers</span></h1>
								<p>Looking for suppliers for your next event? Search here to connect with EventMploy suppliers. To start your search again click the Refresh button next to Search to refresh the EventMploy search tool. Your service not listed? <ContactPopupLetMeKnow />.</p>
							</div>
						</div>

						<SupplierFilter  getResult={(result,source, data) => this.storeResult(result, source, data)} />

                        <div className="row">
                            <div className="col-xl-10">
                                <div className="row">
                                    {this.state.supplier && this.state.supplier.map((supplier, index) => (
                                        <div className="col-sm-6 mb-4" key={index}>
                                            <Link rel="nofollow" to={'/f/supplier/' + supplier.id} className="supplier-card d-block">
												{supplier.gallery_images?.length > 0 ? (
													<img src={supplier.gallery_images[0]} className="d-block w-100" alt={supplier.company} />
												):(
													<img src={Default} className="d-block w-100" alt={supplier.company} />
												)}
                                                <span>{supplier.location && supplier.location.street_address}</span>
                                                <h4>{supplier.company}</h4>
												<div dangerouslySetInnerHTML={{__html: supplier.bio}} />
                                            </Link>
                                        </div>
                                    ))}

                                    {this.state.supplier && this.state.supplier[0] ? null : (
                                        <div className="col-sm-12 mb-md-5 mb-4">
                                            <div className="blank-area text-center">
                                                <h3>No supplier found.</h3>
                                                {/* <p><small>Check for job offers <Link className="text-primary" to="/f/offers">here</Link>!</small></p> */}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
					</div>
				</main>
			</div>
		);
	}
}

export default Supplier;