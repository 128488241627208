import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';

class Notification extends Component {
	constructor(props){
        super(props);

        this.state= {
			notification: [],
			id: '',
			category: '',
			planStatus:1,
            loader: false
        }

		this.updateNotiy = this.updateNotiy.bind();
	}

	async componentDidMount(){
		var myHeaders = new Headers();
		//   myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };

		  try {
  
		let AuthSup = localStorage.getItem('auth_empplySplierUID');

		await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_notifications&user_id=${AuthSup}`, requestOptions)
		  .then(response => response.json())
		  .then(result => {
			//   console.log(result);
			this.setState({
				notification : result,
				loader: true
			})
		  })
		  .catch(error => console.log('error', error));

		  await fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${AuthSup}`, requestOptions)
		  .then(response => response.json())
		  .then(result => {
			  this.setState({
				  planStatus : result.data?.subscription_status
			  });
		  })
		  .catch(error => console.log('error', error));

		} catch (error) {
			console.log('error', error);
		}
	}

	updateNotiy = async (id, jobID, category) =>{
		var myHeaders = new Headers();
		//   myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };
		  try {
		let AuthSup = localStorage.getItem('auth_empplySplierUID');

		  await fetch(`${process.env.REACT_APP_APIURL}?endpoint=update_unread_notification&user_id=${AuthSup}&notification_id=${id}`, requestOptions)
		  .then(response => response.json())
		  .then(result => {
			//   console.log(category)
			this.setState({
				id: jobID,
				category: category
			})
		  })
		  .catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
	}

	render() {
		const notification = this.state.notification;
		const profileStatus = this.state.planStatus;

		// console.log(notification);
		
		// if(this.state.redirect){
		// 	return this.state.category === "Message" ? <Redirect to={`/f/user/${this.state.id}`}/> : <Redirect to={`/f/job/${this.state.id}`}/>
		// }

		if(profileStatus === 0){
			return(
				<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Supplier Notifications" />
				<main className="page-dashboard pt-0">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>notifications</span></h1>
								<p>Job offers will appear here.  Make sure you accept or decline the offer as soon as possible.  Need more information?  Use the chat to send a message to the event organiser.  Job offers may be revoked by event organisers if you don’t respond to the offer in a timely manner, so respond now! </p>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 mb-md-5 mb-4">
								<div className="blank-area text-center">
									<h3>You have no <span>active plan</span>.</h3>
									<p><small>Please buy plan <Link rel="nofollow" className="text-primary" to="/f/membership">here</Link>!</small></p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			)
		}

		return (
			<div>
			<Loader loader={this.state.loader} />
			<SEOPage pageTitle="Supplier Notifications" />
				<main className="page-dashboard pt-0">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>notifications</span></h1>
								<p>Job offers will appear here.  Make sure you accept or decline the offer as soon as possible.  Need more information?  Use the chat to send a message to the event organiser.  Job offers may be revoked by event organisers if you don’t respond to the offer in a timely manner, so respond now! </p>
							</div>
						</div>

						<div className="row">
							{notification.notifications && notification.notifications.map((notify, index) => (
								<div className="col-12 mb-4" key={index}> 
									<div className={`notification ${notify.is_read === "1" ? '' : 'active'}`}>
										<p><button className="btn btn-link p-0 mb-1" onClick={()=>this.updateNotiy(notify.id, notify.job_id, notify.category)}>{notify.mesage}</button></p>
										<div className="notification-date">{notify.date}</div>
									</div>
								</div>
							))}

							{notification.notifications && notification.notifications[0] ? null : (
								<div className="col-sm-12 mb-md-5 mb-4">
									<div className="blank-area text-center">
										<h3>No notifications at the moment</h3>
										{/* <p><small>Please find job on here <Link className="text-primary" to="/f/dashboard">Click</Link>!</small></p> */}
									</div>
								</div>
							)}
						</div>
					</div>
				</main>
			</div>
		);
	}
}

export default Notification;