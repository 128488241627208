import React, { Component } from 'react';
import {Dropdown} from 'react-bootstrap';

import { NavLink, Link, Redirect} from 'react-router-dom';

import logoSm from '../../assets/images/logo.svg';
// import User from '../../assets/images/user.png';
import menuBars from '../../assets/images/menu-bars.svg';


import GetRating from '../snippet/GetRating';



class DashboardHeader extends Component {
	constructor(props){
        super(props);

        this.state= {
			redirect: false,
			userDetails: {},
			sideNav: false,
			starRating : {},
			counter: '',
			counterNotification: '',
            fields: {},
            errors: {},
			jobs: [],
			dropShow: false,
			spin: false
        }

		this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
	}
	
	async componentDidMount(){
		var myHeaders = new Headers();
		  myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };
		  
		  try {
				let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
				//   ?itemType=event_orgniser_detail_web&user_id=1571
				await fetch(`${process.env.REACT_APP_APIURL}?endpoint=event_orgniser_detail_web&user_id=${AuthOrg}`, requestOptions)
				.then(response => response.json())
				.then(result => {
					//   console.log(result);
					this.setState({
						starRating : result.get_average_rating,
						counter: result.count_unread?.message_count,
						counterNotification : result.count_unread_notification?.count,
						userDetails : result.user_detail
					})
				})
				.catch(error => console.log('error', error));
			} catch (error) {
				console.log('error', error);
			}

	}

	logout = () => {
        localStorage.removeItem("auth_empplySplierUID");
        localStorage.removeItem("auth_empplyFreeUID");
        localStorage.removeItem("auth_empplyOrgUID");

        this.setState({
            redirect: true,
			sideNav: !this.state.sideNav
        });
	};

	toggleFun = () => {
		this.setState({
			sideNav: !this.state.sideNav
		})
	}

	// handleDrop = (e) => {
	// 	this.setState({
	// 		dropShow : e
	// 	})
	// }

	handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
	}

	handleSubmit = async (e) => {
        e.preventDefault();
		
        if (this.validateForm()) {

			this.setState({
				spin: true
			})
			
			var myHeaders = new Headers();
	
			var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
			};
	
			let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
		await fetch(`${process.env.REACT_APP_APIURL}?endpoint=search_job_eo&srckeyword=${this.state.fields.searchfield}&user_id=${AuthOrg}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					jobs : result,
					spin: false
				});
				this.props.getSearch(result);
			})
			.catch(error => console.log('error', error));
        
        } //this.validateForm
	}

	validateForm() {
		let fields = this.state.fields;
		let formIsValid = true;
		let errors = {};
	
		// ABN error messages
		if (!fields["searchfield"]) {
			formIsValid = false;
			errors["searchfield"] = "*Please enter search text.";
		}
	
	
		this.setState({
		  errors: errors
		});
		return formIsValid;
	}
	
	render() {
		const userDetails = this.state.userDetails;
		
		const starRating = this.state.starRating;
		// console.log(starRating);
		// console.log(userDetails);


		if (this.state.redirect) {
            return <Redirect push to="/" />;
		}

		
		return (
			<>
				<aside className={`sidebar d-flex flex-column ${this.state.sideNav ? 'active' : ''}`}>

					<div className="sidebar_logo d-flex align-items-center">
						<Link rel="nofollow" onClick={() => this.toggleFun()} to="/"><img src={logoSm} alt="logo" /></Link>
						<button onClick={() => this.toggleFun()} className="btn text-secondary d-block d-lg-none btn-close ml-auto p-0"><i className="fas fa-times"></i></button>
					</div>

					<div className="sidebar_links flex-grow-1">
						<ul className="list-unstyled">
							<li><NavLink rel="nofollow" onClick={() => this.toggleFun()} activeClassName="active" exact to="/">Home</NavLink></li>
						</ul>
						
						<h6>Jobs</h6>
						<ul className="list-unstyled">
							<li><NavLink rel="nofollow" onClick={() => this.toggleFun()} activeClassName="active" exact to="/e/current-jobs">My Current jobs</NavLink></li>
							<li><NavLink rel="nofollow" onClick={() => this.toggleFun()} activeClassName="active" exact to="/e/previous-jobs">My Previous jobs</NavLink></li>
							<li><NavLink rel="nofollow" onClick={() => this.toggleFun()} activeClassName="active" exact to="/e/job-post">Post A New Job</NavLink></li>
							<li><NavLink rel="nofollow" onClick={() => this.toggleFun()} activeClassName="active" exact to="/e/suppliers">Suppliers</NavLink></li>
						</ul>
						
						<h6>Me</h6>
						<ul className="list-unstyled">
							<li><NavLink rel="nofollow" onClick={() => this.toggleFun()} activeClassName="active" exact to="/e/my-rating">My Ratings</NavLink></li>
							<li><NavLink rel="nofollow" onClick={() => this.toggleFun()} activeClassName="active" exact to="/e/my-favourites">My Favourites</NavLink></li>
							<li><NavLink rel="nofollow" onClick={() => this.toggleFun()} activeClassName="active" exact to="/e/leave-rating">Leave Rating</NavLink></li>
							<li><NavLink rel="nofollow" onClick={() => this.toggleFun()} activeClassName="active" exact to="/e/calendar">My Calendar</NavLink></li>
							<li><NavLink rel="nofollow" onClick={() => this.toggleFun()} activeClassName="active" exact to="/e/membership">My Plan</NavLink></li>
						</ul>
						
						<div className="d-block d-lg-none">
							<h6>User Access</h6>
							<ul className="list-unstyled">
								<li><NavLink rel="nofollow" onClick={() => this.toggleFun()} activeClassName="active" exact to="/e/profile">Profile</NavLink></li>
								<li><Link rel="nofollow" to="/" onClick={() => this.logout()}>Sign out</Link></li>
							</ul>
						</div>
					</div>

					{/* <div className="sidebar_user d-flex align-items-center">
						<img src={user} className="d-block d-lg-none" alt="user" />
						<div>
							<strong className="text-truncate text-capitalize">{rating.userFullName}</strong>
							<div className="rating_stars text-primary">
								<i className="fas fa-star"></i>
								<i className="fas fa-star"></i>
								<i className="fas fa-star"></i>
								<i className="fas fa-star-half-alt"></i>
								<i className="far fa-star"></i>
							</div>
							<StarRating name="small-rating" caption="Small!" size={30} totalStars={5} rating={3} />
							<GetRating rating="2" />
						</div>
					</div> */}

					{starRating && (
						<div className="sidebar_user d-flex align-items-center">
							{/* <img src={User} className="d-block d-lg-none" alt="user" /> */}
							<div className='w-100'>
								<strong className="text-truncate text-capitalize">{starRating.userFullName}</strong>
								<GetRating starRating={starRating.avg_rating} />
							</div>
						</div>
					)}

					</aside>

					<div className="header-dashboard d-flex align-items-center">
					<form onSubmit={this.handleSubmit} className="d-none d-md-flex input-group input-group-search">
						<div className="input-group-prepend">
							<span className="input-group-text text-secondary pr-lg-2 pr-1 pl-ld-4 pl-3 border-0 bg-transparent" id="basic-addon1"><i className="fas fa-search"></i></span>
						</div>
						<input type="text" name="searchfield" onChange={this.handleChange} className="form-control border-0 bg-transparent" placeholder="Search events & freelancers..." />
						{this.state.spin && <div className="spin"></div>}
					</form>
					<a className="navbar-brand d-block d-md-none p-md-0" href="/">
						<img src={logoSm} alt="logo" />
					</a>

					<div className="header-controls d-flex align-items-center">
						<NavLink rel="nofollow" to="/e/notifications" className="mr-4 mr-3">
							<i className="fas fa-bell"></i>
							{this.state.counterNotification > 0 && (<span className="badge badge-count">{this.state.counterNotification}</span>)}
						</NavLink>
						<NavLink rel="nofollow" to="/e/chat" className="mr-4 mr-3">
							<i className="fas fa-comment-dots"></i>
							{this.state.counter > 0 && (<span className="badge badge-count">{this.state.counter}</span>)}
						</NavLink>
						<Dropdown>
							<Dropdown.Toggle variant="primary" className="ur_short_name p-0 border-0 btn-hm-primary dropdown-no-arrow dropdown-menu-right d-none d-md-block" id="dropdown-basic">
							{userDetails.first_name && userDetails.first_name.slice(0, 1)}{userDetails.last_name && userDetails.last_name.slice(0, 1)}
							</Dropdown.Toggle>

							<Dropdown.Menu menualign="right">
								<div className="dropdown-info text-secondary d-flex align-items-center">
									<div className="ur_short_name">
										{userDetails.first_name && userDetails.first_name.slice(0, 1)}{userDetails.last_name && userDetails.last_name.slice(0, 1)}
									</div>
									<div className="ml-2">
										<h3>{userDetails.userFullName}</h3>
										{userDetails.emailAddress}
									</div>
								</div>
								<NavLink rel="nofollow" onClick={() => this.toggleFun()} className="dropdown-item" to="/e/profile">Profile</NavLink>
								<Link rel="nofollow" className="dropdown-item" to="/" onClick={() => this.logout()}>Sign out</Link>
							</Dropdown.Menu>
						</Dropdown>
						<button onClick={() => this.toggleFun()} className="btn btn-menu d-block d-lg-none p-0 ml-lg-0 ml-md-4"><img className="d-block" width={30} height={30} src={menuBars} alt="bar" /></button>
					</div>
					</div>

					<div className="body-search d-flex d-md-none">
						<form onSubmit={this.handleSubmit} className="input-group input-group-search">
							<div className="input-group-prepend">
								<span className="input-group-text text-secondary pr-lg-2 pr-1 pl-ld-4 pl-3 border-0 bg-transparent" id="basic-addon1"><i className="fas fa-search"></i></span>
							</div>
							<input type="text" name="searchfield" onChange={this.handleChange} className="form-control border-0 bg-transparent" placeholder="Search events & freelancers..." />
							{this.state.spin && <div className="spin"></div>}
						</form>
					</div>	
</>
		);
	}
}

export default DashboardHeader;