import React, { Component } from 'react';
import signupMob from '../assets/images/signup-mob.png';
import signup from '../assets/images/signup.png';
import {
    Link
  } from "react-router-dom";


import Loader from './snippet/Loader';
import SEOPage from './snippet/SEOPage';

class ForgotPassword extends Component {
	constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
			messageText: '',
			formLoader: false,
			pageData : {},
			imageAlt: '',
            loader: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

	handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
    }


    handleSubmit = (e) => {
        e.preventDefault();

        if (this.validateForm()) {
			this.setState({
				formLoader: true
			})
			
			var myHeaders = new Headers();
			myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
	
			var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
			};
	
			fetch(`${process.env.REACT_APP_APIURL}?endpoint=reset_password&reset_email=${this.state.fields.email}`, requestOptions)
			.then(async response => await response.json())
			.then(result => {
				//   console.log(result);
				this.setState({
					messageText : result.error_string,
					formLoader: false
				})
				setTimeout(() => {
					window.location.reload();
				}, 800);
			})
			.catch(error => console.log('error', error));

        }
    }

	validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
    
        // Email Address error messages
        if (!fields["email"]) {
          formIsValid = false;
          errors["email"] = "*Please enter your Email Address.";
        }
    
        if (typeof fields["email"] !== "undefined") {
          //regular expression for email validation
		  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,15}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(fields["email"])) {
            formIsValid = false;
            errors["email"] = "*Please enter valid Email Address.";
          }
        }
    
    
        this.setState({
          errors: errors
        });
        return formIsValid;
	  }

	  async componentDidMount(){
		var myHeaders = new Headers();
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		// 
		try {
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=Forgot Password`, requestOptions)
			.then(async response => await response.json())
			.then(result => {
				this.setState({
					pageData: result,
					loader : true
				});
				if(result.alt_text){
					this.setState({
						imageAlt : result.alt_text
					})
				}
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
	  }

	render() {
		return (
			<div>
				<Loader loader={this.state.loader} />
            	<SEOPage pageTitle="Forgot Password" />
				<main className="page-access">
					<div className="page-image" style={{backgroundImage: `url(${this.state.pageData && this.state.pageData.background_image ? this.state.pageData.background_image : signup})`}}>
						<img src={this.state.pageData && this.state.pageData.background_image ? this.state.pageData.background_image : signupMob} className="w-100 d-block d-md-none" alt={this.state.imageAlt} />
					</div>
					<div className="container p-0 container-xxl">
						<div className="row no-gutters">
							<div className="col-lg-6">
								<div className="page-content-inner px-4">
									<h1>Forgot <br/><span>password?</span></h1>
									{/* <p>It happens to all of us, just use the form below to reset your password.</p> */}
									<div dangerouslySetInnerHTML={{__html: this.state.pageData && this.state.pageData.content}} />
									
									<div className="form-area pt-md-3 pt-2">
										<form onSubmit={this.handleSubmit}>
											<div className="form-row">
												<div className="form-group col-12">
													<div className="form-field">
														<label htmlFor="emailid">Email</label>
														<input 
															onChange={this.handleChange}
															type="email" 
															className="form-control" 
															placeholder=""
															id="emailid" 
															name="email"
														/>
													</div>
													<div className={`invalid-feedback ${this.state.errors.email ? 'd-block' : null}`}>{this.state.errors.email}</div>
													{/* <div className={`invalid-feedback ${this.state.error ? 'd-block' : null }`}>{this.state.error}</div> */}

												</div>
												<div className="form-group col-12">
													<button type="submit" className="btn btn-hm-primary btn-block btn-lg mb-3 px-4 d-flex align-items-center" disabled={this.state.formLoader ? true : false}>{this.state.formLoader ? 'Sending...' : 'Continue'} <i className="fas ml-auto fa-arrow-right"></i></button>
													{this.state.messageText && <p className="text-success">{this.state.messageText}</p>}
													<p>The information you provide on this form will be retained and handled by TheMode in accordance with our <Link className="text-primary" to="/privacy-policy">Privacy Policy</Link>.</p>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		);
	}
}

export default ForgotPassword;