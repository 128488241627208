import React, { Component } from 'react';
import {
    Link, Redirect
  } from "react-router-dom";
// import logoSm from '../../assets/images/logo-sm.svg';
import logo from '../../assets/images/logo.svg';
import MenuBar from '../../assets/images/menu-bars.svg';

class Header extends Component {
    constructor(props){
        super(props);

        this.state= {
			redirect: false,
			navToggle: false,
			loggedIn : false
        }
	}
	
	toggleFun = () => {
		this.setState({
			navToggle: !this.state.navToggle
		})
	}

	toggleFunLink = () => {
		this.setState({
			navToggle: false
		})
    }

	componentDidMount(){
		let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
		let AuthFree = localStorage.getItem('auth_empplyFreeUID');
		let AuthSup = localStorage.getItem('auth_empplySplierUID');

		if(AuthOrg || AuthFree || AuthSup){
			this.setState({
				loggedIn: true
			})
		}
	}

	logout = () => {
        localStorage.removeItem("auth_empplySplierUID");
        localStorage.removeItem("auth_empplyFreeUID");
        localStorage.removeItem("auth_empplyOrgUID");

        this.setState({
            redirect: true
        });

		window.location.reload();
	};

    render() {
		let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
		let AuthFree = localStorage.getItem('auth_empplyFreeUID');
		let AuthSup = localStorage.getItem('auth_empplySplierUID');

		if (this.state.redirect) {
            return <Redirect push to="/" />;
		}

        return(
			<>
				<div className='header-top'>Your One-Stop Destination For Building Your Event Network!</div>
				<header className="header section-header position-absolute w-100">
					<div className="container-fluid">
						<nav className="navbar p-0 navbar-expand-lg">
						<Link className="navbar-brand p-md-0" to="/" onClick={() => this.toggleFunLink()}>
							<img src={logo} width={274} height={65} alt="logo" />
							{/* <img src={logoSm} className="d-md-none d-block" alt="logo" /> */}
						</Link>
						<button className="navbar-toggler p-0" onClick={() => this.toggleFun()} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"><img src={MenuBar} width={30} height={30} className="d-block" alt="bar" /></span>
						</button>

						<div className={`collapse navbar-collapse ${this.state.navToggle ? 'show' : ''}`} id="navbarSupportedContent">
							<ul className="navbar-nav mx-auto">
							<li className="nav-item active">
								{AuthSup ? (
									<Link className="nav-link" rel="nofollow" onClick={() => this.toggleFunLink()} to={"/s/dashboard"}>Advertise</Link>
								):(
									<Link className="nav-link" onClick={() => this.toggleFunLink()} to={"/advertise"}>Advertise</Link>
								)}
							</li>
							{this.state.loggedIn ? (
								<li className={`nav-item ${AuthOrg || AuthSup ? 'd-none' : ''}`}>
									{AuthFree ? (
										<Link className="nav-link" rel="nofollow" onClick={() => this.toggleFunLink()} to={"/f/accepted"}>Jobs</Link>
									):(
										<Link className="nav-link" onClick={() => this.toggleFunLink()} to={"/login"}>Jobs</Link>
									)}
								</li>
							): (
								<li className="nav-item">
									<Link className="nav-link" onClick={() => this.toggleFunLink()} to="/login">Jobs</Link>
								</li>
							)}

							{this.state.loggedIn ? (
								<li className={`nav-item ${AuthFree || AuthSup ? 'd-none' : ''}`}>
									{AuthOrg ? (
										<Link className="nav-link" rel="nofollow" onClick={() => this.toggleFunLink()} to={"/e/job-post"}>Post A Job</Link>
									):(
										<Link className="nav-link" onClick={() => this.toggleFunLink()} to={"/login"}>Post A Job</Link>
									)}
								</li>
							): (
								<li className="nav-item">
									<Link className="nav-link" onClick={() => this.toggleFunLink()} to="/login">Post A Job</Link>
								</li>
							)}
							{/* <li className="nav-item">
								<Link className="nav-link" onClick={() => this.toggleFunLink()} to="/webinars">webinars</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" onClick={() => this.toggleFunLink()} to="/blog">blog</Link>
							</li> */}
							<li className="nav-item">
								<Link className="nav-link" onClick={() => this.toggleFunLink()} to="/resources">Resources</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" onClick={() => this.toggleFunLink()} to="/about">About Us</Link>
							</li>
							</ul>

							{this.state.loggedIn ? (
								<Link className="nav-link btn-hm btn-hm-primary" to="/" onClick={() => {this.logout(); this.toggleFunLink()}}>Log Out</Link>
							) : (
								<Link className="nav-link btn-hm btn-hm-primary" onClick={() => this.toggleFunLink()} to="/login">Log In</Link>
							)}
							{this.state.loggedIn ? null : (
								<Link to="/get-started" onClick={() => this.toggleFunLink()} className="btn mb-md-0 mb-4 d-block d-md-inline-block btn-hm btn-hm-primary-outline">Sign Up</Link>
							)}
						</div>
						</nav>
					</div>
				</header>
			</>
        );
    }
}
export default Header;