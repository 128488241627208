const { compose, withProps, lifecycle } = require("recompose");
const { withScriptjs } = require("react-google-maps");
const {
  StandaloneSearchBox
} = require("react-google-maps/lib/components/places/StandaloneSearchBox");

const PlacesWithStandaloneSearchBox = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCkHiUSCNalJaGs9LTpzCMpPMtIZ-EaSOA&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};

      this.setState({
        places: [],
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const data = {
            lat : places[0].geometry.location.lat(),
            long : places[0].geometry.location.lng(),
            location : places[0].formatted_address,
            location_address: places[0].address_components
          }
          // console.log(data);
          this.props.getAddress(data);
          this.setState({
            places
          });
        }
      });
    }
  }),
  withScriptjs
)(props => (
  <div data-standalone-searchbox="">
    <StandaloneSearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      onPlacesChanged={props.onPlacesChanged}
    >
      <input
        type="text"
        placeholder={props.value ? props.value : "Enter your location"}
        // value={props.value}
        className="form-control"
        style={{
          textOverflow: `ellipses`
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') e.preventDefault();
        }}
      />
    </StandaloneSearchBox>
    <ol className="d-none">
      {props.places.map(
        ({ place_id, formatted_address, geometry: { location } }) => (
          <li key={place_id}>
            {formatted_address}
            {" at "}
            ({location.lat()}, {location.lng()})
          </li>
        )
      )}
    </ol>
  </div>
));

export default PlacesWithStandaloneSearchBox;