import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import { HelmetProvider } from 'react-helmet-async';

const SEO = ({pageTitle}) => {
    const [content, setContent] = useState();

    useEffect(() => {
        async function fetchData() {
            if(pageTitle){
                var myHeaders = new Headers();
                myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
                myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
                
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                // 
                try {
                    await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=${pageTitle}`, requestOptions)
                    .then(async response => await response.json())
                    .then(result => {
                        if(result.seo){
                            setContent(result.seo);
                        }else{
                            setContent({
                                title: "The Leading Freelance Services Marketplace for Events in Australia",
                                description: "EventMploy is the premier freelance services marketplace for event organisers, connecting you with the top event freelancers and suppliers in Australia. Hire now!",
                                keywords: "freelance services marketplace, event freelancers Australia, event suppliers Australia"
                            })
                        }
                        // console.log(result.seo);
                    })
                    .catch(error => console.log('error', error));
                } catch (error) {
                    console.log('error', error);
                }
            }
          }
          fetchData();
    }, [pageTitle])

    return(
        <HelmetProvider>
            <Helmet >
                <meta charSet="utf-8" />
                <title>{content && content.title}</title>
                <meta name="description" content={content && content.description} />
                <meta name="keywords" content={content && content.keywords} />
                <link rel="canonical" href="https://www.eventmploy.com/" />
                <meta name="robots" content="index, follow" />

                <script type="application/ld+json">{`
                    {
                    "@context": "https://schema.org",
                    "@type": "LocalBusiness",
                    "name": "EventMploy",
                    "image": "https://www.eventmploy.com/static/media/logo.2e6cb1f6.svg",
                    "@id": "",
                    "url": "https://www.eventmploy.com/",
                    "telephone": "+61421 725794",
                    "priceRange": "$$",
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "Eventmploy, Lisa Price 33 George Street",
                        "addressLocality": "Sandringham",
                        "addressRegion": "VIC",
                        "postalCode": "3191",
                        "addressCountry": "AU"
                    },
                    "geo": {
                        "@type": "GeoCoordinates",
                        "latitude": -37.958164,
                        "longitude": 145.0288693
                    },
                    "openingHoursSpecification": {
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday"
                        ],
                        "opens": "00:00",
                        "closes": "23:59"
                    },
                    "sameAs": [
                        "https://www.facebook.com/EventMploy",
                        "https://www.instagram.com/eventmploy/",
                        "https://www.youtube.com/@eventmploy",
                        "https://www.linkedin.com/company/eventmploy"
                    ] 
                    }
                `}</script>

                {pageTitle === "FAQ" && (
                    <script type="application/ld+json">{`
                        {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                            "@type": "Question",
                            "name": "How does EventMploy work?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "EventMploy is designed to connect event organisers with event freelancers by matching skills, availability, location, desired pay rate and certifications."
                            }
                        },{
                            "@type": "Question",
                            "name": "I am an event freelancer. How do I get work?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Event freelancers are offered work when their skills, location and expected pay rate matches a job posting from an event organiser. To receive a job offer you will need to have an EventMploy subscription. Our annual subscription enables your profile to be made available to an event organiser who posts a job when it matches your profile. Event organisers will then pick a freelancer from the list of matching freelancers and make an offer. When you are offered a job you can chat directly with the event organiser for more information, you can accept the job or you can decline the job."
                            }
                        },{
                            "@type": "Question",
                            "name": "I am an event freelancer. How do I get paid?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "EventMploy is designed to connect event freelancers with event organisers. Once you have been offered and accepted a job, you deal directly with the event organiser on the terms of engagement. You can chat with event organisers after they have given you a job offer to clarify this prior to accepting the job offer."
                            }
                        },{
                            "@type": "Question",
                            "name": "I don’t have any event experience. Is EventMploy right for me?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "EventMploy is not a training site, only people who can demonstrate they have the skills and experience listed on their profile will obtain work. EventMploy reserves the right to check information provided by freelancers to ensure accuracy."
                            }
                        },{
                            "@type": "Question",
                            "name": "I am a freelancer. How can I increase my chances of getting work?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "To increase your chances of work, make sure that your profile is accurate, honest and kept up to date, this includes your experience, certifications and skills. Make sure your expected pay rate is in line with the applicable Award or industry standards."
                            }
                        },{
                            "@type": "Question",
                            "name": "I am an event organiser. Does EventMploy guarantee the skills or experience of the freelancers listed?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "EventMploy expects all users to provide accurate and honest information. For the integrity of the service, checks on freelancer skills and experience may be undertaken. Event organisers are encouraged to review the information provided by the freelancer prior to making a job offer."
                            }
                        },{
                            "@type": "Question",
                            "name": "I am an event organiser. How can I find a freelancer?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Event organisers can find matching event freelancers by posting a job. To post a job you will need to have an EventMploy subscription. Our monthly or annual subscriptions allow you to post unlimited jobs. List the work dates, required skills and certifications, event location, and the rate you will be paying. EventMploy will then display only freelancers who match your requirements. Pick a freelancer, offer them the job and when they accept engage with the freelancer directly."
                            }
                        },{
                            "@type": "Question",
                            "name": "I am an event organiser and need a number of freelancers in the same job role. Can I do this with EventMploy?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Absolutely, when listing a job just select how many freelancers you are looking for, all matching freelancers will be shown to you and you can select the number you need."
                            }
                        },{
                            "@type": "Question",
                            "name": "How does the rating system work?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "EventMploy uses a two-way rating system that averages all ratings to provide an overall star rating to be displayed on the system. Once a job is completed both the event organiser and the event freelancer will be able to provide a star rating for the completed job. Users are encouraged to provide an objective rating based on the work. For example, as a freelancer, if you were provided with clear expectations, the tools and resources to meet those expectations, a safe workplace and paid in accordance with the terms of your agreement then this would be a 5-star rating. Event organisers, if the freelancer understood the work outcomes and standards expected and then met or exceeded your expectations then this would be a 5-star rating."
                            }
                        },{
                            "@type": "Question",
                            "name": "I am an event freelancer, and an event organiser can I have two accounts?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, you can. You will need to have a unique email address for each account, but all your other details can remain the same."
                            }
                        },{
                            "@type": "Question",
                            "name": "I want to cancel my subscription what should I do?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "More than any other industry we understand that in Events things change — you might have landed a permanent gig or found the perfect freelancer through word of mouth, or you forgot to cancel in time, or even realised that EventMploy is not for you. Not to worry! We want to make sure that we give our customers real value. To cancel either a monthly or annual subscription please contact our customer team — you can easily do that either by emailing hello@eventmploy.com or reach out via our contact form. For monthly subscriptions please make sure you let us know at least 48 hours before the next month is billed. For annual subscriptions a pro-rata refund will be given for the remaining time left on your subscription. Please keep in mind: When you cancel your subscription, you lose access to all job data on EventMploy. If you are using an app uninstalling the app doesn’t automatically cancel your subscription — you’ll still be charged for your subscription."
                            }
                        }]
                        }
                    `}</script>
                )}
            </Helmet>
        </HelmetProvider>
    )
}

export default SEO;