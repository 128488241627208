import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = ({view}) => (
  <>
  <div className={`not-found ${view}`}>
        <div className="blank-area text-center">
            <h2 className='mb-4' style={{lineHeight: 0.8}}><strong>404 - Not Found! <br /><small style={{fontSize: '0.5em'}}>Ooops something went wrong...</small></strong></h2>
            {view === 'landing' ? (
              <Link className='btn btn-hm-primary' to="/">Go Home</Link>
              ):(
              <Link rel="nofollow" className='btn btn-hm-primary' to="/">Go Dashboard</Link>
            )}
            <p className='d-block d-md-none mt-4 pt-2 px-3'><strong className='text-primary font-weight-bold'>Note: </strong>If your are coming from App then please try to logout in web browser and then continue with same app journey.</p>
          <div>
        </div>
      </div>
    </div>
    
  </>
);

export default NotFound;