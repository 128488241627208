import React, { Component } from 'react'
import update from 'react-addons-update';
import Item from './CalenderAvailabilityitem';

export default class CalenderAvailability extends Component {
    constructor(props){
        super(props);

        this.state= {
			weekDay: [
                {
                    day: 'Sun',
                    start: '',
                    end: ''
                },
                {
                    day: 'Mon',
                    start: '',
                    end: ''
                },
                {
                    day: 'Tue',
                    start: '',
                    end: ''
                },
                {
                    day: 'Wed',
                    start: '',
                    end: ''
                },
                {
                    day: 'Thu',
                    start: '',
                    end: ''
                },
                {
                    day: 'Fri',
                    start: '',
                    end: ''
                },
                {
                    day: 'Sat',
                    start: '',
                    end: ''
                }
            ],
            weekDayGet: [],
            message: ''
        }
	}

    ItemsSave = async () => {
        try {
        let Auth = localStorage.getItem('auth_empplyFreeUID');

          var formdata = new FormData();
            formdata.append("endpoint", "set_availability");
            formdata.append("user_id", Auth);
            formdata.append("type", "all");
            formdata.append("availbility[0][day]",this.state.weekDay[0].day ? this.state.weekDay[0].day : '');
            formdata.append("availbility[0][start]",this.state.weekDay[0].end ? this.state.weekDay[0].start : '');
            formdata.append("availbility[0][end]",this.state.weekDay[0].end ? this.state.weekDay[0].end : '');
            formdata.append("availbility[1][day]",this.state.weekDay[1].day ? this.state.weekDay[1].day : '');
            formdata.append("availbility[1][start]",this.state.weekDay[1].end ? this.state.weekDay[1].start : '');
            formdata.append("availbility[1][end]",this.state.weekDay[1].end ? this.state.weekDay[1].end : '');
            formdata.append("availbility[2][day]",this.state.weekDay[2].day ? this.state.weekDay[2].day : '');
            formdata.append("availbility[2][start]",this.state.weekDay[2].end ? this.state.weekDay[2].start : '');
            formdata.append("availbility[2][end]",this.state.weekDay[2].end ? this.state.weekDay[2].end : '');
            formdata.append("availbility[3][day]",this.state.weekDay[3].day ? this.state.weekDay[3].day : '');
            formdata.append("availbility[3][start]",this.state.weekDay[3].end ? this.state.weekDay[3].start : '');
            formdata.append("availbility[3][end]",this.state.weekDay[3].end ? this.state.weekDay[3].end : '');
            formdata.append("availbility[4][day]",this.state.weekDay[4].day ? this.state.weekDay[4].day : '');
            formdata.append("availbility[4][start]",this.state.weekDay[4].end ? this.state.weekDay[4].start : '');
            formdata.append("availbility[4][end]",this.state.weekDay[4].end ? this.state.weekDay[4].end : '');
            formdata.append("availbility[5][day]",this.state.weekDay[5].day ? this.state.weekDay[5].day : '');
            formdata.append("availbility[5][start]",this.state.weekDay[5].end ? this.state.weekDay[5].start : '');
            formdata.append("availbility[5][end]",this.state.weekDay[5].end ? this.state.weekDay[5].end : '');
            formdata.append("availbility[6][day]",this.state.weekDay[6].day ? this.state.weekDay[6].day : '');
            formdata.append("availbility[6][start]",this.state.weekDay[6].end ? this.state.weekDay[6].start : '');
            formdata.append("availbility[6][end]",this.state.weekDay[6].end ? this.state.weekDay[6].end : '');

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            // console.log(
            //     this.state.weekDay[0].day ? this.state.weekDay[0].day : '',
            //     this.state.weekDay[0].end ? this.state.weekDay[0].start : '',
            //     this.state.weekDay[0].end ? this.state.weekDay[0].end : '',
            //     this.state.weekDay[1].day ? this.state.weekDay[1].day : '',
            //     this.state.weekDay[1].end ? this.state.weekDay[1].start : '',
            //     this.state.weekDay[1].end ? this.state.weekDay[1].end : '',
            //     this.state.weekDay[2].day ? this.state.weekDay[2].day : '',
            //     this.state.weekDay[2].end ? this.state.weekDay[2].start : '',
            //     this.state.weekDay[2].end ? this.state.weekDay[2].end : '',
            //     this.state.weekDay[3].day ? this.state.weekDay[3].day : '',
            //     this.state.weekDay[3].end ? this.state.weekDay[3].start : '',
            //     this.state.weekDay[3].end ? this.state.weekDay[3].end : '',
            //     this.state.weekDay[4].day ? this.state.weekDay[4].day : '',
            //     this.state.weekDay[4].end ? this.state.weekDay[4].start : '',
            //     this.state.weekDay[4].end ? this.state.weekDay[4].end : '',
            //     this.state.weekDay[5].day ? this.state.weekDay[5].day : '',
            //     this.state.weekDay[5].end ? this.state.weekDay[5].start : '',
            //     this.state.weekDay[5].end ? this.state.weekDay[5].end : '',
            //     this.state.weekDay[6].day ? this.state.weekDay[6].day : '',
            //     this.state.weekDay[6].end ? this.state.weekDay[6].start : '',
            //     this.state.weekDay[6].end ? this.state.weekDay[6].end : '',
            // )

            await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.data.availbility[0]){
                    this.setState({
                        weekDay : result.data.availbility,
                        message: result.mesage
                    });
                    window.location.reload();
                }
            })
            .catch(error => console.log('error', error));
        } catch (error) {
            console.log('error', error);
        }
    }

    async componentDidMount(){
        var myHeaders = new Headers();
		//   myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };
          try {
		        let AuthFree = localStorage.getItem('auth_empplyFreeUID');

		  await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_availability&user_id=${AuthFree}&type=all`, requestOptions)
		  .then(response => response.json())
		  .then(result => {
              setTimeout(() => {
                if(result.data.availbility[0]){
                        this.setState({
                            weekDayGet : result.data.availbility,
                            weekDay : result.data.availbility
                        })
                    }
                }, 400);
		  })
		  .catch(error => console.log('error', error));
        } catch (error) {
            console.log('error', error);
        }
    }

    render() {
        const week = this.state.weekDay;
        const weekGet = this.state.weekDayGet;
        // console.log(weekGet);

        return (
            <div className="page-info">
                
                <h1>Set your <span>weekly</span> availability</h1>
                <p className='mb-md-4 mb-3'>Set your availability to make sure you are sent jobs that work for you.</p>

                <div className="availability-list mb-md-5 mb-4">
                    <Item  
                        weekDay={week && week[0].day} 
                        getStart={(e) => this.setState({
                            weekDay: update(week, {0: {start: {$set: e}}})
                        })} 
                        getEnd={(e) => this.setState({
                            weekDay: update(week, {0: {end: {$set: e}}})
                        })} 
                        avail={weekGet[0] && weekGet[0].end ? true : false}
                        start={weekGet[0] && weekGet[0].start} end={weekGet[0] && weekGet[0].end} />
                    <Item  
                        weekDay={week && week[1].day} 
                        getStart={(e) => this.setState({
                            weekDay: update(week, {1: {start: {$set: e}}})
                        })} 
                        getEnd={(e) => this.setState({
                            weekDay: update(week, {1: {end: {$set: e}}})
                        })}  
                        avail={weekGet[1] && weekGet[1].end ? true : false}
                        start={weekGet[1] && weekGet[1].start} end={weekGet[1] && weekGet[1].end} />
                    <Item  
                        weekDay={week && week[2].day} 
                        getStart={(e) => this.setState({
                            weekDay: update(week, {2: {start: {$set: e}}})
                        })} 
                        getEnd={(e) => this.setState({
                            weekDay: update(week, {2: {end: {$set: e}}})
                        })}  
                        avail={weekGet[2] && weekGet[2].end ? true : false}
                        start={weekGet[2] && weekGet[2].start} end={weekGet[2] && weekGet[2].end} />
                    <Item  
                        weekDay={week && week[3].day} 
                        getStart={(e) => this.setState({
                            weekDay: update(week, {3: {start: {$set: e}}})
                        })} 
                        getEnd={(e) => this.setState({
                            weekDay: update(week, {3: {end: {$set: e}}})
                        })}  
                        avail={weekGet[3] && weekGet[3].end ? true : false}
                        start={weekGet[3] && weekGet[3].start} end={weekGet[3] && weekGet[3].end} />
                    <Item  
                        weekDay={week && week[4].day} 
                        getStart={(e) => this.setState({
                            weekDay: update(week, {4: {start: {$set: e}}})
                        })} 
                        getEnd={(e) => this.setState({
                            weekDay: update(week, {4: {end: {$set: e}}})
                        })}  
                        avail={weekGet[4] && weekGet[4].end ? true : false}
                        start={weekGet[4] && weekGet[4].start} end={weekGet[4] && weekGet[4].end} />
                    <Item  
                        weekDay={week && week[5].day} 
                        getStart={(e) => this.setState({
                            weekDay: update(week, {5: {start: {$set: e}}})
                        })} 
                        getEnd={(e) => this.setState({
                            weekDay: update(week, {5: {end: {$set: e}}})
                        })}  
                        avail={weekGet[5] && weekGet[5].end ? true : false}
                        start={weekGet[5] && weekGet[5].start} end={weekGet[5] && weekGet[5].end} />
                    <Item  
                        weekDay={week && week[6].day} 
                        getStart={(e) => this.setState({
                            weekDay: update(week, {6: {start: {$set: e}}})
                        })} 
                        getEnd={(e) => this.setState({
                            weekDay: update(week, {6: {end: {$set: e}}})
                        })}  
                        avail={weekGet[6] && weekGet[6].end ? true : false}
                        start={weekGet[6] && weekGet[6].start} end={weekGet[6] && weekGet[6].end} />
                </div>
                {this.state.message && <p className="text-success d-block">{this.state.message}</p>}

                <button className="btn btn-secondary mr-3 mb-3" onClick={this.ItemsSave}>Save</button>
                
            </div>
        )
    }
}
