import React, { Component } from 'react'

export default class AddFav extends Component {
    constructor(props){
        super(props);

        this.state= {
			favSuccess:'',
            loading: false
        }
	}
    
    addFav = async () => {
        this.setState({
            loading: true
        });

        var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

			var urlencoded = new URLSearchParams();
			urlencoded.append("endpoint", "add_fav");
			urlencoded.append("user_id", this.props.userID);
			urlencoded.append("fav_user_id", this.props.FavID);

            // console.log(this.props.userID);
            // console.log(this.props.FavID);

			var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
			};

			await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
			.then(response => response.json())
			.then(result => {
                if(result.status === 200){
                    // if(!this.props.FavSupplier[0]){
                        window.location.reload();
                    // }

                    this.setState({
                        loading: false
                    })
                }
			})
			.catch(error => console.log('error', error));
	}

    render() {
        // console.log(this.props.FavSupplier)
        return (
            <button onClick={() => this.addFav()} className="btn btn-reset d-inline-flex ml-md-3 p-0 align-items-center btn-add-fav"><i className={`${this.props.FavSupplier && this.props.FavSupplier[0]?.is_favorite === 1 ? 'active' : ''} fas fa-${this.state.loading ? 'spinner' : 'heart'}`}></i> {this.props.FavSupplier &&  this.props.FavSupplier[0]?.is_favorite === 1 ? "Remove from my Favourites" : "Add to My Favourites"}</button>
        )
    }
}
