import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import signup from '../../assets/images/signup.png';
import signupMob from '../../assets/images/signup-mob.png';


import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';


class SignUp extends Component {
	constructor(props){
        super(props);

        this.state= {
            fields: {},
            errors: {},
            redirect: false,
            licAgree: false,
            receiveAgree: false,
			userAlready: "",
			Auth : '',
			pageContent: {},
			formLoader: false,
			recaptch: '',
			recaptchValid: false,
            loader: false,
			checkboxes: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
	}

	onChange = (value) => {
		// console.log("Captcha value:", value);
		this.setState({
			recaptch: value,
			recaptchValid: false
		})
	}
	
	handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })

		// console.log(e.target.value)
	}
	
	handleSubmit = async (e) => {
        e.preventDefault();
		
        if (this.validateForm()) {
			this.setState({
				formLoader: true
			})

			// alert('cdscds')

            var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

			var urlencoded = new URLSearchParams();
			urlencoded.append("endpoint", "register_event_organiser");
			urlencoded.append("first_name", this.state.fields.first_name);
			urlencoded.append("last_name", this.state.fields.last_name);
			urlencoded.append("email", this.state.fields.email_address);
			urlencoded.append("password", this.state.fields.password);

			var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: urlencoded,
			redirect: 'follow'
			};

			await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				if(result[0].status === 500){
					this.setState({
						userAlready: result[0].error_string,
						formLoader: false
					})
				}else{
					this.setState({
						redirect: true,
						Auth: result[0].user_id,
						formLoader: false
					})
				}
			})
			.catch(error => console.log('error', error));
        
        } //this.validateForm
	}
	
	validateForm() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		// Recaptcha error messages
        if (!this.state.recaptchValid) {
			formIsValid = true;
		  }
  
		  
		  // checkbox error messages
		  // licAgree
		  // receiveAgree
		  if (!this.state.licAgree) {
			  formIsValid = false;
			  this.setState({
				checkboxes: true
			  })
			}
	
		// First Name error messages
		if (!fields["first_name"]) {
		  formIsValid = false;
		  errors["first_name"] = "*Please enter your First Name.";
		}
	
		// Last Name error messages
		if (!fields["last_name"]) {
		  formIsValid = false;
		  errors["last_name"] = "*Please enter your Last Name.";
		}

		
	  


		// Password error messages
		if (!fields["password"]) {
			formIsValid = false;
			errors["password"] = "*Please enter your Password.";
		  }
		  
		  // Confirm Password error messages
		  if (!fields["confirmed"]) {
			formIsValid = false;
			errors["confirmed"] = "Please enter your confirm password.";
		  }
	  
		  // Compare Password error messages
		  if (typeof fields["password"] !== "undefined" && typeof fields["confirmed"] !== "undefined") {
			  
			if (fields["password"] !== fields["confirmed"]) {
			  formIsValid = false;
			  errors["password"] = "Passwords don't match.";
			}
		  } 

		  // Email Address error messages
		  if (!fields["email_address"]) {
			formIsValid = false;
			errors["email_address"] = "*Please enter your Email Address.";
		}
		if (typeof fields["email_address"] !== "undefined") {
			var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,15}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		  if (!pattern.test(fields["email_address"])) {
			formIsValid = false;
			errors["email_address"] = "*Please enter valid Email Address.";
		  }
		}
	
	
		this.setState({
		  errors: errors,
		  recaptchValid: true
		});
		return formIsValid;
	}

	async componentDidMount(){
		var myHeaders = new Headers();
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		try {
			// 
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=Sign-up`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					pageContent: result,
					loader : true
				})
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
	  }

	render() {
		
		if (this.state.redirect) {
			return <Redirect push to={{
						pathname: "/e/few-more-details",
						state: this.state.Auth
					}}
				 />;
		}

		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Sign Up" />
				<main className="page-access">
					<div className="page-image" style={{backgroundImage: `url(${this.state.pageContent && this.state.pageContent.background_image ? this.state.pageContent.background_image : signup})`}}>
						<img src={this.state.pageContent && this.state.pageContent.background_image ? this.state.pageContent.background_image : signupMob} className="w-100 d-block d-md-none" alt={this.state.pageContent && this.state.pageContent?.alt_text} />
					</div>
					<div className="container p-0 container-xxl">
						<div className="row no-gutters">
							<div className="col-lg-6">
								<div className="page-content-inner px-4">
									<h1>Let’s get you started</h1>

									<div className="form-area pt-md-3 pt-2">
										<form onSubmit={this.handleSubmit}>
											<div className="form-row">
												<div className="form-group col-6">
													<div className="form-field">
														<label htmlFor="firtname">First Name</label>
														<input 
														id="firtname"
														name="first_name"
														onChange={this.handleChange}
														type="text" 
														className="form-control" 
														// placeholder="Nicola" 
														/>
													</div>
													<div className={`invalid-feedback text-right ${!this.state.fields.first_name ? (this.state.errors.first_name ? 'd-block' : null) : null }`}>{this.state.errors.first_name}</div>
												</div>
												<div className="form-group col-6">
													<div className="form-field">
														<label htmlFor="lastname">Last Name</label>
														<input 
														id="lastname"
														type="text" 
														className="form-control" 
														// placeholder="Bond" 
														name="last_name" 
														onChange={this.handleChange}
														/>
													</div>
													<div className={`invalid-feedback text-right ${!this.state.fields.last_name ? (this.state.errors.last_name ? 'd-block' : null) : null }`}>{this.state.errors.last_name}</div>
												</div>
												<div className="form-group col-12">
													<div className="form-field">
														<label htmlFor="emailAddress">Email</label>
														<input 
														id="emailAddress"
														onChange={this.handleChange}
														type="text" 
														className="form-control" 
														// placeholder="Nicola@alkye.com"
														name="email_address" />
													</div>
													<div className={`invalid-feedback text-right ${this.state.errors.email_address ? 'd-block' : null}`}>{this.state.errors.email_address}</div>
													<div className={`invalid-feedback text-right ${this.state.userAlready ? 'd-block' : null}`}>{this.state.userAlready}</div>
												</div>
												<div className="form-group col-12">
													<div className="form-field">
														<label htmlFor="passwordField">Password</label>
														<input 
														id="passwordField"
														onChange={this.handleChange}
														type="password" 
														className="form-control" 
														// placeholder="Password" 
														name="password" />
													</div>
													<div className={`invalid-feedback text-right ${this.state.errors.password ? 'd-block' : null }`}>{this.state.errors.password}</div>
												</div>
												<div className="form-group col-12">
													<div className="form-field">
														<label htmlFor="confirmPassword">Confirm Password</label>
														<input 
														id="confirmPassword"
														name="confirmed" onChange={this.handleChange}
														type="password" 
														className="form-control" 
														// placeholder="Confirm Password"
														/>
													</div>
													<div className={`invalid-feedback text-right ${this.state.errors.confirmed? 'd-block' : null}`}>{this.state.errors.confirmed}</div>
												</div>
												<div className="form-group py-2 col-12">
													<div className="custom-control mb-2 custom-checkbox">
														<input 
														type="checkbox" 
														className="custom-control-input" 
														id="customCheck_agree"
														onChange={() => this.setState({ licAgree : !this.state.licAgree })} />
														<label className="custom-control-label" htmlFor="customCheck_agree">Agree to our <Link className="text-primary" target="_blank" to="/terms-conditions">Terms & Conditions</Link> and <Link className="text-primary" target="_blank" to="/privacy-policy">Privacy Policy</Link></label>
													</div>

													<div className="custom-control mb-2 custom-checkbox">
														<input type="checkbox" className="custom-control-input" onChange={() => this.setState({ receiveAgree : !this.state.receiveAgree })} id="customCheck_receiving" />
														<label className="custom-control-label" htmlFor="customCheck_receiving">I agree to receiving communications</label>
													</div>
													{this.state.checkboxes &&
													<div className={`invalid-feedback ${!this.state.licAgree ? 'd-block' : null}`}>*Please Agree to Terms & Conditions & Privacy Policy.</div>}
												</div>
												<div className="form-group col-12">
													<ReCAPTCHA
														sitekey="6LeHF6EaAAAAAMGIeMxqLoFXXoZBua98d3rVryHa"
														onChange={this.onChange}
													/>
													{!this.state.recaptch && <div className={`invalid-feedback ${this.state.recaptchValid ? 'd-block' : null }`}>*Please check you are human.</div>}
												</div>
												<div className="form-group col-12">
													{this.state.recaptch ? (
														<button type="submit" 
														className="btn btn-hm-primary btn-block btn-lg mb-3  px-4 d-flex align-items-center">{this.state.formLoader ? 'Sending...' : 'Continue'} <i className="fas ml-auto fa-arrow-right"></i></button>
													):(
														<button type="button" 
														onClick={() => this.validateForm()}
														className="btn btn-hm-primary btn-block btn-lg mb-3  px-4 d-flex align-items-center">{this.state.formLoader ? 'Sending...' : 'Continue'} <i className="fas ml-auto fa-arrow-right"></i></button>
													)}

													<p>The information you provide on this form will be retained and used by EventMploy in accordance with our <Link className="text-primary" target="_blank" to="/privacy-policy">Privacy Policy</Link>.</p>
													<p>If you already have an account <Link className="text-primary" to="/login">Click Here</Link></p>
												</div>
											</div>
										</form>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</main>
			</div>
		);
	}
}

export default SignUp;