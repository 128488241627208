import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';


import dots from '../../assets/images/dots.png';
// import userIcon from '../../assets/images/user-icon.png';
import monthly from '../../assets/images/monthly.png';

import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';

class MembershipPlan extends Component {
	constructor(props){
        super(props);

        this.state= {
			planStatus:{},
			planList:[],
            loader: false,
			customerID: false,
			planId: ''
        }
	}

	async componentDidMount(){
		// console.log(id);
		var myHeaders = new Headers();
		// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");

		var requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
		};

		try {
			let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${AuthOrg}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					planStatus : result.data,
					// loader: true
				});
			})
			.catch(error => console.log('error', error));

			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_plans`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log('result', result);

				this.setState({
					planList : result?.plans ? result?.plans?.reverse() : [],
					loader: true
				});
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
	}

	async handleBtn(e){
		let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
		const formdata = new FormData();
		formdata.append("endpoint", "create_stripe_customer");
		formdata.append("user_id", AuthOrg);

		const requestOptions = {
			method: "POST",
			body: formdata,
			redirect: "follow"
		};

		this.setState({
			planId: e
		})

		await fetch(process.env.REACT_APP_APIURL, requestOptions)
		.then((response) => response.json())
		.then((result) => {
			this.setState({
				customerID: true
			})
		})
		.catch((error) => console.error(error));
	}

	render() {
		const status = this.state.planStatus;
		const products = this.state.planList ?? [];
		// console.log(products);
		

		if(this.state.customerID){
			return <Redirect push to={{
				pathname: "/e/payment",
				state: this.state.planId
			}} />
		}

		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Event Organiser Membership" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-100">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>plan</span></h1>
								<p>Select the plan that best suits your needs. Have the occasional event? Choose the monthly plan. Organising events throughout the year? The annual subscription is definitely the right choice! Every plan comes with one month FREE!</p>
								<ul>
									<li>Easily list jobs based on your requirements</li>
									<li>Suitable Freelancers appear</li>
									<li>Choose one or more Freelancer depending on your requirements</li>
								</ul>
								<p><span className="text-primary">Sign up today</span> and get the first month for free. When first month free trial ends payment subscription starts</p>
							</div>
						</div>

						<div className="row">
							{products?.filter(item => item.type === "EV")?.map((plan) => (
								<div key={plan?.price_id} className="col-md-5 col-lg-6 col-xl-auto mr-xl-3 col-sm-6 mb-4">
									<div className="member-card">
										<img src={dots} className="card-dots" alt="dots" />
										<div className="member-plan"><img src={monthly} alt="icon"/></div>
										<h2>{plan?.product_name}</h2>
										<p>{plan?.product_description}</p>

										<div className="member-price">
											<strong>${plan?.unit_amount}</strong>
											Per {plan?.recurring_interval}
										</div>
										
										

										{status.subscription_status ? (
											status.current_plan_id === plan?.price_id ? (<button className="btn btn-block rounded-4 btn-md btn-hm-primary" disabled>Current Plan</button>) : (
												<button type='button' onClick={() => this.handleBtn(plan?.price_id)} className="btn btn-block rounded-4 btn-md btn-hm-primary">
													{status.monthly_price && 'Upgrade Plan'}
													{status.yearly_price && 'Downgrade Plan'}
												</button>
											)
										): (
											<button type='button' onClick={() => this.handleBtn(plan?.price_id)} className="btn btn-block rounded-4 btn-md btn-hm-primary">Buy Plan</button>
										)}
									</div>
								</div>
							))}
							{/* <div className='col-12'>
								<hr />
							</div> */}
							{/* <div className="col-md-5 col-lg-6 col-xl-auto mr-xl-3 col-sm-6 mb-4">
								<div className="member-card">
									<img src={dots} className="card-dots" alt="dots" />
									<div className="member-plan"><img src={monthly} alt="icon"/></div>
									<h2>Monthly Subscription</h2>
									<p>EventMploy will provide you with unlimited job postings for one month.</p>

									<div className="member-price">
										<strong>${status.monthly_price ? status.monthly_price : '49.99'}</strong>
										Per month
									</div>

									{status.subscription_status ? (
										status.current_plan_id === "price_1ILOlIINkYRCPskePdrMEykB" || status.current_plan_id === "price_1JcoE4INkYRCPskeT1FcSxZf" ? (<button className="btn btn-block rounded-4 btn-md btn-hm-primary" disabled>Current Plan</button>) : (
											<Link rel="nofollow" to={{
												pathname: "/e/payment",
												state: status.monthly_price === "349.99" ? "price_1ILOlIINkYRCPskePdrMEykB" : "price_1JcoE4INkYRCPskeT1FcSxZf"
											}} className="btn btn-block rounded-4 btn-md btn-hm-primary">Downgrade Plan</Link>
										)
									): (
										<Link rel="nofollow" to={{
											pathname: "/e/payment",
											state: status.monthly_price === "349.99" ? "price_1ILOlIINkYRCPskePdrMEykB" : "price_1JcoE4INkYRCPskeT1FcSxZf"
										}} className="btn btn-block rounded-4 btn-md btn-hm-primary">Buy Plan</Link>
									)}
								</div>
							</div> */}
							{/* <div className="col-md-5 col-lg-6 col-xl-auto mr-xl-3 col-sm-6 mb-4">
								<div className="member-card">
									<img src={dots} className="card-dots" alt="dots" />
									<div className="member-plan"><img src={userIcon} alt="icon"/></div>
									<h2>Annual Subscription</h2>
									<p>EventMploy will provide you with unlimited job postings for twelve months.</p>

									<div className="member-price">
										<strong>${status.yearly_price ? status.yearly_price : '499.99'}</strong>
										Per year
									</div>

									{status.subscription_status ? (
										status.current_plan_id === "price_1ILOmAINkYRCPskeKFXgqpJD" || status.current_plan_id === "price_1JcoKEINkYRCPskeVsarKOHH" ? (<button className="btn btn-block rounded-4 btn-md btn-gray" disabled>Current Plan</button>) : (
											<Link rel="nofollow" to={{
												pathname: "/e/payment",
												state: status.yearly_price === "949.99" ? "price_1ILOmAINkYRCPskeKFXgqpJD" : "price_1JcoKEINkYRCPskeVsarKOHH"
											}} className="btn btn-block rounded-4 btn-md btn-hm-primary">Upgrade Plan</Link>
										)
									): (
										<Link rel="nofollow" to={{
											pathname: "/e/payment",
											state: status.yearly_price === "949.99" ? "price_1ILOmAINkYRCPskeKFXgqpJD" : "price_1JcoKEINkYRCPskeVsarKOHH"
										}} className="btn btn-block rounded-4 btn-md btn-hm-primary">Buy Plan</Link>
									)}
								</div>
							</div> */}
						</div>
					</div>
				</main>
			</div>
		);
	}
}

export default MembershipPlan;