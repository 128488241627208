import React, { Component } from 'react'
import PhoneInput from 'react-phone-input-2';

export default class SupplierForm extends Component {
    constructor(props){
        super(props);

        this.state= {
            phone: '',
            phoneError: '',
            fields: {},
            errors: {},
			message:'',
            formLoader: false,
            imageAlt: '',
            startDate: '',
            endDate: '',
            startDateOrg: new Date().toLocaleDateString('en-GB'),
            endDateOrg: new Date().toLocaleDateString('en-GB'),
            dateError: "",
		}
		
		this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
	}

    handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
	}

    handleSubmit = async (e) => {
        e.preventDefault();
		
        
        this.setState({
            formLoader: true
          })
          try {
          let AuthSup = localStorage.getItem('auth_empplySplierUID');

			var myHeaders = new Headers();
            myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("endpoint", "contact_admin");
            urlencoded.append("user_id", AuthSup);
            urlencoded.append("first_name", this.state.fields.first_name);
            urlencoded.append("last_name", this.state.fields.last_name);
            urlencoded.append("company", this.state.fields.company_name);
            urlencoded.append("email", this.state.fields.email);
            urlencoded.append("phone", this.state.phone);
            urlencoded.append("message", this.state.fields.messages);
            urlencoded.append("copy_change", this.state.fields.copy);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if(result.status === 200){
                    let fields = this.state.fields;
                    fields["first_name"] = "";
                    fields["last_name"] = "";
                    fields["company_name"] = "";
                    fields["email"] = "";
                    fields["phone"] = "";
                    fields["messages"] = "";
                    fields["copy"] = "";
    
                    this.setState({
                        formLoader: false,
                        message: 'Your enquiry has been sent to the EventMploy Supplier',
                        fields
                    });
    
                    // setTimeout(() => {
                    //   window.location.reload();
                    // }, 800);
                }else{
                    this.setState({
                        formLoader: false,
                        message: 'Please fill in the required (*) fields and press Sumbit to send your enquiry to the EventMploy Supplier' 
                    });
                }

            })
            .catch(error => console.log('error', error));
        } catch (error) {
            console.log('error', error);
        }
        
	}

		

    render() {
        return (
            <div className="supplier-form">
                <div className="pb-4">
                    <p>Contact Support</p>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="form-group col-sm-6">
                            <div className="form-field">
                                <label>Add first name*</label>
                                <input
                                type="text"
                                className="form-control"
                                name="first_name"
                                onChange={this.handleChange}
                                value={this.state.fields.first_name}
                                placeholder="Enter your First Name"
                                />
                            </div>
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="form-field">
                                <label>Add last name</label>
                                <input
                                type="text"
                                className="form-control"
                                name="last_name"
                                onChange={this.handleChange}
                                value={this.state.fields.last_name}
                                placeholder="Enter your Last Name"
                                />
                            </div>
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="form-field">
                                <label>Add company name*</label>
                                <input
                                type="text"
                                className="form-control"
                                name="company_name"
                                onChange={this.handleChange}
                                value={this.state.fields.company_name}
                                placeholder="Enter your company name"
                                />
                            </div>
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="form-field">
                                <label>Add email address*</label>
                                <input
                                type="text"
                                className="form-control"
                                name="email"
                                onChange={this.handleChange}
                                value={this.state.fields.email}
                                placeholder="Enter your email address"
                                />
                            </div>
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="form-field">
                                <label>Add phone number*</label>
                                <PhoneInput
                                    // inputExtraProps={{
                                    //     required: true,
                                    //     autoFocus: true
                                    // }}
                                    defaultCountry={"au"}
                                    enableAreaCodeStretch
                                    enableAreaCodes={true}
                                    value={this.state.phone}
                                    dropdownClass="form-shadow form-radius border-0"
                                    inputClass="form-shadow form-radius border-0"
                                    onChange={(phone) => this.setState({ phone })}
                                />
                            </div>
                        </div>
                        <div className="form-group col-sm-12">
                            <div className="form-field">
                                <label>Add message*</label>
                                <textarea
                                rows="5"
                                className="form-control form-control-size"
                                name="messages"
                                onChange={this.handleChange}
                                value={this.state.fields.messages}
                                placeholder="Type your Message here..."
                                ></textarea>
                            </div>
                        </div>
                        <div className="form-group col-sm-12">
                            <div className="form-field">
                                <label>Add copy change</label>
                                <textarea
                                rows="5"
                                className="form-control form-control-size"
                                name="copy"
                                onChange={this.handleChange}
                                value={this.state.fields.copy}
                                placeholder="Type your change here..."
                                ></textarea>
                            </div>
                        </div>
                        <div className="form-group col-lg-4 col-sm-5">
                        <button
                            type="submit"
                            className="btn btn-md-2x btn-text-space btn-hm-primary mb-2 btn-block btn-md "
                            disabled={this.state.formLoader ? true : false}>{this.state.formLoader ? 'Sending...' : 'Submit'}
                        </button>
                        </div>
                        <div className="col-sm-12">
                            {this.state.message && <span className="text-success d-block">{this.state.message}</span>}
                        </div>
                    </div>
                    </form>
            </div>
        )
    }
}
