
import {
    Link
  } from "react-router-dom";

  
import ContactBanner from '../assets/images/contact-us.png';
import Header from '../components/snippet/Header';

function Sitemap() {
    let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
	let AuthFree = localStorage.getItem('auth_empplyFreeUID');
    return(
        <div className="#wrapper">
            <Header />
            <main className="page-content">
                <div
                    className="inner-banner overflow-hidden text-white d-flex align-items-center">
                    <img src={ContactBanner} alt="sitemap" className="banner-image" />
                </div>
                <div className="sitemap">
                    <div className="container">
                        <h1>Site map</h1>

                        <ul className="list-unstyled">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/contact-us">Contact Us</Link></li>
                            <li><Link to="/resources">Resources</Link></li>
                            <li><Link to="/blog">Blog</Link>
                                <ul>
                                    <li><Link to="/post/918">Three Event Trends to Look For in 2021 and Beyond</Link></li>
                                    <li><Link to="/post/479">Five tips of Corporate event planner</Link></li>
                                    <li><Link to="/post/483">What is the tips for event professionals?</Link></li>
                                    <li><Link to="/post/655">How to spread the word about your event? – The 2021 edition</Link></li>
                                    <li><Link to="/post/533">Responsibility in events – How do we stay safe?</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/faq">Faq</Link></li>
                            <li>
                                {AuthFree ? (
                                    <Link rel="nofollow" to={"/f/accepted"}>Jobs</Link>
                                ):(
                                    <Link to={"/login"}>Jobs</Link>
                                )}
                            </li>
                            <li>
                                {AuthOrg ? (
                                    <Link rel="nofollow" to={"/e/job-post"}>Post A Job</Link>
                                ):(
                                    <Link to={"/login"}>Post A Job</Link>
                                )}
                            </li>
                            <li><Link to="/get-started">Get Started</Link></li>
                            <li><Link to="/login">Login</Link></li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/get-started">Sign Up</Link></li>
                            <li><Link to="/search">Search</Link></li>
                            <li><Link to="/terms-conditions">Terms Conditions</Link></li>
                        </ul>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Sitemap;