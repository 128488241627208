import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import GooglePlaceholder from "./GoogleSearchLocator";

class Filter extends Component {
    constructor(props){
        super(props);

        this.state= {
            showPopup: false,
            fields: {},
            errors: {},
            location: "",
			LocationError: '',
            loading: false
		}
		
		this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);   
	}

    togglePopup = (e) => {
        this.setState({
            showPopup: e
        })
    }

    handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
	}

	handleSubmit = async (e) => {
        e.preventDefault();
		
        if (this.validateForm()) {
            this.setState({
                loading: true
            })

			var myHeaders = new Headers();
		
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            // 
            await fetch(`${process.env.REACT_APP_APIURL}?endpoint=${this.state.fields.source}&latitude=${this.state.location.lat}&longitude=${this.state.location.long}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.props.getResult(true,this.state.fields.source, result);
                this.setState({
                    showPopup: false,
                    loading: false
                });
            })
            .catch(error => console.log('error', error));
        } //this.validateForm
	}

	validateForm() {
		let LocationError = this.state.LocationError;
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		
		if (!this.state.location.lat) {
			formIsValid = false;
			LocationError = "*Please enter location here";
		} 
		
		// First Name error messages
        // if (!fields["looking_for"]) {
        //     formIsValid = false;
        //     errors["looking_for"] = "*Please enter your requirement";
        // }
    
        // Last Name error messages
        if (!fields["source"]) {
            formIsValid = false;
            errors["source"] = "*Please choose any option";
        }
		
		
		this.setState({
			errors: errors,
			LocationError
		});
		
		return formIsValid;
	}

	passAddress = (value) =>{
		// console.log(value);
		if(value.lat){
		  this.setState({
			location : value
		  })
		}
	}

    render() {
        return (
            <>
             <Modal
                    show={this.state.showPopup}
                    onHide={() => this.togglePopup(false)}
                    centered
                >
                    <Modal.Header className="border-0" closeButton>
                        <Modal.Title>Filters</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pt-0">
                        <div className="filter-inner p-0">
                            <form onSubmit={this.handleSubmit}>
                                {/* <div className="form-group">
                                    <div className="input-group">
                                    <input type="text" className="form-control" onChange={this.handleChange} name="looking_for" placeholder="site manager" />
                                    <div className="input-group-append">
                                        <span className="input-group-text pr-1"><i className="fas fa-search"></i></span>
                                    </div>
                                    </div>
                                    <div
                                        className={`invalid-feedback ${
                                        !this.state.fields.looking_for
                                        ? this.state.errors.looking_for
                                            ? "d-block"
                                            : null
                                        : null
                                        }`}
                                        >
                                        {this.state.errors.looking_for}
                                    </div>
                                </div> */}
                                <div className="form-group">
                                    <select className="form-control" name="source" onChange={this.handleChange}>
                                        <option value="">I am looking for...</option>
                                        <option value="search_freelancer">Event Professionals</option>
                                        <option value="search_event_organiser">Find Events</option>
                                    </select>

                                    <div
                                        className={`invalid-feedback ${
                                        !this.state.fields.source
                                        ? this.state.errors.source
                                            ? "d-block"
                                            : null
                                        : null
                                        }`}
                                        >
                                        {this.state.errors.source}
                                    </div>
                                </div>
                                <div className="form-group bg-white">
                                    <div className="input-group flex-nowrap">
                                    <GooglePlaceholder getAddress={(e) => this.passAddress(e)}/>
                                    <div className="input-group-append">
                                        <span className="input-group-text pr-2"><i className="fas fa-map-pin"></i></span>
                                    </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-block py-0 btn-hm-primary">{this.state.loading ? 'Loading...' : 'Search'}</button>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>

                <div className={`filter-area ${this.props.className}`}>
                    <div className="container">
                        <button className="float-right btn-hm-primary btn d-block btn-hm-auto d-md-none btn-filter" onClick={() => this.togglePopup(true)}><i className="fas fa-sliders-h"></i></button>
                        <div className="filter-inner d-none d-md-block">
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">
                                    {/* <div className="form-group col-md-3 col-lg-4 py-2 py-lg-3 m-0">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="fas fa-search"></i></span>
                                            </div>
                                            <input type="text" className="form-control" onChange={this.handleChange} name="looking_for" placeholder="I am looking for …" />
                                        </div>
                                        <div
                                            className={`invalid-feedback ${
                                            !this.state.fields.looking_for
                                            ? this.state.errors.looking_for
                                                ? "d-block"
                                                : null
                                            : null
                                            }`}
                                            >
                                            {this.state.errors.looking_for}
                                        </div>
                                    </div> */}
                                    <div className="form-group col-md-4 py-2 py-lg-3 m-0">
                                        <select className="form-control" name="source" onChange={this.handleChange}>
                                            <option value="">I am looking for...</option>
                                            <option value="search_freelancer">Event Professionals</option>
                                            <option value="search_event_organiser">Find Events</option>
                                        </select>

                                        <div
                                            className={`invalid-feedback ${
                                            !this.state.fields.source
                                            ? this.state.errors.source
                                                ? "d-block"
                                                : null
                                            : null
                                            }`}
                                            >
                                            {this.state.errors.source}
                                        </div>
                                    </div>
                                    <div className="form-group border-left col-md-4 py-2 py-lg-3 m-0">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="fas fa-map-pin"></i></span>
                                            </div>
                                            <div className="input-group-address">
                                                <GooglePlaceholder getAddress={(e) => this.passAddress(e)}/>
                                            </div>
                                        </div>
                                        <div
                                            className={`invalid-feedback ${
                                            !this.state.location.lat
                                            ? this.state.LocationError
                                                ? "d-block"
                                                : null
                                            : null
                                            }`}
                                            >
                                            {this.state.LocationError}
                                        </div>
                                    </div>
                                    <div className="form-group col-md-4 py-2 py-lg-3 m-0">
                                        <button type="submit" className="btn btn-block py-0 btn-hm-primary">{this.state.loading ? 'Loading...' : 'Search'}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>   
            </>
        );
    }
}

export default Filter;