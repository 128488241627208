import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';


import getStarted from '../assets/images/getstarted.png';
import getStartedMob from '../assets/images/getstarted-mob.png';


import Loader from './snippet/Loader';
import SEOPage from './snippet/SEOPage';

const GetStarted = () => {
	const [content, setContent] = useState()
    const [imageAlt, setImageAlt] = useState()
	const [loader, setloader] = useState(false)

    useEffect(() => {
		async function fetchData() {
			var myHeaders = new Headers();
			
			var requestOptions = {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			};
			// 
			try{
				await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=Sign-up%20%26%20get%20started%20today`, requestOptions)
				.then(async response => await response.json())
				.then(result => {
					// console.log(result);
					setContent(result);
					setImageAlt(result.alt_text);
					setloader(true);
				})
				.catch(error => console.log('error', error));
			} catch (error) {
				console.log('error', error);
			}
		  }
		  fetchData();
    }, [])
    return(
        <div>
			<Loader loader={loader} />
            <SEOPage pageTitle="Sign-up%20%26%20get%20started%20today" />
			<main className="page-access">
				<div className="page-image" style={{backgroundImage: `url(${content && content.background_image ? content.background_image : getStarted})`, backgroundPosition: 'top center'}}>
					<img style={{objectPosition: "top"}} src={content && content.background_image ? content.background_image : getStartedMob} className="w-100 d-block d-md-none" alt={imageAlt} />
				</div>
				<div className="container p-0 container-xxl">
					<div className="row no-gutters">
						<div className="col-lg-6">
							<div className="page-content-inner px-4">
								<h1><span>Sign-up</span> & get started today</h1>
								{/* <p>If you are looking to reduce the time and effort you spend finding freelancers or event gigs, then sign up today and relax.</p> */}
								<div className='mb-3' dangerouslySetInnerHTML={{__html: content && content.content}} />
								<Link to="/e/sign-up-today-and-post-your-first-job" className="btn mb-3 py-0 btn-block text-left btn-lg btn-white">I am looking for event freelancers <strong>to hire</strong></Link>
								<Link to="/f/sign-up-today-and-find-your-first-job" className="btn mb-3 py-0 btn-block text-left btn-lg btn-white">I am looking for <strong>event work</strong></Link>

								<p>If you already have an account, <Link className="text-primary" to="/login">Click Here</Link></p>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
    )
}
export default GetStarted