import React, {useEffect, useState} from 'react';

import Loader from './snippet/Loader';
import SEOPage from './snippet/SEOPage';
import loadable from '@loadable/component'
import { Link } from 'react-router-dom';
import Events from './freelancer/snippets/EventCards';
// import HorizontalScroll from 'react-scroll-horizontal';
// import { useLocation } from 'react-router-dom';
const FilterHome = loadable(() => import('./snippet/FilterHome'));
const Testimonial = loadable(() => import('./snippet/Testimonial'));
const GetStartedBtn = loadable(() => import('./snippet/GetStartedBtn'));
const Tabs = loadable(() => import('./snippet/HomeTabs'));


const Homepage = () => {
	const [imageAlt, setImageAlt] = useState()
	const [background, setBackground] = useState()
	const [signup, setSignup] = useState()
	const [loader, setloader] = useState(false)
	// const location = useLocation()
	// console.log(location.pathname);
	
    useEffect(() => {
		async function fetchData() {
			var myHeaders = new Headers();
		
			var requestOptions = {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			};
			try {
				// console.log(process.env.REACT_APP_APIURL)
				await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=Home`, requestOptions)
				.then(async response => await response.json())
				.then(result => {
					// console.log(result);
					setImageAlt(result.alt_text);
					setBackground(result.background_image);
					setSignup(result.sign_up_today);
					setloader(true);
				})
				.catch(error => console.log('error', error));
			} catch (error) {
				console.log('error', error);
			}
		  }
		  fetchData();
    }, [])
    return(
        <div>
			<Loader loader={loader} />
			<SEOPage pageTitle="Home" />
			<main className="page-content">
				<div className="home-banner overflow-hidden text-white d-flex align-items-center">
					<img src={background} alt={imageAlt} className="banner-image" />
					<div className="container">
						<div className="row">
							<div className="col-10 col-sm-8 col-md-8 col-lg-8 pt-md-5">
								{/* <h1>EventMploy</h1> */}
								<h1 className='mb-md-4 mb-3 me-sm-0 me-5'><span>EventMploy</span> connecting the events industry</h1>
								<Link
								rel="nofollow"
									to={"/get-started"}
										className="btn btn-hm-primary d-inline-flex align-items-center btn-get-started btn-shadow"
									>
										Get Started <i className="fas fa-arrow-right ml-auto"></i>
									</Link>
							</div>
						</div>
					</div>
				</div>

				<FilterHome />

				<div className='filter-area mt-0 pt-md-0 pt-sm-4 pt-2'>
					<div className='container'>
							<Events />
						{/* <HorizontalScroll className="hideChat-icon">
						</HorizontalScroll> */}
					</div>
				</div>

				<div className="content-area position-relative overflow-hidden">
					<div className="circle-pink position-absolute circle-pink-tr"></div>
					<div className="circle-pink position-absolute circle-pink-ml"></div>
					<div className="container">
						<div className="row mb-5 pb-md-4 justify-content-center">
							<div className="col-md-10 col-lg-9 col-sm-12">
								<div className="get-started text-center">
									<h2 className="mb-4"><span style={{fontSize: '1.1em'}} dangerouslySetInnerHTML={{__html: signup && signup.title}} /></h2>
									<p className="px-lg-2 mb-md-0 mb-3">{signup && signup.description}</p>
								</div>
							</div>

							<div className="col-md-12">
								<img src={signup && signup.image} className="img-fluid" alt={signup && signup.image_alt_text} />
							</div>
						</div>

						
						<Tabs />


						<div className="section-testimonial">
							<div className="row align-items-center">
								<div className="col-lg-4 mb-lg-0 mb-sm-4 mb-3">
									<div className="section-title text-secondary">
										<h2>What others say about us</h2>
									</div>
								</div>
								<div className="col-lg-8">
								
									<div className="testimonial_outer">
										<Testimonial />
									</div>
								</div>
							</div>
						</div>

						<div className="section-title align-items-center align-items-md-start flex-wrap flex-sm-nowrap d-flex text-secondary justify-content-between">
							<h2><span>Event professionals</span> near you looking for work</h2>
							{/* <Link to="/search" className="btn btn-link nowrap mt-1 ml-auto mt-lg-2 p-0">start a new search <i className="fas fa-arrow-right ml-2"></i></Link> */}
						</div>

						{/* <HorizontalScroll className="hideChat-icon">
							<Freelancers />
						</HorizontalScroll> */}

						<div className="get-started mt-0 get-started-spacer d-flex flex-column flex-md-row align-items-center">
							<h3 className="mr-3">Need an Event Freelancer?</h3>
							{/* <Link to="/e/sign-up-today-and-post-your-first-job" className="btn btn-secondary d-flex align-items-center btn-get-started btn-shadow">Get Started <i className="fas fa-arrow-right ml-auto"></i></Link> */}
							<GetStartedBtn LinkTo="/e/sign-up-today-and-post-your-first-job" />
						</div>


						<div className="section-title d-none d-md-flex flex-wrap text-secondary justify-content-md-between justify-content-center">
							<h2><span>Event gigs</span> near you</h2>
						</div>
						
						{/* <HorizontalScroll className="hideChat-icon">
							<Events />
						</HorizontalScroll> */}

						<div className="get-started mt-0 get-started-spacer d-flex flex-column flex-md-row align-items-center">
							<h3 className="mr-3">Looking for work?</h3>
							{/* <GetStartedBtn LinkTo="/f/sign-up-today-and-find-your-first-job" /> */}
							<GetStartedBtn LinkTo="/get-started" />
						</div>
					</div>
				</div>
			</main>
		</div>
    )
}
export default Homepage