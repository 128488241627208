import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import GooglePlaceholder from "../snippet/GoogleSearchLocator";
import minus from '../../assets/images/minus.png';
import plus from '../../assets/images/plus.png';
import NeedHelp from "./snippet/NeedHelp";

import { Tab, Nav, Row, Col, Tabs } from 'react-bootstrap';

import PostAJobStep1 from "./snippet/PostStep1";
// import PostAJobStep2 from "./snippet/PostStep2";
// import PostAJobStep3 from "./snippet/PostStep3";
import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';
import { withRouter } from "react-router";
import ContactPopup from './snippet/ContactPopup';

class PostAJob extends Component {
	constructor(props){
        super(props);

        this.state= {
            fields: {},
            errors: {},
			startDate: '',
            endDate: '',
			startDateOrg: new Date().toLocaleDateString('en-GB') +' '+ new Date().toLocaleTimeString('en-GB'),
            endDateOrg: new Date().toLocaleDateString('en-GB') +' '+ new Date().toLocaleTimeString('en-GB'),
            dateError: "",
            freelancer_number: 1,
            LocationError: '',
            location: {},
			userDetails: {},

			redirect: false,
			tabStep: 'first', //
			postStep1: {},
			firstStepDone: false,

			postStep2: {},
			secondStepDone: false,

			postStep3: {},
			thirdStepDone: false,

			frequency: [],

			jobID: "",
			jobIDRedirect: "",
			paramsId: '',
			tabStepBack: true,
			// job: {},
			jobPostData: {},


			right_to_work_in_australia: false,
			// covid_vaccinated: false,
            work_with_children_check: false,
            australian_driving_license: false,
            current_police_check: false,
            australian_business_number: false,
            qualifications: [],
            requiredSkills: [],

			qualificationsGet: [],
			requiredSkillsGet: [],

            eventQualificationsIds: [],
            eventSkillsIds: [],
			industry: [],
            industryError: false,
			industryLoad: false,
            
            eventQualificationsError: '',
            eventSkillsError: '',
			planStatus:1,
            checkboxError: false,
			formLoader : false,
            noneBelow: false,

			industrySelected: '',
			industrySelect: '',
			loader: false
		}
		
		this.secondStep = this.secondStep.bind(this);
		this.stepFirst = this.stepFirst.bind(this);
		this.stepSecond = this.stepSecond.bind(this);
		this.firstStep = this.firstStep.bind(this);
		this.submitForm = this.submitForm.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
		this.handleSubmit3 = this.handleSubmit3.bind(this);
		this.handleSubmit2 = this.handleSubmit2.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.passAddress = this.passAddress.bind(this);
	}

	handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
    }

	noneBelow = (e) => {

		if(e.target.checked){
		  this.setState({
			noneBelow : true,
			eventQualificationsIds:[],
			qualificationsGet:[]
		  })
		}else{
		  this.setState({
			noneBelow : false
		  })
		}
	  }

	  industry = async (value) => {
		this.setState({
			industrySelect: value,
			industryLoad: true
		})
  
		if(value){
		  var myHeaders = new Headers();
		  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
	
		  var urlencoded = new URLSearchParams();
		  urlencoded.append("endpoint", "industry_detail");
		  urlencoded.append("slug", value);
	
		  var requestOptions = {
		  method: 'POST',
		  headers: myHeaders,
		  body: urlencoded,
		  redirect: 'follow'
		  };
	
		  await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
		  .then(response => response.json())
		  .then(result => {
			// console.log(result);
			this.setState({
			  industryError : false,
			  qualifications: result.license,
			  requiredSkills: result.skills,
			  industryLoad: false
			})
		  })
		  .catch(error => console.log('error', error));
		}else{
		  this.setState({
			industryError : true
		  })
		}

		// console.log(this.state.eventQualificationsIds)

		if(value !== this.state.industrySelected){
			this.setState({
				eventQualificationsIds:[],
				eventSkillsIds: [],
				requiredSkillsGet: [],
				qualificationsGet: []
			})
		}
	  }
	
	async componentDidMount(){
		// this.setState({
		// 	startDate: new Date().toLocaleDateString('en-GB'),
		// 	endDate: new Date().toLocaleDateString('en-GB'),
		// });
		// this.setState({
		// 	startDate: new Date().toLocaleDateString('en-GB'),
		// 	endDate: new Date().toLocaleDateString('en-GB')
		// });
		
		var myHeaders = new Headers();
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		// 
		
		try {
			let Auth = localStorage.getItem('auth_empplyOrgUID');
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_industry_list`, requestOptions)
			.then(response => response.json())
			.then(result => {
			// console.log(result)
				this.setState({
					industry: result.data    
				});
			})
			.catch(error => console.log('error', error));

			// ?itemType=get_job_byID&job_id=1967&user_id=1472
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_job_byID&job_id=${this.props.match.params.id}&user_id=${Auth}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				const job = result.jobs;
				this.setState({
					jobID: job.job_id,
					startDate: job.startDate,
					endDate: job.endDate,
					startDateOrg: job.start_date,
					endDateOrg: job.end_date,
					freelancer_number: parseInt(job.freelancer_needed),

					userDetails: job.location,
					// location:{
					// 	lat : job.location && job.location.latitude
					// },

					right_to_work_in_australia: parseInt(job.work_requirement.right_to_work_in_australia),
					// covid_vaccinated: parseInt(job.covid_vaccinated),
					work_with_children_check: parseInt(job.work_requirement.working_with_children_check),
					australian_driving_license: parseInt(job.work_requirement.australian_driving_license),
					current_police_check: parseInt(job.work_requirement.current_police_check),
					willing_to_travel: parseInt(job.work_requirement.willing_to_travel),
					australian_business_number: parseInt(job.work_requirement.australian_business_number),
					
					qualificationsGet: job.qualifications,
					requiredSkillsGet: job.skills,
					industrySelected: job.industry[0] ? job.industry[0].name : "",

					noneBelow: job.qualifications[0] ? false : true,

					fields: {
						event_name: job.title,
						event_pay_duration: job.pay.frequency,
						event_pay : job.pay.amount,
						event_desc: job.desc
					},
					loader: true,

					frequency: result.get_all_frequency?.items,
					planStatus : result.subscription_status,
					// industry: result.industry
				})

				var eventSkillsIds = this.state.eventSkillsIds;
				var requiredSkillsGet = job.skills;

				var eventQualificationsIds = this.state.eventQualificationsIds;
				var qualificationsGet = job.qualifications;
				
				for (var i of requiredSkillsGet) {
					eventSkillsIds.push(i.slug);
				}

				for (var y of qualificationsGet) {
					eventQualificationsIds.push(y.slug);
				}

				this.setState({
					eventSkillsIds,
					eventQualificationsIds
				})

				this.industry(job.industry && job.industry[0].name);
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
		
		
		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${Auth}`, requestOptions)
		// 	.then(response => response.json())
		// 	.then(result => {
		// 		this.setState({
		// 			planStatus : result.data
		// 		});
		// 	})
		// 	.catch(error => console.log('error', error));

	}

	IncrementItem = () => {
        this.setState(prevState => {
          if(prevState.freelancer_number) {
            return {
                freelancer_number: prevState.freelancer_number + 1
            }
          }
        });
    }
    DecreaseItem = () => {
      this.setState(prevState => {
        if(prevState.freelancer_number > 1) {
          return {
            freelancer_number: prevState.freelancer_number - 1
          }
        } else {
          return {
            freelancer_number: 1
          }
        }
      });
    }
	

	stepFirst(){	
		this.setState({
			tabStep: 'first',
			tabStepBack: false
		})
	}

	stepSecond(){	
		this.setState({
			tabStep: 'second',
		})
	}

	handleEvent(event, picker) {
		this.setState({
			startDate: new Date(picker.startDate._d).toLocaleDateString('en-GB'),
			endDate: new Date(picker.endDate._d).toLocaleDateString('en-GB'),
			startDateOrg: new Date(picker.startDate._d).toLocaleDateString('en-GB') +' '+ new Date(picker.startDate._d).toLocaleTimeString('en-GB'),
			endDateOrg: new Date(picker.endDate._d).toLocaleDateString('en-GB') +' '+ new Date(picker.endDate._d).toLocaleTimeString('en-GB')
		});
		// console.log(new Date(picker.startDate._d).toLocaleDateString());
		// console.log(new Date(picker.endDate._d).toLocaleDateString());
	}

	firstStep(personalDetails){	
		this.setState({
			postStep1: personalDetails,
			firstStepDone: true,
			tabStep: 'second'
		});

		// console.log(personalDetails);
	}

	secondStep(aboutEvent){	
		// console.log(aboutEvent);
		this.setState({
			postStep2: aboutEvent,
			secondStepDone: true,
			tabStepBack: true,
			tabStep: this.state.tabStepBack ? 'third' : 'first'
		})

		// console.log(aboutEvent);
	}

	passAddress = (value) =>{
		// console.log(value);
		if(value.lat){
		  this.setState({
			location : value
		  })
		}
	}

	onRequiredSkill = (e,id) => {
		let resultArray = this.state.eventSkillsIds;
		let resultArrayGet = this.state.requiredSkillsGet;

		if(e.target.checked)
		{
			 resultArray = this.state.eventSkillsIds.filter(CheckedId=>
			   CheckedId !== id
			 )
			 resultArray.push(id);
			 resultArrayGet = this.state.requiredSkillsGet.filter(CheckedId=>
			   CheckedId !== id
			 )
			 resultArrayGet.push({'slug': id});
		}
		else
		{
		   resultArray = this.state.eventSkillsIds.filter(CheckedId=>
			   CheckedId !== id
		   )
		   resultArrayGet = this.state.requiredSkillsGet.filter(CheckedId=>
			   CheckedId.slug !== id
		   )
		}
		
		let resultString = resultArray;
		let resultStringGet = resultArrayGet;
  
		this.setState({
		  eventSkillsIds:resultString,
		  requiredSkillsGet: resultStringGet
		})

		// console.log(resultArrayGet);
	}
// 
	onQualification = (e,id) => {
		let resultArray = this.state.eventQualificationsIds;
		let resultArrayGet = this.state.qualificationsGet;

		if(e.target.checked)
		{
			 resultArray = this.state.eventQualificationsIds.filter(CheckedId=>
			   CheckedId !== id
			 )
			 resultArray.push(id);
			 resultArrayGet = this.state.qualificationsGet.filter(CheckedId=>
				CheckedId !== id
			  )
			  resultArrayGet.push({'slug': id});
		}
		else
		{
		   resultArray = this.state.eventQualificationsIds.filter(CheckedId=>
			   CheckedId !== id
		   )
		   resultArrayGet = this.state.qualificationsGet.filter(CheckedId=>
			CheckedId.slug !== id
			)
		}
		
		let resultString = resultArray;
		let resultStringGet = resultArrayGet;
  
		this.setState({
		  eventQualificationsIds:resultString,
		  qualificationsGet: resultStringGet,
		  noneBelow : false
		})
		// console.log(resultArrayGet);
	}

	validateForm2() {
		let fields = this.state.fields;
		let phoneError = this.state.phoneError;
		// let LocationError = this.state.LocationError;
		let dateError = this.state.dateError;
		let errors = {};
		let formIsValid = true;

		// console.log(fields);
	  
		// First Name error messages
		if (!fields.event_name) {
		  formIsValid = false;
		  errors["event_name"] = "*Please type event title.";
		}
	  
		// event_desc
		if (!fields.event_desc) {
		  formIsValid = false;
		  errors["event_desc"] = "*Please type description.";
		}
	  
		// event_pay
		if (!fields.event_pay) {
		  formIsValid = false;
		  errors["event_pay"] = "*Please enter amount.";
		} 
		// if (!this.state.location.lat) {
		//   formIsValid = false;
		//   LocationError = "Please enter your location.";
		// } 
		if (!this.state.startDate) {
		  formIsValid = false;
		  dateError = "Please select start and end date range.";
		} 
	  
	  
		this.setState({
		  errors: errors,
		  phoneError,
		//   LocationError,
		  dateError
		});
		return formIsValid;
	  }

	validateForm3() {
		let eventQualificationsError = this.state.eventQualificationsError;
		let eventSkillsError = this.state.eventSkillsError;
		let formIsValid = true;
		let checkboxError = this.state.checkboxError;
		let industryError = this.state.industryError;
		
		if(this.state.qualifications.length > 0){
			if (!this.state.noneBelow && !this.state.eventQualificationsIds[0]) {
				formIsValid = false;
				eventQualificationsError = "*Please select at least one value under License / Qualifications tab.";
			} 
		}
		
		if(this.state.requiredSkills.length > 0){
			if (!this.state.eventSkillsIds[0]) {
				formIsValid = false;
				eventSkillsError = "*Please select at least one value under Required Skills tab.";
			} 
		}

		if (this.state.right_to_work_in_australia === false) {
			formIsValid = false;
			checkboxError = "*Eligibility to work required.";
		  }

		  if (!this.state.industrySelect) {
			formIsValid = false;
			industryError = true;
		  }
	
	
		this.setState({
			eventQualificationsError,
			eventSkillsError,
		    checkboxError,
			industryError
		});
		return formIsValid;
	}
	  
	  moveStep1(){
		  this.stepFirst();
	  }
	  moveStep2(){
        this.stepSecond();
     }


	handleSubmit2 = (e) => {
        e.preventDefault();		
        if (this.validateForm2()) {
            let eventDetails ={
                event_name: this.state.fields.event_name,
                event_startDate: this.state.startDateOrg,
                event_endDate: this.state.endDateOrg,
                event_desc: this.state.fields.event_desc,
                freelancer_number: this.state.freelancer_number,
                event_pay: this.state.fields.event_pay,
                event_pay_duration: this.state.fields.event_pay_duration,
                location: this.state.location
            }
            // console.log(eventDetails);  
			this.secondStep(eventDetails);      
        } //this.validateForm
    }

	handleSubmit3 = (e) => {
        e.preventDefault();
        if (this.validateForm3()) {
            let lookingFor ={
                right_to_work_in_australia: this.state.right_to_work_in_australia ? 1 : 0,
                // covid_vaccinated: this.state.covid_vaccinated ? 1 : false,
                work_with_children_check: this.state.work_with_children_check ? 1 : 0,
                australian_driving_license: this.state.australian_driving_license ? 1 : 0,
                current_police_check: this.state.current_police_check ? 1 : 0,
                // australian_business_number: this.state.australian_business_number ? 1 : 0,
                event_abn: this.state.australian_business_number ? 1 : 0,

                qualification: this.state.eventQualificationsIds ? this.state.eventQualificationsIds.toString() : '',
                skills: this.state.eventSkillsIds ? this.state.eventSkillsIds.toString() : ''
            }
			// console.log(lookingFor);
            this.submitForm(lookingFor);
        } //this.validateForm
    }

	async submitForm(postStep3){	
		// console.log("postStep3", postStep3);
		
		this.setState({
			postStep3: postStep3,
			thirdStepDone: true,
			// redirect: true,
			formLoader: true
		})

		// console.log(this.state.industrySelect)

		const postStep1 = this.state.postStep1;
		const postStep2 = this.state.postStep2;

		// console.log('postStep1', postStep1);
		// console.log('postStep2', postStep2);
		// console.log(this.state.covid_vaccinated)

		let Auth = localStorage.getItem('auth_empplyOrgUID');
		let event_term00 = '';
		let event_term01 = '';
		let event_term02 = '';

		if(postStep2.location && postStep2.location.location){
			event_term00 = postStep2.location.location_address[0] ? postStep2.location.location_address[0].long_name : "";
			event_term01 = postStep2.location.location_address[2] ? postStep2.location.location_address[2].long_name : "";
			event_term02 = postStep2.location.location_address[3] ? postStep2.location.location_address[3].long_name : "";
		}

		var myHeaders = new Headers();
			// myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
			// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");

			// alert(this.state.jobID);

			var urlencoded = new URLSearchParams();
			urlencoded.append("endpoint", "update_job");
			urlencoded.append("job_id", this.state.jobID);
			urlencoded.append("first_name", postStep1.fullName);
			urlencoded.append("last_name", postStep1.fullName);
			urlencoded.append("mobile", postStep1.phone);
			urlencoded.append("abn", postStep1.abn);
			urlencoded.append("email", postStep1.emailAddress);
			urlencoded.append("event_name", postStep2.event_name);
			urlencoded.append("start_date", postStep2.event_startDate);
			urlencoded.append("end_date", postStep2.event_endDate);
			urlencoded.append("event_description", postStep2.event_desc);
			urlencoded.append("no_of_freelancer", postStep2.freelancer_number);
			urlencoded.append("pay_amount", postStep2.event_pay);
			urlencoded.append("frequency", postStep2.event_pay_duration);
			
			if(postStep2.location && postStep2.location.location){
				urlencoded.append("event_latitude", postStep2.location.lat);
				urlencoded.append("event_longitude", postStep2.location.long);
				// urlencoded.append("event_street_address", event_term01 ? event_term01 +", " + event_term00 : event_term00 );
				urlencoded.append("event_street_address", postStep2.location.location );
				urlencoded.append("event_suburb", event_term00);
				urlencoded.append("event_state", event_term01);
				urlencoded.append("event_country", event_term02);
			}else{
				urlencoded.append("event_latitude", this.state.userDetails.latitude);
				urlencoded.append("event_longitude", this.state.userDetails.longitude);
				urlencoded.append("event_street_address", this.state.userDetails.street_address);
				urlencoded.append("event_suburb", this.state.userDetails.suburb);
				urlencoded.append("event_state", this.state.userDetails.state);
				urlencoded.append("event_country", this.state.userDetails.country);
			}

			urlencoded.append("right_to_work_in_australia", postStep3.right_to_work_in_australia);
			urlencoded.append("covid_vaccinated", 0);
			urlencoded.append("work_with_children_check", postStep3.work_with_children_check);
			urlencoded.append("australian_driving_license", postStep3.australian_driving_license);
			urlencoded.append("current_police_check", postStep3.current_police_check);
			urlencoded.append("event_abn", postStep3.event_abn);
			urlencoded.append("user_id", Auth);
			urlencoded.append("qualification", postStep3.qualification);
			urlencoded.append("skills", postStep3.skills);
			urlencoded.append("industry", this.state.industrySelect.toString());

			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: urlencoded,
				redirect: 'follow'
			};

			// console.log(postStep2.event_startDate, postStep2.event_endDate)

			// console.log(this.state.userDetails.street_address, this.state.userDetails.suburb, this.state.userDetails.state, this.state.userDetails.country);
			// console.log(
			// 	"job_id", this.state.jobID,
			// 	"first_name", postStep1.fullName,
			// 	"last_name", postStep1.fullName,
			// 	"mobile", postStep1.phone,
			// 	"abn", postStep1.abn,
			// 	"email", postStep1.emailAddress,
			// 	"event_name", postStep2.event_name,
			// 	"start_date", postStep2.event_startDate,
			// 	"end_date", postStep2.event_endDate,
			// 	"event_description", postStep2.event_desc,
			// 	"no_of_freelancer", postStep2.freelancer_number,
			// 	"pay_amount", postStep2.event_pay,
			// 	"frequency", postStep2.event_pay_duration,
			// )


			await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					redirect: true,
					formLoader: false
				});
			})
			.catch(error => console.log('error', error));
	}

	render() {
		const frequency = this.state.frequency;
		const qualifications = this.state.qualifications;
        const requiredSkills = this.state.requiredSkills;
		const profileStatus = this.state.planStatus;
        const qualificationsGet = this.state.qualificationsGet;
        const requiredSkillsGet = this.state.requiredSkillsGet;

		// console.log(this.state.userDetails);
		// console.log(this.state.eventQualificationsIds)

		if(this.state.redirect){
			return <Redirect push to={{
						pathname: "/e/thank-you",
						state: this.state.jobID 
					}}  />
		}

		if(profileStatus === 0){
			return(
				<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Update Job Post" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1><span>Edit</span> a job</h1>
								<p>Confirm your details, tell us about the event, and then let us know about who you’re looking for so we can find the perfect Freelancer for you.</p>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 mb-md-5 mb-4">
								<div className="blank-area text-center">
									<h3>You have no <span>active plan</span>.</h3>
									<p><small>Please buy plan <Link rel="nofollow" className="text-primary" to="/e/membership">here</Link>!</small></p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			)
		}

		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Update Job Post" />
				<main className="page-dashboard">
					<div className="container-fluid post-ajob">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1><span>Edit</span> a job</h1>
								<p>Confirm your details, tell us about the event, and then let us know about who you’re looking for so we can find the perfect Freelancer for you.</p>
							</div>
						</div>

						<Tab.Container id="left-tabs-example" activeKey={this.state.tabStep} onSelect={(k) => {
							this.setState({
								tabStep: k
							})
						}}>
							<Row>
								<Col md={12} lg={12} xl={3}>
								<Nav variant="pills" className="flex-column nav-tab-list position-sticky">
									<Nav.Link eventKey="first" className={this.state.firstStepDone ? "done" : " "}>Let’s confirm your details</Nav.Link>
									<Nav.Link eventKey="second" className={this.state.secondStepDone ? "done" : " "}>A little about the event</Nav.Link>
									<Nav.Link eventKey="third" className={this.state.thirdStepDone ? "done" : " "}>A little about the person you are looking for</Nav.Link>
								</Nav>
								</Col>
								<Col md={12} lg={12} xl={8}>
								<Tab.Content>
									<Tab.Pane eventKey="first">
										<PostAJobStep1 postData={(e) => this.firstStep(e)} />
									</Tab.Pane>
									<Tab.Pane eventKey="second">
										{/* <PostAJobStep2 moveStep={this.stepFirst}  postData={(e) => this.secondStep(e)} /> */}
										<div className="job-post-box">
											<div className="mb-4">
											<h2>
												A little about <br />
												the event
											</h2>
											</div>

											<div className="form-area">
											<form onSubmit={this.handleSubmit2}>
												<div className="row">
												<div className="form-group mb-md-4 mb-3 col-sm-6">
													<div className="form-field">
													<label htmlFor="eventName">Event Name</label>
													<input
														id="eventName"
														type="text"
														value={this.state.fields && this.state.fields.event_name}
														className="form-control"
														name="event_name"
														onChange={this.handleChange}
														placeholder="Enter Event Title"
													/>
													</div>
													<div
													className={`invalid-feedback text-right ${
														!this.state.fields.event_name
														? this.state.errors.event_name
															? "d-block"
															: null
														: null
													}`}
													>
													{this.state.errors.event_name}
													</div>
												</div>
												<div className="form-group mb-md-4 mb-3 col-sm-6">
													<div className="form-field">
													<label htmlFor="eventDate">Event Dates</label>
													<div className="d-flex align-items-center">
														<i className="far mr-2 fa-calendar mb-1 fa-calendar-alt"></i>
														<DateRangePicker 
                        								initialSettings={{ minDate: new Date() }}
														// initialSettings={{
														// 	timePicker: true, 
														// 	timePicker24Hour: true,
															// startDate: this.state.startDate,
    														// endDate: this.state.endDate
														// }} 
														onEvent={this.handleEvent}>
															<input type="text" className="form-control" value={this.state.startDate + '-' + this.state.endDate} />
														</DateRangePicker>
													</div>
													</div>
													<div
													className={`invalid-feedback text-right ${
														!this.state.startDate
														? this.state.dateError
															? "d-block"
															: null
														: null
													}`}
													>
													{this.state.dateError}
													</div>
												</div>
												<div className="form-group mb-md-4 mb-3 col-sm-9">
													<div className="form-field">
													<label htmlFor="eventDesc">Event Description</label>
													<textarea
														id="eventDesc"
														className="form-control"
														rows="4"
														name="event_desc"
														value={this.state.fields && this.state.fields.event_desc}
														onChange={this.handleChange}
														placeholder="What are your event requirements?"
													></textarea>
													</div>
													<div
													className={`invalid-feedback text-right ${
														!this.state.fields.event_desc
														? this.state.errors.event_desc
															? "d-block"
															: null
														: null
													}`}
													>
													{this.state.errors.event_desc}
													</div>
												</div>
												<div className="form-group mb-md-4 mb-3 col-sm-3">
													{/* <div className="form-field need-help d-flex align-items-center text-center justify-content-center">
													Need help with event description?
													</div> */}
													<NeedHelp />
												</div>
												<div className="form-group mb-md-4 mb-3 col-sm-4">
													<div className="form-field d-flex d-md-block align-items-center justify-content-between flex-wrap">
													<label>Number of Freelancers Required</label>
													<div className="input-group input-group-counter">
														<div className="input-group-prepend">
														<button
															onClick={this.DecreaseItem}
															className="btn p-0"
															type="button"
														>
															<img src={minus} alt="minus" />
														</button>
														</div>
														<input
														type="text"
														className="form-control text-center"
														value={this.state.freelancer_number}
														name="freelancer_number"
														onChange={this.handleChange}
														/>
														<div className="input-group-append">
														<button
															onClick={this.IncrementItem}
															className="btn p-0"
															type="button"
														>
															<img src={plus} alt="plus" />
														</button>
														</div>
													</div>
													</div>
												</div>
												<div className="form-group mb-md-4 mb-3 col-sm-8">
													<div className="form-field">
													<label>Pay</label>
													<div className="input-group input-group-pay">
														<div className="input-group-prepend form-control">
														${" "}
														<input
															type="number"
															min="0"
															className="form-control"
															placeholder="0"
															value={this.state.fields && this.state.fields.event_pay}
															name="event_pay"
															onChange={this.handleChange}
														/>
														</div>
														<select
														disabled={true}
														className="form-control  form-control-select-disabled border-0 ml-2"
														name="event_pay_duration"
														onChange={this.handleChange}
														value={this.state.fields.event_pay_duration}
														>
														{frequency && frequency.map((item, index) => (
															<option value={item.value} key={index}>{item.label}</option>
														))}
														</select>
													</div>
													</div>
													<div
													className={`invalid-feedback text-right ${
														!this.state.fields.event_pay
														? this.state.errors.event_pay
															? "d-block"
															: null
														: null
													}`}
													>
													{this.state.errors.event_pay}
													</div>
												</div>
												<div className="form-group mb-md-4 mb-3 col-sm-6">
													<div className="form-field">
													<label>Job Location</label>
													<GooglePlaceholder getAddress={(e) => this.passAddress(e)} value={this.state.userDetails && this.state.userDetails.street_address}/>
													</div>
													{/* <div
													className={`invalid-feedback text-right ${
														!this.state.location.lat
														? this.state.LocationError
														? "d-block"
														: null
														: null
													}`}
													>
													{this.state.LocationError}
													</div> */}
												</div>
												<div className="w-100"></div>
												<div className="form-group mb-1 col-sm-6 order-sm-5">
													<button
													type="submit"
													className="btn btn-hm-primary btn-block btn-lg mb-3  px-4 d-flex align-items-center"
													>
													Continue <i className="fas ml-auto fa-arrow-right"></i>
													</button>
												</div>
												<div className="form-group mb-1 col-sm-6">
													<button
													onClick={() => this.moveStep1()}
													className="btn btn-dark btn-block btn-lg mb-3  px-4 d-flex align-items-center"
													>
													<i className="fas mr-auto fa-arrow-left"></i> Previous
													</button>
												</div>
												</div>
											</form>
											</div>
										</div>
									</Tab.Pane>
									<Tab.Pane eventKey="third">
										{/* <PostAJobStep3 moveStep={this.stepSecond}  postData={(e) => this.submitForm(e)} /> */}
										<div className="job-post-box">
											<div className="mb-4">
											<h2>
												Who are you <br />
												looking for?
											</h2>
											</div>

											<div className="form-area">
											<form onSubmit={this.handleSubmit3}>
												<div className="row">
												<div className="col-sm-12">
													<h5>Work Requirements</h5>
												</div>
												<div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
													<label>Right to work in Australia</label>
													<div className="form-switch text-uppercase">
													<input type="checkbox" onChange={() => this.setState({ right_to_work_in_australia : !this.state.right_to_work_in_australia })} name="right_to_work_in_australia" id="formSwitch1" hidden checked={this.state.right_to_work_in_australia ? true : false} />
													<label
														htmlFor="formSwitch1"
														className="d-flex align-items-center justify-content-between"
													>
														<span className="fs-no">No</span>
														<span className="fs-yes">Yes</span>
													</label>
													</div>
													<div
														className={`invalid-feedback ${
															this.state.right_to_work_in_australia === false
															? this.state.checkboxError
																? "d-block"
																: null
															: null
														}`}
														>
														{this.state.checkboxError}
													</div>
												</div>
												{/* <div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
													<label>Covid Vaccinated</label>
													<div className="form-switch text-uppercase">
													<input type="checkbox" onChange={() => this.setState({ covid_vaccinated : !this.state.covid_vaccinated })} name="covid_vaccinated" id="formSwitch1_cod" hidden checked={this.state.covid_vaccinated ? true : false} />
													<label
														htmlFor="formSwitch1_cod"
														className="d-flex align-items-center justify-content-between"
													>
														<span className="fs-no">No</span>
														<span className="fs-yes">Yes</span>
													</label>
													</div>
													<div
														className={`invalid-feedback ${
															this.state.right_to_work_in_australia === false
															? this.state.checkboxError
																? "d-block"
																: null
															: null
														}`}
														>
														{this.state.checkboxError}
													</div>
												</div> */}
												<div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
													<label>Working with children check</label>
													<div className="form-switch text-uppercase">
													<input type="checkbox" id="formSwitch2" onChange={() => this.setState({ work_with_children_check : !this.state.work_with_children_check })} name="working_with_children_check" hidden checked={this.state.work_with_children_check ? true : false} />
													<label
														htmlFor="formSwitch2"
														className="d-flex align-items-center justify-content-between"
													>
														<span className="fs-no">No</span>
														<span className="fs-yes">Yes</span>
													</label>
													</div>
												</div>
												<div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
													<label>Australian Driving License</label>
													<div className="form-switch text-uppercase">
													<input type="checkbox" id="formSwitch3" onChange={() => this.setState({ australian_driving_license : !this.state.australian_driving_license })} name="australian_driving_license" hidden checked={this.state.australian_driving_license ? true : false} />
													<label
														htmlFor="formSwitch3"
														className="d-flex align-items-center justify-content-between"
													>
														<span className="fs-no">No</span>
														<span className="fs-yes">Yes</span>
													</label>
													</div>
												</div>
												<div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
													<label>Current Police Check</label>
													<div className="form-switch text-uppercase">
													<input type="checkbox" id="formSwitch4" onChange={() => this.setState({ current_police_check : !this.state.current_police_check })} name="current_police_check" hidden checked={this.state.current_police_check ? true : false} />
													<label
														htmlFor="formSwitch4"
														className="d-flex align-items-center justify-content-between"
													>
														<span className="fs-no">No</span>
														<span className="fs-yes">Yes</span>
													</label>
													</div>
												</div>
												<div className="form-group form-group-switch mb-1 col-sm-6 col-md-6">
													<label>Australian Business Number (ABN)</label>
													<div className="form-switch text-uppercase">
													<input type="checkbox" id="formSwitch5" onChange={() => this.setState({ australian_business_number : !this.state.australian_business_number })} name="australian_business_number" hidden checked={this.state.australian_business_number ? true : false} />
													<label
														htmlFor="formSwitch5"
														className="d-flex align-items-center justify-content-between"
													>
														<span className="fs-no">No</span>
														<span className="fs-yes">Yes</span>
													</label>
													</div>
												</div>

												<div className="col-sm-8 mt-4">
													<div className="form-group mb-0">
													<label>Which industry do you require? (Select from drop down)</label>
													<select className="form-control" onChange={(e) => this.industry(e.target.value)}>
														<option value="">Choose industry</option>
														{this.state.industry && this.state.industry.map((data, index) => (
														<option key={index} selected={data.name === this.state.industrySelected ? true : false} value={data.slug}>{data.name}</option>
														))}
													</select>
													<div
														className={`invalid-feedback ${
															this.state.industryError
																? "d-block"
																: null
														}`}
														>
														*Please select one Industry
													</div>
													</div>
												</div>

												<div className="col-sm-12 mt-4">
													{this.state.industryLoad && <h3>Loading...</h3>}
													<div className="section-tabs mb-md-5 mb-2">
													{qualifications.length > 0 || requiredSkills.length > 0 ? (
													<Tabs defaultActiveKey={qualifications.length > 0  ? 'events' : 'hospitality'} variant="pills" id="uncontrolled-tab-example">
													{qualifications.length > 0 ? (
														<Tab eventKey="events" title="License / Qualifications">
														<div className="row">
															<div className="col-sm-12">
																{this.state.qualifications[0] ? (
																	<div className="custom-control mb-2 custom-checkbox">
																		<input
																		type="checkbox"
																		value=""
																		name="event_qualification"
																		className="custom-control-input"
																		id={`checkQualificationNone`}
																		onChange={(e) => this.noneBelow(e)}
																		checked={this.state.noneBelow ? true : false}
																		/>
																		<label
																		className="custom-control-label"
																		htmlFor={`checkQualificationNone`}
																		>
																		None of below
																		</label>
																	</div>
																):null}
																<div className="custom-control-outer">
																{qualifications &&
																	qualifications.map((qualification, index) => (
																	<div
																		className="custom-control-outer-item"
																		key={index}
																	>
																		<div className="custom-control mb-2 custom-checkbox">
																		<input
																			type="checkbox"
																			value={qualification.slug}
																			name="event_qualification"
																			className="custom-control-input"
																			id={`checkQualification${index}`}
																			onChange={(e)=>this.onQualification(e,qualification.slug)}
																			// checked={this.state.eventQualificationsIds && this.state.eventQualificationsIds.some(function (obj) {
																			// 	if (obj === qualification.id) {
																			// 	  return true;
																			// 	}else{
																			// 		return false
																			// 	  }
																			//   })}
																			checked={qualificationsGet.some(function (obj) {
																				if (obj.slug === qualification.slug) {
																					return 'checked';
																				}else{
																					return '';
																				}
																			  })}
																		/>
																		<label
																			className="custom-control-label"
																			htmlFor={`checkQualification${index}`}
																			dangerouslySetInnerHTML={{__html: qualification.name}}
																		/>
																		</div>
																	</div>
																	))}
																</div>
															</div>
															</div>
														</Tab>
														) : null}
                        
														{requiredSkills.length > 0 ? (
														<Tab eventKey="hospitality" title="Required Skills">
														<div className="row">
															<div className="col-sm-12">
																<div className="custom-control-outer">
																	{requiredSkills &&
																	requiredSkills.map((requiredSkill, index) => (
																		<div className="custom-control-outer-item" key={index}>
																		<div className="custom-control mb-2 custom-checkbox">
																			<input
																			type="checkbox"
																			value={requiredSkill.slug}
																			name="event_skills"
																			className="custom-control-input"
																			id={`checkSkills${index}`}
																			onChange={(e)=>this.onRequiredSkill(e,requiredSkill.slug)}
																			checked={requiredSkillsGet.some(function (obj) {
																				if (obj.slug === requiredSkill.slug) {
																				  return 'checked';
																				}else{
																					return '';
																				}
																			  })}
																			/>
																			<label
																			className="custom-control-label"
																			htmlFor={`checkSkills${index}`}
																			dangerouslySetInnerHTML={{__html: requiredSkill.name}}
																			/>
																		</div>
																		</div>
																	))}
																</div>
															</div>
														</div>
														</Tab>
													) : null}
													</Tabs>
													) : null}
													{qualifications.length > 0 ? (
													<div
													className={`invalid-feedback text-left ${
														!this.state.noneBelow && !this.state.eventQualificationsIds[0]
														? this.state.eventQualificationsError
														? "d-block"
														: null
														: null
													}`}
													>
													{this.state.eventQualificationsError}
													</div>
													) : null}
                      
													{requiredSkills.length > 0 ? (
													<div
													className={`invalid-feedback text-left ${
														!this.state.eventSkillsIds[0]
														? this.state.eventSkillsError
														? "d-block"
														: null
														: null
													}`}
													>
													{this.state.eventSkillsError}
													</div>
													) : null}
													</div>
												</div>

												<div className="w-100"></div>
												<div className="pb-4 col-12 w-100">
													{/* <Link to="/contact-us" target="_blank" className="btn-link-orange btn-link">Skill not listed?</Link> */}
													<ContactPopup />
												</div>

												<div className="form-group mb-1 col-sm-6 order-sm-5">
													<button type="submit" className="btn btn-hm-primary btn-block btn-lg mb-3  px-4 d-flex align-items-center" disabled={this.state.formLoader ? true : false}>{this.state.formLoader ? 'Sending...' : 'Continue'} <i className="fas ml-auto fa-arrow-right"></i>
													</button>
												</div>
												<div className="form-group mb-1 col-sm-6">
													<button
													onClick={() => this.moveStep2()}
													className="btn btn-dark btn-block btn-lg mb-3  px-4 d-flex align-items-center"
													disabled={this.state.formLoader ? true : false}
													>
													<i className="fas mr-auto fa-arrow-left"></i> Previous
													</button>
												</div>
												</div>
											</form>
											</div>

										</div>
									</Tab.Pane>
								</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</div>
				</main>
			</div>
		);
	}
}

export default withRouter(PostAJob);