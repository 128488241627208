import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import ContactForm from '../../snippet/ContactForm';

const ContactPopupLetMeKnow = () => {
    const [show, setShow] = useState(false);
  
    return (
      <>
        {/* <Button onClick={() => setShow(true)} variant="" className=" form-field need-help d-flex align-items-center text-center justify-content-center">
          Contact to adminstat
        </Button> */}
        <span onClick={() => setShow(true)} style={{cursor: 'pointer'}} className="text-primary">Let us know</span>
  
        <Modal
          size="md"
          show={show}
          centered={true}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w need-help-modal"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Contact to Administrator
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='pt-3 pb-1'>
              <ContactForm />
          </Modal.Body>
        </Modal>
      </>
    );
  }

  export default ContactPopupLetMeKnow;