import React, { Component } from 'react';
import CalendarMonth from './snippets/CalendarDates';
// import Layout from '../../global/PrimaryLayoutFreelancer';
import { Link } from 'react-router-dom';
// import moment from 'moment';
import Availability from '../snippet/CalenderAvailabilityByDay';
import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';

class Calendar extends Component {
	constructor(props){
        super(props);

        this.state= {
			jobs: [],
			selectedDate: '',
			planStatus:1,
			loader: false
        }

		this.child = React.createRef();
	}

	async componentDidMount(){
		var myHeaders = new Headers();
		  myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };
		  
		  try {
			let AuthFree = localStorage.getItem('auth_empplyFreeUID');
			// console.log(AuthFree);
			
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_calendar_invite_web&freelancer_id=${AuthFree}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				this.setState({
					jobs: result.events,
					loader: true,
					planStatus: result.subscription_status
				})
			})
			.catch(error => console.log('error', error));
			} catch (error) {
				console.log('error', error);
			}


		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${AuthFree}`, requestOptions)
		// 	.then(response => response.json())
		// 	.then(result => {
		// 		this.setState({
		// 			planStatus : result.data
		// 		});
		// 	})
		// 	.catch(error => console.log('error', error));

	}

	dateView = (e) =>{
		this.setState({
			selectedDate: e
		});

		this.child.current.getAlert(this.formatDate(e));
		// console.log(new Date(moment(e, 'DD/MM/YYYY')));
	}

	formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [day, month, year].join('/');
    }


	render() {

		// const jobs = this.state.jobs;
		const profileStatus = this.state.planStatus;
		const selectedDate = this.state.selectedDate;
		// console.log(jobs);

		const getDate = this.formatDate(selectedDate);

		if(profileStatus === 0){
			return(
				<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer Calendar" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-md-5 mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>Calendar</span></h1>
								<p>See what events you have coming up. Booked events will show up here so event organisers know when you are available to work.</p>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 mb-md-5 mb-4">
								<div className="blank-area text-center">
									<h3>You have no <span>active plan</span>.</h3>
									<p><small>Please buy plan <Link rel="nofollow" className="text-primary" to="/f/membership">here</Link>!</small></p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			)
		}

		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer Calendar" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-md-5 mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>Calendar</span></h1>
								<p>See what events you have coming up. Booked events will show up here so event organisers know when you are available to work.</p>
							</div>
						</div>

						<div className="calendar">
							<div className="row">
								<div className="col-md-8">
									<CalendarMonth jobs={this.state.jobs} getdate={this.dateView} />	
								</div>
							</div>
						</div>

						<Availability date={getDate} ref={this.child} />
					</div>
				</main>
			</div>
		);
	}
}

export default Calendar;