import {Helmet} from "react-helmet";
import { HelmetProvider } from 'react-helmet-async';

const SEOPost = ({WpPostObj}) => {
    // console.log(WpPostObj);
    return(
        <HelmetProvider>
            <Helmet defer={false}>
                <meta charSet="utf-8" />
                <title>{WpPostObj && WpPostObj.title}</title>
                <meta name="description" content={WpPostObj && WpPostObj.description} />
                <meta name="keywords" content={WpPostObj && WpPostObj.keywords} />
                <link rel="canonical" href="https://www.eventmploy.com/" />
                {/* <meta name="robots" content="index, follow" /> */}
                <meta key="robots" name="robots" content="noindex,nofollow" />
                <meta key="googlebot" name="googlebot" content="noindex,nofollow" />
            </Helmet>
        </HelmetProvider>
    )
}

export default SEOPost;