import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import dots from '../../../assets/images/dots.png';

const Freelancer = (props) => {
    // const [data, setData] = useState([]);
    const [auth, setAuth] = useState(false);

    // const lat = parseFloat(props?.lat);
    // const lng = parseFloat(props?.lng);

    

    // const latMin = lat - 0.2;
    // const latMax = lat + 0.2;

    // const lngMin = lng - 0.2;
    // const lngMax = lng + 0.2;

    useEffect(() => {
        let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
        if(AuthOrg){
            setAuth(true);
        }

        // var myHeaders = new Headers();
        // myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");

        // var requestOptions = {
        // method: 'GET',
        // headers: myHeaders,
        // redirect: 'follow'
        // };

        // fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_all_freelancer`, requestOptions)
        // .then(async response => await response.json())
        // .then(result => {
        //     setData(result.freelancers);
        //     // console.log(result.freelancers);
        //     if(AuthOrg){
        //         setAuth(true);
        //     }
        // })
        // .catch(error => console.log('error', error));
    }, [])

    const dataCity = props?.dataCity;
    const dataCountry = props?.dataCountry;
    const data = props?.data;
    
    if(props.sort){
        return(
            props.sort === "city" ? 
            dataCity?.length > 0 && dataCity?.map((freelancer, index) => (
                <div className="event-card event-gray-card" key={index}>
                    {auth ? (
                        <Link rel="nofollow" to={"/e/user/" + freelancer.id} className="event-card-chat"><i className="fas fa-comment-dots"></i></Link>
                    ):(
                        <Link to={"/e/sign-up-today-and-post-your-first-job"} className="event-card-chat"><i className="fas fa-comment-dots"></i></Link>
                    )}
                    <h3 className="text-capitalize">
                        {auth ? (
                            <Link rel="nofollow" to={"/e/freelancer/" + freelancer.id}>{freelancer.first_name} {freelancer.last_name && freelancer.last_name.slice(0, 1)}.</Link>
                        ):(
                            <Link to={"/e/sign-up-today-and-post-your-first-job"}>{freelancer.first_name} {freelancer.last_name && freelancer.last_name.slice(0, 1)}.</Link>
                        )}
                    </h3>
                    {freelancer?.location && (
                        <p><i className="fas fa-map-pin mr-2"></i> {freelancer?.location?.street_address}</p>
                    )}
                    <div className="event-tags">
                        {freelancer?.skills?.length > 0 && freelancer?.skills?.map((skill, index) => (
                            <div className="ev-tag" key={skill?.skill_id} dangerouslySetInnerHTML={{__html: skill?.skill_name}} />
                        ))}
                    </div>
                    <img src={dots} className="event-dots event-dots-bottom" alt="dots" />
                </div>
            )) : 
            dataCountry?.length > 0 && dataCountry?.map((freelancer, index) => (
                <div className="event-card event-gray-card" key={index}>
                    {auth ? (
                        <Link rel="nofollow" to={"/e/user/" + freelancer.id} className="event-card-chat"><i className="fas fa-comment-dots"></i></Link>
                    ): (
                        <Link to={"/e/sign-up-today-and-post-your-first-job"} className="event-card-chat"><i className="fas fa-comment-dots"></i></Link>
                    )}
                    <h3 className="text-capitalize">
                        {auth ? (
                            <Link rel="nofollow" to={"/e/freelancer/" + freelancer.id}>{freelancer.first_name} {freelancer.last_name && freelancer.last_name.slice(0, 1)}.</Link>
                        ):(
                            <Link to={"/e/sign-up-today-and-post-your-first-job"}>{freelancer.first_name} {freelancer.last_name && freelancer.last_name.slice(0, 1)}.</Link>
                        )}
                    </h3>
                    {freelancer?.location && (
                        <p><i className="fas fa-map-pin mr-2"></i> {freelancer?.location?.street_address}</p>
                    )}
                    <div className="event-tags">
                        {freelancer?.skills?.length > 0 && freelancer?.skills?.map((skill, index) => (
                            <div className="ev-tag" key={skill?.skill_id} dangerouslySetInnerHTML={{__html: skill?.skill_name}} />
                        ))}
                    </div>
                    <img src={dots} className="event-dots event-dots-bottom" alt="dots" />
                </div>
            ))
        )
    }

    return(
        data.length > 0 && data?.map((freelancer, index) => (
            <div className="event-card event-gray-card" key={index}>
                {auth ? (
                    <Link rel="nofollow" to={"/e/user/" + freelancer.id} className="event-card-chat"><i className="fas fa-comment-dots"></i></Link>
                ):(
                    <Link to={"/e/sign-up-today-and-post-your-first-job"} className="event-card-chat"><i className="fas fa-comment-dots"></i></Link>
                )}
                <h3 className="text-capitalize">
                    {auth ? (
                        <Link rel="nofollow" to={"/e/freelancer/" + freelancer.id}>{freelancer.first_name} {freelancer.last_name && freelancer.last_name.slice(0, 1)}.</Link>
                    ):(
                        <Link to={"/e/sign-up-today-and-post-your-first-job"}>{freelancer.first_name} {freelancer.last_name && freelancer.last_name.slice(0, 1)}.</Link>
                            
                    )}
                </h3>
                {freelancer?.location && (
                    <p><i className="fas fa-map-pin mr-2"></i> {freelancer?.location?.street_address}</p>
                )}
                <div className="event-tags">
                    {freelancer?.skills?.length > 0 &&  freelancer?.skills?.map((skill, index) => (
                        <div className="ev-tag" key={skill?.skill_id} dangerouslySetInnerHTML={{__html: skill?.skill_name}} />
                    ))}
                </div>
                <img src={dots} className="event-dots event-dots-bottom" alt="dots" />
            </div>
        ))
    )

    
}

export default Freelancer;

