import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Geocode from "react-geocode";
import SEOPage from '../snippet/SEOPageNoIndex';


// import Layout from "../../global/PrimaryLayoutFreelancer";

// import dots from '../../assets/images/dots.png';

import Events from "./snippets/EventCards";
import HorizontalScroll from "../snippet/HorizontalScroll";
import Loader from '../snippet/Loader';

class dashboard extends Component {
	constructor(props){
        super(props);

        this.state= {
			userDetails: {},
			getAllEvents: [],
			planStatus:1,
			liveLocation: '',
			loader: false,
			lat: '',
			lng: ''
        }

	}

	


	async componentDidMount(){
		Geocode.setApiKey("AIzaSyDvjfqhgd5hYoPDGH8DF8SMxIB9Bs-6mvc"); 
		Geocode.setLanguage("en");
		// Geocode.enableDebug();

		var myHeaders = new Headers();
		  myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };
		  
		  try {
			let AuthFree = localStorage.getItem('auth_empplyFreeUID');
			
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=freelancer_user_detail_web&user_id=${AuthFree}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				this.setState({
				userDetails : result.user_detail,
				planStatus : result.subscription_status,
				loader: true,
				})
			})
			.catch(error => console.log('error', error));

			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_all_jobs`, requestOptions)
			.then(async response => await response.json())
			.then(result => {
				// setData(result.jobs.reverse());
				// console.log(result.jobs.reverse());
				this.setState({
					getAllEvents: result?.jobs ? result?.jobs.reverse() : []
				})
				// console.log(props.location);
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}

		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${AuthFree}`, requestOptions)
		//   .then(response => response.json())
		//   .then(result => {
		// 	  this.setState({
		// 		  planStatus : result.data
		// 	  });
		//   })
		//   .catch(error => console.log('error', error));

			let self = this;
			navigator.geolocation.getCurrentPosition(function(position) {
			//   console.log("Latitude is :", position.coords.latitude);
			//   console.log("Longitude is :", position.coords.longitude);
			  // console.log("Location is :", position);
			  let city, state, country;

				Geocode.fromLatLng(position.coords.latitude, position.coords.longitude)
				.then(response => {
					// const address = response.results[0].formatted_address;
					for (let i = 0; i < response.results[0].address_components.length; i++) {
						for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
							switch (response.results[0].address_components[i].types[j]) {
							case "locality":
								city = response.results[0].address_components[i].long_name;
								break;
							case "administrative_area_level_1":
								state = response.results[0].address_components[i].long_name;
								break;
							case "country":
								country = response.results[0].address_components[i].long_name;
								break;
							default:
								break;
							}
						}
					}
					
					console.log(city, state, country);
					self.setState({
						liveLocation: city,
						lat: (position.coords.latitude).toFixed(1),
						lng: (position.coords.longitude).toFixed(1)
					})
					// console.log(address);	
				},
				(error) => {
					console.error(error);
				}
				);
			});
	}

	render() {
		const userDetails = this.state.userDetails;
		const profileStatus = this.state.planStatus;

		const lat = parseFloat(this.state.lat);
		const lng = parseFloat(this.state.lng);

		

		const latMin = lat - 0.2;
		const latMax = lat + 0.2;

		const lngMin = lng - 0.2;
		const lngMax = lng + 0.2;

		const allEvents = this.state.getAllEvents?.filter(e => e.location && ((e.location.latitude > latMin && e.location.latitude < latMax) && (e.location.longitude > lngMin && e.location.longitude < lngMax)));


		if(profileStatus === 0){
			return(
				<div>
					<Loader loader={this.state.loader} />
					<SEOPage pageTitle="Freelancer Dashboard" />
					<main className="page-dashboard">
						<div className="container-fluid">
							<div className="row page-info mb-md-5 mb-4">
								<div className="col-lg-10 col-xl-7">
									<h1>Hey {userDetails.first_name}, <br/><span>Welcome</span> Back!</h1>
									<p>Keep your skills, experience and certifications updated to increase your chances of being offered work.</p>
								</div>
							</div>

							<div className="row">
								<div className="col-sm-12 mb-md-5 mb-4">
									<div className="blank-area text-center">
										<h3>You have no <span>active plan</span>.</h3>
										<p><small>Please buy plan <Link rel="nofollow" className="text-primary" to="/f/membership">here</Link>!</small></p>
									</div>
								</div>
							</div>
						</div>
					</main>
				</div>
			)
		}

		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer Dashboard" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-md-5 mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>Hey {userDetails.first_name}, <br/><span>Welcome</span> Back!</h1>
								<p>Keep your skills, experience and certifications updated to increase your chances of being offered work.</p>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-10 col-xl-6 col-md-9 admin-subheading">
								<h3>Events in <span className="text-underline">{this.state.liveLocation ? this.state.liveLocation : 'Loading...'}</span></h3>
								<p>Here are just some of the jobs being listed by <span>event organisers</span>, check your notifications to see all your job offers.</p>
							</div>
						</div>
						
						{allEvents?.length > 0 ? this.state.liveLocation && (
							allEvents.length < 3 ? (
								<div className='dashboard-hscroll dashboard-hscroll-single'>
									<Events data={this.state.getAllEvents ?? []} dataFilter={allEvents ?? []} location={this.state.liveLocation} lat={this.state.lat} lng={this.state.lng} />
								</div>
							):(
								<HorizontalScroll className="dashboard-hscroll">
									<Events data={this.state.getAllEvents ?? []} dataFilter={allEvents ?? []} location={this.state.liveLocation} lat={this.state.lat} lng={this.state.lng} />
								</HorizontalScroll>
							)
							
						): (
							<div className="he_extra"><h3>No Result found</h3></div>
						)}

						<div className="row mt-5 d-none">
							<div className="col-lg-10 col-xl-6 col-md-9 admin-subheading">
								<h3>Events professionals in <span className="text-underline">Australia</span></h3>
								<p>These are some of the professionals in Sydney, looking for work. Simply <span>connect, chat and work</span>, with anyone you think would be suitable for the job.</p>
							</div>
						</div>
						
						{/* <HorizontalScroll className="dashboard-hscroll">
							<Events location="Australia" />
						</HorizontalScroll> */}
					</div>
				</main>
			</div>
		);
	}
}

export default dashboard;