import React, {useState, useEffect} from 'react';
import OwlCarousel from 'react-owl-carousel';
import { ResponsiveEmbed } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loader from './snippet/Loader';
import SEOPage from './snippet/SEOPage';

const HomepageAdv = () => {
	const [homePage, setHomePage] = useState()
	const [loader, setloader] = useState(false)

    useEffect(() => {
        async function fetchData() {
            var myHeaders = new Headers();
		
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            try{
                await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=Advertise`, requestOptions)
                .then(async response => await response.json())
                .then(result => {
                    // console.log(result);
                    setHomePage(result);
                    setloader(true);
                })
                .catch(error => console.log('error', error));
            } catch (error) {
                console.log('error', error);
            }
          }
          fetchData();
        
    }, [])
    return(
        <div>
            <Loader loader={loader} />
			<SEOPage pageTitle="Advertise" />
			<main className="page-content">
				<div className="home-banner overflow-hidden text-white d-flex align-items-center">
					<img src={homePage && homePage.banner_section.banner_image} alt={homePage && homePage.tag_line} className="banner-image" />
					<div className="container">
						<div className="row">
							<div className="col-md-10 col-lg-9 pt-md-5">
								{/* <h1>EventMploy</h1> */}
								<h1 dangerouslySetInnerHTML={{__html: homePage && homePage.banner_section.title}}/>
							</div>
						</div>
					</div>
				</div>

				<div className="content-area position-relative overflow-hidden">
					<div className="circle-pink position-absolute circle-pink-tr"></div>
					<div className="circle-pink position-absolute circle-pink-ml"></div>
					<div className="container">
						<div className="row mb-5 mt-5 pb-md-4 justify-content-center">
							<div className="col-md-10 col-lg-9 col-sm-12">
								<div className="get-started">
									<h2 className="mb-4 text-center" dangerouslySetInnerHTML={{__html: homePage && homePage.advertise_with_eventmploy.title}}/>
									
									<div className='text-center' dangerouslySetInnerHTML={{__html: homePage && homePage.advertise_with_eventmploy.content}}/>
								</div>
							</div>
						</div>


                        <div className="section-title mb-4 pb-2 text-center section-title-md">
                            <div className="row justify-content-center">
                                <div className="col-md-12">	
                                    <h2 dangerouslySetInnerHTML={{__html: homePage && homePage.how_does_section.title}} />
                                </div>
                            </div>
                        </div>

                        <div className="row d-none d-md-flex text-center justify-content-between">
                            {homePage && homePage.how_does_section.how_does_steps.map((card, index) => (
                                <div className="col-md-4 col-lg-3 mb-4" key={index}>
                                    <div className="count-info btn-shadow">
                                        <strong>{index + 1}</strong>
                                        <h3>{card.title}</h3>
                                        <p>{card.content}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <OwlCarousel
                            items = {1} 
                            className="owl-theme text-center d-block d-md-none owl-emwork"  
                            loop  
                            margin = {1}
                            dots
                        >
                            {homePage && homePage.how_does_section.how_does_steps.map((card, index) => (
                                <div className="item" key={index}>
                                    <div className="col-md-4 col-lg-3 mb-4">
                                        <div className="count-info btn-shadow">
                                            <strong>{index + 1}</strong>
                                            <h3>{card.title}</h3>
                                            <p>{card.content}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}                            
                        </OwlCarousel>


						<div className="get-started mt-5 get-started-spacer d-flex flex-column flex-md-row align-items-center">
							<h3 className="mr-3" dangerouslySetInnerHTML={{__html: homePage && homePage.how_does_section.want_to_learn}}/>
							<Link to="/s/get-started" className="btn btn-secondary d-flex align-items-center btn-get-started btn-shadow"><span dangerouslySetInnerHTML={{__html: homePage && homePage.how_does_section.button_text}} /> <i className="fas fa-arrow-right ml-auto"></i></Link>
							{/* <GetStartedBtn LinkTo="/s/get-started" /> */}
						</div>


                        <div className="row mb-5 pb-md-4 justify-content-center">
							<div className="col-md-10 col-lg-9 col-sm-12">
								<div className="get-started text-center"> 
                                    <h2 className="mb-4" dangerouslySetInnerHTML={{__html: homePage && homePage.supplier_portolio.title}} />
                                    <div className="row mb-sm-4 justify-content-center">
                                        <div className="col-12 col-sm-11 pb-4 pt-3">                
                                        {/* <div className="col-10 col-sm-8 py-4"> */}
                                            <ResponsiveEmbed aspectRatio="16by9">
                                                <embed src={homePage && homePage.supplier_portolio.video_url} />
                                            </ResponsiveEmbed>
                                        </div>
                                    </div>

                                    <h2 className="mb-4"  dangerouslySetInnerHTML={{__html: homePage && homePage.supplier_portolio.sub_title}}/>
									<div dangerouslySetInnerHTML={{__html: homePage && homePage.supplier_portolio.content}} />
								</div>
							</div>

							<div className="col-md-12">
								<img src={homePage && homePage.supplier_portolio.image} className="img-fluid" alt={homePage && homePage.tag_line} />
							</div>
						</div>
						
						<div className="get-started mt-0 get-started-spacer d-flex flex-column flex-md-row align-items-center">
							<h3 className="mr-3" dangerouslySetInnerHTML={{__html: homePage && homePage.supplier_portolio.want_to_learn}} />
							<Link to="/s/get-started" className="btn btn-secondary d-flex align-items-center btn-get-started btn-shadow"><span dangerouslySetInnerHTML={{__html: homePage && homePage.supplier_portolio.button_text}} /> <i className="fas fa-arrow-right ml-auto"></i></Link>
						</div>
					</div>
				</div>
			</main>
		</div>
    )
}
export default HomepageAdv