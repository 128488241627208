import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import GetRating from '../snippet/GetRating';
import Loader from '../snippet/Loader';
import SEOPost from '../snippet/SEOPost';

class SingleFreelancer extends Component {
	constructor(props){
        super(props);

        this.state= {
            userDetails: {},
            starRating : {},
            AvgRating : {},
			loader: false
        }
	}

    async componentDidMount(){
        try {
            var myHeaders = new Headers();
            myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
            myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
            
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            // ?itemType=freelancer_user_detail_web&user_id=1446
            await fetch(`${process.env.REACT_APP_APIURL}?endpoint=freelancer_user_detail_web&user_id=${this.props.match.params.id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                this.setState({
                    userDetails: result.user_detail,
                    starRating : result.get_eo_to_leave_rating,
                    AvgRating : result.get_average_rating,
                    loader: true
                })
            })
            .catch(error => console.log('error', error));
        } catch (error) {
            console.log('error', error);
        }
        
        // let AuthOrg = localStorage.getItem('auth_empplyOrgUID');

        // fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_eo_to_leave_rating&user_id=${this.props.match.params.id}`, requestOptions)
        // .then(response => response.json())
        // .then(result => {
        // //   console.log(result);
        // this.setState({
        //     starRating : result
        // })
        // })
        // .catch(error => console.log('error', error));


        // fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_average_rating&user_id=${this.props.match.params.id}`, requestOptions)
		//   .then(response => response.json())
		//   .then(result => {
		// 	//   console.log(result);
		// 	this.setState({
		// 		AvgRating : result
		// 	})
		//   })
		//   .catch(error => console.log('error', error));
	}

    render() {
        const starRating = this.state.starRating;
        const AvgRating = this.state.AvgRating;
        const userDetails = this.state.userDetails;
        const userSeo = this.state.userDetails.seo;
		// const job = this.state.job;
		// console.log(starRating);

        return (
            <div>
                <Loader loader={this.state.loader} />
            	<SEOPost WpPostObj={userSeo && userSeo} />	
                <main className="page-dashboard">
					<div className="container-fluid">
						<div className="row">
                            <div className="col-xl-9 mb-md-5 mb-4">
                                <div className="post-card mb-xl-4">
									<div className="post-card-header align-items-center d-sm-flex">
										<div className="post-card-header-info text-nowrap mr-auto">
                                            <div className="d-flex align-items-center">
                                                <div className="ur_short_name">{userDetails.first_name && userDetails.first_name.slice(0, 1)}{userDetails.last_name && userDetails.last_name.slice(0, 1)}</div>
                                                <div className="ml-3 ml-md-4 freelancer-info">
                                                    <h3 className="mb-1">{userDetails.userFullName}</h3>
                                                    {userDetails.location && (
                                                        <p className="mb-0"><i className="fas fa-map-pin mr-2"></i> {userDetails.location.street_address}</p>
                                                    )}
                                                </div>
                                            </div>
										</div>
										
										<div className="post-card-chat">
                                            <Link rel="nofollow" to={'/e/user/' + this.props.match.params.id}>
                                                <i className="fas fa-2x fa-comment-dots"></i>
                                                <strong>Chat Now</strong>
                                            </Link>
                                        </div>
									</div>

									<div className="post-card-body">
										<div className="d-flex text-primary post-card-body-header align-items-center mb-md-4 mb-2">
											<h3 className="m-0">Overview</h3>
										</div>
										<p dangerouslySetInnerHTML={{__html: userDetails.bio}} />
                                        {!userDetails.bio && <p>Not added Bio yet!</p>}
									</div>

									<div className="post-card-body">
										<div className="d-flex text-primary post-card-body-header align-items-center mb-2 pb-md-2">
											<h3 className="m-0">Skills</h3>
										</div>

										<div className="pc-tags mt-0">
											{userDetails.skills && userDetails.skills.map((skill, index) => (
												<div className="pc-tag" key={index}>{skill.skill_name}</div>
											))}

                                            {userDetails.skills && userDetails.skills[0] ? null :  "No added Skill yet!"}
										</div>
									</div>

                                    <div className="post-card-body">
										<div className="d-flex text-primary post-card-body-header align-items-center mb-2 pb-md-2">
											<h3 className="m-0">Qualifications</h3>
										</div>

										<div className="pc-tags">
											{userDetails.license_qualification && userDetails.license_qualification.map((qualification, index) => (
												<div className="pc-tag" key={index}>{qualification.qualification_name}</div>
											))}
                                            {userDetails.license_qualification && userDetails.license_qualification[0] ? null :  "No added Qualification yet!"}
										</div>
									</div>

                                    <div className="post-card-body">
										<div className="d-flex text-primary post-card-body-header align-items-center mb-4 pb-md-2">
											<h3 className="m-0">Reviews</h3>
										</div>

										<div className="row">
                                            {starRating.eo && starRating.eo.map((rating, index) => (
                                                <div className="col-sm-6 mb-4" key={index}>
                                                    <div className="chat-contact rating-box position-relative w-100 d-flex">
                                                        <span className="contact-auth">{rating && rating.first_name.slice(0, 1)}{rating && rating.last_name.slice(0, 1)}</span>
                                                        <div className="contact-name">
                                                            <strong className="text-truncate w-100">{rating && rating.userFullName}</strong>
                                                            Please rate out of 5 stars 

                                                            <GetRating className="mt-2 rating_stars_2x" starRating={rating && rating.rated} />
                                                        </div>
                                                        <div className="contact-review mt-md-0 mt-auto text-center">
                                                            <p>{rating && rating.rated_on}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {starRating.eo && starRating.eo[0] ? null :  "No Reviewed yet!"}
									</div>

									

                                </div>
                            </div>
                            <div className="col-xl-3">
                                <div className="post-card no-bg p-0">
                                    <ul className="list-group">
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            Rating:
                                            <GetRating className="ml-auto" starRating={AvgRating.avg_rating} /> &nbsp; {AvgRating.total_reviews &&  "(" + AvgRating.total_reviews + ")"}
                                        </li>
                                        {userDetails.experience && (
                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                Experience:
                                                <span className="text-primary">{userDetails.experience && userDetails.experience+"+ Years"}</span>
                                            </li>
                                        )}
                                        {userDetails.location && (
                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                Location:
                                                <span className="text-primary">{userDetails.location && userDetails.location.street_address}</span>
                                            </li>
                                        )}
                                        {userDetails.upload_cv !== "na" && (
                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                Preview CV
                                                <a className="text-primary btn-link bg-transparent p-0" href={userDetails.upload_cv} target="_blank" rel="noopener noreferrer"><i className="fas m-0 fa-eye"></i></a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
					</div>
				</main>
            </div>
        );
    }
}

export default withRouter(SingleFreelancer);