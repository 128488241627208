import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';
import { withRouter } from "react-router";

// import Thankyou from '../../assets/images/thankyou.png';

class ThankYouPayment extends Component {
    constructor(props){
        super(props);

        this.state= {
            redirect: false,
            jobId: '',
			planStatus:1,
            loader: false
        }

    }

    async componentDidMount(){
		
		var myHeaders = new Headers();
		//   myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		
		try {
			let Auth = localStorage.getItem('auth_empplyOrgUID');
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${Auth}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				this.setState({
					planStatus : result.data?.subscription_status,
					loader: true
				});
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
	}

    render() {
		const profileStatus = this.state.planStatus;

        if(profileStatus === 0){
			return(
				<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Event Organizer Thank You" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row">
							<div className="col-sm-12 mb-md-5 mb-4">
								<div className="blank-area text-center">
									<h3>You have no <span>active plan</span>.</h3>
									<p><small>Please buy plan <Link rel="nofollow" className="text-primary" to="/e/membership">here</Link>!</small></p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			)
		}
        
        return (
            <div>
			<Loader loader={this.state.loader} />
			<SEOPage pageTitle="Event Organizer Thank You" />
                <main className="page-dashboard">
					<div className="container-fluid">
                        <div className="row my-md-3 py-5">
                            <div className="thank-you col-md-6 col-sm-8 text-left">
                                {/* <img src={Thankyou} alt="pic" /> */}
                                <h1 className="text-primary mb-2">Thank You!</h1>
                                <h4>Thank you for payment, now activate your account and find a freelancer and post an jobs!</h4>

                                <Link to={"/e/job-post"} className="btn mt-3 text-uppercase  btn-md px-5 btn-hm-primary">Post A Job</Link>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default withRouter(ThankYouPayment);