import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import ContactPopup from './ContactPopup';
// import { Link } from 'react-router-dom';

class PostStep3 extends Component {
    constructor(props){
        super(props);

        this.state= {
            fields: {},
            errors: {},
            right_to_work_in_australia: true,
            work_with_children_check: false,
            australian_driving_license: false,
            current_police_check: false,
            australian_business_number: true,
            // covid_vaccinated: false,
            qualifications: [],
            requiredSkills: [],
            industry: [],
            industryError: false,

            eventQualificationsIds: [],
            eventSkillsIds: [],
            
            eventQualificationsError: '',
            eventSkillsError: '',
            checkboxError: false,
            formLoader: false,
            noneBelow: false,
            industrySelect: '',
            industryLoad: false
        }
    }

    handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
    }

    industry = async (e) => {
      this.setState({
        industrySelect: e.target.value,
        eventQualificationsIds:[],
			  eventSkillsIds: [],
			  requiredSkillsGet: [],
			  qualificationsGet: [],
        industryLoad : true
      })

      if(e.target.value){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
        var urlencoded = new URLSearchParams();
        urlencoded.append("endpoint", "industry_detail");
        urlencoded.append("slug", e.target.value);
  
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };
        try {
          await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
          .then(response => response.json())
          .then(result => {
            // console.log(result);
            this.setState({
              industryError : false,
              qualifications: result.license,
              requiredSkills: result.skills,
              industryLoad: false
            })
          })
          .catch(error => console.log('error', error));
        } catch (error) {
          console.log('error', error);
      }
      }else{
        this.setState({
          industryError : true
        })
      }
    }

    noneBelow = (e) => {

      if(e.target.checked){
        this.setState({
          noneBelow : true,
          eventQualificationsIds:[]
        })
      }else{
        this.setState({
          noneBelow : false
        })
      }
    }

    handleSubmit = (e) => {
        e.preventDefault();
		
        if (this.validateForm()) {
            this.setState({
              formLoader: true
            })  

            let lookingFor ={
                right_to_work_in_australia: this.state.right_to_work_in_australia ? 1 : 0,
                // covid_vaccinated: this.state.covid_vaccinated ? 1 : false,
                work_with_children_check: this.state.work_with_children_check ? 1 : 0,
                australian_driving_license: this.state.australian_driving_license ? 1 : 0,
                current_police_check: this.state.current_police_check ? 1 : 0,
                event_abn: this.state.australian_business_number ? 1 : 0,

                qualification:this.state.eventQualificationsIds ? this.state.eventQualificationsIds.toString() : '',
                skills:this.state.eventSkillsIds ? this.state.eventSkillsIds.toString() : '',
                industry:this.state.industrySelect.toString()
            }
            this.props.postData(lookingFor);        
        } //this.validateForm
    }

    validateForm() {
      let eventQualificationsError = this.state.eventQualificationsError;
      let eventSkillsError = this.state.eventSkillsError;
      let formIsValid = true;
      let checkboxError = this.state.checkboxError;
      let industryError = this.state.industryError;
      

      if(this.state.qualifications.length > 0){
        if (!this.state.noneBelow && !this.state.eventQualificationsIds[0]) {
          formIsValid = false;
          eventQualificationsError = "*Please select at least one value under License / Qualifications tab.";
        } 
      }
      
      if(this.state.requiredSkills.length > 0){
        if (!this.state.eventSkillsIds[0]) {
          formIsValid = false;
          eventSkillsError = "*Please select at least one value under Required Skills tab.";
        } 
      }

      if (this.state.right_to_work_in_australia === false) {
        formIsValid = false;
        checkboxError = "*Eligibility to work required.";
      }

      if (!this.state.industrySelect) {
        formIsValid = false;
        industryError = true;
      }

     
	
	
      this.setState({
        eventQualificationsError,
        eventSkillsError,
		    checkboxError,
        industryError
      });
		  return formIsValid;
    }
    
    async componentDidMount(){
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        // fetch("${process.env.REACT_APP_APIURL}?endpoint=get_all_qualifications", requestOptions)
        // .then(response => response.json())
        // .then(result => {
        //   // console.log(result)
        //     this.setState({
        //         qualifications: result.data    
        //     })
        // })
        // .catch(error => console.log('error', error));

        // fetch(
        //   "${process.env.REACT_APP_APIURL}?endpoint=get_all_skills",
        //   requestOptions
        // )
        //   .then((response) => response.json())
        //   .then((result) => {
        //     this.setState({
        //       requiredSkills: result.data,
        //     });
        //   })
        //   .catch((error) => console.log("error", error));
        try {
          await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_industry_list`, requestOptions)
          .then(response => response.json())
          .then(result => {
            // console.log(result)
              this.setState({
                  industry: result.data    
              })
          })
          .catch(error => console.log('error', error));
        } catch (error) {
          console.log('error', error);
      }
    }

    moveStep(){
        this.props.moveStep();
    }

    onQualification = (e,id) => {
      let resultArray = []
      if(e.target.checked)
      {
           resultArray = this.state.eventQualificationsIds.filter(CheckedId=>
             CheckedId !== id
           )
           resultArray.push(id) 
      }
      else
      {
         resultArray = this.state.eventQualificationsIds.filter(CheckedId=>
             CheckedId !== id
         )
      }
      
      let resultString = resultArray;

      this.setState({
        eventQualificationsIds:resultString,
        noneBelow : false
      })
    }

    onRequiredSkill = (e,id) => {
      let resultArray = []
      if(e.target.checked)
      {
           resultArray = this.state.eventSkillsIds.filter(CheckedId=>
             CheckedId !== id
           )
           resultArray.push(id) 
      }
      else
      {
         resultArray = this.state.eventSkillsIds.filter(CheckedId=>
             CheckedId !== id
         )
      }
      
      let resultString = resultArray;

      this.setState({
        eventSkillsIds:resultString
      })
    }

    render() {
        const qualifications = this.state.qualifications;
        const requiredSkills = this.state.requiredSkills;

        // console.log(qualifications);

        return (
          <>
            <div className="job-post-box">
              <div className="mb-4">
                <h2>
                  Who are you <br />
                  looking for?
                </h2>
              </div>

              <div className="form-area">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-sm-12">
                      <h5>Work Requirements</h5>
                    </div>
                    <div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
                      <label>Right to work in Australia</label>
                      <div className="form-switch text-uppercase">
                        <input type="checkbox" onChange={() => this.setState({ right_to_work_in_australia : !this.state.right_to_work_in_australia })} name="right_to_work_in_australia" id="formSwitch1" hidden checked={this.state.right_to_work_in_australia ? true : false} />
                        <label
                          htmlFor="formSwitch1"
                          className="d-flex align-items-center justify-content-between"
                        >
                          <span className="fs-no">No</span>
                          <span className="fs-yes">Yes</span>
                        </label>
                      </div>
                      <div
                          className={`invalid-feedback ${
                            this.state.right_to_work_in_australia === false
                            ? this.state.checkboxError
                              ? "d-block"
                              : null
                            : null
                          }`}
                          >
                          {this.state.checkboxError}
                        </div>
                    </div>
                    {/* <div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
                      <label>Covid Vaccinated</label>
                      <div className="form-switch text-uppercase">
                        <input type="checkbox" onChange={() => this.setState({ covid_vaccinated : !this.state.covid_vaccinated })} name="covid_vaccinated" id="formSwitch1_cod" hidden />
                        <label
                          htmlFor="formSwitch1_cod"
                          className="d-flex align-items-center justify-content-between"
                        >
                          <span className="fs-no">No</span>
                          <span className="fs-yes">Yes</span>
                        </label>
                      </div>
                      <div
                          className={`invalid-feedback ${
                            this.state.right_to_work_in_australia === false
                            ? this.state.checkboxError
                              ? "d-block"
                              : null
                            : null
                          }`}
                          >
                          {this.state.checkboxError}
                        </div>
                    </div> */}
                    <div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
                      <label>Working with children check</label>
                      <div className="form-switch text-uppercase">
                        <input type="checkbox" id="formSwitch2" onChange={() => this.setState({ work_with_children_check : !this.state.work_with_children_check })} name="working_with_children_check" hidden />
                        <label
                          htmlFor="formSwitch2"
                          className="d-flex align-items-center justify-content-between"
                        >
                          <span className="fs-no">No</span>
                          <span className="fs-yes">Yes</span>
                        </label>
                      </div>
                    </div>
                    <div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
                      <label>Australian Driving License</label>
                      <div className="form-switch text-uppercase">
                        <input type="checkbox" id="formSwitch3" onChange={() => this.setState({ australian_driving_license : !this.state.australian_driving_license })} name="australian_driving_license" hidden />
                        <label
                          htmlFor="formSwitch3"
                          className="d-flex align-items-center justify-content-between"
                        >
                          <span className="fs-no">No</span>
                          <span className="fs-yes">Yes</span>
                        </label>
                      </div>
                    </div>
                    <div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
                      <label>Current Police Check</label>
                      <div className="form-switch text-uppercase">
                        <input type="checkbox" id="formSwitch4" onChange={() => this.setState({ current_police_check : !this.state.current_police_check })} name="current_police_check" hidden />
                        <label
                          htmlFor="formSwitch4"
                          className="d-flex align-items-center justify-content-between"
                        >
                          <span className="fs-no">No</span>
                          <span className="fs-yes">Yes</span>
                        </label>
                      </div>
                    </div>
                    <div className="form-group form-group-switch mb-1 col-sm-6 col-md-6">
                      <label>Australian Business Number (ABN)</label>
                      <div className="form-switch text-uppercase">
                        <input type="checkbox" id="formSwitch5" 
                        checked={this.state.australian_business_number ? true : false}
                        onChange={() => this.setState({ australian_business_number : !this.state.australian_business_number })} name="australian_business_number" hidden />
                        <label
                          htmlFor="formSwitch5"
                          className="d-flex align-items-center justify-content-between"
                        >
                          <span className="fs-no">No</span>
                          <span className="fs-yes">Yes</span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-8 mt-4">
                      <div className="form-group mb-0">
                        <label>Which industry do you require? (Select from drop down)</label>
                        <select className="form-control" onChange={(e) => this.industry(e)}>
                          <option value="">Choose industry</option>
                          {this.state.industry && this.state.industry.map((data, index) => (
                            <option key={index} value={data.slug}>{data.name}</option>
                          ))}
                        </select>
                        <div
                          className={`invalid-feedback ${
                            this.state.industryError
                              ? "d-block"
                              : null
                          }`}
                          >
                            *Please select one Industry
                        </div>
                      </div>
                    </div>
                    
                    <div className="col-sm-12 mt-4">
                      {this.state.industryLoad && <h3>Loading...</h3>}
                      <div className="section-tabs mb-md-5 mb-2">
                        {/* {qualifications.length > 0  ? 'true' : 'false'} */}
                        {qualifications.length > 0 || requiredSkills.length > 0 ? (
                          <Tabs defaultActiveKey={qualifications.length > 0  ? 'events' : 'hospitality'} variant="pills" id="uncontrolled-tab-example">
                            {qualifications.length > 0 ? (
                              <Tab eventKey="events" title="License / Qualifications">
                                <div className="row">
                                    <div className="col-sm-12">
                                      {this.state.qualifications[0] ? (
                                        <div className="custom-control mb-2 custom-checkbox">
                                          <input
                                            type="checkbox"
                                            value=""
                                            name="event_qualification"
                                            className="custom-control-input"
                                            id={`checkQualificationNone`}
                                            onChange={(e) => this.noneBelow(e)}
                                            checked={this.state.noneBelow ? true : false}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={`checkQualificationNone`}
                                          >
                                            None of below
                                            </label>
                                        </div>
                                      ):null}
                                    
                                      <div className="custom-control-outer">
                                        {qualifications &&
                                          qualifications.map((qualification, index) => (
                                            <div
                                              className="custom-control-outer-item"
                                              key={index}
                                            >
                                              <div className="custom-control mb-2 custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  value={qualification.slug}
                                                  name="event_qualification"
                                                  className="custom-control-input"
                                                  id={`checkQualification${index}`}
                                                  onChange={(e)=>this.onQualification(e,qualification.slug)}
                                                  checked={this.state.eventQualificationsIds && this.state.eventQualificationsIds.some(function (obj) {
                                                    if (obj === qualification.slug) {
                                                      return true;
                                                    }else{
                                                      return false
                                                    }
                                                    })}
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor={`checkQualification${index}`}
                                                  dangerouslySetInnerHTML={{__html: qualification.name}}
                                                />
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                              </Tab>
                            ) : null}
                            
                            {requiredSkills.length > 0 ? (
                              <Tab eventKey="hospitality" title="Required Skills">
                                <div className="row">
                                    <div className="col-sm-12">
                                      <div className="custom-control-outer">
                                          {requiredSkills &&
                                            requiredSkills.map((requiredSkill, index) => (
                                              <div className="custom-control-outer-item" key={index}>
                                                <div className="custom-control mb-2 custom-checkbox">
                                                  <input
                                                    type="checkbox"
                                                    value={requiredSkill.slug}
                                                    name="event_skills"
                                                    className="custom-control-input"
                                                    id={`checkSkills${index}`}
                                                    onChange={(e)=>this.onRequiredSkill(e,requiredSkill.slug)}
                                                  />
                                                  <label
                                                    className="custom-control-label"
                                                    htmlFor={`checkSkills${index}`}
                                                    dangerouslySetInnerHTML={{__html: requiredSkill.name}}
                                                  />
                                                </div>
                                              </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                              </Tab>
                            ) : null}
                          </Tabs>
                        ) : null}
                        
                        
                        {qualifications.length > 0 ? (
                          <div
                            className={`invalid-feedback text-left ${
                              !this.state.noneBelow && !this.state.eventQualificationsIds[0]
                              ? this.state.eventQualificationsError
                                ? "d-block"
                                : null
                              : null
                            }`}
                            >
                            {this.state.eventQualificationsError}
                            </div>
                        ) : null}
                        
                        {requiredSkills.length > 0 ? (
                          <div
                            className={`invalid-feedback text-left ${
                              !this.state.eventSkillsIds[0]
                              ? this.state.eventSkillsError
                                ? "d-block"
                                : null
                              : null
                            }`}
                            >
                            {this.state.eventSkillsError}
                            </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="w-100"></div>
                    <div className="pb-4 col-12 w-100">
                      {/* <Link to="/contact-us" target="_blank" className="btn-link-orange btn-link">Skill not listed?</Link> */}
                      {/* <span className="btn-link-orange btn p-0 btn-reset btn-link">Skill not listed?</span> */}
                      <ContactPopup />
                    </div>
                    <div className="form-group mb-1 col-sm-6 order-sm-5">
                      <button type="submit" className="btn btn-hm-primary btn-block btn-lg mb-3  px-4 d-flex align-items-center" disabled={this.state.formLoader ? true : false}>{this.state.formLoader ? 'Sending...' : 'Continue'} <i className="fas ml-auto fa-arrow-right"></i>
                      </button>
                    </div>
                    <div className="form-group mb-1 col-sm-6">
                      <button
                        type='button'
                        onClick={() => this.moveStep()}
                        className="btn btn-dark btn-block btn-lg mb-3  px-4 d-flex align-items-center"
                        disabled={this.state.formLoader ? true : false}
                      >
                        <i className="fas mr-auto fa-arrow-left"></i> Previous
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        );
    }
}

export default PostStep3;