import React from 'react';
// import React from 'react';

// import appleStore from '../../assets/images/apple-store.png';
import logo from '../../assets/images/logo.svg';
import googleStore from '../../assets/images/google-play-store.svg';
import appleStore from '../../assets/images/apple-play-store.svg';
import { Link } from 'react-router-dom';
import FooterInstagram from '../../assets/images/footer-instagram.svg';
import FooterFacebook from '../../assets/images/footer-facebook.svg';
import FooterYoutube from '../../assets/images/footer-youtube.svg';
import FooterLinkedIn from '../../assets/images/footer-linkedin.svg';

const Footer = () =>{
	return (
		<>
			<footer className='section-footer'>
				<div className='container-fluid'>
					<div className='section-footer-inner'>
						<div className="row">
							<div className="col-md-4 mb-sm-4 mb-3">
								<div className="footer-about">
									<img src={logo} className="section-footer-logo img-fluid mb-2" width={428} height={103} alt="logo" />
										
									<h5>Connecting People. Creating Memories.</h5>

									<div className="social-icons my-4">
										<a target="_blank" rel="noreferrer" href="https://www.instagram.com/eventmploy"><img width={30} height={30} src={FooterInstagram} alt="FooterInstagram" /></a>
										<a target="_blank" rel="noreferrer" href="https://www.facebook.com/EventMploy"><img width={30} height={30} src={FooterFacebook} alt="FooterFacebook" /></a>
										<a target="_blank" rel="noreferrer" href="https://www.youtube.com/@eventmploy"><img width={30} height={30} src={FooterYoutube} alt="FooterYoutube" /></a>
										<a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/eventmploy"><img width={30} height={30} src={FooterLinkedIn} alt="FooterLinkedIn" /></a>
									</div>
								</div>
							</div>

							<div className="col-md-3 ml-xl-auto col-xl-2 mb-md-0 mb-3">
								<h5>Quick Links</h5>
								<ul className="list-unstyled mb-0">
									<li><Link to="/about">About Us</Link></li>
									<li><Link to="/contact-us">Contact us</Link></li>
									<li><Link to="/faq">FAQs</Link></li>
									<li><Link to="/privacy-policy">Privacy Policy</Link></li>
									<li><Link to="/terms-conditions">Terms & Conditions</Link></li>
									{/* <li><Link to="/sitemap">Sitemap</Link></li> */}
								</ul>
							</div>
							<div className="col-md-3 col-lg-2 mb-sm-4 mb-3">
								<h5>Address</h5>
								<p>Sandringham, Vic, 3191</p>
							</div>
							<div className="col-md-3 d-flex flex-column col-xl-2 mb-md-4 mb-3">
								<h5>Download Now</h5>
								<div className='d-flex align-items-center app-icons flex-wrap'>
								{/* <p className="ml-md-0 ml-3"><a rel="noreferrer" href="https://apps.apple.com/in/app/eventmploy/id1500900842" target="_blank"><img src={appleStore} className="img-fluid" alt="icon" /></a></p> */}
									<p className="d-inline-block mr-3"><a rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.alkyeservices.eventmploy" target="_blank"><img width={144} height={144} src={googleStore} style={{maxWidth: '186px'}} className="img-fluid" alt="icon" /></a></p>
									<p className="d-inline-block mr-3"><a rel="noreferrer" href="https://apps.apple.com/in/app/eventmploy-app/id1632789380" target="_blank"><img width={144} height={48} src={appleStore} style={{maxWidth: '186px'}} className="img-fluid" alt="icon" /></a></p>
								</div>
							</div>
						</div>
					</div>
					<div className='section-footer-copyrights text-center'>
						<p>Copyright {new Date().getFullYear()} EventMploy. All rights reserved.</p>
						<p>Website by <a target='_blank' rel="noreferrer" href='https://alkye.com'>Alkye Services</a></p>
					</div>
				</div>
			</footer>


			{/* <footer className="footer bg-blue">
				<div className="container">
					<div className="row">
						<div className="col-md-3 col-lg-3 mb-sm-4 mb-3">
							<div className="footer-about">
								<div className="row align-items-center">
									<div className="col-md-12 col-4">
									</div>
									<div className="col-md-12 text-md-left text-center col-8">
										<h5>Connecting Event Organisers With Event Professionals.</h5>
									</div>
								</div>
							</div>
							<h5 className="d-none d-md-block">© EventMploy, {new Date().getFullYear()}</h5>
							<h5 className="d-none d-md-block">Website by <a rel="noreferrer" href="https://alkye.com/" className="text-primary" target="_blank">Alkye</a></h5>
						</div>

						<div className="col-md-3 ml-lg-auto col-lg-2 mb-sm-4 mb-3">
							<h5>Quick Links</h5>
							<ul className="list-unstyled mb-0">
								<li><Link to="/about">About us</Link></li>
								<li><Link to="/contact-us">Contact us</Link></li>
								<li><Link to="/faq">FAQs</Link></li>
								<li><Link to="/privacy-policy">Privacy Policy</Link></li>
								<li><Link to="/terms-conditions">Terms & Conditions</Link></li>
							</ul>
						</div>
						<div className="col-md-3 col-lg-2 mb-sm-4 mb-3">
							<h5>Address</h5>
							<h5><small>Sandringham, Vic, 3191</small></h5>

							<div className="social-icons my-4">
								<a target="_blank" rel="noreferrer" href="https://www.facebook.com/EventMploy"><i className="fab fa-facebook-f"></i></a>
								<a target="_blank" rel="noreferrer" href="https://www.instagram.com/eventmploy"><i className="fab fa-instagram"></i></a>
								<a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/eventmploy"><i className="fab fa-linkedin-in"></i></a>
								<a target="_blank" rel="noreferrer" href="https://www.youtube.com/results?search_query=eventmploy"><i className="fab fa-youtube"></i></a>
							</div>

							<h5 className="d-md-none">Website by <a rel="noreferrer" href="https://alkye.com/" className="text-primary" target="_blank">Alkye</a></h5>
						</div>
						<div className="col-md-3 d-flex flex-md-column flex-row align-items-start col-lg-2 mb-md-4 mb-3">
							<h5>Download Now</h5>
							<div>
								<p className="ml-md-0 ml-3"><a rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.alkyeservices.eventmploy" target="_blank"><img src={googleStore} style={{maxWidth: '120px'}} className="img-fluid" alt="icon" /></a></p>
								<p className="ml-md-0 ml-3"><a rel="noreferrer" href="https://apps.apple.com/in/app/eventmploy-app/id1632789380" target="_blank"><img src={appleStore} style={{maxWidth: '120px'}} className="img-fluid" alt="icon" /></a></p>
							</div>
						</div>
					</div>
				</div>
			</footer> */}
		</>
	);
}

export default Footer;