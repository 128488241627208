import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import Layout from "../../global/PrimaryLayoutFreelancer";
import ReactStars from "react-rating-stars-component";
import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';


class LeaveRating extends Component {
	constructor(props){
        super(props);

        this.state= {
			starRating : {},
			success: '',
			planStatus:1,
			loader: false
        }
	}
	
	async componentDidMount(){
		var myHeaders = new Headers();
		  myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };
		  
		  try {
			let AuthFree = localStorage.getItem('auth_empplyFreeUID');
			// ?itemType=get_eo_to_leave_rating_web&user_id=1516
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_eo_to_leave_rating_web&user_id=${AuthFree}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				//   console.log(result);
				this.setState({
					starRating : result,
					loader: true,
					planStatus: result.subscription_status
				})
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}



		//  fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${AuthFree}`, requestOptions)
		//   .then(response => response.json())
		//   .then(result => {
		// 	  this.setState({
		// 		  planStatus : result.data
		// 	  });
		//   })
		//   .catch(error => console.log('error', error));
	}

	ratingChanged = async (newRating, id) => {
		// console.log(newRating, id);
		var myHeaders = new Headers();
		  myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };
		  
		  try {
			let AuthOrg = localStorage.getItem('auth_empplyFreeUID');
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=leave_rating_freelancer&freelancer_user_id=${AuthOrg}&eo_user_id=${id}&rating=${newRating}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				//   console.log(result);
				this.setState({
					success : result.message
				});
				window.location.reload();
			})
			.catch(error => console.log('error', error));
			} catch (error) {
				console.log('error', error);
			}
	};

	render() {
        const starRating = this.state.starRating;
		const profileStatus = this.state.planStatus;
        // console.log(starRating);

		if(profileStatus === 0){
			return(
				<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer Leave Rating" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-md-5 mb-4">
							<div className="col-lg-10 col-xl-7">
                                <h1>Leave a <span>rating</span></h1>
                                <p>When rating an event organiser please be objective.  For example, if you have been treated fairly, been given clear expectations, paid according to agreed terms and provided a workplace environment that was safe and healthy that is a five-star work experience!</p>
                            </div>
						</div>

						<div className="row">
							<div className="col-sm-12 mb-md-5 mb-4">
								<div className="blank-area text-center">
									<h3>You have no <span>active plan</span>.</h3>
									<p><small>Please buy plan <Link rel="nofollow" className="text-primary" to="/f/membership">here</Link>!</small></p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			)
		}

		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer Leave Rating" />
				<main className="page-dashboard">
					<div className="container-fluid">
					<div className="row page-info mb-4">
                            <div className="col-lg-10 col-xl-7 mb-2">
                                <h1>Leave a <span>rating</span></h1>
                                <p>When rating an event organiser please be objective.  For example, if you have been treated fairly, been given clear expectations, paid according to agreed terms and provided a workplace environment that was safe and healthy that is a five-star work experience!</p>
                            </div>
							<div className="col-sm-12">
								{this.state.success && <span className="text-success d-block">{this.state.success}</span>}
							</div>
						</div>

						<div className="row">
                            {starRating.eo && starRating.eo.map((rating, index) => (
                                <div className="col-sm-6 mb-4" key={index}>
                                    <div className="chat-contact rating-box position-relative w-100 d-flex">
                                        <span className="contact-auth">{rating.first_name && rating.first_name.slice(0, 1)}{rating.last_name && rating.last_name.slice(0, 1)}</span>
                                        <div className="contact-name">
                                            <strong className="text-truncate w-100">{rating.userFullName}</strong>
                                            Please rate out of 5 stars 

                                            <ReactStars
												count={5}
												value={rating.rated}
												classNames={rating.rated ? 'disabled' : ''}
												onChange={(e) => this.ratingChanged(e, rating.id)}
												size={24}
												isHalf={true}	
												activeColor="#4A55BE"
											/>
                                        </div>
                                        <div className="contact-review mt-md-0 mt-auto text-center">
											<p>{rating.rated_on}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}

							{/* {starRating.eo && starRating.eo[0] ? null : (
								<div className="col-sm-12 mb-md-5 mb-4">
									<div className="blank-area text-center">
										<h3>You have no completed jobs yet.</h3>
										<p><small>Check for job offers <Link className="text-primary" to="/f/offers">here</Link>!</small></p>
									</div>
								</div>
							)} */}

							{starRating.eo && starRating.eo[0] ? null : (
								starRating.rated && starRating.rated === 1 ? (
									<div className="col-sm-12 mb-md-5 mb-4">
										<div className="blank-area text-center">
											<h3>All ratings have been completed to date.</h3>
											{/* <p><small>Please find job on here <Link className="text-primary" to="/f/dashboard">Click</Link>!</small></p> */}
										</div>
									</div>
								) : (
									<div className="col-sm-12 mb-md-5 mb-4">
										<div className="blank-area text-center">
											<h3>You have no completed jobs yet.</h3>
											<p><small>Check for job offers <Link rel="nofollow" className="text-primary" to="/f/offers">here</Link>!</small></p>
										</div>
									</div>
								)
							)}
                        </div>
					</div>
				</main>
			</div>
		);
	}
}

export default LeaveRating;