import React, { Component } from 'react';
import GooglePlaceholder from "./GoogleSearchLocator";
import { Redirect } from "react-router-dom";

class FilterHomeNew extends Component {
    constructor(props){
        super(props);

        this.state= {
            showPopup: false,
            fields: {},
            errors: {},
            location: "",
			LocationError: '',
            redirect: false,
            searchResult : {},
            source : '',
            result : false,
            loading: false
		}
		
		
        this.handleBtn = this.handleBtn.bind(this);   
	}

    handleBtn = (e) => {
        if (this.validateForm()) {
            this.setState({
                loading: true
            })

			var myHeaders = new Headers();
		
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            // 
            fetch(`${process.env.REACT_APP_APIURL}?endpoint=${e}&latitude=${this.state.location.lat}&longitude=${this.state.location.long}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                // const { history } = this.props;
                this.setState({
                    redirect: true,
                    result : true,
                    source: e,
                    searchResult : result,
                    showPopup: false,
                    loading: false
                });
                // history.push('/search')
                // console.log(this.state.fields.source)
                // this.props.getResult(true,this.state.fields.source, result);
            })
            .catch(error => console.log('error', error));
        } //this.validateForm
    }

	validateForm() {
		let LocationError = this.state.LocationError;
		let formIsValid = true;

		
		if (!this.state.location.lat) {
			formIsValid = false;
			LocationError = "*Please enter location here";
		} 
		
		this.setState({
			LocationError
		});
		
		return formIsValid;
	}

	passAddress = (value) =>{
		// console.log(value);
		if(value.lat){
		  this.setState({
			location : value
		  })
		}
	}

    render() {
        // console.log(this.state.searchResult, this.state.source, this.state.result);
        
        if (this.state.redirect) {
			return <Redirect push to={{
						pathname: "/search",
                        state : {
                            searchResult : this.state.searchResult,
                            source : this.state.source,
                            result : this.state.result
                        }
					}}
				 />;
		}

        return (
            <>
             
                {/* New form start */}
                <form className="home-banner-form">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="input-group">
                                {/* <input type="text" className="form-control" placeholder="Enter Location" aria-label="Enter Location" aria-describedby="basic-addon2"/> */}
                                <GooglePlaceholder getAddress={(e) => this.passAddress(e)}/>
                                <div className="input-group-append">
                                    <button className="btn" type="submit" id="button-addon2">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M19.1523 19.3607L13.5415 13.5257C14.9726 12.0904 15.8592 10.1118 15.8592 7.92962C15.8592 3.557 12.3022 0 7.92962 0C3.557 0 0 3.557 0 7.92962C0 12.3022 3.557 15.8592 7.92962 15.8592C9.84896 15.8592 11.6105 15.1731 12.9834 14.0347L18.6078 19.8841C18.6818 19.9615 18.7807 20 18.88 20C18.9744 20 19.0685 19.9649 19.1417 19.8946C19.292 19.75 19.2965 19.511 19.1523 19.3607ZM7.92962 15.104C3.97349 15.104 0.755201 11.8857 0.755201 7.92962C0.755201 3.97349 3.97349 0.755201 7.92962 0.755201C11.8857 0.755201 15.104 3.97349 15.104 7.92962C15.104 11.8857 11.8854 15.104 7.92962 15.104Z"
                                                fill="#4A55BE"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div
                                className={`invalid-feedback ${
                                !this.state.location.lat
                                ? this.state.LocationError
                                    ? "d-block"
                                    : null
                                : null
                                }`}
                                >
                                <span className="text-white">{this.state.LocationError}</span>
                            </div>
                        </div>
                        {this.state.loading ? (
                            <div className="col-12">
                                <button type='button' className={'btn btn-hm w-100 btn-hm-white'}>Loading...</button>
                            </div>
                        ):(
                            <>
                                <div className="col-6">
                                    <button type='button' onClick={() => this.handleBtn('search_event_organiser')} className={'btn btn-hm w-100 btn-hm-white-outline'}>Find a Job</button>
                                </div>
                                <div className="col-6">
                                    <button type='button' onClick={() => this.handleBtn('search_freelancer')} className={'btn btn-hm w-100 btn-hm-white'}>Hire</button>
                                </div>
                            </>
                        )}
                    </div>
                </form>
                {/* New form end */}  
            </>
        );
    }
}

export default FilterHomeNew;