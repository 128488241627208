import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import Layout from '../../global/PrimaryLayoutFreelancer';
import Modal from 'react-bootstrap/Modal'
import { withRouter } from "react-router";
import Loader from '../snippet/Loader';
import SEOPost from '../snippet/SEOPostNoIndex';

class SingleJob extends Component {
	constructor(props){
        super(props);

        this.state= {
			job: [],
			successAccepted: '',
			successAdded: '',
			checkMySelected: {},
			selectedCandidate: {},
			show: false,
			planStatus:1,
			loader: false
        }
	}

    async componentDidMount(){
		
		var myHeaders = new Headers();
		// myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
		// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		try {
			let Auth = localStorage.getItem('auth_empplyFreeUID');
			// ?itemType=get_job_byID&job_id=1965&user_id=1572
			// console.log(`${process.env.REACT_APP_APIURL}?endpoint=get_job_byID&job_id=${this.props.match.params.id}&user_id=${Auth}`);
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_job_byID&job_id=${this.props.match.params.id}&user_id=${Auth}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					job: result.jobs,
					checkMySelected: result.check_my_selection,
					selectedCandidate: result.selectedCandidate,
					planStatus: result.subscription_status,
					loader: true
				})
			})
			.catch(error => console.log('error', error));	
		} catch (error) {
			console.log('error', error);
		}

		
		// 
		// 
		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_my_selection&freelancer_id=${Auth}&job_id=${this.props.match.params.id}`, requestOptions)
		// .then(response => response.json())
		// .then(result => {
		// 	this.setState({
		// 		checkMySelected: result
		// 	})
		// })
		// .catch(error => console.log('error', error));	

		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_selected_candidates&job_id=${this.props.match.params.id}`, requestOptions)
		// .then(response => response.json())
		// .then(result => {
		// 	this.setState({
		// 		selectedCandidate: result
		// 	})
		// })
		// .catch(error => console.log('error', error));	

		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${Auth}`, requestOptions)
		// 	.then(response => response.json())
		// 	.then(result => {
		// 		this.setState({
		// 			planStatus : result.data
		// 		});
		// 	})
		// 	.catch(error => console.log('error', error));
	}

	async acceptJob(){
		var myHeaders = new Headers();
		// myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
		// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		try {
			// 
			let AuthFree = localStorage.getItem('auth_empplyFreeUID');
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=accept_offer&freelancer_id=${AuthFree}&job_id=${this.props.match.params.id}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					successAccepted: result.message
				});
				setTimeout(() => {
					window.location.reload();
				}, 200);
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
	}

	async declineJob(){
		var myHeaders = new Headers();
		// myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
		// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		try {
			// 
			let AuthFree = localStorage.getItem('auth_empplyFreeUID');
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=decline_offer&freelancer_id=${AuthFree}&job_id=${this.props.match.params.id}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					successAccepted: result.message
				});
				setTimeout(() => {
					window.location.reload();
				}, 200);
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
	}

	handleShow(){
		this.setState({
			show: true
		})
	}

	handleClose(){
		this.setState({
			show: false
		})
	}

	// formatDate = (date) => {
    //     var d = new Date(date),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         year = d.getFullYear();
    
    //     if (month.length < 2) 
    //         month = '0' + month;
    //     if (day.length < 2) 
    //         day = '0' + day;
    
    //     return [day, month, year].join('/');
    // }


    render() {
		const job = this.state.job;
		// const checkMySelected = this.state.checkMySelected;
		const selectedCandidate = this.state.selectedCandidate;
		let AuthFree = localStorage.getItem('auth_empplyFreeUID');
		const profileStatus = this.state.planStatus;
		// console.log(job);
		// console.log(job?.freelancers?.selected);

		if(profileStatus === 0){
			return(
				<div>
				<Loader loader={this.state.loader} />
            	<SEOPost WpPostObj={job && job.seo} />	
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row">
							<div className="col-sm-12 mb-md-5 mb-4">
								<div className="blank-area text-center">
									<h3>You have no <span>active plan</span>.</h3>
									<p><small>Please buy plan <Link rel="nofollow" className="text-primary" to="/f/membership">here</Link>!</small></p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			)
		}

        return (
            <div WpPostSEO={job && job.seo}>
			<Loader loader={this.state.loader} />
				<Modal show={this.state.show} centered onHide={() => this.handleClose()}>
					<Modal.Header className="border-0" closeButton>
					</Modal.Header>
					<Modal.Body className="text-center confirm-modal">
						<h2 className="text-primary">Are you sure you want to decline this job?</h2>
						<p>Please enter the below and click 'Confirm' <br />if you want to remove this.</p>

						<div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
						<button type="button" className="btn mr-2" onClick={() => this.handleClose()}>
							Cancel
						</button>
						<button onClick={() => this.declineJob()} className="btn btn-secondary ">Confirm</button>
						</div>
					</Modal.Body>
				</Modal>
                <main className="page-dashboard">
					<div className="container-fluid">
						<div className="row">
                            <div className="col-sm-12 mb-md-5 mb-4">
                                <div className="post-card mb-xl-4">
									<div className="post-card-header align-items-center d-flex flex-wrap">
										<div className="post-card-header-info mr-auto">
											<h2 className="mt-0 mb-0" dangerouslySetInnerHTML={{__html: job && job.title}} />
											<p className="mb-2 pb-1 mw-100"><span className="d-none d-md-inline-block">Posted on : </span>{job && job.post_date} <span className="ml-sm-3">Posted by : <Link rel="nofollow" to={'/f/user/' + job?.added_by_id}>{job?.added_by_name} <i className="fas ml-2 fa-comment-dots"></i></Link></span></p>
											<div className="d-flex flex-wrap">
												{job && job.location ? (<div className="pc-info d-none d-md-block"><i className="fas fa-map-pin"></i> {job && job.location.street_address}</div>) : null}
												{job && job.location ? (<div className="pc-info d-block d-md-none"><i className="fas fa-map-marker-alt"></i> {job && job.location.street_address}</div>) : null}
												<div className="pc-info"><i className="far fa-calendar-alt"></i> {job && job.startDate} - {job && job.endDate}</div>
												<div className="pc-info"><i className="fas fa-users"></i> {job && job.freelancer_booked} Selected</div>
											</div>
											<p className>Status: 
												<span className="pl-2 text-primary">
													{(() => {
														if(job && job.job_status === "1"){
															return "Active"
														}else if(job && job.job_status === "2"){
															return "Closed"
														}else if(job && job.job_status === "3"){
															return "Cancelled"
														}else if(job && job.job_status === "0"){
															return "Inactive"
														}
													})()}

													{/* {job && job.freelancers.accepted ? (job && job.freelancers.accepted[0].user_id) : null } */}

													{/* {job && job.freelancers && job && job.freelancers.accepted.map((accepted) => {
														return accepted.user_id  === parseInt(AuthFree) ? 'Accepted' : null
													})}
													{job && job.freelancers && job && job.freelancers.delined.map((delined) => {
														return delined.user_id  === parseInt(AuthFree) ? 'Declined' : null
													})}
													{job && job.freelancers && job && job.freelancers.selected.map((selected) => {
														return selected.user_id  === parseInt(AuthFree) ? 'Selected' : null
													})} */}
												</span>
												{this.state.successAccepted && <span className="text-success d-block">{this.state.successAccepted}</span>}
											</p>
											{/* <a href={'/f/user/' + job.added_by_id} className="btn p-0 btn-link btn-auto"><i className="fas fa-comment-dots"></i> Message {job.added_by_name}</a> */}
										</div>
										
										{job && job.pay ? (
											<div className="post-card-header-budget text-center">
												<div>
													${parseInt(job && job.pay.amount).toLocaleString()}
													<span className="d-block text-primary">({job && job.pay.frequency})</span>
												</div>
												<div className="mt-xl-3">
													{job && job.freelancer_needed}
													<span className="d-block text-primary">Freelancer Needed</span>
												</div>
											</div>
										) : null}
									</div>
									
									{/* <div className="post-card-body d-flex flex-wrap align-items-center"> */}
										{/* {job && job.freelancers && job && job.freelancers.accepted && job && job.freelancers.accepted[0].user_id === parseInt(AuthFree) ? (<button onClick={() => this.handleShow()} className="btn my-1 btn-secondary ">Decline Job</button>) : null} */}

										{/* {job && job.freelancers && job && job.freelancers.accepted.map((accepted) => {
											return accepted.user_id  === parseInt(AuthFree) ? (<button onClick={() => this.handleShow()} className="btn my-1 btn-secondary mr-auto ">Decline Job</button>) : null
										})} */}
										{/* {job && job.freelancers && job && job.freelancers.delined.map((delined) => {
											return delined.user_id  === parseInt(AuthFree) ? '' : null
										})} */}
										{job && job?.job_status === "1" && (
											job && job?.freelancers?.selected?.filter(item => item?.user_id === parseInt(AuthFree)).map(() => {
												return (
													<div className="post-card-body d-flex flex-wrap align-items-center">
														<div className="mr-auto">
															<button onClick={() => this.acceptJob()} className="btn mr-md-3 my-1 mr-2 btn-secondary ">Accept Job</button>
															<button onClick={() => this.handleShow()} className="btn my-1 btn-secondary ">Decline Job</button>
														</div>
													</div>
												)
											})
										)}

										{job && job.freelancers && job && job.freelancers.delined.map((declined) => {
											return declined.user_id  === parseInt(AuthFree) ? (<div className="post-card-body d-flex flex-wrap align-items-center"><div className="mr-auto">
											<h3 className='mb-0 text-primary'>Job has been Declined</h3>
										</div></div>) : null
										})}

										{job && job.freelancers && job && job.freelancers.accepted.map((accepted) => {
											return accepted.user_id  === parseInt(AuthFree) ? (<div className="post-card-body d-flex flex-wrap align-items-center"><div className="mr-auto">
											<h3 className='mb-0'>Job has been accepted</h3>
										</div></div>) : null
										})}

										{/* {job && job.freelancers.selected.filter()} */}
										
										{/* <Link to={'/f/user/' + user.id} className="text-nowrap mt-sm-0 mt-3"><i className="fas fa-comment-dots mr-1"></i> Message Nicola</Link> */}
									{/* </div> */}

									{/* <a href={'/f/user/' + job.added_by_id}>{job.added_by_name}</a> */}

									<div className="post-card-body" style={{minHeight: '200px'}}>
										<div className="d-flex text-primary post-card-body-header align-items-center mb-4">
											<h3 className="m-0">Details</h3>
											{/* <button className="btn p-0 font-weight-bold text-underline ml-auto text-primary btn-link">Edit</button> */}
										</div>
										<p dangerouslySetInnerHTML={{__html: job && job.desc}} />
										<div className="pc-tags">
											{job && job.skills && job && job.skills.map((skill, index) => (
												<div className="pc-tag" key={index}>{skill.name}</div>
											))}
										</div>
									</div>

									<div className="post-card-body">
										<div className="d-flex text-primary post-card-body-header align-items-center mb-4 pb-2">
											<h3 className="m-0">Other selected freelancers</h3>
										</div>

										{/* <div className="d-flex flex-wrap">
											{checkMySelected.selected_freelancer && checkMySelected.selected_freelancer.map((check, index) => (
												<div className="selected-circle rounded-pill d-flex" key={index}><span className="m-auto">Adam S.</span></div>
											))}
										</div> */}

										{selectedCandidate && selectedCandidate?.length > 0 ? (
											<div className="d-flex flex-wrap">
												{selectedCandidate?.filter(id => id.id === parseInt(AuthFree)).map((candidate, index) => (
													<div className="selected-circle selected-circle-me rounded-pill d-flex" key={index}><span className="m-auto">Me</span></div>
												))}
												{selectedCandidate?.filter(id => id.id !== parseInt(AuthFree)).map((candidate, index) => (
													<div className="selected-circle rounded-pill d-flex" key={index}><span className="m-auto">{candidate.first_name} {candidate.last_name && candidate.last_name.slice(0, 1)}.</span></div>
												))}
											</div>
										): (
											<h5>No freelancers have been selected</h5>
										)}

									</div>

                                </div>
                            </div>
                        </div>
					</div>
				</main>
            </div>
        );
    }
}

export default withRouter(SingleJob);