import React from 'react';
import moment from 'moment';


class Calendar extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {
        month: moment(),
        selected: moment().startOf('day'),
        jobs: []
      };
      
      this.previous = this.previous.bind(this);
      this.next = this.next.bind(this);
    }
    
    previous() {
      const {
        month,
      } = this.state;
  
      this.setState({
        month: month.subtract(1, 'month'),
      });
    }
  
    next() {
      const {
        month,
      } = this.state;
  
      this.setState({
        month: month.add(1,'month'),
      });
    }
    
    select(day) {
      this.setState({
        selected: day.date,
        month: day.date.clone(),
      });

      this.props.getClick(true);

      setTimeout(() => {
        this.props.getdate(this.state.selected);
      }, 100);
    }

    componentDidMount(){
      // this.props.getdate(this.state.selected);

      // var myHeaders = new Headers();
  
      //   var requestOptions = {
      //     method: 'GET',
      //     headers: myHeaders,
      //     redirect: 'follow'
      //   };
    
      //   let AuthFree = localStorage.getItem('auth_empplyFreeUID');
      //   // console.log(AuthFree);
      
      //   fetch(`https://backend.eventmploy.com/app-api?endpoint=get_calendar_invite&freelancer_id=${AuthFree}`, requestOptions)
      //     .then(response => response.json())
      //     .then(result => {
      //     this.setState({
      //       jobs: result.events
      //     })
      //     })
      //     .catch(error => console.log('error', error));

      setTimeout(() => {
        this.props.getdate(this.state.selected);
      }, 100);
    }
  
    renderWeeks() {
      let weeks = [];
      let done = false;
      let date = this.state.month.clone().startOf("month").add("w" -1).day("Sunday");
      let count = 0;
      let monthIndex = date.month();
  
      const {
        selected,
        month,
      } = this.state;
  
      while (!done) {
        weeks.push(
          <Week key={date} 
            date={date.clone()} 
            month={month} 
            select={(day)=>this.select(day)} 
            selected={selected}
            jobs={this.props.jobs}
             />
        );
  
        date.add(1, "w");
        
        done = count++ > 2 && monthIndex !== date.month();
        monthIndex = date.month();
      }
  
      return weeks;
    };
  
    renderMonthLabel() {
      const {
        month,
      } = this.state;
  
      return <span className="month-label text-secondary">{month.format("MMMM YYYY")}</span>;
    }
  
    render() {
      return (
        <section className="calendar">
          <div className="calendar-header">
            <div className="month-display d-flex align-items-center justify-content-center">
              <i className="arrow fa fa-angle-left" onClick={this.previous}/>
              {this.renderMonthLabel()}
              <i className="arrow fa fa-angle-right" onClick={this.next}/>
            </div>
            <DayNames />
          </div>
          {this.renderWeeks()}
        </section>
      );
    }
  }
  
  class DayNames extends React.Component {
      render() {
          return (
            <div className="d-flex align-items-center justify-content-between day-names">
              <span className="day">S</span>
              <span className="day">M</span>
              <span className="day">T</span>
              <span className="day">W</span>
              <span className="day">T</span>
              <span className="day">F</span>
              <span className="day">S</span>
            </div>
          );
      }
  }
  
  class Week extends React.Component {
    render() {
      let days = [];
      let {
        date,
      } = this.props;
      
      const {
        month,
        selected,
        select,
      } = this.props;
  
      for (var i = 0; i < 7; i++) {
        let day = {
            name: date.format("dd").substring(0, 1),
            number: date.date(),
            isCurrentMonth: date.month() === month.month(),
            isToday: date.isSame(new Date(), "day"),
            date: date
        };
        days.push(
          <Day day={day}
            selected={selected}
            select={select}
            jobs={this.props.jobs}
          />
        );
  
        date = date.clone();
        date.add(1, "day");
      }
  
      return (
        <div className="d-flex align-items-center justify-content-between week" key={days[0]}>
          {days}
        </div>
      );
    }
  
  }
  
  class Day extends React.Component {
    
    render() {
      const {
        day,
        day: {
          date,
          isCurrentMonth,
          isToday,
          number
        },
        select,
        selected
      } = this.props;

      return (
        <span 
          key={date.toString()} 
          className={"day" + (isToday ? " today" : "") + (isCurrentMonth ? "" : " different-month") + (date.isSame(selected) ? " selected" : "") + (new Date(day.date._d).getTime() > new Date().getTime() ? " " : " old-date")} 
          onClick={()=>select(day)}>
            {number}
            <span className="active-events d-flex justify-content-center flex-wrap">
              {this.props.jobs && this.props.jobs.filter((e) => new Date(moment(e.start_date, 'DD/MM/YYYY')) <= day.date._d && new Date(moment(e.end_date, 'DD/MM/YYYY')) >= day.date._d).map((
                index) => (
                <span key={index} className="active"></span>
              ))}
            </span>
          </span>
      );
    }
  }

  export default Calendar;