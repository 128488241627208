import React, { useEffect, useState} from 'react';
import {
    FacebookShareButton,
    FacebookIcon,

    LinkedinShareButton,
    LinkedinIcon,

    TwitterShareButton,
    TwitterIcon
  } from "react-share";

import { useLocation } from 'react-router-dom';

import Loader from './snippet/Loader';
import SEOPost from './snippet/SEOPost';

const BlogpageSingle = () => {
    const { pathname } = useLocation();
    const [content, setContent] = useState();
	const [loader, setloader] = useState(false)
    // const [imageAlt, setImageAlt] = useState();
    // console.log(pathname.slice(6))
    useEffect(() => {
        async function fatchData(){
            var myHeaders = new Headers();
		
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            // 
            try{
                await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_blog_by_id&slug=${pathname.slice(6)}`, requestOptions)
                .then(async response => await response.json())
                .then(result => {
                    // console.log(result);
                    setContent(result.items[0]);
                    setloader(true);
                })
                .catch(error => console.log('error', error));
            } catch (error) {
                console.log('error', error);
            }
        }
        fatchData();
    }, [pathname])

    // Function will execute on click of button
    const onButtonClick = (e) => {
        // using Java Script method to get PDF file
        fetch(e).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'EventMploy E-book.pdf';
                alink.click();
            })
        })
    }

    return(
        <div>
            <Loader loader={loader} />
            <SEOPost WpPostObj={content && content.seo} />
			<main className="page-content">
				<div className="inner-banner text-white d-flex align-items-center">
                    <img src={content && content.featured_image} alt={content && content.featured_image_alt_text} className="banner-image" />
					<div className="container">
						<div className="row">
							<div className="col-md-11 col-lg-9 mt-md-5 mt-2 pt-md-5">
								<h1 dangerouslySetInnerHTML={{__html: content && content.name}} />
                                <p><i className="far fa-calendar-alt mr-2"></i> {content && content.post_date_full}</p>
							</div>
						</div>
					</div>
				</div>

				<div className="page-inner-content">
                    <div className="container">
                        <div className="row mb-md-5 mb-4">
                            {content?.upload_pdf && (
                                <div className='col-md-12 mb-3 d-flex flex-wrap align-items-center'>
                                    <h2 className='my-2 mr-4'>Get your EventMploy E-book here</h2>
                                    <button  onClick={() => onButtonClick(content?.upload_pdf)} className='btn btn-md btn-hm-primary ml-auto d-inline-flex align-items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mr-2" viewBox="0 0 16 16">
                                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                            <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
                                        </svg>
                                        Download PDF
                                    </button>
                                </div>
                            )}

                            <div className="col-md-12 content-section single-post" dangerouslySetInnerHTML={{__html: content &&     content.content}} />

                            <div className="col-md-12 d-none mt-5">
                                <div className="py-3 d-flex align-items-center justify-content-between border-top border-bottom">
                                    <strong>SHARE IT:</strong>

                                    <div className="">
                                        <FacebookShareButton url="https://www.facebook.com/">
                                            <FacebookIcon className="mr-2" size={32} round={true} />
                                        </FacebookShareButton>
                                        <TwitterShareButton url="https://twitter.com/?lang=en">
                                            <TwitterIcon className="mr-2" size={32} round={true} />    
                                        </TwitterShareButton>
                                        <LinkedinShareButton url="https://www.linkedin.com/">
                                            <LinkedinIcon size={32} round={true} />    
                                        </LinkedinShareButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</main>
		</div>
    )
}

  
export default BlogpageSingle