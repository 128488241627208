import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../snippet/Loader';
import SEOPost from '../snippet/SEOPostNoIndex';
import { withRouter } from "react-router";
import { Modal } from 'react-bootstrap';

class SingleJob extends Component {
	constructor(props){
        super(props);

        this.state= {
			job: [],
			potentialMatches: [],
			selectedMatches: [],
			successCancel: '',
			successAdded: '',
			successRemove: '',
			planStatus:1,
			loader: false,
			show: false
        }
	}

    async componentDidMount(){
		var myHeaders = new Headers();
		myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
		myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		try {
			let Auth = localStorage.getItem('auth_empplyOrgUID');
			// 
			// console.log(process.env.REACT_APP_APIURL);
			// ${process.env.REACT_APP_APIURL}?endpoint=get_job_byID&job_id=${this.props.match.params.id}&created_user_id=${Auth}
			// ?itemType=get_job_byID&job_id=1967&user_id=1571
			await fetch(`${process.env.REACT_APP_APIURL}/?endpoint=get_job_byID&job_id=${this.props.match.params.id}&user_id=${Auth}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					job: result.jobs,
					potentialMatches: result.potentialMatches?.freelancers,
					selectedMatches: result.selectedCandidate,
					planStatus : result.subscription_status,
					loader: true
				})
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}

		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_potential_matches&job_id=${this.props.match.params.id}&user_id=${Auth}`, requestOptions)
		// .then(response => response.json())
		// .then(result => {
		// 	// console.log(result);
		// 	this.setState({
		// 		potentialMatches: result.freelancers
		// 	})
		// })
		// .catch(error => console.log('error', error));

		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_selected_candidates&job_id=${this.props.match.params.id}`, requestOptions)
		// .then(response => response.json())
		// .then(result => {
		// 	// console.log(result);
		// 	this.setState({
		// 		selectedMatches: result.selected_freelancer
		// 	})
		// })
		// .catch(error => console.log('error', error));	

		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${Auth}`, requestOptions)
		//   .then(response => response.json())
		//   .then(result => {
		// 	  this.setState({
		// 		  planStatus : result.data
		// 	  });
		//   })
		//   .catch(error => console.log('error', error));
	}

	async cancelEvent(){
		this.setState({
			loader: false
		})
		var myHeaders = new Headers();
		// myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
		// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		try {
			// 
			await fetch(`${process.env.REACT_APP_APIURL}/?endpoint=cancel_job&job_id=${this.props.match.params.id}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					successCancel: result.mesage
				});
				window.location.reload();
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
	}

	async addedFreelancer(id){
		// alert(id);
		this.setState({
			loader: false
		})
		var myHeaders = new Headers();
		// myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
		// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		try {
			// 
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=select_candidate&job_id=${this.props.match.params.id}&freelancer_id=${id}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					successAdded: result.message
				});
				window.location.reload();
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
	}

	async sendCalendarInvite(id){
		// alert(id);
		this.setState({
			loader: false
		})
		var myHeaders = new Headers();
		// myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
		// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		try {
			// 
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=invite_freelancer&freelancer_id=${id}&job_id=${this.props.match.params.id}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					successAdded: result.message
				});
				window.location.reload();
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}

	}

	async removeFreelancer(id){
		// alert(id);
		this.setState({
			loader: false
		})
		var myHeaders = new Headers();
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		try {
			// 
			//http://backend.eventmploy.com/app-api?endpoint=remove_selected&job_id=762&freelancer_id=181
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=remove_selected&freelancer_id=${id}&job_id=${this.props.match.params.id}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					successRemove: result.message
				});
				window.location.reload();
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
	}

	async activate(){
		this.setState({
			loader: false
		})
        // alert(this.props.location.state);
        var myHeaders = new Headers();
		//   myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		  };

		  try {
  
		await fetch(`${process.env.REACT_APP_APIURL}?endpoint=activate_job&job_id=${this.props.match.params.id}`, requestOptions)
		  .then(response => response.json())
		  .then(result => {
			this.setState({
				successCancel: result.mesage
			});
			window.location.reload();
		  })
		  .catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
    }

	handleShow(){
		this.setState({
			show: true
		})
	}

	handleClose(){
		this.setState({
			show: false
		})
	}

	// formatDate = (date) => {
    //     var d = new Date(date),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         year = d.getFullYear();
    
    //     if (month.length < 2) 
    //         month = '0' + month;
    //     if (day.length < 2) 
    //         day = '0' + day;
    
	// 	return [day, month, year].join('/');
    // }

    render() {
		const job = this.state.job;
		const potentialMatches = this.state.potentialMatches;
		const selectedMatches = this.state.selectedMatches;
		const profileStatus = this.state.planStatus;
		// console.log(job?.job_status === "3");

		if(profileStatus === 0){
			return(
				<div>
				<Loader loader={this.state.loader} />
            	<SEOPost WpPostObj={job && job.seo} />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row">
							<div className="col-sm-12 mb-md-5 mb-4">
								<div className="blank-area text-center">
									<h3>You have no <span>active plan</span>.</h3>
									<p><small>Please buy plan <Link rel="nofollow" className="text-primary" to="/e/membership">here</Link>!</small></p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			)
		}

        return (
            <div>
			<Loader loader={this.state.loader} />
			<SEOPost WpPostObj={job && job.seo} />
				<Modal show={this.state.show} centered onHide={() => this.handleClose()}>
					<Modal.Header className="border-0" closeButton>
					</Modal.Header>
					<Modal.Body className="text-center confirm-modal">
						<h2 className="text-primary">Are you sure you want to Cancel this event?</h2>
						<p>Please enter the below and click 'Confirm' <br />if you want to remove this.</p>

						<div className="mt-5 mb-3 d-flex align-items-center justify-content-center">
						<button type="button" className="btn mr-2" onClick={() => this.handleClose()}>
							Cancel
						</button>
						<button onClick={() => this.cancelEvent()} className="btn btn-secondary ">Confirm</button>
						</div>
					</Modal.Body>
				</Modal>
                <main className="page-dashboard">
					<div className="container-fluid">
						<div className="row">
                            <div className="col-sm-12 mb-md-5 mb-4">
                                <div className="post-card mb-xl-4">
									<div className="post-card-header align-items-center d-flex flex-wrap">
										<div className="post-card-header-info mr-auto">
											<h2 className="mt-0 mb-0" dangerouslySetInnerHTML={{__html: job && job.title}} />
											<p className="mb-2 pb-1"><span className="d-none d-md-inline-block">Posted on : </span>{job && job.post_date}</p>
											<div className="d-flex flex-wrap">
												{job && job.location ? (<div className="pc-info d-none d-md-block"><i className="fas fa-map-pin"></i> {job && job.location.street_address}</div>) : null}
												{job && job.location ? (<div className="pc-info d-block d-md-none"><i className="fas fa-map-marker-alt"></i> {job && job.location.street_address}</div>) : null}
												<div className="pc-info"><i className="far fa-calendar-alt"></i> {job && job.startDate} - {job && job.endDate}</div>
												<div className="pc-info"><i className="fas fa-users"></i> {job && job.freelancer_booked} Selected</div>
											</div>
											<p>Status: 
												<span className="pl-2 text-primary">
													{(() => {
														if(job && job.job_status === "1"){
															return "Active"
														}else if(job && job.job_status === "2"){
															return "Closed"
														}else if(job && job.job_status === "3"){
															return "Cancelled"
														}else{
															return "Inactive"
														}
													})()}
												</span>
												{this.state.successCancel && <span className="text-success d-block">{this.state.successCancel}</span>}
											</p>
										</div>
										<div className={`post-card-header-btn ${job && job.job_status === "1" ? "" : "d-none"}`}>
											<button onClick={() => this.handleShow()} className="btn btn-gray rounded-pill">Cancel Event</button>
										</div>
										<div className={`post-card-header-btn ${job && job.job_status === "0" ? "" : "d-none"}`}>
											<button onClick={() => this.activate()} className="btn btn-hm-primary rounded-pill">Activate Event</button>
										</div>
										
										{job && job.pay ? (
											<div className="post-card-header-budget text-center">
												<div>
													${parseInt(job && job.pay.amount).toLocaleString()}
													<span className="d-block text-primary">({job && job.pay.frequency})</span>
												</div>
												<div className="mt-xl-3">
													{job && job.freelancer_needed}
													<span className="d-block text-primary">Freelancer Needed</span>
												</div>
											</div>
										) : null}
									</div>

									<div className="post-card-body" style={{minHeight: '200px'}}>
										<div className="d-flex text-primary post-card-body-header align-items-center mb-4">
											<h3 className="m-0">Details</h3>
											<Link rel="nofollow" to={'/e/job-post-update/' + (job && job.job_id)} className="btn btn-hm-primary rounded-pill p-0 ml-auto" style={{minWidth: '100px'}}>Edit</Link>
										</div>
										<p dangerouslySetInnerHTML={{__html: job && job.desc}} />
										<div className="pc-tags">
											{job && job?.skills && job?.skills?.map((skill, index) => (
												<div className="pc-tag" key={index} dangerouslySetInnerHTML={{__html: skill.name}} />
											))}
										</div>
									</div>
									
									{selectedMatches.length < 1 &&
										<div className="post-card-body">
											<div className="d-flex text-primary post-card-body-header align-items-center mb-4 pb-md-2">
												<h3 className="m-0">No selected matches</h3>
											</div>
										</div>
									}

									{selectedMatches.length > 0 &&
									<div className="post-card-body">
										<div className="d-flex text-primary post-card-body-header align-items-center mb-4 pb-md-2">
											<h3 className="m-0">Selected Matches</h3>
											<span className="h3 p-0 font-weight-bold ml-auto text-primary">{selectedMatches.length}/{job && job.freelancer_needed}</span>
										</div>
										{job?.job_status === "3" && <h5 className='mb-3 text-primary'>This event is Cancelled now. So you can't share calendar invite.</h5>}
										{this.state.successRemove ? <p className="text-success">{this.state.successRemove}</p> : null}
										{selectedMatches.filter(id => id.is_favorite === 1).map((selected, index) => (
											<div className="info-card-list align-items-center d-flex flex-wrap flex-lg-nowrap event-card event-gray-card" key={index}>
												<div className="info-card-name mb-auto">
													<h3>
														{selected.is_favorite ? (
															<i className="fas event-card-heart fa-heart"></i>
														): null}
														<Link rel="nofollow" to={'/e/freelancer/'+selected.id}>{selected.first_name} {selected.last_name.slice(0, 1)}.</Link></h3>
													{selected.location && selected.location.street_address ? (
														<p className="mb-0"><i className="fas fa-map-pin mr-2"></i> {selected.location && selected.location.street_address}</p>
													) : null}
												</div>
												<div className="event-tags mr-auto">
													{selected.skills && selected.skills.map((skill, index) => (
														<div className="ev-tag" key={index} dangerouslySetInnerHTML={{__html: skill.skill_name}} />
													))}
												</div>
												{job?.job_status === "3" ? (<div className="info-card-controls"><Link rel="nofollow" to={'/e/user/' + selected.id} className="btn text-truncate p-0 btn-link btn-auto btn-block"><i className="fas fa-comment-dots"></i> Message {selected.first_name}</Link></div>):(
													<div className="info-card-controls">
														<button onClick={() => this.removeFreelancer(selected.id)} className="btn  mb-3 px-md-4 btn-secondary btn-block">Revoke Offer</button>
														{selected.calendar_invite_sent > 0 ? (
															<button disabled className="btn  mb-3 px-md-4 btn-secondary btn-block">Calendar Invite Sent</button>
														): (
															<button onClick={() => this.sendCalendarInvite(selected.id)} className="btn  mb-3 px-md-4 btn-secondary btn-block">Share Calendar Invite</button>
														)}
														<Link rel="nofollow" to={'/e/user/' + selected.id} className="btn text-truncate p-0 btn-link btn-auto btn-block"><i className="fas fa-comment-dots"></i> Message {selected.first_name}</Link>
													</div>
												)}
												
											</div>
										))}
										{selectedMatches.filter(id => id.is_favorite === 0).map((selected, index) => (
											<div className="info-card-list align-items-center d-flex flex-wrap flex-lg-nowrap event-card event-gray-card" key={index}>
												<div className="info-card-name mb-auto">
													<h3>
														{selected.is_favorite ? (
															<i className="fas event-card-heart fa-heart"></i>
														): null}
														<Link rel="nofollow" to={'/e/freelancer/'+selected.id}>{selected.first_name} {selected.last_name?.slice(0, 1)}.</Link></h3>
													{selected.location && selected.location.street_address ? (
														<p className="mb-0"><i className="fas fa-map-pin mr-2"></i> {selected.location && selected.location.street_address}</p>
													) : null}
												</div>
												<div className="event-tags mr-auto">
													{selected.skills && selected.skills.map((skill, index) => (
														<div className="ev-tag" key={index} dangerouslySetInnerHTML={{__html: skill.skill_name}} />
													))}
												</div>
												{job?.job_status === "3" ? (
													<div className="info-card-controls">
														<Link rel="nofollow" to={'/e/user/' + selected.id} className="btn text-truncate p-0 btn-link btn-auto btn-block"><i className="fas fa-comment-dots"></i> Message {selected.first_name}</Link>
													</div>
												):(
													<div className="info-card-controls">
														<button onClick={() => this.removeFreelancer(selected.id)} className="btn  mb-3 px-md-4 btn-secondary btn-block">Revoke Offer</button>
														{selected.calendar_invite_sent > 0 ? (
															<button disabled className="btn  mb-3 px-md-4 btn-secondary btn-block">Calendar Invite Sent</button>
														): (
															<button onClick={() => this.sendCalendarInvite(selected.id)} className="btn  mb-3 px-md-4 btn-secondary btn-block">Share Calendar Invite</button>
														)}
														<Link rel="nofollow" to={'/e/user/' + selected.id} className="btn text-truncate p-0 btn-link btn-auto btn-block"><i className="fas fa-comment-dots"></i> Message {selected.first_name}</Link>
													</div>
												)}
											</div>
										))}
									</div>
									}

									{potentialMatches.length < 1 &&
										<div className="post-card-body mb-5 pb-5">
											<div className="d-flex text-primary post-card-body-header align-items-center mb-4 pb-md-2">
												<h3 className="m-0">No other potential matches</h3>
											</div>
										</div>
									}

									{potentialMatches.length > 0 &&
										<div className="post-card-body mb-5 pb-5">
											<div className="post-card-body-header mb-4 pb-md-2">
												<h3 className="text-primary mt-0">Other potential Matches</h3>
												<p>All Freelancers that meet your criteria {this.state.successAdded && <span className="text-success d-block">{this.state.successAdded}</span>}</p>
											</div>
											{job?.job_status === "3" && <h5 className='mb-3 text-primary'>This event is Cancelled now. So you can't select potential matches.</h5>}
											
											{potentialMatches.filter(id => id.is_favorite === 1).map((potential, index) => (
												<div className="info-card-list align-items-center d-flex flex-wrap flex-lg-nowrap event-card event-gray-card" key={index}>
													<div className="info-card-name mb-auto">
														<h3>
															{potential.is_favorite ? (
																<i className="fas event-card-heart fa-heart"></i>
															): null}
															<Link rel="nofollow" to={'/e/freelancer/'+potential.id}>{potential.first_name} {potential.last_name.slice(0, 1)}.</Link></h3>
														{potential.location && potential.location.street_address ? (
															<p className="mb-0"><i className="fas fa-map-pin mr-2"></i> {potential.location && potential.location.street_address}</p>
														): null}
													</div>
													
													<div className="event-tags mr-auto">
														{potential.skills && potential.skills.map((skill, index) => (
															<div className="ev-tag" key={index} dangerouslySetInnerHTML={{__html: skill.skill_name}} />
														))}
													</div>
													{job?.job_status === "3" ? (
														<div className="info-card-controls"><Link rel="nofollow" to={'/e/user/' + potential.id} className="btn  text-truncate p-0 btn-link btn-auto btn-block"><i className="fas fa-comment-dots"></i> Message {potential.first_name}</Link></div>
													):(
														<div className="info-card-controls">
															{parseInt(selectedMatches.length) === parseInt(job?.freelancer_needed) ? (
																<button disabled={true} className="btn btn-disabled  mb-3 px-md-4 btn-secondary btn-block">Select</button>
															): (
																<button onClick={() => this.addedFreelancer(potential.id)} className="btn  mb-3 px-md-4 btn-secondary btn-block">Select</button>
															)}
															<Link rel="nofollow" to={'/e/user/' + potential.id} className="btn  text-truncate p-0 btn-link btn-auto btn-block"><i className="fas fa-comment-dots"></i> Message {potential.first_name}</Link>
														</div>
													)}
												</div>
											))}
											{potentialMatches.filter(id => id.is_favorite === 0).map((potential, index) => (
												<div className="info-card-list align-items-center d-flex flex-wrap flex-lg-nowrap event-card event-gray-card" key={index}>
													<div className="info-card-name mb-auto">
														<h3>
															{potential.is_favorite ? (
																<i className="fas event-card-heart fa-heart"></i>
															): null}
															<Link rel="nofollow" to={'/e/freelancer/'+potential.id}>{potential.first_name} {potential.last_name.slice(0, 1)}.</Link></h3>
														{potential.location && potential.location.street_address ? (
															<p className="mb-0"><i className="fas fa-map-pin mr-2"></i> {potential.location && potential.location.street_address}</p>
														): null}
													</div>
													
													<div className="event-tags mr-auto">
														{potential.skills && potential.skills.map((skill, index) => (
															<div className="ev-tag" key={index} dangerouslySetInnerHTML={{__html: skill.skill_name}} />
														))}
													</div>
													{job?.job_status === "3" ? (
														<div className="info-card-controls">
															<Link rel="nofollow" to={'/e/user/' + potential.id} className="btn  text-truncate p-0 btn-link btn-auto btn-block"><i className="fas fa-comment-dots"></i> Message {potential.first_name}</Link>
														</div>
													):(
														<div className="info-card-controls">
															{parseInt(selectedMatches.length) === parseInt(job?.freelancer_needed) ? (
																<button disabled={true} className="btn btn-disabled  mb-3 px-md-4 btn-secondary btn-block">Select</button>
															): (
																<button onClick={() => this.addedFreelancer(potential.id)} className="btn  mb-3 px-md-4 btn-secondary btn-block">Select</button>
															)}
															{/* <button className="btn  mb-3 px-md-4 btn-secondary btn-block">Select</button> */}
															<Link rel="nofollow" to={'/e/user/' + potential.id} className="btn  text-truncate p-0 btn-link btn-auto btn-block"><i className="fas fa-comment-dots"></i> Message {potential.first_name}</Link>
														</div>
													)}
												</div>
											))}
										</div>
									}

                                </div>
                            </div>
                        </div>
					</div>
				</main>
            </div>
        );
    }
}

export default withRouter(SingleJob);