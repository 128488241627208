import { Carousel } from "react-bootstrap";
import Ratings from '../../../assets/images/ratings.svg';
import { useEffect, useState } from "react";

export default function HomeTestimonials(){
    const [testimonials, setTestimonials] = useState([]);
    useEffect(() => {
        async function fetchData() {
            var myHeaders = new Headers();
            myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
            myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
            
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            try {
                // 
                // console.log(`${process.env.REACT_APP_APIURL}?endpoint=get_testimonials`);
                await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_testimonials`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setTestimonials(result?.testimonials);
                })
                .catch(error => console.log('error', error));
            } catch (error) {
                console.log('error', error);
            }
        }
        fetchData();
      }, []);

    return(
        testimonials?.length > 0 && (
            <div className="section-home-testimonials">
                <div className="container">
                    <div className="section-home-title text-center">
                        <h2>What Others Say About Us?</h2>
                    </div>

                    <Carousel>
                        {testimonials?.filter(item => item?.image)?.map((item, index) => (
                           <Carousel.Item key={index}>
                                <TestimonialItem data={item ?? {}} />
                            </Carousel.Item> 
                        ))}
                    </Carousel>
                </div>
            </div>
        )
    )
}

const TestimonialItem = ({data}) => {
    return(
        <div className="row justify-content-between">
            <div className="col-lg-4 mb-lg-0 mb-3 order-lg-1">
                {data?.image  && <img src={data?.image} width={370} height={420} alt={data?.image_alt_text} className="img-fluid testimonial-hm-image w-100"/>}
            </div>
            <div className="col-lg-6">
                <div className="testimonial-hm-content d-flex flex-column align-items-start justify-content-start">
                    <div className="testimonial-hm-avatar d-flex align-items-center">
                        {data?.image  && <img src={data?.image} width={80} height={80} alt={data?.image_alt_text} className="img-fluid "/>}
                        <h6>{data?.title} <span>Freelancer</span></h6>
                    </div>
                    <img src={Ratings} width={133} height={20} alt="Ratings" className="img-fluid"/>
                    <h5 className="mb-0">{data?.designation}</h5>
                    <p>{data?.content}</p>
                    <div className="signature">{data?.signature}</div>
                </div>
            </div>
        </div>
    )
}