import React, { Component } from 'react';

import {
    Link
  } from "react-router-dom";

// Layout
// import Layout from '../global/BaseLayout';
import Filter from './snippet/Filter';

import SearchBanner from '../assets/images/search-banner.png';

// import loadable from '@loadable/component'
// const Layout = loadable(() => import('../global/BaseLayout'));
// const Filter = loadable(() => import('./snippet/Filter'));


class Search extends Component {
    constructor(props){
        super(props);

        this.state= {
            searchResult : {},
            result: false,
            source : ''
		}
	}

    storeResult(result,source, data){
        this.setState({
            searchResult : data,
            result : result,
            source: source
        })
    }

    componentDidMount(){
        if(this.props.location.state){
            this.setState({
                searchResult: this.props.location.state.searchResult,
                result: this.props.location.state.result,
                source: this.props.location.state.source
            })
        }
    }

    render() {
        const freelancer = this.state.searchResult;
        const event = this.state.searchResult;
        let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
        let AuthFree = localStorage.getItem('auth_empplyFreeUID');
        

        // console.log(event);
        // console.log('search page');
        // const events = this.state.searchResult.freelancers
        
        return(
            <div>
                <main className="page-content">
                    <div className="inner-banner text-white d-flex align-items-center" style={{backgroundImage: `url(${SearchBanner})`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-11 col-lg-6 pr-md-5 mt-md-5 mt-2 pt-md-5">
                                    <h1>Search Result</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Filter className="mb-0" getResult={(result,source, data) => this.storeResult(result, source, data)} />

                    <div className="page-inner-content mt-md-0 mt-5">
                        <div className="container">
                            <div className="row mb-md-4 mb-3">
                                <div className="col-md-12 content-section d-flex flex-wrap">
                                    <h2>Search Results "<span>{this.state.result ? (this.state.source === 'search_freelancer' ? 'Event Professionals' : this.state.source === 'search_event_organiser' ? "Find Events" : 'No Result') : 'No Result'}</span>"</h2>

                                    {(() => {
                                        if(this.state.source === 'search_freelancer'){
                                            return <div className="search-results ml-auto mt-md-2">{freelancer.freelancers && freelancer.freelancers.length + " results"}</div>
                                        }
                                    })()}

                                    {(() => {
                                        if( this.state.source === 'search_event_organiser'){
                                            return <div className="search-results ml-auto mt-md-2">{event.eo && event.eo.length + " results"}</div>
                                        }
                                    })()}
                                    
                                </div>
                            </div>

                            <div className="row">
                                {freelancer.freelancers && freelancer.freelancers.map((free, index) => (
                                    <div className="col-sm-12 mb-md-4 mb-3" key={index}>
                                        <div className="search-item">
                                            <div className="search-heading mb-lg-4 mb-3 align-items-center d-flex">
                                                <div className="ur_short_name">{free.first_name && free.first_name.slice(0, 1)}{free.last_name && free.last_name.slice(0, 1)}</div>
                                                <div className="ml-lg-4 ml-3">
                                                    <h3 className="mb-1">
                                                        {AuthOrg ? (
                                                            <Link rel="nofollow" to={"/e/freelancer/" + free.id}>{free.first_name} {free.last_name && free.last_name.slice(0, 1)}.</Link>
                                                            ):(
                                                            <Link to={"/login"}>{free.first_name} {free.last_name && free.last_name.slice(0, 1)}.</Link>
                                                        )}
                                                    </h3>
                                                    {free.location && (
                                                        <p className="m-0"><i className="fas fa-map-pin mr-1"></i> {free.location.street_address}</p>
                                                    )}
                                                </div>
                                            </div>

                                            <p dangerouslySetInnerHTML={{__html: free.bio}} />

                                            <div className="pc-tags mt-0">
                                                {free.skills && free.skills.map((skill, index) => (
                                                    <div className="pc-tag" key={index}>{skill.skill_name}</div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}


                                {event?.events?.map((eo, index) => (
                                    <div className="col-sm-12 mb-md-4 mb-3" key={index}>
                                        <div className="search-item">
                                            <div className="search-heading mb-lg-4 mb-3 align-items-center d-flex">
                                                <div className="ur_short_name text-white">{eo.name && eo.name.slice(0, 1)}{eo.name && eo.name.slice(0, 1)}</div>
                                                <div className="ml-lg-4 ml-3">
                                                    <h3 className="mb-1">
                                                        {AuthFree ? (
                                                            <Link rel="nofollow" to={"/f/job/" + eo.id}>{eo.name} {eo.name && eo.name.slice(0, 1)}.</Link>
                                                        ):(
                                                            <Link to={`/login/?job=${eo.id}`}>{eo.name} {eo.name && eo.name.slice(0, 1)}.</Link>
                                                        )}
                                                    </h3>
                                                    {eo.location && (
                                                        <p className="m-0"><i className="fas fa-map-pin mr-1"></i> {eo.location.street_address}, {eo.location.suburb}</p>
                                                    )}
                                                </div>
                                            </div>

                                            <p dangerouslySetInnerHTML={{__html: eo.bio}} />

                                            {/* <div class="pc-tags mt-0">
                                                <div class="pc-tag">Site Manager</div>
                                                <div class="pc-tag">Warden</div>
                                                <div class="pc-tag">Technician</div>
                                            </div> */}
                                        </div>
                                    </div>
                                ))}

                                {(() => {
                                    if(this.state.source === 'search_freelancer'){
                                        return freelancer.freelancers && freelancer.freelancers[0] ? null : (
                                                <div className="col-sm-12 mb-md-5 mb-4">
                                                    <div className="blank-area text-center">
                                                        <h3>No result Found</h3>
                                                    </div>
                                                </div>
                                            )
                                    }
                                })()}

                                {(() => {
                                    if(this.state.source === 'search_event_organiser'){
                                        return event?.events && event?.events[0] ? null : (
                                                <div className="col-sm-12 mb-md-5 mb-4">
                                                    <div className="blank-area text-center">
                                                        <h3>No result Found</h3>
                                                    </div>
                                                </div>
                                            )
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default Search;