import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Geocode from "react-geocode";

import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';
import Freelancers from "./snippet/FreelancerCards";
import HorizontalScroll from "../snippet/HorizontalScroll";



class dashboard extends Component {

	constructor(props){
        super(props);

        this.state= {
			userDetails: {},
			freelancerData: [],
			planStatus:1,
			liveLocation: '',
			liveLocationCountry: '',
			loader: false,
			lat: '',
			lng: ''
        }
	}

	async componentDidMount(){
		Geocode.setApiKey("AIzaSyDvjfqhgd5hYoPDGH8DF8SMxIB9Bs-6mvc"); 
		Geocode.setLanguage("en");

		var myHeaders = new Headers();
		  myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };
  
		  try {
			let AuthOrg = localStorage.getItem('auth_empplyOrgUID');

			// fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${AuthOrg}`, requestOptions)
			//   .then(response => response.json())
			//   .then(result => {
			// 	  this.setState({
			// 		  planStatus : result.data
			// 	  });
			//   })
			//   .catch(error => console.log('error', error));
	
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=event_orgniser_detail&user_id=${AuthOrg}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				//   console.log(result);
				this.setState({
				userDetails : result.user_detail,
				planStatus : result.subscription_status,
				loader: true,
				//   loader: true
				})
			})
			.catch(error => console.log('error', error));

			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_all_freelancer`, requestOptions)
			.then(async response => await response.json())
			.then(result => {
				// setData(result.freelancers);
				// console.log(result.freelancers);
				this.setState({
					freelancerData: result.freelancers ?? []
				})
			})
			.catch(error => console.log('error', error));


		

			let self = this;
			navigator.geolocation.getCurrentPosition(function(position) {
				//   console.log("Latitude is :", position.coords.latitude);
				//   console.log("Longitude is :", position.coords.longitude);
				// console.log("Location is :", position);
				let city, state, country;

				Geocode.fromLatLng(position.coords.latitude, position.coords.longitude)
				.then(response => {
					// const address = response.results[0].formatted_address;
					for (let i = 0; i < response.results[0].address_components.length; i++) {
						for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
							switch (response?.results[0]?.address_components[i]?.types[j]) {
							case "locality":
								city = response?.results[0]?.address_components[i]?.long_name;
								break;
							case "administrative_area_level_1":
								state = response?.results[0]?.address_components[i]?.long_name;
								break;
							case "country":
								country = response?.results[0]?.address_components[i]?.long_name ?? 'Australia';
								break;
							default:
								break;
							}
						}
					}
					
					console.log(city, state, country);
					self.setState({
						liveLocation: city,
						liveLocationCountry: country,
						lat: (position.coords.latitude).toFixed(1),
						lng: (position.coords.longitude).toFixed(1)
					})
					// console.log(address);				
				},
				(error) => {
					console.error(error);
				});
			});
		} catch (error) {
			console.log('error', error);
		}
	}

	render() {
		const userDetails = this.state.userDetails;
		const profileStatus = this.state.planStatus;

		const lat = parseFloat(this.state.lat);
		const lng = parseFloat(this.state.lng);

		

		const latMin = lat - 0.2;
		const latMax = lat + 0.2;

		const lngMin = lng - 0.2;
		const lngMax = lng + 0.2;

		const freelancerDataByCity = this.state.liveLocation ? this.state.freelancerData?.filter(f => f.location && ((f.location.latitude > latMin && f.location.latitude < latMax) && (f.location.longitude > lngMin && f.location.longitude < lngMax))) : [];
		const freelancerDataByCountry = this.state.liveLocationCountry ? this.state.freelancerData?.filter(f => f.location && f.location.country === this.state.liveLocationCountry) : [];

		// console.log(profileStatus);

		if(profileStatus === 0){
			return(
				<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Organizer Dashboard" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-md-5 mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>Hey {userDetails.first_name}, <br/><span>Welcome</span> Back!</h1>
								<p>Post your events and get started now!</p>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 mb-md-5 mb-4">
								<div className="blank-area text-center">
									<h3>You have no <span>active plan</span>.</h3>
									<p><small>Please buy plan <Link rel="nofollow" className="text-primary" to="/e/membership">here</Link>!</small></p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			)
		}

		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Organizer Dashboard" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-md-5 mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>Hey {userDetails.first_name}, <br/><span>Welcome</span> Back!</h1>
								<p>Post your events and get started now!</p>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-10 col-xl-8 col-md-9 admin-subheading">
								<h3>Event professionals in <span className="text-underline">{this.state.liveLocation ? this.state.liveLocation : 'Loading...'}</span></h3>
								<p>These are some of the professionals in {this.state.liveLocation}, looking for work. Simply <span>connect, chat and work</span>, with anyone you think would be suitable for the job.</p>
							</div>
						</div>

						{/* <HorizontalScroll className="dashboard-hscroll">
							<Freelancers location="Sydney" />
						</HorizontalScroll> */}
						{freelancerDataByCity?.length > 0 ? this.state.liveLocation && (
							freelancerDataByCity.length < 4 ? (
								<div className='dashboard-hscroll dashboard-hscroll-single'>
									<Freelancers sort="city" data={this.state.freelancerData ?? []} dataCity={freelancerDataByCity ?? []} location={this.state.liveLocation} lat={this.state.lat} lng={this.state.lng} />
								</div>
							):(
								<HorizontalScroll className="dashboard-hscroll">
									<Freelancers sort="city" data={this.state.freelancerData ?? []} dataCity={freelancerDataByCity ?? []} location={this.state.liveLocation} lat={this.state.lat} lng={this.state.lng} />
									
								</HorizontalScroll>
							)
						):(
							<div className="he_extra"><h3>No Result found</h3></div>
						)}
						{/* section-hscroll dashboard-hscroll overflow-auto d-sm-flex flex-sm-nowrap */}

						<div className="row mt-5">
							<div className="col-lg-10 col-xl-8 col-md-9 admin-subheading">
								<h3>Recently listed Event professionals in <span className="text-underline">{this.state.liveLocationCountry ? this.state.liveLocationCountry : 'Loading...	'}</span></h3>
								<p>These are some of the professionals in {this.state.liveLocationCountry}, looking for work. Simply <span>connect, chat and work</span>, with anyone you think would be suitable for the job.</p>
							</div>
						</div>
						{/* <HorizontalScroll className="dashboard-hscroll">
							<Freelancers location="Australia" />
						</HorizontalScroll> */}
						{freelancerDataByCountry.length > 0 ? this.state.liveLocationCountry && (
							freelancerDataByCountry.length < 4 ? (
								<div className='dashboard-hscroll dashboard-hscroll-single'>
									<Freelancers sort="country" data={this.state.freelancerData ?? []} dataCountry={freelancerDataByCountry ?? []} location={this.state.liveLocationCountry} />
								</div>
							):(
								<HorizontalScroll className="dashboard-hscroll">
									<Freelancers sort="country" data={this.state.freelancerData ?? []} dataCountry={freelancerDataByCountry ?? []} location={this.state.liveLocationCountry} />
								</HorizontalScroll>
							)
						):(
							<div className="he_extra"><h3>No Result found</h3></div>
						)}
					</div>
				</main>
			</div>
		);
	}
}

export default dashboard;