import React, { Component } from 'react';
import ContactBanner from '../assets/images/contact-us.png';
import ContactForm from './snippet/ContactForm';

import Loader from './snippet/Loader';
import SEOPage from './snippet/SEOPage';


class ContactUs extends Component {
    constructor(props){
        super(props);

        this.state= {
            imageAlt: false,
            tagline: '',
            loader: false,
            image: false
		}
	}

    async componentDidMount(){
      var myHeaders = new Headers();
      myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
      myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
      
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      try {
        await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=contact us`, requestOptions)
        .then(response => response.json())
        .then(result => {
          // console.log(result);
          if(result.alt_text){
            this.setState({
              imageAlt : result.alt_text
            })
          }
          this.setState({
            loader : true,
            tagline: result.tag_line,
            image: result.background_image
          })
        })
        .catch(error => console.log('error', error));
      } catch (error) {
          console.log('error', error);
      }
    }

    render() {
        return (
          <div>
            <Loader loader={this.state.loader} />
            <SEOPage pageTitle="contact us" />
            <main className="page-content">
              <div
                className="inner-banner overflow-hidden text-white d-flex align-items-center">
                <img src={this.state?.image || ContactBanner} alt={this.state.imageAlt || this.state.tagline} className="banner-image" />
                <div className="container">
                  <div className="row">
                    <div className="col-md-11 col-lg-6 pr-md-5 mt-md-5 mt-2 pt-md-5">
                      <h1>
                        <span>Contact</span> Us
                      </h1>
                      <p>
                        We always want to hear from you! Let us know how we can
                        best help you and we'll do our very best.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="page-inner-content">
                <div className="container">
                  <div className="row d-none mb-md-5 mb-4 justify-content-center text-center">
                    <div className="col-md-6 content-section">
                      <h2>
                        Get in <span>Touch</span>
                      </h2>
                      <p>
                        Quisque aliquam egestas semper. Phasellus est ipsum,
                        facilisis at pellentesque et, dapibus at nulla. Morbi
                        risus dolor, lobortis volutpat cursus et, fermentum
                        vitae purus.
                      </p>
                    </div>
                  </div>

                  <div className="row mb-md-5 mb-4 justify-content-center text-center">
                    <div className="col-sm-4 d-none mb-3">
                      <div className="contact-info">
                        <i className="fas fa-map-marker-alt"></i>
                        <p>
                          <strong>Address</strong>
                          <br />
                          33 George Street, <br />
                          Sandringham Vic 3191
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 mb-3">
                      <div className="contact-info">
                        <i className="fas fa-phone-alt"></i>
                        <p>
                          <strong>Phone</strong>
                          <br />
                          +61421 725794
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 mb-3">
                      <div className="contact-info">
                        <i className="fas fa-envelope"></i>
                        <p>
                          <strong>Email</strong>
                          <br />
                          hello@eventmploy.com
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="contact-form content-section">
                    <div className="text-center pb-4">
                      <h2>
                        Send Us a Message
                      </h2>
                    </div>

                    <ContactForm />
                  </div>
                </div>
              </div>
            </main>
          </div>
        );
    }
}

export default ContactUs;