import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';
// import Layout from "../../global/PrimaryLayoutFreelancer";


class ProfileChange extends Component {
	constructor(props){
        super(props);

        this.state= {
            fields: {},
			errors: {},
			success:'',
			enableFields: false,
			userDetails: {},
			formLoader: false,
			loader: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
	}
	
	handleSubmit = async (e) => {
		e.preventDefault();
		
		let AuthOrg = localStorage.getItem('auth_empplyFreeUID');
		
        if (this.validateForm()) {
			this.setState({
				formLoader: true
			})

            var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

			var urlencoded = new URLSearchParams();
			urlencoded.append("endpoint", "update_password");
			urlencoded.append("user_id", AuthOrg);
			urlencoded.append("password", this.state.fields.password);

			var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: urlencoded,
			redirect: 'follow'
			};

			await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					success: result.mesage,
					formLoader: false
				})
				setTimeout(() => {
					window.location.reload();
				}, 800);
			})
			.catch(error => console.log('error', error));
        
        } //this.validateForm
	}
	
	validateForm() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;
	
	
		// Password error messages
		if (!fields["password"]) {
		  formIsValid = false;
		  errors["password"] = "*Please enter your Password.";
		}
		
		// Confirm Password error messages
		if (!fields["confirmed"]) {
		  formIsValid = false;
		  errors["confirmed"] = "Please enter your confirm password.";
		}
	
		// Compare Password error messages
		if (typeof fields["password"] !== "undefined" && typeof fields["confirmed"] !== "undefined") {
			  
		  if (fields["password"] !== fields["confirmed"]) {
			formIsValid = false;
			errors["password"] = "Passwords don't match.";
		  }
		} 
	
	
		this.setState({
		  errors: errors
		});
		return formIsValid;
	}

	enableFields(){
		this.setState({
			enableFields: !this.state.enableFields
		})
	}

	async componentDidMount(){
		var myHeaders = new Headers();
		  myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };
  
		  try {
			let AuthFree = localStorage.getItem('auth_empplyFreeUID');
	
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=freelancer_user_detail&user_id=${AuthFree}`, requestOptions)
			.then(response => response.json())
			.then(result => {
			// console.log(result.user_detail);
				this.setState({
				userDetails : result.user_detail,
				loader: true
				})
			})
			.catch(error => console.log('error', error));
			} catch (error) {
				console.log('error', error);
			}
	}

	render() {
		const userDetails = this.state.userDetails;
		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer Profile Change" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>profile</span></h1>
								<p>It happens to all of us, just use the form below to reset your password.</p>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-5 mb-4 col-md-4 col-xl-3">
							<div className="profile-card d-flex flex-column align-items-center text-center">
								<div className="position-relative">
									<div className="contact-auth">{userDetails.first_name && userDetails.first_name.slice(0, 1)}{userDetails.last_name && userDetails.last_name.slice(0, 1)}</div>
								</div>

								<strong className="text-capitalize">{userDetails.first_name + ' ' + userDetails.last_name}</strong>
								<span>Event Professional</span>
								</div>

								<div className="list-group">
									<Link rel="nofollow" to="/f/profile" className="list-group-item list-group-item-action"><i className="fas fa-file-alt"></i> Personal Information</Link>
									<Link rel="nofollow" to="/f/change-password" className="list-group-item active list-group-item-action"><i className="fas fa-lock"></i> Change Password</Link>
									<Link rel="nofollow" to="/f/complete-profile" className="list-group-item list-group-item-action"><i className="fas fa-user"></i> Complete Profile</Link>
								</div>
							</div>
							<div className="col-lg-12 col-md-8 col-xl-8">
								<div className="profile-box">
									<div className="d-flex flex-nowrap mb-4">
										<div className="d-auto">
											<h3>Change Password</h3>
											{/* <p>Upgrade your password</p> */}
										</div>
										<div className="d-auto ml-auto">
											<button onClick={() => this.enableFields()} type="button" className="btn btn-link p-0 text-primary btn-edit"><i className="fas fa-pen"></i>  {this.state.enableFields && 'Now '} Edit Fields</button>
										</div>
									</div>

									<div className="form-area">
									<form onSubmit={this.handleSubmit}>
											<div className="row">
												<div className="form-group mb-md-4 mb-3 col-sm-6" title={this.state.enableFields ? "" : "Please click on edit button"}>
													<div className="form-field">
														<label>Enter New Password</label>
														<input 
															onChange={this.handleChange}
															type="password" 
															className="form-control" 
															name="password"
															placeholder="Password" 
															disabled={this.state.enableFields ? false : true}
														/>
													</div>
													<div className={`invalid-feedback text-right ${this.state.errors.password ? 'd-block' : null }`}>{this.state.errors.password}</div>
												</div>
												<div className="form-group mb-md-4 mb-3 col-sm-6" title={this.state.enableFields ? "" : "Please click on edit button"}>
													<div className="form-field">
														<label>Confirm New Password</label>
														<input 
															onChange={this.handleChange}
															type="password" 
															className="form-control" 
															name="confirmed"
															placeholder="Confirm Password" 
															disabled={this.state.enableFields ? false : true}
														/>
													</div>
													<div className={`invalid-feedback text-right ${this.state.errors.confirmed? 'd-block' : null}`}>{this.state.errors.confirmed}</div>
												</div>
												<div className="w-100 col-md-12 mb-sm-5">
													{this.state.success && (
														<div className="text-success font-weight-bold">
															{this.state.success}
														</div>
													)}
												</div>
												<div className="form-group mb-md-0 mb-3 col-sm-6 ml-auto">
													<button type="submit" className="btn btn-hm-primary btn-block btn-lg mb-3 px-4 d-flex align-items-center" disabled={this.state.formLoader ? true : false}>{this.state.formLoader ? 'Sending...' : 'Save'} <i className="fas ml-auto fa-arrow-right"></i></button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		);
	}
}

export default ProfileChange;