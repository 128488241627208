import React, { useEffect, useState, useRef } from 'react';
// import {
//     Link
//   } from "react-router-dom";

import Loader from './snippet/Loader';
import SEOPage from './snippet/SEOPage';

// import BlogBanner from '../assets/images/blogImage.png';

const WebinarPage = () => {
    const [content, setContent] = useState();
    const [visible, setVisible] = useState(6);
    const [items, setTtems] = useState(0);
    const [loadingState, setLoading] = useState(false);
    const [data, setData] = useState();
	const [loader, setloader] = useState(false)

    const myRef = useRef();

    useEffect(() => {
        async function fetchData() {
            var myHeaders = new Headers();
            
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            // 
            try{
                await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_webinars`, requestOptions)
                .then(async response => await response.json())
                .then(result => {
                    // console.log(result);
                    setContent(result?.webinars);
                    setTtems(result?.webinars.length);
                    setData(result)
                    setloader(true);
                })
                .catch(error => console.log('error', error));
            } catch (error) {
                console.log('error', error);
            }
        }
        fetchData();

        // fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=Our Blog`, requestOptions)
        // .then(async response => await response.json())
        // .then(result => {
        //     console.log(result);
        //     setImageAlt(result.alt_text)
        // })
        // .catch(error => console.log('error', error));
    }, [])

    function loadMore(){    
        setLoading(true);
        setloader(false);
        setTimeout(() => {
        //   this.setState((prev) => {
        //     return {visible: prev.visible + 15, loadingState: false};
        //   });
          setVisible(visible + 6);
          setLoading(false);
          setloader(true);
          if (typeof window !== 'undefined' && visible < items ) {
            // const height = document.getElementsByClassName('article')[0].clientHeight;
            // window.scrollTo({behavior: 'smooth', top: myRef.current.offsetTop - (height * 16)})
          }
        }, 1000);
    }

    return(
        <div>
            <Loader loader={loader} />
			<SEOPage pageTitle="Webinars" />
			<main className="page-content">
				<div className="inner-banner text-white d-flex align-items-center">
                    <img src={data && data.background_image} alt="Webinar banner" className="banner-image" />
					<div className="container">
						<div className="row">
							<div className="col-md-11 col-lg-6 pr-md-5 mt-md-5 mt-2 pt-md-5">
								<h1><span>Our</span> {data && data.pageTitle}</h1>
                                {/* <p>Quisque aliquam egestas semper. Phasellus est ipsum, facilisis at pellentesque et,</p> */}
							</div>
						</div>
					</div>
				</div>

				<div className="page-inner-content">
                    <div className="container">
                        <div className="row mb-md-5 mb-4">
                            <div className="col-md-12 content-section">
                                <h2 className='mb-0'>Past webinars</h2>
                                {/* <p>Donec placerat est enim, molestie finibus sem eleifend. In commodo, velit sed consequat vehicula, ipsum tellus vehicula neque, eget porttitor quam odio vitae tellus. Nullam sem lacus, aliquet sed fermentum vel, placerat sit amet erat volutpat. Quisque rhoncus lectus et nisl mattis aliquet. Aenean eleifend dolor sit amet imperdiet semper phasellus finibus auctor dui eget bibendum. Suspendisse ac tortor consequat, dictum urna ac.</p> */}
                            </div>
                        </div>

                        <div className="row article">
                            {content && content.slice(0, visible).map((post, index) => (
                                <div className="col-sm-6 col-lg-4 mb-4" key={index}>
                                    <a rel="noreferrer" href={post.youtubeLink} target="_blank" className="post-box d-block post-box-webinar">
                                        <div className="post-image d-block" style={{backgroundImage: `url(${post.image})`}}>
                                            {/* <div className="post-meta"><strong>{post.post_date}</strong></div> */}
                                        </div>
                                        <div className="post-info pb-3 px-0">
                                            <h3><span dangerouslySetInnerHTML={{__html: post.title}} /></h3>
                                            {/* {
                                                post.tags && (
                                                    <div className="d-flex flex-wrap">
                                                        {post.tags.map((tag, index) => (
                                                            <div className="post-tag" key={index}><i className="fas fa-tags"></i> {tag.name}</div>
                                                        ))}
                                                    </div>
                                                )
                                            } */}
                                            {/* <p dangerouslySetInnerHTML={{__html: post.content}} /> */}
                                            <div className='webinar-status text-primary'>Info Session Ended</div>
                                            <div className='text-center'>
                                                <span className="btn d-inline-flex align-items-center btn-hm-primary">
                                                    <span className="play-btn">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" className="bi bi-play-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
                                                        </svg>    
                                                    </span> 
                                                    View Recording</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>

                        {visible < items && 
                            <div className="text-center pt-md-4 pt-2">
                                <button onClick={loadMore} ref={myRef} className="btn btn-outline-primary btn-lg btn-text-space px-md-5">{loadingState ? 'Loading...' : 'Load More'}</button>
                            </div>
                        }

                    </div>
                </div>
			</main>
		</div>
    )
}

  
export default WebinarPage