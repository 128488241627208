import React from 'react';
import { Redirect, useLocation, Route } from 'react-router-dom';


import DashboardHeader from '../components/supplier/DashboardHeader';
import SupplierMembership from "../components/supplier/MembershipPlan";
import SupplierDashboard from "../components/supplier/dashboard";
import SupplierPayment from "../components/supplier/Payment";
import SupplierNotification from "../components/supplier/Notification";
import SupplierProfile from "../components/supplier/Profile";
import SupplierProfileChange from "../components/supplier/ProfileChange";
import SupplierContactAdmin from "../components/supplier/ContactAdmin";
import NotFound from '../components/notFound';
import ThankYouPayment from "../components/supplier/ThankYouPayment";

// import NotFound from '../components/notFound'
const PrimarylayoutSupplier = () => {
    const {pathname} = useLocation();

    let AuthSup = localStorage.getItem('auth_empplySplierUID');

    if(!AuthSup){
        return <Redirect push to="/" />
    }
    return (
        <div id="wrapper">
            
            {AuthSup && <DashboardHeader />}
                            
            {pathname === "/s/membership" && <SupplierMembership />}
            {pathname === "/s/dashboard" && <SupplierDashboard />}
            {/* {pathname === "/s/payment" && <SupplierPayment />} */}
            <Route path="/s/payment" component={SupplierPayment} />
            {pathname === "/s/notifications" && <SupplierNotification />}
            {pathname === "/s/profile" && <SupplierProfile />}
            {pathname === "/s/change-password" && <SupplierProfileChange />}
            {pathname === "/s/contact-admin" && <SupplierContactAdmin />}
            {pathname === "/s/thank-you-payment" && <ThankYouPayment />}
            {pathname === "/" && <SupplierDashboard />}
            
            <NotFound view="dashboard" />
            {/* <Route path="*" component={NotFound} /> */}
        </div>
    );
}

export default PrimarylayoutSupplier;