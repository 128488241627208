import React, { useEffect, useState } from 'react';


import Loader from './snippet/Loader';
import SEOPage from './snippet/SEOPage';
import TermsBanner from '../assets/images/terms-condition.png';


const PrivacyPage = () => {
    const [content, setContent] = useState()
    const [imageAlt, setImageAlt] = useState();
	const [loader, setloader] = useState(false)

    useEffect(() => {
		async function fetchData() {
			var myHeaders = new Headers();
			myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
			myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
			
			var requestOptions = {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			};
			// 
			// fetch(`${process.env.REACT_APP_APIURL}?endpoint=privacypolicy`, requestOptions)
			// .then(async response => await response.json())
			// .then(result => {
			// 	setContent(result);
			// 	setloader(true);
			// })
			// .catch(error => console.log('error', error));
			try{
				await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=Privacy Policy`, requestOptions)
				.then(async response => await response.json())
				.then(result => {
					// console.log(result);
					setContent(result);
					setloader(true);
					setImageAlt(result.alt_text)
				})
				.catch(error => console.log('error', error));
			} catch (error) {
				console.log('error', error);
			}
		}
		fetchData();
    }, [])

    return(
        <div>
			<Loader loader={loader} />
			<SEOPage pageTitle="Privacy Policy" />
			<main className="page-content">
				<div className="inner-banner text-white d-flex align-items-center">
					<img src={content && content.background_image ? content.background_image : TermsBanner} alt={imageAlt} className="banner-image" />
					<div className="container">
						<div className="row">
							<div className="col-md-11 col-lg-6 pr-md-5 mt-md-5 mt-2 pt-md-5">
								<h1><span>Privacy</span> Policy</h1>
                                <p dangerouslySetInnerHTML={{__html: content && content.tag_line}} />
							</div>
						</div>
					</div>
				</div>

				<div className="page-inner-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 content-section">
                                {/* <h2>Our privacy Obligations</h2> */}
                                
                                <div className='info-content-text' dangerouslySetInnerHTML={{__html: content && content.content}} />
                            </div>
                        </div>
                    </div>
                </div>
			</main>
		</div>
    )
}

  
export default PrivacyPage