import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';
import { withRouter } from "react-router";
// import Layout from "../../global/PrimaryLayoutFreelancer";
import GooglePlaceholder from "../snippet/GoogleSearchLocator";
import SEOPage from '../snippet/SEOPageNoIndex';


import deletes from '../../assets/images/delete.png';
import Loader from "../snippet/Loader";


class ProfileComplete extends Component {
	constructor(props){
        super(props);

        this.state= {
          fields: {},
          errors: {},
          success:'',
		  userDetails: {},
          location: "",
          LocationError: '',
		  preferred_work_location: {},
          enableFields: false,
		  frequency: [],
		  qualifications: [],
		  requiredSkills: [],
		  file: '',
		  errorsfile: '',

		  eventQualificationsIds: [],
		  eventSkillsIds: [],

			eventQualificationsIdsError: '',
			eventSkillsIdsError: '',

		  textareaLength: 0,
		  
		  right_to_work_in_australia: false,
		  willing_to_travel: false,
		//   covid_vaccinated: false,
		  work_with_children_check: false,
		  australian_driving_license: false,
		  current_police_check: false,
		  australian_business_number: false,
		  redirect: false,
		  checkboxError: false,
		//   checkboxErrorCovid: false,
		  formLoader: false,
		  loader: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        // this.handleChangeSkills = this.handleChangeSkills.bind(this);
        this.onQualification = this.onQualification.bind(this);
        this.onRequiredSkill = this.onRequiredSkill.bind(this);
	}

	handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });

		if(fields['biography']){
			let leth = e.target.value.length;
			// console.log(leth);
			this.setState({
				textareaLength: leth 
			});
		}
	}

	// handleChangeSkills = (e) =>{
	// 	console.log(e)
	// }

	async handleSubmit(e) {
        e.preventDefault();
		//  && this.state.location.lat
        if (this.validateForm() && this.state.preferred_work_location.latitude) {
			this.setState({
				formLoader: true
			})
			
			let Auth = localStorage.getItem('auth_empplyFreeUID');

			// let complete_term00 = this.state.location.location && this.state.location.location.value.terms[0] ? this.state.location.location.value.terms[0].value : "";
			// let complete_term01 = this.state.location.location && this.state.location.location.value.terms[1] ? this.state.location.location.value.terms[1].value : "";
			// let complete_term02 = this.state.location.location && this.state.location.location.value.terms[2] ? this.state.location.location.value.terms[2].value : "";

			let complete_term00 = this.state.location.lat ? (this.state.location.location_address[1] ? this.state.location.location_address[1].long_name : "" ) : "";
			let complete_term01 = this.state.location.lat ? (this.state.location.location_address[2] ? this.state.location.location_address[2].long_name : "" ) : "";
			let complete_term02 = this.state.location.lat ? (this.state.location.location_address[3] ? this.state.location.location_address[3].long_name : "" ) : "";
			
			// var myHeaders = new Headers();
			// myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
			// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");

			var urlencoded = new FormData();
			urlencoded.append("endpoint", "update_freelancer_profile");
			urlencoded.append("first_name", this.state.userDetails.first_name);
			urlencoded.append("last_name", this.state.userDetails.last_name);
			urlencoded.append("phone", this.state.userDetails.phoneNumber);
			urlencoded.append("abn", this.state.userDetails.abn);
			urlencoded.append("email", this.state.userDetails.emailAddress);
			urlencoded.append("latitude", this.state.userDetails.location && this.state.userDetails.location.latitude);
			urlencoded.append("longitude", this.state.userDetails.location && this.state.userDetails.location.longitude);
			urlencoded.append("street_address", this.state.userDetails.location && this.state.userDetails.location.street_address);
			urlencoded.append("suburb", this.state.userDetails.location && this.state.userDetails.location.suburb);
			urlencoded.append("state", this.state.userDetails.location && this.state.userDetails.location.state);
			urlencoded.append("country", this.state.userDetails.location && this.state.userDetails.location.country);
			urlencoded.append("amount", this.state.fields.acceptMinimumRate);
			urlencoded.append("rate_type", this.state.fields.event_pay_duration);
			urlencoded.append("experience", this.state.fields.experienceInyears);
			urlencoded.append("pwl_latitude", this.state.location.lat ? this.state.location.lat : this.state.preferred_work_location.latitude);
			urlencoded.append("pwl_longitude", this.state.location.long ? this.state.location.long : this.state.preferred_work_location.longitude);
			//u.rlencoded.append("pwl_street_address", complete_term01 ? complete_term01 +", " + complete_term00 : complete_term00);
			urlencoded.append("pwl_street_address", this.state.location.location ? this.state.location.location : this.state.preferred_work_location.street_address);
			urlencoded.append("pwl_suburb", complete_term00 ? complete_term00 : this.state.preferred_work_location.suburb);
			urlencoded.append("pwl_state", complete_term01 ? complete_term01 : this.state.preferred_work_location.state);
			urlencoded.append("pwl_country", complete_term02 ? complete_term02 : this.state.preferred_work_location.country);
			urlencoded.append("work_range", this.state.fields.workRange);
			urlencoded.append("willing_to_travel", this.state.willing_to_travel ? 1 : 0);
			urlencoded.append("right_to_work_in_australia", this.state.right_to_work_in_australia ? 1 : 0);
			urlencoded.append("covid_vaccinated", 0);
			urlencoded.append("work_with_children_check", this.state.work_with_children_check ? 1 : 0);
			urlencoded.append("australian_driving_license", this.state.australian_driving_license ? 1 : 0);
			urlencoded.append("current_police_check", this.state.current_police_check ? 1 : 0);
			urlencoded.append("event_abn", this.state.australian_business_number ? 1 : 0);
			urlencoded.append("user_id", Auth);
			urlencoded.append("qualification", this.state.eventQualificationsIds.toString());
			urlencoded.append("skills", this.state.eventSkillsIds.toString());
			urlencoded.append("bio", this.state.fields.biography);

			// console.log(this.state.eventQualificationsIds.toString())
			// console.log(this.state.eventSkillsIds.toString())

			// console.log(
			// 	"itemType", "update_freelancer_profile",
			// 	"first_name", this.state.userDetails.first_name,
			// 	"last_name", this.state.userDetails.last_name,
			// 	"phone", this.state.userDetails.phoneNumber,
			// 	"abn", this.state.userDetails.abn,
			// 	"email", this.state.userDetails.emailAddress,
			// 	"latitude", this.state.userDetails.location && this.state.userDetails.location.latitude,
			// 	"longitude", this.state.userDetails.location && this.state.userDetails.location.longitude,
			// 	"street_address", this.state.userDetails.location && this.state.userDetails.location.street_address,
			// 	"suburb", this.state.userDetails.location && this.state.userDetails.location.suburb,
			// 	"state", this.state.userDetails.location && this.state.userDetails.location.state,
			// 	"country", this.state.userDetails.location && this.state.userDetails.location.country,
			// 	"amount", this.state.fields.acceptMinimumRate,
			// 	"rate_type", this.state.fields.event_pay_duration,
			// 	"experience", this.state.fields.experienceInyears,
			// 	"pwl_latitude", this.state.location.lat ? this.state.location.lat : this.state.preferred_work_location.latitude,
			// 	"pwl_longitude", this.state.location.long ? this.state.location.long : this.state.preferred_work_location.longitude,
			// 	"pwl_street_address", this.state.location.location ? this.state.location.location : this.state.preferred_work_location.street_address,
			// 	"pwl_suburb", complete_term00 ? complete_term00 : this.state.preferred_work_location.suburb,
			// 	"pwl_state", complete_term01 ? complete_term01 : this.state.preferred_work_location.state,
			// 	"pwl_country", complete_term02 ? complete_term02 : this.state.preferred_work_location.country,
			// 	"work_range", this.state.fields.workRange ? 1 : 0,
			// 	"willing_to_travel", this.state.willing_to_travel ? 1 : 0,
			// 	"right_to_work_in_australia", this.state.right_to_work_in_australia ? 1 : 0,
			// 	"covid_vaccinated", this.state.covid_vaccinated ? 1 : false,
			// 	"work_with_children_check", this.state.work_with_children_check ? 1 : 0,
			// 	"australian_driving_license", this.state.australian_driving_license ? 1 : 0,
			// 	"current_police_check", this.state.current_police_check ? 1 : 0,
			// 	"event_abn", this.state.australian_business_number ? 1 : 0,
			// 	"user_id", Auth,
			// 	"qualification", this.state.eventQualificationsIds.toString(),
			// 	"skills", this.state.eventSkillsIds.toString(),
			// 	"bio", this.state.fields.biography
			// )

			// console.log("right_to_work_in_australia", this.state.right_to_work_in_australia)
			
			if(this.state.filedata){
                // const toBase64 = (file) =>
                // new Promise((resolve, reject) => {
                //     const reader = new FileReader();
                //     reader.readAsDataURL(file);
                //     reader.onload = () => resolve(reader.result);
                //     reader.onerror = (error) => reject(error);
                // });

				// urlencoded.append("cv", await toBase64(this.state.filedata));
				urlencoded.append("cv", this.state.filedata);

                // console.log(await toBase64(this.state.filedata));
                // console.log(this.state.filedata);

            }else{
				urlencoded.append("cv", this.state.file);
			}

			var requestOptions = {
				method: 'POST',
				// headers: myHeaders,
				body: urlencoded,
				redirect: 'follow'
			};

			// console.log(this.state.covid_vaccinated)

			await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				this.setState({
					redirect: true,
					success: result.response_string,
					formLoader: false
				})
				// setTimeout(() => {
					window.location.reload();
				// }, 800);
			})
			.catch(error => console.log('error', error));
        } //this.validateForm
	}

	async componentDidMount(){

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        // fetch(`${process.env.REACT_APP_APIURL}?endpoint=license_skills`, requestOptions)
        // .then(response => response.json())
        // .then(result => {
		// 	// console.log(result);
		// 	this.setState({
        //         qualifications: result.license,
		// 		requiredSkills: result.skills  
        //     })
        // })
        // .catch(error => console.log('error', error));

        // fetch(
        //   "${process.env.REACT_APP_APIURL}?endpoint=get_all_skills",
        //   requestOptions
        // )
        //   .then((response) => response.json())
        //   .then((result) => {
        //     this.setState({
		// 	  requiredSkills: result.data
        //     });
        //   })
		//   .catch((error) => console.log("error", error));
		  
		// fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_all_frequency`, requestOptions)
        // .then(response => response.json())
        // .then(result => {
		// 	// console.log(result.items[1].value)
        //   this.setState({
        //     frequency: result.items,
		// 	fields :{
		// 		event_pay_duration: result.items[1].value
		// 	}
        //   })
        // })
		// .catch(error => console.log('error', error));
		try {
			let AuthFree = localStorage.getItem('auth_empplyFreeUID');
			// ?itemType=freelancer_user_detail_web&user_id=1446
		await fetch(`${process.env.REACT_APP_APIURL}?endpoint=freelancer_user_detail_web&user_id=${AuthFree}`, requestOptions)
		  .then(response => response.json())
		  .then(result => {
			console.log(result)
			let resultArrayQuali = [];
			result.user_detail.license_qualification && result.user_detail.license_qualification.map((user_detail) => {
				return resultArrayQuali.push(user_detail.qualification_slug)
			})
			
			let resultArraySkills = [];
			result.user_detail.skills && result.user_detail.skills.map((user_detail) => {
				return resultArraySkills.push(user_detail.skill_slug)
			})

			this.setState({
				preferred_work_location: result.user_detail.preferred_work_location ? result.user_detail.preferred_work_location : {latitude : ''},
				userDetails : result.user_detail,
				eventQualificationsIds : resultArrayQuali,
				eventSkillsIds : resultArraySkills,
				file: result.user_detail.upload_cv,
				right_to_work_in_australia: result.user_detail.work_requirement && result.user_detail.work_requirement.right_to_work_in_australia === "1" ? true : false,
				covid_vaccinated: result.user_detail.covid_vaccinated === "1" ? true : false,
				work_with_children_check: result.user_detail.work_requirement && result.user_detail.work_requirement.working_with_children_check === "1" ? true : false,
				willing_to_travel: result.user_detail.work_requirement && result.user_detail.work_requirement.willing_to_travel === "1" ? true : false,
				australian_driving_license: result.user_detail.work_requirement && result.user_detail.work_requirement.australian_driving_license === "1" ? true : false,
				current_police_check: result.user_detail.work_requirement && result.user_detail.work_requirement.current_police_check === "1" ? true : false,
				australian_business_number: result.user_detail.abn ? true : false,

				fields : {
					acceptMinimumRate: result.user_detail.minimum_accepted_rate && result.user_detail.minimum_accepted_rate.amount ? result.user_detail.minimum_accepted_rate.amount : 0,
					event_pay_duration: result.user_detail.minimum_accepted_rate.rate_type ? result.user_detail.minimum_accepted_rate.rate_type : "Hourly",
					experienceInyears: result.user_detail.experience ? result.user_detail.experience : 0,
					workRange: result.user_detail.work_range || '20',
					biography: result.user_detail.bio ? result.user_detail.bio : '',
					// event_pay_duration: result.get_all_frequency && result.get_all_frequency.items[1].value
				},
				loader: true,
				qualifications: result.license_skills && result.license_skills.license,
				requiredSkills: result.license_skills && result.license_skills.skills,
				frequency: result.get_all_frequency && result.get_all_frequency.items
			})
		  })
		  .catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}
	}


	onQualification = (e,id) => {
		let resultArray = []
		if(e.target.checked)
		{
			 resultArray = this.state.eventQualificationsIds.filter(CheckedId=>
			   CheckedId !== id
			 )
			 resultArray.push(id) 
		}
		else
		{
		   resultArray = this.state.eventQualificationsIds.filter(CheckedId=>
			   CheckedId !== id
		   )
		}
		
		let resultString = resultArray;
		
		this.setState({
		  eventQualificationsIds:resultString
		})
	  }

	  onRequiredSkill = (e,id) => {
		let resultArray = []
		if(e.target.checked)
		{
			 resultArray = this.state.eventSkillsIds.filter(CheckedId=>
			   CheckedId !== id
			 )
			 resultArray.push(id) 
		}
		else
		{
		   resultArray = this.state.eventSkillsIds.filter(CheckedId=>
			   CheckedId !== id
		   )
		}
		
		let resultString = resultArray;
  
		this.setState({
		  eventSkillsIds:resultString
		})
	  }

	  validateForm() {
		let LocationError = this.state.LocationError;
		let errorsfile = this.state.errorsfile;
		let eventQualificationsIdsError = this.state.eventQualificationsIdsError;
		let eventSkillsIdsError = this.state.eventSkillsIdsError;
		let fields = this.state.fields;
		let formIsValid = true;
		let errors = {};
		let checkboxError = this.state.checkboxError;
		let checkboxTravelError = this.state.checkboxTravelError;
		// let checkboxErrorCovid = this.state.checkboxErrorCovid;

		
		if (!this.state.preferred_work_location.latitude) {
			formIsValid = false;
			LocationError = "Please enter your location.";
		} 

		// if (!fields["acceptMinimumRate"]) {
		// 	formIsValid = false;
		// 	errors["acceptMinimumRate"] = "*Please enter your Minimum Rate.";
		// }
		
		if (!fields.acceptMinimumRate) {
			formIsValid = false;
			errors["acceptMinimumRate"] = "*Please enter your Minimum Rate.";
		}

		if (!fields.event_pay_duration) {
			formIsValid = false;
			errors["event_pay_duration"] = "*Please choose your Rate Type.";
		}

		if (!fields.experienceInyears) {
			formIsValid = false;
			errors["experienceInyears"] = "*Please enter your experience.";
		}

		// if (!fields["workRange"]) {
		// 	formIsValid = false;
		// 	errors["workRange"] = "*Please enter your Work Range only in numbers.";
		// }

		if (!fields.biography) {
			formIsValid = false;
			errors["biography"] = "*Please enter your Biography.";
		}

		if (!this.state.file) {
			formIsValid = false;
			errorsfile = "*Please upload your CV.";
		}

		if (!this.state.eventQualificationsIds[0]) {
			formIsValid = false;
			eventQualificationsIdsError = "*Please select Qualifications.";
		}
		
		if (!this.state.eventSkillsIds[0]) {
			formIsValid = false;
			eventSkillsIdsError = "*Please select Skills.";
		}

		if (this.state.right_to_work_in_australia === false) {
			formIsValid = false;
			checkboxError = "*Eligibility to work required.";
		}
		// if (this.state.willing_to_travel === false) {
		// 	formIsValid = false;
		// 	checkboxTravelError = "*Eligibility to work required.";
		// }
		// if (this.state.covid_vaccinated === false) {
		// 	formIsValid = false;
		// 	checkboxErrorCovid = "*Covid vaccinated is required.";
		// }
	
	
		this.setState({
		  errors: errors,
		  LocationError,
		  errorsfile,
		  checkboxError,
		  checkboxTravelError,
		//   checkboxErrorCovid,
		  eventQualificationsIdsError,
		  eventSkillsIdsError
		});
		return formIsValid;
	}

	passAddress = (value) =>{
		// console.log(value);
		if(value.lat){
		  this.setState({
			location : value,
			preferred_work_location :{
				latitude: value.lat
			}
		  })
		}
	}


	render() {
		const userDetails = this.state.userDetails;
        const qualifications = this.state.qualifications;
        const requiredSkills = this.state.requiredSkills;
		const frequency = this.state.frequency;

		// console.log(frequency)

		if (this.props.location.state && this.state.redirect) {
			return <Redirect push to="/f/membership" />;
		}

		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer Profile Completed" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>
								Update your <span>profile</span>
								</h1>
								{/* <p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								Phasellus volutpat neque urna, a vestibulum tellus
								sollicitudin ut. Vivamus dictum, dolor eget placerat mollis,
								risus tortor ornare nisi, at interdum urna dui eget urna.
								</p> */}
							</div>
						</div>

						<div className="row">
							<div className="col-lg-5 mb-4 col-md-4 col-xl-3">
							<div className="profile-card d-flex flex-column align-items-center text-center">
								<div className="position-relative">
									<div className="contact-auth">{userDetails.first_name && userDetails.first_name.slice(0, 1)}{userDetails.last_name && userDetails.last_name.slice(0, 1)}</div>
								</div>

								<strong className="text-capitalize">{userDetails.first_name + ' ' + userDetails.last_name}</strong>
								<span>Event Professional</span>
								</div>

								<div className="list-group">
								<Link
								 rel="nofollow"
									to="/f/profile"
									className="list-group-item list-group-item-action"
								>
									<i className="fas fa-file-alt"></i> Personal Information
								</Link>
								<Link
								 rel="nofollow"
									to="/f/change-password"
									className="list-group-item list-group-item-action"
								>
									<i className="fas fa-lock"></i> Change Password
								</Link>
								<Link
								 rel="nofollow"
									to="/f/complete-profile"
									className="list-group-item active list-group-item-action"
								>
									<i className="fas fa-user"></i> Complete Profile
								</Link>
								</div>
							</div>
							<div className="col-lg-12 col-md-8 col-xl-8">
								<div className="profile-box">
								<div className="d-flex flex-nowrap mb-3">
									<div className="d-auto">
									<h3>Complete Profile</h3>
									<p>Fill your full information here</p>
									</div>
									{/* <div className="d-auto ml-auto">
									<button
										type="button"
										className="btn btn-link p-0 text-primary btn-edit"
									>
										<i className="fas fa-pen"></i> Edit Profile
									</button>
									</div> */}
								</div>

								<div className="form-area">
									<form onSubmit={this.handleSubmit}>
									<div className="row">
										<div className="form-group mb-md-4 mb-3 col-sm-6">
											<div className="form-field">
												<label>Accepted Minimum Rate</label>
												<input type="number" min="0" className="form-control" name="acceptMinimumRate" onChange={this.handleChange} value={this.state.fields && this.state.fields.acceptMinimumRate} />
											</div>
											<div
												className={`invalid-feedback text-right ${
													!this.state.fields.acceptMinimumRate
													? this.state.errors.acceptMinimumRate
														? "d-block"
														: null
													: null
												}`}
												>
												{this.state.errors.acceptMinimumRate}
											</div>
										</div>
										<div className="form-group mb-md-4 mb-3 col-sm-6">
											<div className="form-field pb-0">
												<label className="mb-1">Rate Type</label>
												<select
													disabled={true}
													className="form-control form-control-select-disabled border-0"
													name="event_pay_duration"
													onChange={this.handleChange}
													value={this.state.fields && this.state.fields.event_pay_duration}
												>
													{frequency && frequency.map((item, index) => (
														<option value={item.value} key={index}>{item.label}</option>
													))}
												</select>
											</div>
											<div
												className={`invalid-feedback text-right ${
													!this.state.fields.event_pay_duration
													? this.state.errors.event_pay_duration
														? "d-block"
														: null
													: null
												}`}
												>
												{this.state.errors.event_pay_duration}
											</div>
										</div>
										<div className="form-group mb-md-4 mb-3 col-sm-12">
											<div className="form-field">
												<label>Experience (in years)</label>
												<input type="number" min="0" className="form-control" name="experienceInyears" value={this.state.fields && this.state.fields.experienceInyears} onChange={this.handleChange} />
											</div>
											<div
												className={`invalid-feedback text-right ${
													!this.state.fields.experienceInyears
													? this.state.errors.experienceInyears
														? "d-block"
														: null
													: null
												}`}
												>
												{this.state.errors.experienceInyears}
											</div>
										</div>
										<div className="form-group d-none mb-md-4 mb-3 col-sm-6">
											<div className="form-field">
												<label>Work range (in km)</label>
												<input type="number" min="0" className="form-control" name="workRange" value={this.state.fields && this.state.fields.workRange} onChange={this.handleChange} />
											</div>
											{/* <div
												className={`invalid-feedback text-right ${
													!this.state.fields.workRange
													? this.state.errors.workRange
														? "d-block"
														: null
													: null
												}`}
												>
												{this.state.errors.workRange}
											</div> */}
										</div>
										<div className="form-group mb-md-4 mb-3 col-sm-12">
											<div className="form-field">
												<label>Preferred Work Location <small>{this.state.preferred_work_location && this.state.preferred_work_location.street_address}</small></label>
												<GooglePlaceholder
													value={this.state.preferred_work_location && this.state.preferred_work_location.latitude ? "Enter new location" : "Enter your location"}
													getAddress={(e) => this.passAddress(e)}
												/>
											</div>
											<div
												className={`invalid-feedback text-right ${
													this.state.preferred_work_location && !this.state.preferred_work_location.latitude
													? this.state.LocationError
														? "d-block"
														: null
													: null
												}`}
												>
												{this.state.LocationError}
											</div>
										</div>
										<div className="form-group col-12">
										<h4 className="mb-0">Work Requirements <br /><small style={{fontSize: '0.7em'}}>(Choose below options)</small></h4>
										</div>
										<div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
											<label>Willing to Travel</label>
											<div className="form-switch text-uppercase">
											<input type="checkbox" onChange={() => this.setState({ willing_to_travel : !this.state.willing_to_travel })} name="willing_to_travel" id="formSwitch0" hidden checked={this.state.willing_to_travel ? true : false} />
											<label
												htmlFor="formSwitch0"
												className="d-flex align-items-center justify-content-between"
											>
												<span className="fs-no">No</span>
												<span className="fs-yes">Yes</span>
											</label>
											</div>
											<div
												className={`invalid-feedback ${
													this.state.willing_to_travel === false
													? this.state.checkboxTravelError
														? "d-block"
														: null
													: null
												}`}
												>
												{this.state.checkboxTravelError}
											</div>
										</div>
										<div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
											<label>Right to work in Australia</label>
											<div className="form-switch text-uppercase">
											<input type="checkbox" onChange={() => this.setState({ right_to_work_in_australia : !this.state.right_to_work_in_australia })}
											value={this.state.right_to_work_in_australia === true ? 'checked' : null} 
											checked={this.state.right_to_work_in_australia ? true : false} name="right_to_work_in_australia" id="formSwitch1" hidden />
											<label
												htmlFor="formSwitch1"
												className="d-flex align-items-center justify-content-between"
											>
												<span className="fs-no">No</span>
												<span className="fs-yes">Yes</span>
											</label>
											</div>
											<div
												className={`invalid-feedback ${
													this.state.right_to_work_in_australia === false
													? this.state.checkboxError
														? "d-block"
														: null
													: null
												}`}
												>
												{this.state.checkboxError}
											</div>
										</div>
										{/* <div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
											<label>Covid Vaccinated</label>
											<div className="form-switch text-uppercase">
											<input type="checkbox" onChange={() => this.setState({ covid_vaccinated : !this.state.covid_vaccinated })}
											value={this.state.covid_vaccinated === true ? 'checked' : null}
											checked={this.state.covid_vaccinated ? true : false}
											name="covid_vaccinated" id="formSwitch1_cod" hidden />
											<label
												htmlFor="formSwitch1_cod"
												className="d-flex align-items-center justify-content-between"
											>
												<span className="fs-no">No</span>
												<span className="fs-yes">Yes</span>
											</label>
											</div>
											<div
												className={`invalid-feedback ${
													this.state.covid_vaccinated === false
													? this.state.checkboxErrorCovid
														? "d-block"
														: null
													: null
												}`}
												>
												{this.state.checkboxErrorCovid}
											</div>
										</div> */}
										<div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
											<label>Working with children check</label>
											<div className="form-switch text-uppercase">
											<input type="checkbox" id="formSwitch2" onChange={() => this.setState({ work_with_children_check : !this.state.work_with_children_check })} 
											 checked={this.state.work_with_children_check ? true : false}name="working_with_children_check" hidden />
											<label
												htmlFor="formSwitch2"
												className="d-flex align-items-center justify-content-between"
											>
												<span className="fs-no">No</span>
												<span className="fs-yes">Yes</span>
											</label>
											</div>
										</div>
										<div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
											<label>Australian Driving License</label>
											<div className="form-switch text-uppercase">
											<input type="checkbox" id="formSwitch3" onChange={() => this.setState({ australian_driving_license : !this.state.australian_driving_license })} 
											checked={this.state.australian_driving_license ? true : false}name="australian_driving_license" hidden />
											<label
												htmlFor="formSwitch3"
												className="d-flex align-items-center justify-content-between"
											>
												<span className="fs-no">No</span>
												<span className="fs-yes">Yes</span>
											</label>
											</div>
										</div>
										<div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
											<label>Current Police Check</label>
											<div className="form-switch text-uppercase">
											<input type="checkbox" id="formSwitch4" onChange={() => this.setState({ current_police_check : !this.state.current_police_check })} 
											checked={this.state.current_police_check ? true : false}name="current_police_check" hidden />
											<label
												htmlFor="formSwitch4"
												className="d-flex align-items-center justify-content-between"
											>
												<span className="fs-no">No</span>
												<span className="fs-yes">Yes</span>
											</label>
											</div>
										</div>
										<div className="form-group form-group-switch mb-1 col-sm-6 col-md-4">
											<label>Australian Business Number (ABN)</label>
											<div className="form-switch text-uppercase">
											<input type="checkbox" id="formSwitch5" onChange={() => this.setState({ australian_business_number : !this.state.australian_business_number })} 
											// checked={this.state.australian_business_number === true ? true : false} 
											checked={this.state.australian_business_number ? true : false} name="australian_business_number" hidden />
											<label
												htmlFor="formSwitch5"
												className="d-flex align-items-center justify-content-between"
											>
												<span className="fs-no">No</span>
												<span className="fs-yes">Yes</span>
											</label>
											</div>
										</div>

										<div className="form-group mb-md-4 mb-3 mt-3 col-sm-12">
										<div className="form-field form-field-pills">
											<label>License/Qualification</label>
											<div className="w-100">
												{qualifications &&
													qualifications.filter(name => this.state.eventQualificationsIds.includes(name.slug)).map((qualification, index) => (
													<span className="skill-pill selected" key={index}>
														<input
															hidden
															type="checkbox"
															value={qualification.id || ''}
															name="event_qualification"
															className="custom-control-input"
															id={`checkQualificationSelected${qualification.id}`}
															onChange={(e)=>this.onQualification(e,qualification.slug)}
															checked={true}
														/>
														<label
															htmlFor={`checkQualificationSelected${qualification.id}`}
														>
															<span dangerouslySetInnerHTML={{__html: qualification.name}} />
															<img src={deletes} className="ml-1" alt="icon" />
														</label>
													</span>
												))}
											</div>
											<div className="w-100">
												{qualifications &&
													qualifications.filter(name => !this.state.eventQualificationsIds.includes(name.slug)).map((qualification, index) => (
													<span className="skill-pill" key={index}>
														<input
															hidden
															type="checkbox"
															value={qualification.id || ''}
															name="event_qualification"
															className="custom-control-input"
															id={`checkQualificationNotSelected${qualification.id}`}
															onChange={(e)=>this.onQualification(e,qualification.slug)}
															checked={false}
														/>
														<label
															htmlFor={`checkQualificationNotSelected${qualification.id}`}
															dangerouslySetInnerHTML={{__html: qualification.name}}
														/>
													</span>
												))}
											</div>
										</div>
										<div
											className={`invalid-feedback text-right ${
												!this.state.eventQualificationsIds[0]
												? this.state.eventQualificationsIdsError
													? "d-block"
													: null
												: null
											}`}
											>
											{this.state.eventQualificationsIdsError}
										</div>
										</div>
										<div className="form-group mb-md-4 mb-3 col-sm-12">
											<div className="form-field">
												<div className="d-flex align-items-center">
													<label>Biography</label>
													<div className="textarea_count ml-auto">{this.state.fields.biography && this.state.fields.biography.length ? this.state.fields.biography && this.state.fields.biography.length : 0}/500</div>
												</div>
												<textarea maxLength='500' className="form-control" rows="4"  name="biography" value={this.state.fields && this.state.fields.biography} onChange={this.handleChange}></textarea>
											</div>
											<div
												className={`invalid-feedback text-right ${
													!this.state.fields.biography
													? this.state.errors.biography
														? "d-block"
														: null
													: null
												}`}
												>
												{this.state.errors.biography}
											</div>
										</div>
										<div className="form-group mb-md-4 mb-3 col-sm-12">
											<div className="form-field d-flex align-items-center">
												<div className="w-100">
												<label>Upload CV <small>(Format .pdf)</small></label>
												{
													this.state.file ? 
														this.state.filedata && this.state.filedata.name ? (
															<input
																type="text"
																className="form-control"
																value={this.state.file}
																readOnly={true}
															/>
														) : (
															<div>
																<a href={this.state.file} download>
																	<i className="fas mr-2 fa-download"></i>{" "}
																	<span className="d-sm-inline-block d-none">
																	Download CV
																	</span>
																</a>
															</div>	
														)
													: (
														<input
															type="text"
															className="form-control"
															value={this.state.file}
															readOnly={true}
														/>
													)
												}
												</div>
												<div>
												<input type="file" id="cvUpload" 
													hidden 
													accept="application/msword, text/plain, application/pdf"
													onChange={(e) =>{
														// this.handleChange;
														this.setState({ 
															filedata: e.target.files[0],
															file: e.target.files[0].name,
														 })
													}}
												/>
												<label
													htmlFor="cvUpload"
													className="upload_cv text-secondary m-0"
												>
													<i className="fas mr-2 fa-upload"></i>{" "}
													<span className="d-sm-inline-block d-none">
													Upload File
													</span>
												</label>
												</div>
											</div>
											<div
												className={`invalid-feedback text-right ${
													!this.state.file
													? this.state.errorsfile
														? "d-block"
														: null
													: null
												}`}
												>
												{this.state.errorsfile}
											</div>
										</div>
										<div className="form-group mb-md-4 mb-3 col-sm-12">
											<div className="form-field form-field-pills">
												<label>Skills</label>
												<div className="w-100">
													{requiredSkills &&
														requiredSkills.filter(name => this.state.eventSkillsIds.includes(name.slug)).map((requiredSkill, index) => (
														<span className="skill-pill selected" key={index}>
															<input
																type="checkbox"
																value={requiredSkill.id}
																name="event_skills"
																className="custom-control-input"
																id={`checkSkillsSelected${requiredSkill.id}`}
																onChange={(e)=>this.onRequiredSkill(e,requiredSkill.slug)}
																checked={true}
															/>
															<label
																htmlFor={`checkSkillsSelected${requiredSkill.id}`}
															>
																{/* {requiredSkill.name} */}
																<span dangerouslySetInnerHTML={{__html: requiredSkill.name}} />
																<img src={deletes} className="ml-1" alt="icon" />
															</label>
														</span>
													))}
												</div>
												<div className="w-100">
													{requiredSkills &&
														requiredSkills.filter(name => !this.state.eventSkillsIds.includes(name.slug)).map((requiredSkill, index) => (
														<span className="skill-pill" key={index}>
															<input
																type="checkbox"
																value={requiredSkill.id}
																name="event_skills"
																className="custom-control-input"
																id={`checkSkillsNotSelected${index}`}
																onChange={(e)=>this.onRequiredSkill(e,requiredSkill.slug)}
																checked={false}
															/>
															<label
																htmlFor={`checkSkillsNotSelected${index}`}
																dangerouslySetInnerHTML={{__html: requiredSkill.name}}
															/>
														</span>
													))}
												</div>
											</div>
											<div
												className={`invalid-feedback text-right ${
													!this.state.eventSkillsIds[0]
													? this.state.eventSkillsIdsError
														? "d-block"
														: null
													: null
												}`}
												>
												{this.state.eventSkillsIdsError}
											</div>
										</div>
										<div className="form-group mt-md-4 mb-md-0 mb-3 col-sm-6 ml-auto">
										<button type="submit" className="btn btn-hm-primary btn-block btn-lg mb-3 px-4 d-flex align-items-center" disabled={this.state.formLoader ? true : false}>{this.state.formLoader ? 'Sending...' : 'Save'} <i className="fas ml-auto fa-arrow-right"></i>
										</button>
										{this.state.success && <span className="text-success d-block">{this.state.success}</span>}
										</div>
									</div>
									</form>
								</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
    	);
	}
}

export default withRouter(ProfileComplete);