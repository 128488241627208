import React, { Component } from 'react';
// import { Redirect } from "react-router-dom";

// import PaymentIntigration from '../snippet/PaymentCard';

import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';
import PaymentCheckout from '../snippet/payment';

class Payment extends Component {
	
	constructor(props){
        super(props);

        this.state= {
			errorStatus:false,
			loader: false,
			clientSecret: null
        }
	}

	async componentDidMount(){
		let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
		const formdata = new FormData();
		formdata.append("endpoint", "create_payment_intent");
		formdata.append("plan_id", this.props.location.state);
		formdata.append("user_id", AuthOrg);

		// console.log("create_payment_intent", this.props.location.state, AuthOrg);

		const requestOptions = {
		method: "POST",
		body: formdata,
		redirect: "follow"
		};

		try {
			await fetch(process.env.REACT_APP_APIURL, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if(result?.clientSecret){
					this.setState({
						loader: true,
						clientSecret: result?.clientSecret
					})
				}else{
					this.setState({
						errorStatus: true,
						loader: true,
					})
				}
			})
			.catch((error) => console.error(error));
		} catch (error) {
			console.log('error', error);
		}
	}

	// async postInfo(id){
	// 	// console.log(id);
	// 	if(id.decline_code || id.type === 'validation_error'){
	// 		this.setState({
	// 			errorStatus : id.message
	// 		})
	// 	}else{
	// 		var myHeaders = new Headers();
	// 		// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");

	// 		var requestOptions = {
	// 		method: 'GET',
	// 		headers: myHeaders,
	// 		redirect: 'follow'
	// 		};
	// 		try {
	// 			let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
	// 			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=subscribe_to_plan&subscr_plan=${this.props.location.state}&stripe_token=${id}&user_id=${AuthOrg}&monthly_price=49.99&yearly_price=499.99`, requestOptions)
	// 			.then(response => response.json())
	// 			.then(result => {
	// 				// console.log(result);
	// 				this.setState({
	// 					redirect: true,
	// 					errorStatus : result.status
	// 				});
	// 			})
	// 			.catch(error => console.log('error', error));
	// 		} catch (error) {
    //             console.log('error', error);
    //         }
	// 	}
	// }

	render() {
		// if (this.state.errorStatus) {
		// 	return <Redirect to="/e/membership" />;
		// }

		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Event Organizer Payment" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row justify-content-center">
							<div className="col-lg-12">
								<div className="payment-card shadow page-info">
									<h1 className="mw-100 pb-2">Please enter your <br/><span>payment</span> details</h1>

									<div className="form-area pt-md-3 pt-2">
										{/* <PaymentIntigration submitPayment={(e) => this.postInfo(e)} /> */}
										{this.state.clientSecret &&  <PaymentCheckout clientSecret={this.state.clientSecret} />}
										{this.state.errorStatus && <p>Please try again. I think you have already buy this plan!</p>}
									</div>
									{this.state.errorStatus && <div className="text-danger pt-3 d-block">Card details invalid!</div>}
								</div>
								
							</div>
						</div>
					</div>
				</main>
			</div>
		);
	}
}

export default Payment;