import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import Loader from '../snippet/Loader';

// import PaymentIntigration from '../snippet/PaymentCard';
import SEOPage from '../snippet/SEOPageNoIndex';

// import Layout from '../../global/PrimaryLayoutFreelancer';

class Payment extends Component {

	constructor(props){
        super(props);

        this.state= {
			errorStatus:'',
			redirect: false,
			loader : false
        }
	}

	componentDidMount(){
		setTimeout(() => {
			this.setState({
				loader : true
			})
		}, 500);
	}
	
	async postInfo(id){
		// console.log(id.code);

		if(id.decline_code || id.type === 'validation_error'){
			this.setState({
				errorStatus : id.message
			})
		}else{
			var myHeaders = new Headers();
			// myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
	
			var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
			};
			try {
				let AuthOrg = localStorage.getItem('auth_empplyFreeUID');
				await fetch(`${process.env.REACT_APP_APIURL}?endpoint=subscribe_to_plan&subscr_plan=${this.props.location.state}&stripe_token=${id}&user_id=${AuthOrg}&monthly_price=9.99&yearly_price=99.99`, requestOptions)
				.then(response => response.json())
				.then(result => {
					// console.log(result);
					this.setState({
						redirect: true,
						errorStatus : result.status
					});
				})
				.catch(error => console.log('error', error));
			} catch (error) {
                console.log('error', error);
            }
		}

	}

	render() {
		if (this.state.redirect) {
			return <Redirect push to="/" />;
		}
		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer Payment" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row justify-content-center">
							<div className="col-lg-12">
								<div className="payment-card shadow page-info">
									<h1 className="mw-100 pb-2">Please enter your <br/><span>payment</span> details</h1>

									<div className="form-area pt-md-3 pt-2">
										{/* <PaymentIntigration submitPayment={(e) => this.postInfo(e)} /> */}
									</div>
									{this.state.errorStatus && <div className="text-danger pt-3 d-block">Card details invalid!</div>}
								</div>
								
							</div>
						</div>
					</div>
				</main>
			</div>
		);
	}
}

export default Payment;