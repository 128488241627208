import React, { Component } from 'react';
import CalendarMonth from './snippets/CalendarDates';
// import Layout from '../../global/PrimaryLayoutFreelancer';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Availability from '../snippet/CalenderAvailabilitys';
import AvailabilityByDay from '../snippet/CalenderAvailabilityByDay';
import { Tab, Tabs } from 'react-bootstrap';
import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';

class Calendar extends Component {
	constructor(props){
        super(props);

        this.state= {
			jobs: [],
			selectedDate: '',
			planStatus:1,
			dateClick: false,
			loader: false
        }

		this.child = React.createRef();
		this.child2 = React.createRef();
	}

	async componentDidMount(){
		var myHeaders = new Headers();
		  myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };
		  
		  try {
			let AuthFree = localStorage.getItem('auth_empplyFreeUID');
			// console.log(AuthFree);
	
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_calendar_invite_web&freelancer_id=${AuthFree}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				//   console.log(result)
				this.setState({
					jobs: result.events,
					loader: true,
					planStatus: result.subscription_status
				})
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}


		//  fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${AuthFree}`, requestOptions)
		// 	.then(response => response.json())
		// 	.then(result => {
		// 		this.setState({
		// 			planStatus : result.data
		// 		});
		// 	})
		// 	.catch(error => console.log('error', error));

	}

	dateView = (e) =>{
		this.setState({
			selectedDate: e
		});

		this.child.current.getAlert(this.formatDate(e));
		this.child2.current.getAlert(this.formatDate(e));
		// console.log(e);
	}

	dateClick = (e) => {
		this.setState({
			dateClick: e
		});
	}

	formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [day, month, year].join('/');
    }


	render() {

		const jobs = this.state.jobs;
		const profileStatus = this.state.planStatus;
		const selectedDate = this.state.selectedDate;
		// const selectedDate = this.state.selectedDate;
		// console.log(jobs);
		// console.log(selectedDate);

		const getDate = this.formatDate(selectedDate);

		if(profileStatus === 0){
			return(
				<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer Calendar" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-md-5 mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>Calendar</span></h1>
								<p>See what events you have coming up. Booked events will show up here so event organisers know when you are available to work.</p>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 mb-md-5 mb-4">
								<div className="blank-area text-center">
									<h3>You have no <span>active plan</span>.</h3>
									<p><small>Please buy plan <Link rel="nofollow" className="text-primary" to="/f/membership">here</Link>!</small></p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			)
		}

		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer Calendar" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-md-5 mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>Calendar</span></h1>
								<p>See what events you have coming up. Booked events will show up here so event organisers know when you are available to work.</p>
							</div>
						</div>

						<div className="calendar">
							<div className="row justify-content-between">
								<div className="col-md-8 col-xl-7">
									<CalendarMonth jobs={this.state.jobs} getdate={this.dateView} getClick={this.dateClick} />	
								</div>
								<div className="col-md-4 col-xl-4">
									<div className="calender-events">
										{/* .filter((e) => new Date(e.start_date).getTime() >= new Date(this.state.selectedDate).getTime() && new Date(this.state.selectedDate).getTime() <= new Date(e.end_date).getTime()) */}

										{/* .filter((e) => new Date(e.start_date).getTime() <= new Date(this.state.selectedDate).getTime() && new Date(e.end_date).getTime() <= new Date(this.state.selectedDate).getTime()) */}
										
										{/* new Date(e.start_date).getTime() <= new Date(this.state.selectedDate).getTime() && new Date(e.end_date).getTime() <= new Date(this.state.selectedDate).getTime() */}
										{jobs && jobs.filter((e) => new Date(moment(e.start_date, 'DD/MM/YYYY')) <= new Date(this.state.selectedDate) && new Date(moment(e.end_date, 'DD/MM/YYYY')) >= new Date(this.state.selectedDate)).map((event, index) => (
											<div className="event-card mb-3 event-gray-card p-3 text-left" key={index}>
												{/* {new Date(event.start_date).getTime()} < br />
												{new Date(event.end_date).getTime()} < br />
												{new Date(this.state.selectedDate).getTime()} */}
												<small className="text-uppercase">
													{(() => {
														if(event.job_status === "1"){
															return "Active";
														}else if(event.job_status === "2"){
															return "Closed"
														}else{
															return "Inactive"
														}
													})()}
												</small>
												<h3 className="mb-3">{event.title}</h3>
												{event.location ? (<p className="pc-info"><i className="fas fa-map-pin mr-2"></i> {event.location.street_address}</p>) : null}
												<p className="pc-info"><i className="far fa-calendar-alt mr-2"></i> {event.start_date} - {event.end_date}</p>
												<p className="pc-info"><i className="fas fa-dollar-sign mr-2"></i> ${event.pay && parseInt(event.pay.amount).toLocaleString()}</p>
												<p className="pc-info"><i className="fas fa-users mr-2"></i> {event.freelancer_booked} Applicants</p>
												{/* <div className="event-tags">
													<div className="ev-tag">Site Manager</div>
													<div className="ev-tag">Warden</div>
													<div className="ev-tag">Technician</div>
												</div> */}
		
												<Link rel="nofollow" to={`/f/job/${event.job_id}`} className="btn btn-block btn-hm-primary mt-4 text-uppercase">Show Details</Link>
		
											</div>
										))}

										<div className="event_blank text-secondary rounded p-2 bg-white text-center d-flex">
											<h5 className="m-auto">No event on selected date</h5>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div className='mt-md-5 mt-4'>
							<div className='row d-none d-md-flex'>
								<div className='col-md-6'>
									<Availability />
								</div>
								<div className='col-md-6'>
									<AvailabilityByDay date={getDate} ref={this.child} select={this.state.dateClick} />
								</div>
							</div>

							<div className='d-block section-tabs d-md-none'>
								<Tabs defaultActiveKey={'weekly'} variant="pills" id="uncontrolled-tab-example">
									<Tab eventKey="weekly" title="Weekly availability">
										<Availability />
									</Tab>
									<Tab eventKey="daily" title="Date overrides">
										<AvailabilityByDay date={getDate} ref={this.child2} select={this.state.dateClick} />
									</Tab>
								</Tabs>
							</div>
						</div>
					</div>
				</main>
			</div>
		);
	}
}

export default Calendar;