import React, { Component } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import GooglePlaceholder from "../../snippet/GoogleSearchLocator";
// import Geocode from "react-geocode";


import minus from '../../../assets/images/minus.png';
import plus from '../../../assets/images/plus.png';


import NeedHelp from "./NeedHelp";

// Geocode.setApiKey("AIzaSyCkHiUSCNalJaGs9LTpzCMpPMtIZ-EaSOA");

class PostStep2 extends Component {
    constructor(props){
        super(props);

        this.state= {
            fields: {},
            errors: {},
            startDate: '',
            endDate: '',
            startDateOrg: new Date().toLocaleDateString('en-GB') +' '+ new Date().toLocaleTimeString('en-GB'),
            endDateOrg: new Date().toLocaleDateString('en-GB') +' '+ new Date().toLocaleTimeString('en-GB'),
            dateError: "",
            freelancer_number: 1,
            LocationError: '',
            location: {},
            paramsId: '',
            jobPostData: {},
            locale: {
              'format': 'DD/MM/YYYY  hh:mm A'
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.passAddress = this.passAddress.bind(this);
    }

    handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();		
        if (this.validateForm()) {
            let eventDetails ={
                event_name: this.state.fields.event_name,
                event_startDate: this.state.startDateOrg,
                event_endDate: this.state.endDateOrg,
                event_desc: this.state.fields.event_desc,
                freelancer_number: this.state.freelancer_number,
                event_pay: this.state.fields.event_pay,
                event_pay_duration: this.state.fields.event_pay_duration,
                location: this.state.location
            }
            this.props.postData(eventDetails);
            // console.log(eventDetails)        
        } //this.validateForm
    }

    validateForm() {
      let fields = this.state.fields;
      let phoneError = this.state.phoneError;
      let LocationError = this.state.LocationError;
      let dateError = this.state.dateError;
      let errors = {};
      let formIsValid = true;
	
      // First Name error messages
      if (!fields["event_name"]) {
        formIsValid = false;
        errors["event_name"] = "*Please type event title.";
      }
    
      // event_desc
      if (!fields["event_desc"]) {
        formIsValid = false;
        errors["event_desc"] = "*Please type description.";
      }
    
      // event_pay
      if (!fields["event_pay"]) {
        formIsValid = false;
        errors["event_pay"] = "*Please enter amount.";
      } 
      if (!this.state.location.lat) {
        formIsValid = false;
        LocationError = "Please enter your location.";
      } 
      if (!this.state.startDate) {
        formIsValid = false;
        dateError = "Please select start and end date range.";
      } 
    
    
      this.setState({
        errors: errors,
        phoneError,
        LocationError,
        dateError
      });
      return formIsValid;
    }
    
    moveStep(){
        this.props.moveStep();
    }

    IncrementItem = () => {
        this.setState(prevState => {
          if(prevState.freelancer_number) {
            return {
                freelancer_number: prevState.freelancer_number + 1
            }
          }
        });
    }
    DecreaseItem = () => {
      this.setState(prevState => {
        if(prevState.freelancer_number > 1) {
          return {
            freelancer_number: prevState.freelancer_number - 1
          }
        } else {
          return {
            freelancer_number: 1
          }
        }
      });
    }

    handleEvent(event, picker) {
      this.setState({
        startDate: new Date(picker.startDate._d).toLocaleDateString('en-GB'),
        endDate: new Date(picker.endDate._d).toLocaleDateString('en-GB'),
        startDateOrg: new Date(picker.startDate._d).toLocaleDateString('en-GB') +' '+ new Date(picker.startDate._d).toLocaleTimeString('en-GB'),
        endDateOrg: new Date(picker.endDate._d).toLocaleDateString('en-GB') +' '+ new Date(picker.endDate._d).toLocaleTimeString('en-GB')
      });
      // console.log(new Date(picker.startDate._d).toLocaleDateString('en-GB'));
      // console.log(new Date(picker.endDate._d).toLocaleDateString('en-GB'));
    }

    async componentDidMount(){
      var myHeaders = new Headers();

      // console.log(new Date().toLocaleDateString('en-GB'))

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      try {
      await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_all_frequency`, requestOptions)
        .then(response => response.json())
        .then(result => {
          // console.log(result);
          this.setState({
            frequency: result.items,
            fields: {
              event_pay_duration: result.items[0].value,
              event_pay : 0
            }
          })
        })
        .catch(error => console.log('error', error));
      } catch (error) {
          console.log('error', error);
      }

        this.setState({
          startDate: new Date().toLocaleDateString('en-GB'),
          endDate: new Date().toLocaleDateString('en-GB'),
        });
    }

    passAddress = (value) =>{
      // console.log(value);
      if(value.lat){
        this.setState({
          location : value
        })
      }
    }

    render() {
        const frequency = this.state.frequency;
        // const jobPostData = this.state.jobPostData;
        // console.log(jobPostData);

        return (
          <div className="job-post-box">
            <div className="mb-4">
              <h2>
                A little about <br />
                the event
              </h2>
            </div>

            <div className="form-area">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group mb-md-4 mb-3 col-sm-6">
                    <div className="form-field">
                      <label htmlFor="eventName">Event Name</label>
                      <input
                        id="eventName"
                        type="text"
                        value={this.state.fields.event_name}
                        className="form-control"
                        name="event_name"
                        onChange={this.handleChange}
                        placeholder="Enter Event Title"
                      />
                    </div>
                    <div
                      className={`invalid-feedback text-right ${
                        !this.state.fields.event_name
                          ? this.state.errors.event_name
                            ? "d-block"
                            : null
                          : null
                      }`}
                    >
                      {this.state.errors.event_name}
                    </div>
                  </div>
                  <div className="form-group mb-md-4 mb-3 col-sm-6">
                    <div className="form-field">
                      <label htmlFor="eventDate">Event Dates &nbsp;</label>
                      <div className="d-flex align-items-center">
                        <i className="far mr-2 fa-calendar mb-1 fa-calendar-alt"></i>
                        <DateRangePicker 
                        initialSettings={{ startDate: new Date(), endDate: new Date(), minDate: new Date() }}
                        // initialSettings={{
                        //   timePicker: true, 
                        //   timePicker24Hour: true,
                        //   locale: {
                        //     format: 'M/DD hh:mm A'
                        //   }
                        // }}  
                        onEvent={this.handleEvent}>
                          <input type="text" className="form-control" value={this.state.startDate + '-' + this.state.endDate} />
                        </DateRangePicker>
                      </div>
                    </div>
                    <div
                      className={`invalid-feedback text-right ${
                        !this.state.startDate
                          ? this.state.dateError
                            ? "d-block"
                            : null
                          : null
                      }`}
                    >
                      {this.state.dateError}
                    </div>
                  </div>
                  <div className="form-group mb-md-4 mb-3 col-sm-9">
                    <div className="form-field">
                      <label htmlFor="eventDesc">Event Description</label>
                      <textarea
                        id="eventDesc"
                        className="form-control"
                        rows="4"
                        name="event_desc"
                        value={this.state.fields.event_desc}
                        onChange={this.handleChange}
                        placeholder="What are your event requirements?"
                      ></textarea>
                    </div>
                    <div
                      className={`invalid-feedback text-right ${
                        !this.state.fields.event_desc
                          ? this.state.errors.event_desc
                            ? "d-block"
                            : null
                          : null
                      }`}
                    >
                      {this.state.errors.event_desc}
                    </div>
                  </div>
                  <div className="form-group mb-md-4 mb-3 col-sm-3">
                    {/* <div className="form-field need-help d-flex align-items-center text-center justify-content-center">
                      Need help with event description?
                    </div> */}
                    <NeedHelp />
                  </div>
                  <div className="form-group mb-md-4 mb-3 col-sm-4">
                    <div className="form-field d-flex d-md-block align-items-center justify-content-between flex-wrap">
                      <label>Number of Freelancers Required</label>
                      <div className="input-group input-group-counter">
                        <div className="input-group-prepend">
                          <button
                            onClick={this.DecreaseItem}
                            className="btn p-0"
                            type="button"
                          >
                            <img src={minus} alt="minus" />
                          </button>
                        </div>
                        <input
                          type="text"
                          className="form-control text-center"
                          value={this.state.freelancer_number}
                          name="freelancer_number"
                          onChange={this.handleChange}
                        />
                        <div className="input-group-append">
                          <button
                            onClick={this.IncrementItem}
                            className="btn p-0"
                            type="button"
                          >
                            <img src={plus} alt="plus" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-md-4 mb-3 col-sm-8">
                    <div className="form-field">
                      <label>Pay</label>
                      <div className="input-group input-group-pay">
                        <div className="input-group-prepend form-control">
                          ${" "}
                          <input
                            type="number"
                             min="0"
                            className="form-control"
                            // placeholder="1000"
                            value={this.state.fields.event_pay}
                            name="event_pay"
                            onChange={this.handleChange}
                          />
                        </div>
                        <select
                          disabled={true}
                          className="form-control  form-control-select-disabled border-0 ml-2"
                          name="event_pay_duration"
                          onChange={this.handleChange}
                          value={this.state.fields.event_pay_duration}
                        >
                          {frequency && frequency.map((item, index) => (
                            <option value={item.value} key={index}>{item.label}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div
                      className={`invalid-feedback text-right ${
                        !this.state.fields.event_pay
                          ? this.state.errors.event_pay
                            ? "d-block"
                            : null
                          : null
                      }`}
                    >
                      {this.state.errors.event_pay}
                    </div>
                  </div>
                  <div className="form-group mb-md-4 mb-3 col-sm-6">
                    <div className="form-field">
                      <label>Job Location</label>
                      <GooglePlaceholder getAddress={(e) => this.passAddress(e)} />
                    </div>
                    <div
                      className={`invalid-feedback text-right ${
                        !this.state.location.lat
                        ? this.state.LocationError
                          ? "d-block"
                          : null
                        : null
                      }`}
                      >
                      {this.state.LocationError}
                      </div>
                  </div>
                  <div className="w-100"></div>
                  <div className="form-group mb-1 col-sm-6 order-sm-5">
                    <button
                      type="submit"
                      className="btn btn-hm-primary btn-block btn-lg mb-3  px-4 d-flex align-items-center"
                    >
                      Continue <i className="fas ml-auto fa-arrow-right"></i>
                    </button>
                  </div>
                  <div className="form-group mb-1 col-sm-6">
                    <button
                      onClick={() => this.moveStep()}
                      className="btn btn-dark btn-block btn-lg mb-3  px-4 d-flex align-items-center"
                    >
                      <i className="fas mr-auto fa-arrow-left"></i> Previous
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        );
    }
}

export default PostStep2;