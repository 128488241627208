import React, { Component } from 'react'
import update from 'react-addons-update';
import Item from './CalenderAvailabilityitem';
import ItemDelete from './CalenderAvailabilityitemDelete';

export default class CalenderAvailability extends Component {
    constructor(props){
        super(props);

        this.state= {
			weekDay: [
                {
                    day: '',
                    start: '',
                    end: ''
                }
            ],
            weekDayGet: [],
            allWeeks:[],
            message: '',
            override: false
        }
	}

    ItemsSave = async () => {
        console.log(this.state.weekDay[0].start);
        if(this.state.weekDay[0].start === '' || this.state.weekDay[0].end === ''){
            return null
        }
        try {
        let Auth = localStorage.getItem('auth_empplyFreeUID');

          var formdata = new FormData();
            formdata.append("endpoint", "set_availability");
            formdata.append("user_id", Auth);
            formdata.append("type", "date");
            formdata.append("availbility[0][day]",this.props.date ? this.props.date : '');
            formdata.append("availbility[0][start]",this.state.weekDay[0].end ? this.state.weekDay[0].start : '');
            formdata.append("availbility[0][end]",this.state.weekDay[0].end ? this.state.weekDay[0].end : '');

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            // console.log(
            //     this.props.date, this.state.weekDay[0].start, this.state.weekDay[0].end
            // )

            await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if(result.data.specific_date[0]){
                    this.setState({
                        weekDay : result.data.specific_date,
                        message: result.mesage
                    });
                      window.location.reload();
                }
            })
            .catch(error => console.log('error', error));
        } catch (error) {
            console.log('error', error);
        }
    }

    async getAlert(e) {
        var myHeaders = new Headers();
		//   myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };

          this.setState({
                weekDayGet : [],
            });
            
            try {
		let AuthFree = localStorage.getItem('auth_empplyFreeUID');

		  await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_availability&user_id=${AuthFree}&type=date`, requestOptions)
		  .then(response => response.json())
		  .then(result => {
                const arr = [];
                // console.log(result);
                if(result.data.specific_date[0]){
                    result.data.specific_date && result.data.specific_date.filter(item => {
                        if(item.day === e){
                            arr.push(item);
                            this.setState({
                                weekDayGet : arr,
                                weekDay : result.data.specific_date
                            });
                        }
                        return null
                    })
                }

                this.setState({
                    allWeeks: result.data.specific_date
                })
		  })
		  .catch(error => console.log('error', error));
        } catch (error) {
            console.log('error', error);
        }
    }

    componentDidMount(){
        this.getAlert(this.props.date);
    }

    addDate = () =>{
        this.setState({
            override: true
        })
    }

    render() {
        const week = this.state.weekDay;
        const weekGet = this.state.weekDayGet;

        // console.log(this.state.allWeeks);

        return (
            <div className="page-info">
                <h1>Set date  <span>overrides</span></h1>

                {this.state.override ? (
                    <>
                        <p className='mb-md-4 mb-3'>Click the date in the calendar you want to select.</p>

                        {this.props.select ? (
                            <>
                                <div className="availability-list mb-md-5 mb-4">
                                    <Item  
                                        weekDay={this.props.date} 
                                        getStart={(e) => this.setState({
                                            weekDay: update(week, {0: {start: {$set: e}}})
                                        })} 
                                        getEnd={(e) => this.setState({
                                            weekDay: update(week, {0: {end: {$set: e}}})
                                        })} 
                                        avail={weekGet[0] && weekGet[0].end ? true : false}
                                        start={weekGet[0] && weekGet[0].start} end={weekGet[0] && weekGet[0].end} />
                                </div>
                                {this.state.message && <p className="text-success d-block">{this.state.message}</p>}
                                {this.state.weekDay[0].start === '' && this.state.weekDay[0].end === '' && <p className="invalid-feedback d-block">Please choose time.</p>}
                                <button onClick={this.ItemsSave} className="btn btn-secondary mr-3 mb-3">Save</button>
                            </>
                        ) : null}
                    </>
                ) : (
                    <div className='pt-4'>
                        <button onClick={this.addDate} className="btn btn-secondary mr-3 mb-3">Add date overrides</button>
                    </div>
                )}

                <div className="availability-list mt-4">
                    {this.state.allWeeks && this.state.allWeeks.filter(id => id.end !== '').map((item, index) => (
                        <ItemDelete  
                            key={index}
                            date={item.day}
                            weekDay={item.day} 
                            getStart={(e) => this.setState({
                                weekDay: update(week, {0: {start: {$set: e}}})
                            })} 
                            getEnd={(e) => this.setState({
                                weekDay: update(week, {0: {end: {$set: e}}})
                            })} 
                            avail={item.end ? true : false}
                            start={item.start} end={item.end} />
                    ))}
                </div>

            </div>
        )
    }
}
