import { useState } from "react";

export default function HomeWork({data}){
    const [active, isActive] = useState('organiser');
    // console.log(data);
    const organizer = data[0] ?? {};
    const frelancer = data[1] ?? {};
    return(
        <div className="pt-100 pb-100">
            <div className="container container-xl">
                <div className="row">
                    <div className="col-lg-4 pr-xl-5">
                        <div className="section-home-title text-lg-left text-center">
                            <h2>How Does <br />EventMploy Work?</h2>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 col-sm-6 mb-4">
                                <button onClick={() => isActive('organiser')} className={`btn btn-hm btn-hm-work w-100 d-flex align-items-center justify-content-between ${active === 'organiser' && 'active'}`}>
                                    <span>For Organiser</span>
                                    <svg width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M43.9998 6.00007H33.9999V4.00002C33.9999 1.79399 32.206 0 29.9999 0H17.9999C15.7939 0 13.9999 1.7939 13.9999 4.00002V5.99998H4.00002C1.7939 6.00007 0 7.79397 0 10V16C0 18.2061 1.7939 20 4.00002 20H20V19C20 18.4472 20.4473 17.9999 21 17.9999H27C27.5528 17.9999 28 18.4472 28 19V20H44C46.2059 20 47.9998 18.2061 47.9998 16V10C47.9998 7.79397 46.2059 6.00007 43.9998 6.00007ZM29.9999 6.00007H17.9999V4.00002H29.9999V6.00007Z" fill="currentColor"/>
                                        <path d="M47.4451 21.0792C47.1044 20.9103 46.6971 20.9494 46.3963 21.1779C45.6854 21.716 44.8572 22.0001 43.9997 22.0001H27.9999V25.0001C27.9999 25.5529 27.5527 26.0002 26.9999 26.0002H20.9999C20.4472 26.0002 19.9999 25.5529 19.9999 25.0001V22.0001H4.00002C3.14258 22.0001 2.31449 21.716 1.60349 21.1779C1.30171 20.9474 0.895497 20.9083 0.554623 21.0792C0.214874 21.2483 0 21.5949 0 21.9748V36.0002C0 38.2062 1.7939 40.0002 4.00002 40.0002H43.9999C46.2059 40.0002 47.9999 38.2063 47.9999 36.0002V21.9748C47.9998 21.5949 47.785 21.2483 47.4451 21.0792Z" fill="currentColor"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="col-lg-12 col-sm-6 mb-4">
                                <button onClick={() => isActive('freelancer')} className={`btn btn-hm btn-hm-work w-100 d-flex align-items-center justify-content-between ${active === 'freelancer' && 'active'}`}>
                                    <span>For Freelancer</span>
                                    <svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.7425 5.94219C8.7425 2.66875 11.4112 0 14.6823 0C17.9558 0 20.6237 2.66875 20.6237 5.94141C20.6237 9.2125 17.955 11.8805 14.6823 11.8805C11.4112 11.8813 8.7425 9.2125 8.7425 5.94219ZM29.287 30.293C27.6245 20.9922 21.7159 14.118 14.6823 14.118C7.65109 14.118 1.7425 20.9922 0.0776541 30.293C-0.16844 31.6641 0.172966 32.9414 1.06515 34.0094C1.53 34.5633 2.07531 34.9797 2.68625 35.2539V25.4922C2.68625 24.2789 3.67844 23.2867 4.89172 23.2867H25.1527C26.3644 23.2867 27.3581 24.2789 27.3581 25.4922V34.8734C27.6995 34.6391 28.0159 34.3508 28.2995 34.0094C29.1941 32.9422 29.5331 31.6641 29.287 30.293ZM25.1527 24.575H4.89172C4.38937 24.575 3.97687 24.9867 3.97687 25.4922V39.0852C3.97687 39.5898 4.38859 40 4.89172 40H25.1527C25.655 40 26.0675 39.5906 26.0675 39.0852V25.4922C26.0675 24.9875 25.6558 24.575 25.1527 24.575Z" fill="currentColor"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* /.col-lg-5 */}
                    <div className="col-lg-8 pl-xl-5">
                        {active === 'organiser' && (
                            <>
                                <div className="embed-responsive embed-responsive-hm embed-responsive-16by9">
                                    <iframe className="embed-responsive-item" src={organizer?.youtube} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                </div>

                                <div className="row">
                                    {organizer?.steps?.map((item, index) => (
                                        <div className="col-sm-4 mt-sm-4 mt-3" key={index}>
                                            <div className="card-hm-counter">
                                                <span>{index + 1}</span>
                                                <h3>{item?.title}</h3>
                                                <p>{item?.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}                       

                        {active === 'freelancer' && (
                            <>
                                <div className="embed-responsive embed-responsive-hm embed-responsive-16by9">
                                    <iframe className="embed-responsive-item" src={frelancer?.youtube} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                </div>

                                <div className="row">
                                    {frelancer?.steps?.map((item, index) => (
                                        <div className="col-sm-4 mt-sm-4 mt-3" key={index}>
                                            <div className="card-hm-counter">
                                                <span>{index + 1}</span>
                                                <h3>{item?.title}</h3>
                                                <p>{item?.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        
                    </div>
                    {/* /.col-lg-7 */}
                </div>
            </div>
        </div>
    )
}