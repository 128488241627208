import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import Layout from "../../global/PrimaryLayoutFreelancer";

import GetRating from '../snippet/GetRating';
import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';

class MyRating extends Component {
	constructor(props){
        super(props);

        this.state= {
			starRating : {},
			planStatus:1,
			loader: false
        }
	}
	
	async componentDidMount(){
		var myHeaders = new Headers();
		  myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
  
		  var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		  };
		  
		  try {
			let AuthFree = localStorage.getItem('auth_empplyFreeUID');

			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_average_rating_v2&user_id=${AuthFree}`, requestOptions)
			.then(response => response.json())
			.then(result => {
				//   console.log(result);
				this.setState({
					starRating : result,
					loader: true,
					planStatus : result.subscription_status
				})
			})
			.catch(error => console.log('error', error));
			} catch (error) {
				console.log('error', error);
			}

		//  fetch(`${process.env.REACT_APP_APIURL}?endpoint=check_status&user_id=${AuthFree}`, requestOptions)
		//   .then(response => response.json())
		//   .then(result => {
		// 	  this.setState({
		// 		//   planStatus : result.data
		// 	  });
		//   })
		//   .catch(error => console.log('error', error));
	}
	render() {
		const starRating = this.state.starRating;
		const profileStatus = this.state.planStatus;

		if(profileStatus === 0){
			return(
				<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer my rating" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>ratings</span></h1>
								<p>EventMploy uses a 2-way rating system where Freelancers and Organisers can both leave a rating after working together. The rating below is based on all of the reviews received.</p>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 mb-md-5 mb-4">
								<div className="blank-area text-center">
									<h3>You have no <span>active plan</span>.</h3>
									<p><small>Please buy plan <Link rel="nofollow" className="text-primary" to="/f/membership">here</Link>!</small></p>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			)
		}

		return (
			<div>
				<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Freelancer my rating" />
				<main className="page-dashboard">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-10 col-xl-7">
								<h1>My <span>ratings</span></h1>
								<p>EventMploy uses a 2-way rating system where Freelancers and Organisers can both leave a rating after working together. The rating below is based on all of the reviews received.</p>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-6 mb-4">
								<div className="chat-contact rating-box position-relative w-100 d-flex align-items-center">
									<span className="contact-auth">{starRating.first_name && starRating.first_name.slice(0, 1)}{starRating.last_name && starRating.last_name.slice(0, 1)}</span>
									<div className="contact-name">
										<strong className="text-truncate w-100">{starRating.userFullName}</strong>
										{starRating.mesage}
									</div>
									<div className="contact-review text-center">
										<strong>{starRating.avg_rating}</strong>
										<GetRating starRating={starRating.avg_rating} />
										<p>Based on {starRating.total_reviews} review{starRating.total_reviews > 1 ? 's' : ''}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		);
	}
}

export default MyRating;