import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import dotsVertical from '../../../assets/images/dots-vertical.png';

const Events = (props) => {
    // const [data, setData] = useState([]);
    const [auth, setAuth] = useState(false);

    // const lat = parseFloat(props?.lat);
    // const lng = parseFloat(props?.lng);

    

    // const latMin = lat - 0.2;
    // const latMax = lat + 0.2;

    // const lngMin = lng - 0.2;
    // const lngMax = lng + 0.2;
    // console.log(lat,  "||" , lng);
    // console.log(latMin, latMax,  "||" , lngMin, lngMax);

    useEffect(() => {
        let AuthFree = localStorage.getItem('auth_empplyFreeUID');

        if(AuthFree){
            setAuth(true);
        }
        // var myHeaders = new Headers();
        // myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");

        // var requestOptions = {
        // method: 'GET',
        // headers: myHeaders,
        // redirect: 'follow'
        // };

        // let AuthFree = localStorage.getItem('auth_empplyFreeUID');

        // fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_all_jobs`, requestOptions)
        // .then(async response => await response.json())
        // .then(result => {
        //     setData(result.jobs.reverse());
        //     // console.log(result.jobs.reverse());
        //     if(AuthFree){
        //         setAuth(true);
        //     }
        //     // console.log(props.location);
        // })
        // .catch(error => console.log('error', error));
    }, [])
    // console.log(data);
    // console.log(data.filter(e => e.location && ((e.location.latitude > latMin && e.location.latitude < latMax) && (e.location.longitude > lngMin && e.location.longitude < lngMax))));

    const dataFilter = props?.dataFilter ?? [];
    const data = props?.data ?? [];

    if(props.location){
        return(
            dataFilter.length > 0 && dataFilter?.map((event, index) => (
                <div className="event-card event-blue-card" key={index}>
                    <h3>
                    {auth ? (
                        <Link rel="nofollow" to={"/f/job/" + event.job_id} className="text-white">{event.title}</Link>
                    ):(
                        <Link to={"/f/sign-up-today-and-find-your-first-job"} className="text-white">{event.title}</Link>
                    )}
                    </h3>
                    {event.location && event.location.street_address && <p><i className="fas fa-map-pin mr-2"></i> {event.location.street_address !== '0' ? event.location.street_address : event.location.suburb + ' ' + event.location.state +', '+ event.location.country }</p>}
                    {/* {event.start_date && <p><i className="far fa-calendar-alt mr-2"></i> {formatDate(event.start_date)} - {formatDate(event.end_date)}</p>} */}
                    {event.start_date && <p><i className="far fa-calendar-alt mr-2"></i> {event.start_date} - {event.end_date}</p>}
                    {event.pay && <p><i className="fas fa-dollar-sign mr-2"></i> ${parseInt(event.pay.amount).toLocaleString()}</p>}
                    <div className="event-tags">
                        {event?.skills?.length > 0 && event?.skills?.map((skill, index) => (
                            <div className="ev-tag" key={index} dangerouslySetInnerHTML={{__html: skill?.name}} />
                        ))}
                    </div>
                    <img src={dotsVertical} className="event-dots event-dots-top" alt="dots" />
                </div>
            ))
        )
    }

    return(
        <div className='row'>
            {data && data.map((event, index) => (
                event?.pay?.amount > 29 &&
                <div className='col-md-4 col-sm-6 mb-md-4 mb-3'>
                    <div className="event-card event-blue-card" key={index}>
                        <h3>
                            {auth ? (
                                <Link rel="nofollow" to={"/f/job/" + event.job_id} className="text-white">{event.title}</Link>
                            ):(
                                <Link to={"/f/sign-up-today-and-find-your-first-job"} className="text-white">{event.title}</Link>
                            )}
                        </h3>
                        {event?.location && event?.location?.street_address && <p><i className="fas fa-map-pin mr-2"></i> {event.location.street_address !== "0" ? event.location.street_address : event.location.suburb+ ' ' + event.location.state +', '+event.location.country}</p>}
                        {/* {event?.start_date && <p><i className="far fa-calendar-alt mr-2"></i> {event.start_date} - {event.end_date}</p>} */}
                        {event?.pay && <p><i className="fas fa-dollar-sign mr-2"></i> ${parseInt(event.pay.amount).toLocaleString()}</p>}
                        <div className="event-tags">
                            {event?.skills?.length > 0 &&  event?.skills?.map((skill, index) => (
                                <div className="ev-tag" key={index} dangerouslySetInnerHTML={{__html: skill.name}} />
                            ))}
                        </div>
                        <img src={dotsVertical} className="event-dots event-dots-top" alt="dots" />
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Events;

// const formatDate = (date) => {
//     var d = new Date(date),
//         month = '' + (d.getMonth() + 1),
//         day = '' + d.getDate(),
//         year = d.getFullYear();

//     if (month.length < 2) 
//         month = '0' + month;
//     if (day.length < 2) 
//         day = '0' + day;

//     return [day, month, year].join('/');
// }