import React from 'react'
import Logo from '../../assets/images/logo.svg';

export default function Loader({loader}) {
  return (
    <div className={`page_loader w-100 d-flex position-fixed ${loader ? 'loader-off' : '' }`}>
        <img src={Logo} width={274} height={65} className="m-auto saturate" alt="logo"/>
    </div>
  )
}
