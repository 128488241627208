import React, { Component } from 'react'
import PhoneInput from 'react-phone-input-2';

export default class SupplierForm extends Component {
    constructor(props){
        super(props);

        this.state= {
            phone: '',
            phoneError: '',
            fields: {},
            errors: {},
			success:'',
            formLoader: false,
            imageAlt: '',
            startDate: '',
            endDate: '',
            startDateOrg: new Date().toLocaleDateString('en-GB'),
            endDateOrg: new Date().toLocaleDateString('en-GB'),
            dateError: "",
            supplier: {}
		}
		
		this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
	}

    handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
	}

    handleSubmit = async (e) => {
        e.preventDefault();
		
        if (this.validateForm()) {
          this.setState({
            formLoader: true
          })

			var myHeaders = new Headers();
            myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("endpoint", "contact_supplier");
            urlencoded.append("user_id", this.props.userID);
            urlencoded.append("first_name", this.state.fields.first_name);
            urlencoded.append("last_name", this.state.fields.last_name);
            urlencoded.append("company", this.state.fields.company_name);
            urlencoded.append("email", this.state.fields.email);
            urlencoded.append("phone", this.state.phone);
            urlencoded.append("event_date", this.state.fields.date);
            urlencoded.append("guest_numbers", this.state.fields.guests);
            urlencoded.append("message", this.state.fields.messages);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                let fields = this.state.fields;
                fields["first_name"] = "";
                fields["last_name"] = "";
                fields["company_name"] = "";
                fields["email"] = "";
                fields["phone"] = "";
                fields["date"] = "";
                fields["guests"] = "";
                fields["messages"] = "";

                this.setState({
                    formLoader: false,
                    success: result.mesage,
                    fields
                });

                setTimeout(() => {
                  window.location.reload();
                }, 800);

            })
            .catch(error => console.log('error', error));
        } //this.validateForm
	}

    validateForm() {
		let phoneError = this.state.phoneError;
        // let dateError = this.state.dateError;
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		// Email Address error messages
			if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "*Please enter your Email Address.";
			}
		
			if (typeof fields["email"] !== "undefined") {
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,15}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (!pattern.test(fields["email"])) {
                    formIsValid = false;
                    errors["email"] = "*Please enter valid Email Address.";
                }
            }
		
		// error messages
        if (!fields["first_name"]) {
            formIsValid = false;
            errors["first_name"] = "*Please enter your First Name.";
        }
		
        // error messages
        if (!fields["last_name"]) {
            formIsValid = false;
            errors["last_name"] = "*Please enter your Last Name.";
        }
		
        // error messages
        if (!fields["messages"]) {
            formIsValid = false;
            errors["messages"] = "*Please type your message.";
        }

        // error messages
        if (!fields["company_name"]) {
            formIsValid = false;
            errors["company_name"] = "*Please type your company name.";
        }

        // error messages
        if (!fields["date"]) {
            formIsValid = false;
            errors["date"] = "*Please type your company name.";
        }

        // error messages
        if (!fields["guests"]) {
            formIsValid = false;
            errors["guests"] = "*Please type your company name.";
        }

        if (!this.state.phone) {
			formIsValid = false;
			phoneError = "Please enter your phone number.";
		}

        // if (!this.state.startDate) {
        //     formIsValid = false;
        //     dateError = "Please select start and end date range.";
        //   } 
		
		
        this.setState({
            errors: errors,
            phoneError,
            // dateError
        });
    
        return formIsValid;
	}

    async componentDidMount(){
        try {
		 let Auth = localStorage.getItem('auth_empplyFreeUID') || localStorage.getItem('auth_empplyOrgUID') || localStorage.getItem('auth_empplySplierUID');
          var formdata = new FormData();
            formdata.append("endpoint", "supplier_detail");
            formdata.append("user_id", this.props.userID);
            formdata.append("current_user_id", Auth);

            var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
            };

            await fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result);
              this.setState({
                  supplier : result.user_detail
              })
            })
            .catch(error => console.log('error', error));
        } catch (error) {
            console.log('error', error);
        }

	}

    
    render() {
        const supplier = this.state.supplier;
        // console.log(supplier);

        return (
            <div className="supplier-form">
                <div className="pb-4">
                    <h3>Contact this EventMploy Supplier</h3>
                    <p className="d-none"><strong>{supplier.phoneNumber}</strong></p>
                    <p className="d-none"><a href={`mailto:${supplier.emailAddress}`}>{supplier.emailAddress}</a></p>
                </div>

                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="form-group col-sm-6">
                            <div className="form-field">
                                <label>Add first name</label>
                                <input
                                type="text"
                                className="form-control"
                                name="first_name"
                                onChange={this.handleChange}
                                value={this.state.fields.first_name}
                                placeholder="Enter your First Name"
                                />
                            </div>
                            <div
                                className={`invalid-feedback text-right ${
                                !this.state.fields.first_name
                                ? this.state.errors.first_name
                                    ? "d-block"
                                    : null
                                : null
                                }`}
                                >
                                {this.state.errors.first_name}
                            </div>
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="form-field">
                                <label>Add last name</label>
                                <input
                                type="text"
                                className="form-control"
                                name="last_name"
                                onChange={this.handleChange}
                                value={this.state.fields.last_name}
                                placeholder="Enter your Last Name"
                                />
                            </div>
                            <div
                                className={`invalid-feedback text-right ${
                                !this.state.fields.last_name
                                ? this.state.errors.last_name
                                    ? "d-block"
                                    : null
                                : null
                                }`}
                                >
                                {this.state.errors.last_name}
                            </div>
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="form-field">
                                <label>Add company name</label>
                                <input
                                type="text"
                                className="form-control"
                                name="company_name"
                                onChange={this.handleChange}
                                value={this.state.fields.company_name}
                                placeholder="Enter your company name"
                                />
                            </div>
                            <div
                                className={`invalid-feedback text-right ${
                                !this.state.fields.company_name
                                ? this.state.errors.company_name
                                    ? "d-block"
                                    : null
                                : null
                                }`}
                                >
                                {this.state.errors.company_name}
                            </div>
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="form-field">
                                <label>Add email address</label>
                                <input
                                type="text"
                                className="form-control"
                                name="email"
                                onChange={this.handleChange}
                                value={this.state.fields.email}
                                placeholder="Enter your email address"
                                />
                            </div>
                            <div
                                className={`invalid-feedback text-right ${
                                    this.state.errors.email
                                    ? "d-block"
                                    : null
                                }`}
                                >
                                {this.state.errors.email}
                            </div>
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="form-field">
                                <label>Add phone number</label>
                                <PhoneInput
                                    inputExtraProps={{
                                        required: true
                                    }}
                                    defaultCountry={"au"}
                                    enableAreaCodeStretch
                                    enableAreaCodes={true}
                                    value={this.state.phone}
                                    dropdownClass="form-shadow form-radius border-0"
                                    inputClass="form-shadow form-radius border-0"
                                    onChange={(phone) => this.setState({ phone })}
                                />
                            </div>
                            <div
								className={`invalid-feedback text-right ${
									!this.state.phone
									? this.state.phoneError
										? "d-block"
										: null
									: null
								}`}
								>
								{this.state.phoneError}
							</div>
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="form-field">
                                <label>Add event date</label>
                                <input
                                type="date"
                                className="form-control"
                                name="date"
                                required={true}
                                onChange={this.handleChange}
                                value={this.state.fields.date}
                                placeholder="Enter your event date"
                                />
                            </div>
                            <div
								className={`invalid-feedback text-right ${
									!(new Date(this.state.fields.date).getTime() > new Date().getTime())
									? this.state.fields.date
										? "d-block"
										: null
									: null
								}`}
								>
								Please enter valid date
							</div>
                            {/* <div
                                className={`invalid-feedback text-right ${
                                !this.state.fields.date
                                ? this.state.errors.date
                                    ? "d-block"
                                    : null
                                : null
                                }`}
                                >
                                {this.state.errors.date}
                            </div> */}
                        </div>
                        <div className="form-group col-sm-6">
                            <div className="form-field">
                                <label>Add number of guests</label>
                                <input
                                type="number"
                                min="0"
                                className="form-control"
                                name="guests"
                                onChange={this.handleChange}
                                value={this.state.fields.guests}
                                placeholder="Enter your number of guests"
                                />
                            </div>
                            <div
                                className={`invalid-feedback text-right ${
                                !this.state.fields.guests
                                ? this.state.errors.guests
                                    ? "d-block"
                                    : null
                                : null
                                }`}
                                >
                                {this.state.errors.guests}
                            </div>
                        </div>
                        <div className="form-group col-sm-12">
                            <div className="form-field">
                                <label>Type your Message</label>
                                <textarea
                                rows="5"
                                className="form-control form-control-size"
                                name="messages"
                                onChange={this.handleChange}
                                value={this.state.fields.messages}
                                placeholder="Type your Message here..."
                                ></textarea>
                            </div>
                            <div
                                className={`invalid-feedback text-right ${
                                !this.state.fields.messages
                                ? this.state.errors.messages
                                    ? "d-block"
                                    : null
                                : null
                                }`}
                                >
                                {this.state.errors.messages}
                            </div>
                        </div>
                        <div className="form-group col-lg-4 col-sm-5">
                        <button
                            type="submit"
                            className="btn btn-md-2x btn-text-space btn-hm-primary mb-2 btn-block btn-md "
                            disabled={this.state.formLoader ? true : false}>{this.state.formLoader ? 'Sending...' : 'Submit'}
                        </button>
                        {this.state.success && <span className="text-success d-block">{this.state.success}</span>}
                        </div>
                    </div>
                    </form>
            </div>
        )
    }
}
