import { useEffect, useState } from "react";
import HomeBanner from "./snippet/homeNew/HomeBanner";
import HomeCards from "./snippet/homeNew/HomeCards";
import HomeInstagram from "./snippet/homeNew/HomeInstagram";
import HomePosts from "./snippet/homeNew/HomePosts";
import HomeSignup from "./snippet/homeNew/HomeSignup";
import HomeTestimonials from "./snippet/homeNew/HomeTestimonials";
import HomeUpcoming from "./snippet/homeNew/HomeUpcoming";
import HomeWork from "./snippet/homeNew/HomeWork";
import Loader from "./snippet/Loader";
import SEOPage from './snippet/SEOPage';

const HomePageNew = () => {
    // const [imageAlt, setImageAlt] = useState()
	// const [background, setBackground] = useState()
	const [signup, setSignup] = useState({})
	const [loader, setloader] = useState(false)
	const [slider, setSlider] = useState(null)
	const [blogs, setBlogs] = useState([])
	const [webinar, setWebinar] = useState([])
	const [upcomingEvents, setUpcomingEvents] = useState(null)
	const [advertiseCards, setadvertiseCards] = useState([])
	const [howWorks, setHowWorks] = useState([])
	const [instagram, setInstagram] = useState()
	// const location = useLocation()
	// console.log(location.pathname);
	
    useEffect(() => {
        async function fetchData() {
            var myHeaders = new Headers();
		
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            try {
                // console.log(process.env.REACT_APP_APIURL)
                await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=Home`, requestOptions)
                .then(async response => await response.json())
                .then(result => {
                    // console.log(result);
                    // setImageAlt(result.alt_text);
                    // setBackground(result.background_image);
                    setSlider(result);
                    setBlogs(result?.blog_posts);
                    setWebinar(result?.webinar_posts);
                    setUpcomingEvents(result?.related_jobs);
                    setadvertiseCards(result?.advertise_area);
                    setSignup(result?.sign_up_today);
                    setHowWorks([result?.how_we_works_eo_section, result?.how_we_works_freelancer_section])
                    setInstagram(result?.instagram_gallery);
                    setloader(true);
                })
                .catch(error => console.log('error', error));
            } catch (error) {
                console.log('error', error);
            }
        }
        fetchData();
    }, [])

    return (
        <div>
            <SEOPage pageTitle="Home" />
            <Loader loader={loader} />
            <HomeBanner data={slider} />
            <HomeCards data={advertiseCards} />
            <HomeUpcoming events={upcomingEvents} />
            <HomeSignup data={signup ?? {}} />
            <HomeWork data={howWorks ?? []} />
            <HomeTestimonials />
            <HomePosts data={[blogs, webinar]} />
            <HomeInstagram data={instagram} />
        </div>
    );
}

export default HomePageNew;