import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import { HelmetProvider } from 'react-helmet-async';

const SEO = ({pageTitle}) => {
    const [content, setContent] = useState();

    useEffect(() => {
        async function fetchData() {
            if(pageTitle){
                var myHeaders = new Headers();
                myHeaders.append("eventmploy", "eventmploy@Alkye@2020");
                myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");
                
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                // 
                try{
                    await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=${pageTitle}`, requestOptions)
                    .then(async response => await response.json())
                    .then(result => {
                        if(result.seo){
                            setContent(result.seo);
                        }else{
                            setContent({
                                title: "Eventmploy",
                                description: "Eventmploy Description",
                                keywords: "Eventmploy, keywords"
                            })
                        }
                        // console.log(result.seo);
                    })
                    .catch(error => console.log('error', error));
                } catch (error) {
                    console.log('error', error);
                }
            }
        }
        fetchData();
    }, [pageTitle])

    return(
        <HelmetProvider>
            <Helmet defer={false}>
                <meta charSet="utf-8" />
                <meta name="robots" content="nofollow" />
                <title>{content && content.title}</title>
                <meta name="description" content={content && content.description} />
                <meta name="keywords" content={content && content.keywords} />
                <link rel="canonical" href="https://www.eventmploy.com/" />
                <meta key="robots" name="robots" content="noindex,nofollow" />
                <meta key="googlebot" name="googlebot" content="noindex,nofollow" />
                {/* <meta name="robots" content="index, follow" /> */}
            </Helmet>
        </HelmetProvider>
    )
}

export default SEO;