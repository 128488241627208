import React, { Component } from 'react';

import Footer from '../components/snippet/Footer';

// snipet
// import DashboardFreeHeader from '../components/freelancer/DashboardHeader';
// import Search from '../components/snippet/SearchDashboardFree';

// SEO
// import SEOPage from '../components/snippet/SEOPage';
// import SEOPost from '../components/snippet/SEOPost';

import loadable from '@loadable/component'

const SEOPage = loadable(() => import('../components/snippet/SEOPage'));
const SEOPost = loadable(() => import('../components/snippet/SEOPost'));

class Openlayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: true
        };
    }

    render() {
        // const url = window.location.pathname;
        // const n = url.lastIndexOf('/');
        // const result = url.substring(n + 1);
        // console.log(result);
        // console.log(url.slice(0, -result.length));

        // const getPath = Auth ? '' : url.slice(0, -result.length);
        // console.log(getPath);
        // if(getPath === '/personalized-plan/'){
        //     return <Redirect to={{pathname: "/sign-in", state: "/personalized-plan/" + result}} />
        // }

        return (
            <div id="wrapper">
                {this.props.WpPageTitle ? <SEOPage pageTitle={this.props.WpPageTitle} /> : null}
                {this.props.WpPostSEO ? <SEOPost WpPostObj={this.props.WpPostSEO} /> : null}
                
                {this.props.children}

                
                <Footer />

            </div>
        );
    }
}

export default Openlayout;