import React, { Component } from 'react';

import ContactForm from './snippet/SupplierContactAdmin';
import Loader from '../snippet/Loader';
import SEOPage from '../snippet/SEOPageNoIndex';

class ContactAdmin extends Component {
	constructor(props){
        super(props);

        this.state= {
			loader: false
        }
	}
	componentDidMount(){
		setTimeout(() => {
			this.setState({
				loader: true
			})
		}, 500);
	}
	render() {
		return(
			<div>
			<Loader loader={this.state.loader} />
			<SEOPage pageTitle="Supplier Contact Admin" />
				<main className="page-dashboard pt-0">
					<div className="container-fluid">
						<div className="row page-info mb-4">
							<div className="col-lg-5">
								<h1>Contact <span>Support</span></h1>
								<p>Have a question, or want to update your current page?  Use the form to let us know and we will be in touch soon.</p>
							</div>
						</div>

						<ContactForm />
					</div>
				</main>
			</div>
		)
	}
}

export default ContactAdmin;