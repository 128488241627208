import React, { Component } from 'react';
import {
    Link, Redirect, withRouter
  } from "react-router-dom";
  import ReCAPTCHA from "react-google-recaptcha";

import Loader from './snippet/Loader';
import SEOPage from './snippet/SEOPage';
import signup from '../assets/images/signup.png';
import signupMob from '../assets/images/signup-mob.png';

class Login extends Component {
	constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            redirect: false,
            subscribe: 'inactive',
            error: '',
            errorPass: '',

            pageHeading: '',
            pageContent: '',
            backgroundImage: '',
            pageData: {},
            response: {},
			formLoader: false,
			recaptch: '',
			recaptchValid: false,
			imageAlt: '',
            loader: false,
			role: '',
			jobId: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

	onChange = (value) => {
		// console.log("Captcha value:", value);
		this.setState({
			recaptch: value,
			recaptchValid: false
		})
	}


    handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })
    }


    handleSubmit = (e) => {
        e.preventDefault();

        if (this.validateForm()) {
			this.setState({
				formLoader : true
			})

			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
			myHeaders.append("Cookie", "wordpress_logged_in_b7bbebb031677a6205b4b951f21a824a=freelancer%40gmail.com%7C1611296485%7CvfF2HwnxdnzdSwzsl7mfKkN6CtqWue5iAh1wE9VfHLy%7Ccc927f7bacdea23190b35afb3124545b5b113a4e4b3b1a4fed1bc1a546fdea09");

			var urlencoded = new URLSearchParams();
			urlencoded.append("endpoint", "login");
			urlencoded.append("email", this.state.fields.email);
			urlencoded.append("password", this.state.fields.password);

			var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: urlencoded,
			redirect: 'follow'
			};

			fetch(`${process.env.REACT_APP_APIURL}/`, requestOptions)
			.then(async response => await response.json())
			.then(result => {
				// console.log(result)
				setTimeout(() => {
					if(result.user_id){
						this.setState({
							response: result,
							formLoader: false
						});
					}

					if(result.role === "freelancer"){
						localStorage.setItem('auth_empplyFreeUID', result.user_id);
						localStorage.removeItem("auth_empplySplierUID");
						localStorage.removeItem("auth_empplyOrgUID");
						this.setState({
							role : "f"
						})
					}
					if(result.role === "event_organiser"){
						localStorage.setItem('auth_empplyOrgUID', result.user_id);
						localStorage.removeItem("auth_empplySplierUID");
						localStorage.removeItem("auth_empplyFreeUID");
						this.setState({
							role : "e"
						})
					}
					if(result.role === "supplier"){
						localStorage.setItem('auth_empplySplierUID', result.user_id);
						localStorage.removeItem("auth_empplyFreeUID");
						localStorage.removeItem("auth_empplyOrgUID");
						this.setState({
							role : "s"
						})
					}
				}, 300)

				if(result.error_string){
					this.setState({
						error: result.error_string,
						formLoader: false
					});
				}

				if(result.error){
					this.setState({
						errorPass: result.error,
						formLoader: false
					});
				}
			})
			.catch(error => console.log('error', error));

        }
    }

	async componentDidMount(){
		const { location } = this.props;
		const query = new URLSearchParams(location.search);
		const jobId = query.get("job");
		this.setState({ jobId });

		var myHeaders = new Headers();
		
		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};
		// 
		try {
			await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=Let’s Get You Logged-In`, requestOptions)
			.then(response => response.json())
			.then(result => {
				this.setState({
					pageData: result,
					loader : true
				});

				if(result.alt_text){
					this.setState({
						imageAlt : result.alt_text
					})
				}
			})
			.catch(error => console.log('error', error));
		} catch (error) {
			console.log('error', error);
		}

		// await fetch(`${process.env.REACT_APP_APIURL}?endpoint=get_page&page_title=Let’s Get You Logged-In`, requestOptions)
		// .then(response => response.json())
		// .then(result => {
		// 	// console.log(result.alt_text);
		// 	if(result.alt_text){
		// 		this.setState({
		// 			imageAlt : result.alt_text
		// 		})
		// 	}
		// })
		// .catch(error => console.log('error', error));
	  }


    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
    
        // Email Address error messages
        if (!fields["email"]) {
          formIsValid = false;
          errors["email"] = "*Please enter your Email Address.";
        }
    
        if (typeof fields["email"] !== "undefined") {
			var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,15}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(fields["email"])) {
            formIsValid = false;
            errors["email"] = "*Please enter valid Email Address.";
          }
        }
    
    
        // Password error messages
        if (!fields["password"]) {
          formIsValid = false;
          errors["password"] = "*Please enter your Password.";
        }
    
        // Recaptcha error messages
        if (!this.state.recaptchValid) {
          formIsValid = true;
        }
    
    
        this.setState({
          errors: errors,
		  recaptchValid: true
        });
        return formIsValid;
	  }
	  
	render() {
		const { jobId, role } = this.state;

		// console.log(`/${role}/job/${jobId}`);
		// if(this.state.response.result && this.state.response.result.role === "freelancer"){
		// 	return <Redirect to="/f/dashboard" />;
		// }

		// if(this.state.response.result && this.state.response.result.role === "event_organiser"){
		// 	return <Redirect to="/e/dashboard" />;
		// }

		// console.log(this.state.response);
		if(this.state.response.user_id){
			if(role){
				return jobId ? <Redirect push to={`/${role}/job/${jobId}`} /> : <Redirect push to="/" />;
			}
			// if(this.state.response.role === "freelancer"){
			// 	return <Redirect to="/" />;
			// }
			
			// if(this.state.response.role === "supplier"){
			// 	return <Redirect to="/" />;
			// }
			
			// if(this.state.response.role === "event_organiser"){
			// 	return <Redirect to="/" />;
			// }
		}

		return (
			<div>
            	<Loader loader={this.state.loader} />
				<SEOPage pageTitle="Let’s Get You Logged-In" />
				<main className="page-access">
					<div className="page-image" style={{backgroundImage: `url(${this.state.pageData && this.state.pageData.background_image ? this.state.pageData.background_image : signup})`}}>
						<img src={this.state.pageData && this.state.pageData.background_image ? this.state.pageData.background_image : signupMob} className="w-100 d-block d-md-none" alt={this.state.imageAlt} />
					</div>
					<div className="container p-0 container-xxl">
						<div className="row no-gutters">
							<div className="col-lg-6">
								<div className="page-content-inner px-4">
									<h1>Let’s get you <span>logged-in</span></h1>
									{/* <p>Log in to post jobs, update availability, check job offers and notifications.</p> */}
									<div dangerouslySetInnerHTML={{__html: this.state.pageData && this.state.pageData.content}} />
								
									<div className="form-area pt-md-3 pt-2">
										<form onSubmit={this.handleSubmit}>
											<div className="form-row">
												<div className="form-group col-12">
													<div className="form-field">
														<label htmlFor="emailid">Email</label>
														<input 
														onChange={this.handleChange}
														type="email" 
														className="form-control" 
														placeholder=""
														id="emailid" 
														name="email"
														/>
													</div>
													<div className={`invalid-feedback ${this.state.errors.email ? 'd-block' : null}`}>{this.state.errors.email}</div>
													<div className={`invalid-feedback ${this.state.error ? 'd-block' : null }`}>{this.state.error}</div>

												</div>
												<div className="form-group col-12">
													<div className="form-field">
														<label htmlFor="passwordID">Password</label>
														<input 
														onChange={this.handleChange}
														type="password" 
														id="passwordID"
														className="form-control" 
														placeholder="" 
														name="password"
														/>
													</div>
													<div className={`invalid-feedback ${this.state.errors.password ? 'd-block' : null }`}>{this.state.errors.password}</div>
													<div className={`invalid-feedback ${this.state.errorPass ? 'd-block' : null }`}>{this.state.errorPass}</div>
												</div>
												<div className="form-group col-12">
													<Link to="/forgot-password" className="btn-link text-primary">Forgot password?</Link>
												</div>
												<div className="form-group col-12">
													<ReCAPTCHA
														sitekey="6LeHF6EaAAAAAMGIeMxqLoFXXoZBua98d3rVryHa"
														onChange={this.onChange}
													/>
													{!this.state.recaptch && <div className={`invalid-feedback ${this.state.recaptchValid ? 'd-block' : null }`}>*Please check you are human.</div>}
												</div>
												<div className="form-group col-12">
													{/* <Link to="/dashboard"> */}
														{this.state.recaptch ? (
															<button type="submit" className="btn btn-hm-primary btn-block btn-lg mb-3 px-4 d-flex align-items-center" disabled={this.state.formLoader ? true : false}>{this.state.formLoader ? 'Sending...' : 'Continue'} <i className="fas ml-auto fa-arrow-right"></i></button>
														) : (
															<button type="button" onClick={() => this.validateForm()} className="btn btn-hm-primary btn-block btn-lg mb-3 px-4 d-flex align-items-center">{this.state.formLoader ? 'Sending...' : 'Continue'} <i className="fas ml-auto fa-arrow-right"></i></button>
														)}
														{/* </Link> */}

													<p>The information you provide on this form will be retained and handled by EventMploy in accordance with our <Link className="text-primary" target="_blank" to="/privacy-policy">Privacy Policy</Link>.</p>
													<p>Don’t have an account? <Link className="text-primary" to="/get-started">Sign-up</Link></p>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		);
	}
}

export default withRouter(Login);