// import Instagram from '../../../assets/images/instagram-images.webp';

export default function HomeInstagram({data}){
    return(
        <div className="section-instagram">
            <div className="container">
                <div className="section-home-title text-center">
                    <h2>{data?.title}</h2>
                </div>

                 {/* <img src={Instagram} width={1170} height={225} alt="Instagram" className="img-fluid w-100"/> */}
                <div className="instagram-images justify-content-center">
                    {data?.images?.map((item, index) => (
                        <div key={index}><img src={item?.url} width={1170} height={225} alt={item?.alt} className="img-fluid w-100"/></div>
                    ))}
                </div>
            </div>
        </div>
    )
}